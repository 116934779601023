import { api } from '../../api/apiSlice';

const customerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerList: builder.query({
      query: () => ({
        url: `/customers/all-customers`,
      }),
      providesTags: ['Customers'],
    }),
    getCustomerSearchResult: builder.mutation({
      query: (data) => ({
        url: '/customers/search',
        method: 'POST',
        body: data,
      })
    }),
    getCustomerDetails: builder.query({
      query: (id) => ({
        url: `/customers/single/${id}`,
      }),
      providesTags: ['Customer'],
    }),
    getCustomerOrders: builder.query({
      query: (id) => ({
        url: `/customers/orders/${id}`,
      }),
      providesTags: ['CustomerOrders'],
    }),
    // createMenu: builder.mutation({
    //   query: (data) => ({
    //     url: '/menu/create',
    //     method: 'POST',
    //     body: data,
    //   }),
    //   invalidatesTags: ['Courses', 'CourseId'],
    // }),
    // updateClass: builder.mutation({
    //   query: ({ id, ...data }) => ({
    //     url: `/courses/${id}`,
    //     method: 'PATCH',
    //     body: data,
    //   }),
    //   invalidatesTags: ['Courses', 'CourseId', 'Course'],
    // }),
    // updateTeacherType: builder.mutation({
    //   query: ({ course_id, ...data }) => ({
    //     url: `/courses/updateteachertype/${course_id}`,
    //     method: 'POST',
    //     body: data,
    //   }),
    //   invalidatesTags: ['Course'],
    // }),
    // deleteCourse: builder.mutation({
    //   query: (id) => ({
    //     url: `/courses/${id}`,
    //     method: 'DELETE',
    //   }),
    //   invalidatesTags: ['Courses'],
    // }),
    // deleteCourseTeachers: builder.mutation({
    //   query: ({ courseId, sectionId, ...data }) => ({
    //     url: `/courses/removeteacherfromsection/${courseId}/${sectionId}`,
    //     method: 'POST',
    //     body: data
    //   }),
    //   invalidatesTags: ['Courses', 'Course'],
    // }),
    // deleteCourseStudents: builder.mutation({
    //   query: ({ courseId, sectionId, ...data }) => ({
    //     url: `/courses/removestudentfromsection/${courseId}/${sectionId}`,
    //     method: 'POST',
    //     body: data
    //   }),
    //   invalidatesTags: ['Courses', 'Course'],
    // })
    // // getClassSearchResult: builder.mutation({
    // //   query: (data) => ({
    // //     url: '/courses/search',
    // //     method: 'POST',
    // //     body: data,
    // //   })
    // // }),
    // getClassStudentSearchResult: builder.mutation({
    //   query: (data) => ({
    //     url: '/classes/searchStudent',
    //     method: 'POST',
    //     body: data,
    //   })
    // }),
    // addCourseStudents: builder.mutation({
    //   query: ({ courseId, sectionId, ...data }) => ({
    //     url: `/courses/addstudenttosection/${courseId}/${sectionId}`,
    //     method: 'POST',
    //     body: data,
    //   }),
    //   invalidatesTags: ['Courses', 'Course'],
    // }),
    // // addCourseStudents: builder.mutation({
    // //   query: (data) => ({
    // //     url: `/courses/addstudents`,
    // //     method: 'POST',
    // //     body: data,
    // //   }),
    // //   invalidatesTags: ['Courses', 'Course'],
    // // }),
    // addCourseTeachers: builder.mutation({
    //   query: ({ courseId, sectionId, data }) => ({
    //     url: `/courses/addteachertosection/${courseId}/${sectionId}`,
    //     method: 'POST',
    //     body: data,
    //   }),
    //   invalidatesTags: ['Courses', 'Course'],
    // }),
    // getCourseStudentsSearchResult: builder.mutation({
    //   query: ({ courseId, sectionId, ...data }) => ({
    //     url: `/courses/searchstudentsinsection/${courseId}/${sectionId}`,
    //     method: 'POST',
    //     body: data,
    //   }),
    // }),
    // // getCourseStudentsSearchResult: builder.mutation({
    // //   query: (data) => ({
    // //     url: '/courses/searchstudents',
    // //     method: 'POST',
    // //     body: data,
    // //   })
    // // }),
    // getCourseTeachersSearchResult: builder.mutation({
    //   query: (data) => ({
    //     url: '/courses/searchteachers',
    //     method: 'POST',
    //     body: data,
    //   })
    // }),
    // getClassLevelList: builder.query({
    //   query: () => ({
    //     url: '/classes/all',
    //   }),
    //   providesTags: ['Classes'],
    // }),
    // addClassLevel: builder.mutation({
    //   query: (data) => ({
    //     url: `/classes/create`,
    //     method: 'POST',
    //     body: data,
    //   }),
    //   invalidatesTags: ['Classes'],
    // }),
    // checkClassLevelName: builder.mutation({
    //   query: (data) => ({
    //     url: `/classes/checkName`,
    //     method: 'POST',
    //     body: data,
    //   })
    // }),
    // editClassLevel: builder.mutation({
    //   query: ({ id, ...data }) => ({
    //     url: `/classes/${id}`,
    //     method: 'PATCH',
    //     body: data,
    //   }),
    //   invalidatesTags: ['Classes'],
    // }),
    // deleteClassLevel: builder.mutation({
    //   query: (id) => ({
    //     url: `/classes/${id}`,
    //     method: 'DELETE',
    //   }),
    //   invalidatesTags: ['Classes'],
    // }),
    // getClassDetails: builder.query({
    //   query: (id) => ({
    //     url: `/classes/getSingle/${id}`,
    //   }),
    //   providesTags: ['Classes'],
    // }),
    // addClassStudents: builder.mutation({
    //   query: (data) => ({
    //     url: `/classes/addStudent`,
    //     method: 'POST',
    //     body: data,
    //   }),
    //   invalidatesTags: ['Classes'],
    // }),
    // deleteClassUsers: builder.mutation({
    //   query: (data) => ({
    //     url: `/classes/removeStudent`,
    //     method: 'POST',
    //     body: data,
    //   }),
    //   invalidatesTags: ['Classes'],
    // }),
    // getStudentBySchoolYearAndClass: builder.mutation({
    //   query: (data) => ({
    //     url: `/classes/getStudents`,
    //     method: 'POST',
    //     body: data,
    //   }),
    //   invalidatesTags: ['Classes'],
    // }),
    // getStudentClass: builder.mutation({
    //   query: (data) => ({
    //     url: `/classes/findStudentClass`,
    //     method: 'POST',
    //     body: data,
    //   })
    // }),
    // addSectionToCourse: builder.mutation({
    //   query: ({ id, ...data }) => ({
    //     url: `/courses/addsection/${id}`,
    //     method: 'POST',
    //     body: data,
    //   }),
    //   invalidatesTags: ['Course'],
    // }),
    // updateSectionToCourse: builder.mutation({
    //   query: ({ course_id, ...data }) => ({
    //     url: `/courses/updatesection/${course_id}`,
    //     method: 'POST',
    //     body: data,
    //   }),
    //   invalidatesTags: ['Course'],
    // }),
    // deleteSectionToCourse: builder.mutation({
    //   query: ({ course_id, ...data }) => ({
    //     url: `/courses/removesection/${course_id}`,
    //     method: 'POST',
    //     body: data,
    //   }),
    //   invalidatesTags: ['Course'],
    // }),
    // getSectionSearchResult: builder.mutation({
    //   query: ({ course_id, ...data }) => ({
    //     url: `/courses/searchsections/${course_id}`,
    //     method: 'POST',
    //     body: data,
    //   })
    // }),
    // getAttendanceOfSectionOfCourse: builder.mutation({
    //   query: (data) => ({
    //     url: `/courses/getattendanceofsection`,
    //     method: 'POST',
    //     body: data,
    //   }),
    //   providesTags: ['SectionAttendances']
    // }),
    // addAttendanceToSectionOfCourse: builder.mutation({
    //   query: (data) => ({
    //     url: `/courses/addattendancetosection`,
    //     method: 'POST',
    //     body: data,
    //   }),
    //   invalidatesTags: ['SectionAttendances', 'Course']
    // }),
    // updateAttendanceToSectionOfCourse: builder.mutation({
    //   query: ({ ...data }) => ({
    //     url: `/courses/updateattendancetosection`,
    //     method: 'POST',
    //     body: data,
    //   }),
    //   invalidatesTags: ['SectionAttendances', 'Course']
    // }),
    // updateMultipleAttendanceToSectionOfCourse: builder.mutation({
    //   query: ({ ...data }) => ({
    //     url: `/courses/updatemultipleattendancetosection`,
    //     method: 'POST',
    //     body: data,
    //   }),
    //   invalidatesTags: ['SectionAttendances', 'Course']
    // }),
    // getAttendanceOfStudentInSection: builder.mutation({
    //   query: ({ ...data }) => ({
    //     url: `/courses/getattendanceofstudentinsection`,
    //     method: 'POST',
    //     body: data,
    //   })
    // })

  }),
});

export const {
  useGetCustomerListQuery,
  useGetCustomerSearchResultMutation,
  useGetCustomerDetailsQuery,
  useGetCustomerOrdersQuery,
  useCreateMenuMutation,
  // useUpdateClassMutation,
  // useDeleteCourseMutation,
  // useGetClassSearchResultMutation,
  // useGetClassStudentSearchResultMutation,
  // useAddCourseStudentsMutation,
  // useAddCourseTeachersMutation,
  // useGetCourseStudentsSearchResultMutation,
  // useGetCourseTeachersSearchResultMutation,
  // useGetClassLevelListQuery,
  // useAddClassLevelMutation,
  // useGetClassDetailsQuery,
  // useAddClassStudentsMutation,
  // useDeleteClassUsersMutation,
  // useGetStudentBySchoolYearAndClassMutation,
  // useEditClassLevelMutation,
  // useDeleteClassLevelMutation,
  // useDeleteCourseTeachersMutation,
  // useGetStudentClassMutation,
  // useDeleteCourseStudentsMutation,
  // useUpdateTeacherTypeMutation,
  // useCheckClassLevelNameMutation,
  // useAddSectionToCourseMutation,
  // useUpdateSectionToCourseMutation,
  // useDeleteSectionToCourseMutation,
  // useGetSectionSearchResultMutation,
  // useGetAttendanceOfSectionOfCourseMutation,
  // useAddAttendanceToSectionOfCourseMutation,
  // useUpdateAttendanceToSectionOfCourseMutation,
  // useUpdateMultipleAttendanceToSectionOfCourseMutation,
  // useGetAttendanceOfStudentInSectionMutation
} = customerApi;
