import { api } from "../../api/apiSlice";

const galleryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGalleryList: builder.query({
      query: () => ({
        url: "/gallery/private/find-all",
      }),
      providesTags: ["Gallery"],
    }),

    getGalleryDetails: builder.query({
      query: (id) => ({
        url: `/gallery/private/getSingle/${id}`,
      }),
      providesTags: ["Gallery"],
    }),
    deleteGallery: builder.mutation({
      query: (id) => ({
        url: `/gallery/private/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Gallery"],
    }),
    updateGallery: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/gallery/private/update/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Gallery"],
    }),
    addGalleryData: builder.mutation({
      query: (data) => ({
        url: `/gallery/private/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Gallery"],
    }),
    updatedGalleryList: builder.mutation({
      query: (data) => ({
        url: `/gallery/private/update-sort-order`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Gallery"],
    }),
  }),
});

export const {
  useAddGalleryDataMutation,
  useDeleteGalleryMutation,
  useGetGalleryDetailsQuery,
  useGetGalleryListQuery,
  useUpdateGalleryMutation,
  useUpdatedGalleryListMutation,
} = galleryApi;
