import React, { useEffect, useState } from "react";
import Table from "../../../../components/Table/Table";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

const Orders = (props) => {
  const { customerDetails, customerOrders } = props;
  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (customerOrders?.length > 0) {
      let ordersExceptNew = customerOrders?.filter(
        (singleOrder) => singleOrder?.orderStatus != "new"
      );
      setRows(convertDataToCellRows(ordersExceptNew));
    }
  }, [customerOrders]);

  const dateFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const options = [
    {
      value: "in-progress",
      label: "In progress",
      color: "#FF6200",
      border: "#FF62003D",
    },
    {
      value: "ready-to-pickup",
      label: "Ready for pickup",
      color: "#0450E1",
      border: "#0450E13D",
    },
    {
      value: "out-for-delivery",
      label: "Out for delivery",
      color: "#7266FB",
      border: "#7266FB3D",
    },
    {
      value: "delivered",
      label: "Delivered",
      color: "#4C9F41",
      border: "#4C9F413D",
    },
    {
      value: "cancelled",
      label: "Cancelled",
      color: "#1B1B1F",
      border: "#1e1b1b1f",
    },
  ];

  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map((item) => {
        let myDate = new Date(item?.createdAt);
        let formattedDate = myDate.toLocaleString("en-US", dateFormatOptions);

        // let statusColor = ""
        // let statusLabel = ""
        // let statusBackground = ""

        // if(item?.status == "active"){
        //   statusColor = "#0B9444"
        //   statusLabel = "Active"
        //   statusBackground = "#E1F2E8"
        // }else if(item?.status == "blocked") {
        //   statusColor = "#E63946"
        //   statusLabel = "Blocked"
        //   statusBackground = "#FCE7E9"
        // }
        let statusObject = options?.find(
          (option) => option.value == item?.orderStatus
        );
        return {
          cell: [
            { date: formattedDate },
            { textBold: item?.orderNumber },
            { textBold: `$${item?.orderTotal}` },
            {
              Status: true,
              background: statusObject?.border || "#E1F2E8",
              color: statusObject?.color || "#0B9444",
              label: statusObject?.label || "N/A",
            },
            {
              Button: true,
              buttonLabel: "View",
              buttonTextcolor: "#4C9F41",
              buttonLink: `/order-details/${item?._id}`,
            },
          ],
        };
      });
    }
  };

  const headCells = [
    {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "Date",
      width: "25%",
    },
    {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "Order ID",
      width: "25%",
    },
    {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "Amount",
      width: "25%",
    },
    {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "Status",
      width: "20%",
    },
    {
      id: "Actions",
      numeric: true,
      disablePadding: false,
      label: "",
      width: "5%",
    },
  ];

  //   const rows = [
  //     {
  //         cell: [
  //             { date: "Dec 13, 2022, 10:00 AM", },
  //             { textBold: "ORDR1000001", },
  //             { textBold: "$50", },
  //             { Status: true, background: "#E1F2E8", color: "#0B9444", label: "Delivered", },
  //             { Button: true, buttonLabel: "View", buttonTextcolor: "#4C9F41", buttonLink: "/order-details/48934893", },
  //         ]
  //     },
  //     {
  //         cell: [
  //             { date: "Dec 13, 2022, 10:00 AM", },
  //             { textBold: "ORDR1000001", },
  //             { textBold: "$50", },
  //             { Status: true, background: "#FCE7E9", color: "#E63946", label: "Cancelled", },
  //             { Button: true, buttonLabel: "View", buttonTextcolor: "#4C9F41", buttonLink: "/order-details/48934893", },
  //         ]
  //     },
  //     {
  //         cell: [
  //             { date: "Dec 13, 2022, 10:00 AM", },
  //             { textBold: "ORDR1000001", },
  //             { textBold: "$50", },
  //             { Status: true, background: "#E1F2E8", color: "#0B9444", label: "Delivered", },
  //             { Button: true, buttonLabel: "View", buttonTextcolor: "#4C9F41", buttonLink: "/order-details/48934893", },
  //         ]
  //     },
  //     {
  //         cell: [
  //             { date: "Dec 13, 2022, 10:00 AM", },
  //             { textBold: "ORDR1000001", },
  //             { textBold: "$50", },
  //             { Status: true, background: "#E1F2E8", color: "#0B9444", label: "Delivered", },
  //             { Button: true, buttonLabel: "View", buttonTextcolor: "#4C9F41", buttonLink: "/order-details/48934893", },
  //         ]
  //     },
  //     {
  //         cell: [
  //             { date: "Dec 13, 2022, 10:00 AM", },
  //             { textBold: "ORDR1000001", },
  //             { textBold: "$50", },
  //             { Status: true, background: "#FCE7E9", color: "#E63946", label: "Cancelled", },
  //             { Button: true, buttonLabel: "View", buttonTextcolor: "#4C9F41", buttonLink: "/order-details/48934893", },
  //         ]
  //     },
  // ]

  return (
    <>
      <div className="TableSection">
        <Table
          title="Course"
          tableHeader={false}
          addLabel="New User"
          link="add-courses"
          headCells={headCells}
          rows={rows}
          AddButton={true}
          Checkbox={false}
          innerHeader={true}
          footer={true}
          innerTitle="Orders"
          AddButtonInner={false}
          addLabelInner="Add Payment"
          InnerButtonColor="#004FE0"
          InnerButtonTextColor="#fff"
          InnderButtonIcon={<AddRoundedIcon />}
          showSearchBox={false}
        />
      </div>
    </>
  );
};

export default Orders;
