import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

import AlrtMessage from "../../components/Form/AlrtMessage/AlertMessage";
import { Grid } from "@material-ui/core/";
import Buttons from "../../components/Form/Button/Button";
import TextField from "../../components/Form/TextField/TextField";
import Header from "./header/Header";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import OTPInput, { ResendOTP } from "otp-input-react";
import "./Form.css";
import CustomizedSnackbar from "../../hooks/Snackbar";

function Otp(props) {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("error");

  const [OTP, setOTP] = useState("");
  const [token, setToken] = useState("");
  
  const processResponse = (response) => {
    console.log(response);
    if (response.data.body.status == true) {
      setAlertSeverity("success");
      setAlertMessage(response.data.body.message);
      setAlertOpen(true);
      // redirect to sign in page
      setTimeout(() => {
        setAlertMessage("Redirecting to sign in page...");
        window.location.replace(
          `${window.location.origin}/signin?token=${token}`
        );
      }, 2000);
    } else {
      setAlertSeverity("error");
      setAlertMessage(response.data.body.message);
      setAlertOpen(true);
    }
  };

  const sendResetPasswordRequest = () => {
    console.log(OTP);

    if (OTP == "") {
      setAlertSeverity("error");
      setAlertMessage("You should put an valid OTP");
      setAlertOpen(true);
      return false;
    } else {
      const baseUrl =
        process.env.REACT_APP_PROJECT_ENV === "prod"
          ? process.env.REACT_APP_API_URL_PROD
          : process.env.REACT_APP_API_URL_DEV;
      const postData = { OTP: OTP };

      axios
        .post(`${baseUrl}/verifySignUpOtp`, postData)
        .then((response) => processResponse(response))
        .catch((err) => {
          console.log(err);
          if (err.response.data.message) {
            setAlertSeverity("error");
            setAlertMessage(err.response.data.message);
            setAlertOpen(true);
          }
        });

      return true;
    }
  };

  const alertCloseHandler = () => {
    setAlertOpen(false);
  };

  // handle reference login
  let [searchParams, setSearchParams] = useSearchParams();
  let userToken = searchParams.get("token");
  // console.log(userToken);

  useEffect(() => {
    if (userToken) {
      // console.log(userToken);
      // userToken = token.replace(" ", "+");
      console.log(userToken);
      setToken(userToken);
    }
  }, []);

  return (
    <>
      <Header />
      <div className="SignContainer">
        <div className="SignContainerInner">
          <div className="SignForm">
            <h1>Verify email address</h1>

            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                {/* <AlrtMessage open={alertOpen} message={alertMessage} severity={alertSeverity} closeHandler={alertCloseHandler}/> */}
                <CustomizedSnackbar
                  open={alertOpen}
                  severity={alertSeverity}
                  message={alertMessage}
                  handleClose={alertCloseHandler}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <div className="OtpFields">
                  <OTPInput
                    className="OtpField"
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                    // style={false}
                  />
                </div>
              </Grid>

              <p style={{ textAlign: "center" }}>
                An email with a verification code has been sent to your email
                address. Enter the code to continue.
              </p>

              <Grid item xs={12} md={12}>
                <Buttons
                  label="Verify"
                  buttonColor="#4C9F41"
                  border="#ccc 1px solid"
                  color="#fff"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  link=""
                  onClick={sendResetPasswordRequest}
                />
              </Grid>

              <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                {/*  renderButton={renderButton} renderTime={renderTime}  */}
                <ResendOTP className="ResendButton" maxTime={10} />
              </Grid>
            </Grid>
          </div>
          <div className="SignFormFooter">
            <LockOutlinedIcon />
            <span>SECURE SIGN IN</span>
            <SecurityOutlinedIcon />
            <span>SECURE SSL ENCRYPTION</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Otp;
