import React, { useEffect, useState } from "react";
import TextFieldCom from "@mui/material/TextField";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import Tooltip from "../Tooltip/Tooltip";
import "./ZipCode.css";

const ZipCode = (props) => {
  const [zipCode, setZipCode] = useState(props.zipCode || "");
  const {
    label,
    validation,
    error,
    required,
    placeholder,
    value,
    onZipCodeChange,
    helperText,
    count,
    disabled,
  } = props;

  const handleZipCodeChange = (event) => {
    // allow only numbers and maximum length of 5
    const regex = /^[0-9]{0,5}$/;
    const input = event.target.value;
    if (regex.test(input)) {
      setZipCode(input);
      onZipCodeChange(input); // call the callback function with the new zip code value
    }
  };

  useEffect(() => {
    // update the TextField value prop when the zipCode state changes
    if (zipCode !== "") {
      const regex = /^[0-9]{5}$/;
      if (regex.test(zipCode)) {
        // only set the value prop if the zip code is valid
        onZipCodeChange(zipCode);
      }
    }
  }, [zipCode, onZipCodeChange]);

  return (
    <div className="TextField">
      <div className="FieldLabel">
        {label}
        {required && <span>*</span>}
        {props.TooltipStatus && (
          <Tooltip
            TooltipText={props.TooltipText}
            Tooltip={<InfoRoundedIcon />}
          />
        )}
      </div>
      <TextFieldCom
        className="inputField"
        id="outlined-basic"
        variant="outlined"
        error={error || validation}
        placeholder={placeholder}
        // value={zipCode}
        value={value}
        onChange={handleZipCodeChange}
        helperText={helperText}
        disabled={disabled ? disabled : false}
        inputProps={{
          maxLength: 5,
        }}
      />
      <span className="FieldCount">
        {count && value && (
          <span style={{ color: "#B6B9B9", alignSelf: "flex-end" }}>
            {" "}
            CHAR {value.length} / {count}
          </span>
        )}
      </span>
      {/* temporary disabled by sujan */}
      {/* <a className="AvailableButton">Available?</a> */}
    </div>
  );
};

export default ZipCode;
