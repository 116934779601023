import React, { useEffect, useState } from "react";
import { Avatar, Button, Grid, IconButton } from "@mui/material";
import MenuStapperDropDown from "../../../../../components/MenuComponents/MenuStapperDropDown/MenuStapperDropDown";
import FileUplaoder from "../../../../../components/Form/FileUplaoder/FileUplaoder";
import MenuFileUploader from "../../../../../components/MenuComponents/MenuFileUploader/MenuFileUploader";
import TextField from "../../../../../components/Form/TextField/TextField";
import SelectField from "../../../../../components/Form/SelectField/SelectField";
import "./ItemDetails.css";
// import SideBarDrawer from "../../../../../components/SideBarDrawer/SideBarDrawer";
import MediaLibrarySelectMedia from "../../../../../components/MediaLibrary/MediaLibrarySelectMedia";
import Externaluse from "../../../../../components/MediaLibrary/Externaluse";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

import {
  useAddMediaLibMutation,
  useDeleteMediaLibMutation,
  useGetMediaLibDetailsQuery,
  useGetMediaLibListQuery,
  useGetMediaLibrarySearchResultMutation,
  useReArrangedFeildMutation,
  useUpdateMediaLibMutation,
} from "../../../../../redux/features/media-lib/mediaLibApi";
import { toast } from "react-toastify";
import SideDrawer from "../../../../../components/SideDrawer/SideDrawer";
import { deepPurple } from "@mui/material/colors";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import MultipleSelect from "../../../../../components/Form/MultipleSelect/MultipleSelet";
import {
  useGetCategoryListByOrgIdQuery,
  useGetCategoryListQuery,
} from "../../../../../redux/features/categories/categoryApi";
import useProtected from "../../../../../hooks/useProtected";

const ItemDetails = (props) => {
  let { menuInfo, setMenuInfo, errors, setErrors, categoryOptions } = props;

  const { data, isError, isLoading, error } = useGetMediaLibListQuery(
    undefined,
    { refetchOnMountOrArgChange: true }
  );

  useProtected(error);

  const [spicy, setSpicy] = useState(menuInfo?.spicyLevel);
  const [values, setValues] = useState({
    itemName: "",
    description: "",
  });
  const [category, setCategory] = React.useState([]);
  const [medialib, setMediaLib] = useState(false);
  // =================== Media lib ================================= //
  const [selectedMediaId, setSelectedMediaId] = useState("");

  // =================== Media lib end ============================== //
  const handleChangesOfField = (fieldName, fieldValue) => {
    if (fieldName === "spicyLevel") {
      setSpicy(fieldValue);
    }
    // console.log(fieldName, 342);
    setMenuInfo({ ...menuInfo, [fieldName]: fieldValue });
  };

  // =============================== media lib code start ================================ //
  const [openSelectMedia, setOpenSelectMedia] = useState(false);
  const [openNewMedia, setOpenNewMedia] = useState(false);
  const [mediaItem, setMediaItem] = useState({});
  const [isActiveLogoIcon, setActiveLogoIcon] = useState(true);
  const [selectedValue, setSelectedValue] = React.useState(false);

  const [openSelectMedia2, setOpenSelectMedia2] = useState(false);
  const [openNewMedia2, setOpenNewMedia2] = useState(false);
  const [selectedValue2, setSelectedValue2] = React.useState(false);
  const [mediaItem2, setMediaItem2] = useState({});

  const getMediaItem = (item) => {
    // setting the media item
    setMediaItem(item);
  };
  const handleMediaUploadClose2 = () => {
    setOpenNewMedia2(false);
  };

  const handleDrawerCloseSelectMedia = () => {
    setOpenSelectMedia(false);
    //setOpen(true);
  };

  const handleDrawerOpenNewMedia2 = () => {
    setOpenNewMedia2(true);
  };

  const handleOpenNewMedia2 = (status) => {
    setOpenNewMedia2(!status);
    handleDrawerOpenSelectMedia2(status);
  };
  const getMediaItem2 = (item) => {
    // setting the media item
    setMediaItem2(item);
  };
  const handleDrawerOpenSelectMedia2 = () => {
    setOpenSelectMedia2(true);
  };

  const handleOpenNewMedia = (status) => {
    setOpenNewMedia(!status);
    handleDrawerOpenSelectMedia(status);
  };
  const handleDrawerOpenSelectMedia = () => {
    setOpenSelectMedia(true);
    setActiveLogoIcon(!isActiveLogoIcon);
  };
  const clickSelectMediaItem = async () => {
    //check if any item is selected
    if (selectedValue) {
      if (mediaItem?.mimeType?.startsWith("image/")) {
        let newObject = {
          ...menuInfo,
          photo: mediaItem.url,
          photoName: mediaItem.name,
        };
        setMenuInfo(newObject);
        toast.success("File uploaded successfully");
        setMediaLib(false);
      } else {
        toast.error("Image file can be accepeted");
      }
    } else {
      toast.error("Please select any media");
    }
  };

  const handleRemovePhoto = () => {
    let newObject = {
      ...menuInfo,
      photo: "",
    };
    setMenuInfo(newObject);
  };
  // const deleteAttachment = (id) => {
  //   let newAttachments = messageObject?.messageEmailAttachments?.filter((attachment, index) => index !== id)
  //   setMessageObject({ ...messageObject, messageEmailAttachments: newAttachments })
  // }
  // =============================== media lib code end  ================================ //

  // useEffect(() => {
  //   console.log(menuInfo, 328);
  // }, [menuInfo]);

  // * ========================== Multiple select Code =====================================

  const setLabelAsValueForMultiSelect = (classLevelOptions) => {
    let optionArray = classLevelOptions?.map((option) => {
      return {
        label: option.label,
        value: option.value,
      };
    });
    return optionArray;
  };

  const options = [
    {
      label: "Popoular",
      value: "popoular",
    },

    {
      label: "New Addition",
      value: "new addition",
    },

    {
      label: "Appetizers",
      value: "appetizers",
    },

    {
      label: "Sweets",
      value: "sweets",
    },

    {
      label: "Biryani & Rice ",
      value: "biryani & rice ",
    },

    {
      label: "Breads",
      value: "breads",
    },

    {
      label: "Meat Curry",
      value: "meat curry",
    },

    {
      label: "Tandoor & Kebabs",
      value: "tandoor & kebabs",
    },

    {
      label: "Seafood",
      value: "seafood",
    },

    {
      label: "Vegetable",
      value: "vegetable",
    },

    {
      label: "Beverages",
      value: "beverages",
    },
  ];

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCategory(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // === Multi Select == //
  const [value, setvalue] = React.useState([]);
  const handleChangeMultipleSelect = (event, fieldName) => {
    const {
      target: { value },
    } = event;
    setvalue(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    let newObject = {
      ...menuInfo,
      [fieldName]: typeof value === "string" ? value.split(",") : value,
    };
    setMenuInfo(newObject);
  };
  // === Multi Select == //

  useEffect(() => {
    if (menuInfo && menuInfo?.categories && menuInfo?.categories?.length > 0) {
      setvalue(menuInfo?.categories);
    }
    setSpicy(menuInfo?.spicyLevel);
  }, [menuInfo]);

  return (
    <>
      <div className="itemDetailsGrandContainer">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h3>Basic info</h3>
          </Grid>
          <Grid item xs={12}>
            <MenuStapperDropDown
              menuInfo={menuInfo}
              setMenuInfo={setMenuInfo}
            />
          </Grid>

          <Grid item xs={12}>
            {menuInfo?.photo ? (
              <div className="MenuItemImageContainer">
                {" "}
                <Avatar
                  id="basic-button"
                  sx={{ bgcolor: deepPurple[300] }}
                  src={menuInfo?.photo ? menuInfo?.photo : ""}
                ></Avatar>
                <p>{menuInfo?.photoName || menuInfo?.name} </p>
                <IconButton onClick={handleRemovePhoto}>
                  <CloseRoundedIcon />
                </IconButton>
              </div>
            ) : (
              <Button
                variant="outlined"
                sx={{
                  py: 1.5,
                  width: "100%",
                  borderColor: `${errors?.photo ? "red" : "#E4E8EC"}`,
                  color: "#4C9F41",
                  textTransform: "capitalize",
                  "&:hover": {
                    borderColor: "#E4E8EC",
                    color: "#4C9F41",
                  },
                }}
                startIcon={<FileUploadOutlinedIcon />}
                onClick={() => setMediaLib(true)}
              >
                Choose File
              </Button>
            )}

            {errors?.photo && (
              <p
                style={{
                  margin: "0px",
                  color: "#d32f2f",
                  fontSize: "12px",
                  textTransform: "capitalize",
                }}
              >
                {errors?.photo}
              </p>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Item name"
              required={false}
              email={false}
              value={menuInfo?.name}
              // handleValidation={handleValidation}
              InnerFieldCount={true}
              count={100}
              handleChange={(e) => handleChangesOfField("name", e.target.value)}
              // placeholder="Email address"
              error={errors?.name}
              helperText={errors?.name}
            />
          </Grid>
          <Grid item xs={12}>
            <MultipleSelect
              FieldLabel={true}
              label="Category"
              option={setLabelAsValueForMultiSelect(categoryOptions)}
              handleChange={(e) => handleChangeMultipleSelect(e, "categories")}
              value={value}
              helperText={errors?.categories}
              error={errors?.categories}
              errorHelperText={errors?.categories}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description (optional)"
              // required={true}
              email={false}
              value={menuInfo?.description}
              // handleValidation={handleValidation}
              InnerFieldCount={true}
              count={200}
              handleChange={(e) =>
                handleChangesOfField("description", e.target.value)
              }
              // placeholder="Email address"
            />
          </Grid>
          <Grid item xs={12}>
            <SelectField
              error={false}
              label="Spicy level"
              required={true}
              options={spicyLevel}
              selectedOption={spicy}
              handleChange={(e) =>
                handleChangesOfField("spicyLevel", e.target.value)
              }
            />
          </Grid>
          <div>
            {/* <button onClick={() => setMediaLib(true)}>media lib</button> */}
            <SideDrawer
              title="Media Library"
              ButtonLabel="Add"
              //clickSaveButton={clickSaveButton}
              cancelButtonLabel="Cancel"
              sideOpen="right"
              open={medialib}
              handleDrawerClose={() => setMediaLib(false)}
              FooterWithoutTab={true}
              LeftButton={true}
              ButtonLeft="Upload New"
              // ButtonLeftIcon={<FileUploadOutlinedIcon />}
              clickButtonLeft={handleDrawerOpenNewMedia2}
              clickSaveButton={clickSelectMediaItem}
              padding="0"
              body={
                <MediaLibrarySelectMedia
                  onClickRadioIcon={setSelectedValue}
                  onItemSelected={getMediaItem}
                />
              }
            />

            <Externaluse
              open={openNewMedia2}
              handleCloseButton={handleMediaUploadClose2}
              handleOpenNewMedia={getMediaItem2}
            />
          </div>
        </Grid>
      </div>
    </>
  );
};

export default ItemDetails;

const spicyLevel = [
  {
    label: "Not Applicable",
    value: "Not Applicable",
  },
  {
    label: "Mild",
    value: "Mild",
  },

  {
    label: "Medium",
    value: "Medium",
  },

  {
    label: "Hot",
    value: "Hot",
  },

  {
    label: "Extra Hot",
    value: "Extra Hot",
  },

  {
    label: "Extremely Hot",
    value: "Extremely Hot",
  },
];
