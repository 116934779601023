import react from "react";
import Button from '@mui/material/Button';
import styles from './Button.css';






function Buttons(props) {



  return (
    <div className={styles.ButtonContainer}>
      <Button
        href={props.link}
        disabled={props.disabled}

        style={{
          background: props.buttonColor,
          color: props.color,
          height: props.height,
          fontSize: props.fontSize,
          border: props.border,
          width: props.width,
          borderRadius: props.borderRadius,
        }}
        onClick={() => typeof props.onClick !== "undefined" ? props.onClick() : {}}
      >
        {props.iconStart}
        {props.label}
        {props.icon}
      </Button>


      {/* <Buttons
        label="Join for Free"
        buttonColor="#004FE0"
        color="#fff"
        height="60px"
        fontSize="20px"
        link=""
      /> */}


    </div>
  );
}


export default Buttons;

