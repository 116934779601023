import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import "./OrdersTable.css";

const OrdersTable = (props) => {
  const {
    scheduledOrders,
    setActiveData,
    allOrders,
    inProgressOrders,
    deliveredOrders,
    cancelledOrders,
    newOrders,
    activeData,
  } = props;
  const [tabValue, setTabValue] = React.useState(activeData);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setActiveData(newValue);
  };

  return (
    <div className="OrdersTableGrandParent">
      <Box
        sx={{ maxWidth: { xs: 320, sm: "100%" }, bgcolor: "background.paper" }}
      >
        <StyledTabs
          value={activeData}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          sx={{
            "& .MuiButtonBase-root": {
              textTransform: "capitalize !important",
            },
          }}
        >
          <Tab
            label={
              <p>
                <span className="tablabelred">{newOrders?.length || 0}</span>
                &nbsp;New
              </p>
            }
          />
          <Tab
            label={
              <p>
                <span className="tablabelorange">
                  {inProgressOrders?.length || 0}
                </span>
                &nbsp;In
                <span style={{ marginLeft: "5px", textTransform: "lowercase" }}>
                  progress
                </span>
              </p>
            }
          />
          <Tab
            label={
              <p>
                <span className="tablabelyellow">
                  {scheduledOrders?.length || 0}
                </span>

                <span style={{ marginLeft: "5px", textTransform: "initial" }}>
                  Scheduled
                </span>
              </p>
            }
          />
          <Tab
            label={
              <p>
                {/* <span className="tablabelred">{deliveredOrders?.length || 0}</span>&nbsp; */}
                Delivered
              </p>
            }
          />
          <Tab
            label={
              <p>
                {/* <span className="tablabelred">{deliveredOrders?.length || 0}</span>&nbsp; */}
                Declined
              </p>
            }
          />
          <Tab
            label={
              <p>
                {/* <span className="tablabelred">{cancelledOrders?.length || 0}</span>&nbsp; */}
                Cancelled
              </p>
            }
          />
          <Tab
            label={
              <p>
                {/* <span className="tablabelred">{allOrders?.length || 0}</span>&nbsp; */}
                All
              </p>
            }
          />
        </StyledTabs>
      </Box>
    </div>
  );
};

export default OrdersTable;

// tabs css
const StyledTabs = styled(Tabs)(({ theme }) => ({
  padding: "0 1rem",
  "& .MuiButtonBase-root": {
    color: "#45464F !important",
  },
  "& .Mui-selected": {
    color: "black !important",
  },
  "& .MuiButtonBase-root p": {
    margin: "0 !important",
    display: "flex",
    alignItems: "center",
  },
  "& .MuiButtonBase-root p .tablabelred": {
    margin: "0",
    padding: "4px 6px",
    background: "#BA1A1A !important",
    color: "#fff",
    borderRadius: "5px",
  },
  "& .MuiButtonBase-root p .tablabelorange": {
    margin: "0",
    padding: "4px 6px",
    background: "#FF6200 !important",
    color: "#fff",
    borderRadius: "5px",
  },
  "& .MuiButtonBase-root p .tablabelyellow": {
    margin: "0",
    padding: "4px 6px",
    background: "#FFB703 !important",
    color: "#ffffff",
    borderRadius: "5px",
  },
  "& .MuiTabs-indicator": {
    background: "#4C9F41 !important",
  },
}));
