import React, { useEffect, useState } from "react";
import VerifiedUser from "@mui/icons-material/Verified";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { USformatPhoneNumber } from "../../../../hooks/HelperFunctions";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

function Overview(props) {
  const { customerDetails } = props;
  // consoel.log( "sd" )
  const formattedNumber = USformatPhoneNumber(customerDetails?.phone?.slice(2));
  return (
    <>
      <div className="TableContainer">
        <div className="TableInnerHeader">
          <h3>Access details</h3>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left">Full name</StyledTableCell>
                <StyledTableCell align="left">
                  <div className="cellwithchip">
                    {customerDetails?.fullName || "N/A"}
                  </div>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">Phone</StyledTableCell>
                <StyledTableCell align="left">
                  <div className="cellwithchip">
                    {customerDetails?.phone
                      ? formattedNumber
                        ? `+1 ${formattedNumber}`
                        : "Invalid Number"
                      : "N/A"}
                  </div>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">Email address</StyledTableCell>
                <StyledTableCell align="left">
                  <div className="cellwithchip">
                    {customerDetails?.email || "N/A"}
                    {/* <div className="VerifyChip">
                      <VerifiedRoundedIcon /> Verified
                    </div> */}
                  </div>
                </StyledTableCell>
              </StyledTableRow>

              {/* <StyledTableRow >
                <StyledTableCell align="left">Phone</StyledTableCell>
                <StyledTableCell align="left">+1 (123) 123-1234</StyledTableCell>
              </StyledTableRow>

              <StyledTableRow >
                <StyledTableCell align="left">Password</StyledTableCell>
                <StyledTableCell align="left">
                  <div className="PassworedDots">
                    <FiberManualRecordIcon />
                    <FiberManualRecordIcon />
                    <FiberManualRecordIcon />
                    <FiberManualRecordIcon />
                    <FiberManualRecordIcon />
                    <FiberManualRecordIcon />
                    <FiberManualRecordIcon />
                    <FiberManualRecordIcon />
                    <FiberManualRecordIcon />
                    <FiberManualRecordIcon />
                  </div>
                </StyledTableCell>
              </StyledTableRow> */}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default Overview;
