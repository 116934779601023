import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import { Container } from "@mui/material";
import SettingsDetails from "./details/SettingsDetails";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import SideBarDrawer from "../../components/SideBarDrawer/SideBarDrawer";

const Settings = () => {
  // menu
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Container sx={{ mt: 14 }}>
        <SettingsDetails />
      </Container>

    </>
  );
};

export default Settings;
