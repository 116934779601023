import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const TabName = () => {
  const location = useLocation();
  const [tabName, setTabName] = useState("");

  useEffect(() => {
    const path = location.pathname;
    let name = "";

    // You can define your own logic to extract the route name
    if (path === "/" || path === "/order-details/:order_id") {
      name = "Orders -";
    } else if (
      path === "/menu-listing" ||
      path === "/add-menuItem" ||
      path === "/update-menuItem/:id"
    ) {
      name = "Menu -";
    } else if (
      path === "/customers-listing" ||
      path === "/customer-details/:customer_id"
    ) {
      name = "Customers -";
    } else if (
      path === "/location-listing" ||
      path === "/location-stapper" ||
      path === "/location-update/:location_id"
    ) {
      name = "Location -";
    } else if (
      path === "/user-listing" ||
      path === "/user-stapper" ||
      path === "/user-update/:user_id"
    ) {
      name = "Users -";
    } else if (path === "/media-library-table-view") {
      name = "Media lib -";
    } else if (path === "/settings") {
      name = "Settings -";
    } else if (path === "/signin") {
      name = "Sign in -";
    }

    // Set the tab name along with the company name
    setTabName(
      `${name} Radhuni Sweets & Restaurant | Best halal deshi restaurant in NYC`
    );
  }, [location]);

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{tabName}</title>;
    </Helmet>
  );
};

export default TabName;
