import React, { useState } from "react";
import axios from "axios";
// import emailValidation from '../../hooks/HelperFunctions';

import { Grid } from "@material-ui/core/";
import Buttons from "../../components/Form/Button/Button";
import TextField from "../../components/Form/TextField/TextField";
import Header from "./header/Header";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import "./Form.css";
import CustomizedSnackbar from "../../hooks/Snackbar";
import DeenLogo from "../../assets/imgs/deen360-logo.png";
import { Button } from "@mui/material";
import { createRECAPTCHAToken } from "../../hooks/HelperFunctions";

function ForgotPassword(props) {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [btnDisabled, setBtnDisabled] = React.useState(false);

  const handleChanges = (fieldName) => (event) => {
    const userEmail = event.target.value;
    console.log(userEmail);
    setEmail(userEmail);
  };

  const processResponse = (response) => {
    setBtnDisabled(false);
    // console.log(response, 328);

    if (!response.data.error) {
      setEmailError(false);
      setShowSnackbar(true);
      setSnackbarSeverity("success");
      setSnackbarMessage(response?.data?.message);
    } else {
      setEmailError(true);
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(response.data.message);
    }
  };

  const sendForgotPasswordRequest = async () => {
    // console.log(email);
    const token = await createRECAPTCHAToken();
    ///===================== recapcha ==============================///
    if (emailValidation(email)) {
      const baseUrl =
        process.env.REACT_APP_PROJECT_ENV === "prod"
          ? process.env.REACT_APP_API_URL_PROD
          : process.env.REACT_APP_API_URL_DEV;
      const postData = {
        email: email,
        token,
        // passwordResetURL: `${window.location.origin}/reset-password`,
      };

      setEmailError(false);
      setBtnDisabled(true);

      axios
        .post(`${baseUrl}/users/public/forgot-password`, postData)
        .then((response) => processResponse(response))
        .catch((err) => {
          // console.log(err, 328);
          if (err.response.data.message) {
            setBtnDisabled(false);
            setEmailError(true);
            setShowSnackbar(true);
            setSnackbarSeverity("error");
            setSnackbarMessage(err.response.data.message);
          }
        });
    } else {
      setEmailError(true);
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Please enter a valid email address.");
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  const emailValidation = (email) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || regex.test(email) === false) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Header />
      <div className="SignContainer">
        <div className="SignContainerInner">
          <div className="SignForm">
            <h1>Forgot password?</h1>
            <p style={{ textAlign: "center" }}>
              Enter the email address below that you use with your Radhuni
              account.
            </p>
            <CustomizedSnackbar
              open={showSnackbar}
              severity={snackbarSeverity}
              message={snackbarMessage}
              handleClose={handleSnackbarClose}
            />
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} className="HideLabelClass">
                <TextField
                  error={emailError ? true : false}
                  value={email}
                  handleChange={handleChanges("email")}
                  placeholder="Email address"
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Buttons
                  label="Continue"
                  buttonColor="#4C9F41"
                  border="#ccc 1px solid"
                  color="#fff"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  link=""
                  onClick={sendForgotPasswordRequest}
                  disabled={btnDisabled}
                />
                <div style={{ paddingTop: "4px" }}>
                  <Buttons
                    label="Cancel"
                    color="#4C9F41"
                    width=""
                    height="48px"
                    fontSize="14px"
                    link="/signin"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="SignFormFooter">
            <LockOutlinedIcon />
            <span>SECURE SIGN IN</span>
            <SecurityOutlinedIcon />
            <span>SECURE SSL ENCRYPTION</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
