import React from 'react'
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Buttons from '../Button/Button';
import './Modals.css'


function ModalTwo(props) {

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => setOpen(false);



  return (
    <div>


      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>

          <div className="ModalTwo">

            <div className="ModalTwoContainer" style={{ width: props.width, padding: props.padding, }}>

              <div className="ModalTwoIconCtn">
                <div className="ModalTitle">{props.ModalTitle}</div>
                <CloseRoundedIcon className="closeIcon" onClick={() => props.handleClose()} />
              </div>

              <div className="ModalTwoMdlBody">
                {props.body}
              </div>

              {props.ModalFooter && 

              <div className="ModalTwoMdlFooter">
                <Buttons
                  label="CANCEL"
                  buttonColor="#fff"
                  color="#4C5E67"
                  height="50px"
                  fontSize="14px"
                  borderRadius="4"
                  border="#E4E8EC 1px solid"
                  onClick={props.handleCancel}
                />
                <Buttons
                  label="YES, REMOVE"
                  buttonColor="#FF0033"
                  color="#fff"
                  height="50px"
                  fontSize="14px"
                  borderRadius="4"
                  onClick={props.handleYes}
                />
              </div>
              }

            </div>
          </div>

        </Fade>
      </Modal>


    </div>


  )
}






export default ModalTwo;