import React, { useEffect, useState } from "react";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
// import SendIcon from '@mui/icons-material/Send';
// import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
// import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import InsightsRoundedIcon from "@mui/icons-material/InsightsRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
// import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import SettingsIcon from "@mui/icons-material/Settings";
import PermMediaOutlinedIcon from "@mui/icons-material/PermMediaOutlined";
// import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import radhuniLogo from "../../assets/imgs/Rahdunilogo.png";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import "./Sidebar.css";
import CustomMenuItem from "./CustomMenuItem";

function Sidebar(props) {
  const [activeItem, setActiveItem] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // Update activeItem based on the current location
    setActiveItem(location.pathname);
  }, [location.pathname]);

  return (
    <div className="SidebarMenu">
      <MenuList>
        <MenuItem>
          <Link
            to="/"
            className={activeItem === "/" ? "active-route" : ""}
            // style={{ backgroundColor: activeItem === "/" ? "#f0f0f0" : "" }}
          >
            <img
              src={radhuniLogo}
              alt="website logo"
              style={{ margin: "0 auto", width: "100px" }}
            />
          </Link>
        </MenuItem>

        <MenuItem>
          <Link
            to="/"
            className={activeItem === "/" ? "active-route" : ""}
            // style={{ backgroundColor: activeItem === "/" ? "#f0f0f0" : "" }}
          >
            <ListItemIcon sx={{ minWidth: "0px" }}>
              <ReceiptIcon />
            </ListItemIcon>
            <div>Orders</div>
          </Link>
        </MenuItem>

        {/* <span className="divider"></span> */}

        <MenuItem>
          <Link
            to="/menu-listing"
            className={activeItem === "/menu-listing" ? "active-route" : ""}
          >
            <ListItemIcon>
              <MenuBookRoundedIcon />
            </ListItemIcon>
            <div>Menu</div>
          </Link>
        </MenuItem>

        {/* <span className="divider"></span> */}

        <MenuItem>
          <Link
            to="/customers-listing"
            className={
              activeItem === "/customers-listing" ? "active-route" : ""
            }
          >
            <ListItemIcon>
              <GroupsRoundedIcon />
            </ListItemIcon>
            <div>Customers</div>
          </Link>
        </MenuItem>

        <MenuItem>
          <Link
            to="/location-listing"
            className={activeItem === "/location-listing" ? "active-route" : ""}
          >
            <ListItemIcon>
              <LocationOnRoundedIcon />
            </ListItemIcon>
            <div>Locations</div>
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            to="/user-listing"
            className={activeItem === "/user-listing" ? "active-route" : ""}
          >
            <ListItemIcon>
              <PersonRoundedIcon />
            </ListItemIcon>
            <div>Users</div>
          </Link>
        </MenuItem>

        <MenuItem>
          <Link
            to="/media-library-table-view"
            className={
              activeItem === "/media-library-table-view" ? "active-route" : ""
            }
          >
            <ListItemIcon>
              <PermMediaOutlinedIcon />
            </ListItemIcon>
            <div>Media</div>
          </Link>
        </MenuItem>

        <MenuItem>
          <Link
            to="/gallery"
            className={activeItem === "/gallery" ? "active-route" : ""}
          >
            <ListItemIcon>
              <ImageRoundedIcon />
            </ListItemIcon>
            <div>Gallery</div>
          </Link>
        </MenuItem>

        <MenuItem>
          <Link
            to="/settings"
            className={activeItem === "/settings" ? "active-route" : ""}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <div>Settings</div>
          </Link>
        </MenuItem>
      </MenuList>
    </div>
  );
}

export default Sidebar;
