import axios from "axios";

// const baseUrl = process.env.REACT_APP_API_URL;
const baseUrl = process.env.REACT_APP_API_URL_MEDIA;

const user = localStorage.getItem("user");

const userToken = localStorage.getItem("userToken");

axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;

//function to convert date from this format "2021-03-04T10:00:00.000Z" to this format "04/03/2021"
export const convertDate = (date) => {
  let newDate = new Date(date);
  let day = newDate.getDate();
  let month = newDate.toLocaleString("en-US", { month: "long" });
  let year = newDate.getFullYear();
  let fullDate = day + "-" + month + "-" + year;
  return fullDate;
};

//function to convert file size from kilo bytes to KB/MB/GB
export const convertFileSize = (kiloBytes) => {
  if (kiloBytes < 1) {
    return "N/A";
  } else {
    let fileSize = Number(kiloBytes);
    let fileSizeType = "KB";
    if (fileSize > 1024) {
      fileSize = fileSize / 1024;
      fileSizeType = "MB";
    }
    if (fileSize > 1024) {
      fileSize = fileSize / 1024;
      fileSizeType = "GB";
    }
    return fileSize.toFixed(1) + " " + fileSizeType;
  }
};

export const getVideoId = (url) => {
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  if (match && match[2]) {
    return match[2];
  } else {
    return null;
  }
};

export const generateTableRows = (data, handleChangeRedio, selectedValue) => {
  let temp = [];
  data.map((item, index) => {
    //make the item.file_type first letter capital
    let mimeType =
      item?.mimeType?.charAt(0)?.toUpperCase() + item?.mimeType?.slice(1);
    temp.push({
      cell: [
        {
          img: item.url,
          imgStatus: true,
          imgText: item.name,
          align: "left",
          textLink: "#",
          TextLight: true,
          Radio: true,
          handleChangeRedio: handleChangeRedio,
          RedioName: "rizwan",
          RedioValue: item._id,
          selectedValue: selectedValue == item._id,
        },
        { text: mimeType },
        {
          text: convertFileSize(item.size),
          textcolor: item?.mimeType?.startsWith("image")
            ? "#1B1B1F"
            : "#A4A7B0",
        },
      ],
    });
  });
  return temp;
};
export const generaImageUrl = (data) => {
  //if data.file_type starts with image then return data.file_url
  //else if data.file_type starts with video then return youtube image
  //else return file image
  if (data.isImage) {
    return data.url;
  } else if (data.mimeType?.startsWith("video")) {
    return "https://cdn.mytakbir.com/static-assets/images/YouTube.svg";
  } else {
    return "https://cdn.mytakbir.com/static-assets/images/Attachment.svg";
  }
};

export const onFileUpload = async (
  imageName,
  filePath,
  fileName,
  fileSize,
  fileType,
  width,
  height
) => {
  try {
    console.log("filePath", filePath);
    // props.loadingIconStatusChange(true);
    //call to api via axios
    const response = await axios.post(baseUrl + "/media-lib/create", {
      name: imageName,
      schoolId: user ? JSON.parse(user).schollId : "",
      url: filePath,
      originalFileName: fileName,
      size: fileSize,
      width: width,
      height: height,
      mimeType: fileType,
    });

    // console.log( response );
    // props.loadingIconStatusChange(false);
    //if response status is 200
    if (!response.data?.error) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log(e);
  }
};
