import React from "react";
import "./Header.css";
import DeenLogo from "../../../assets/imgs/AfnanInnovationLab-Logo.png";
// import OrgLogo from 'https://deen360.s3.amazonaws.com/jayhan/logo/deen360-muna-academy-lms-learn-islam-logo.png';
import OrgLogo from "../../../assets/imgs/Rahdunilogo.png";

function Header(props) {
  return (
    <>
      <div className="HeaderTwo">
        <a href="https://afnanilab.com/">
          <img src={DeenLogo} />
        </a>
        <div className="HeaderTwoR">
          <span>Need help?</span>
          <a href="mailto:support@afnanilab.com">Contact Us </a>
        </div>
      </div>
      <a href="https://goradhuni.com/" className="OrgLogo">
        <img src={OrgLogo} />
      </a>
    </>
  );
}

export default Header;
