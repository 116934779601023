/**
 * authors: Rizwan Shah (Design), Ashrafuzzaman Sujan (Development)
 */

import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import "./AddTextField.css";
import { InputAdornment } from "@mui/material";

function AddTextField(props) {
  const {
    label,
    labelSecond,
    validation,
    placeholder,
    placeholderPrice,
    helperText,
    primaryField,
    textField,
    maxText,
    options,
    handleChange,
    InnerFieldCount,
    addButtonLabel,
    addVariationHandler,
    minItem,
    maxItem,
    menuInfo,
    setMenuInfo,
    errors,
  } = props;

  const [inputList, setInputList] = useState(() => {
    if (options.length == 0) {
      var obj = {};
      obj[primaryField] = 0;
      obj[textField] = "";
      return [obj];
    }
    return options;
  });
  const [price, setPrice] = useState("");

  /**
   * remove an item from input list and send the data to handleChange function
   * @param {*} event
   * @param {*} index
   */
  // const handleRemove = (index, event) => {
  //   const list = [...inputList];

  //   list.splice(index, 1);
  //   // update state variable
  //   setInputList(list);
  //   // send input list to parent component
  //   handleChange(event, list);
  // };

  const handleRemove = (index, event) => {
    let oldVariations = menuInfo?.variations;
    let newVariations = oldVariations?.filter((variation, i) => i != index);
    setMenuInfo({ ...menuInfo, variations: newVariations });
  };

  /**
   * add new empty item
   */
  const handleaddclick = () => {
    var obj = {};
    obj[primaryField] = 0;
    obj[textField] = "";
    setInputList([...inputList, obj]);
  };

  /**
   * onChange handler
   * @param {*} index
   * @returns
   */
  // const onChangeHandler = (index) => (event) => {
  //   const fieldData = event.target.value;

  //   const list = [...inputList];

  //   const currentListItem = list.findIndex((element, objIndex, array) => {
  //     return objIndex == index;
  //   });

  //   list[currentListItem][textField] = fieldData;
  //   // update state variable
  //   setInputList(list);
  //   // send input list to parent component
  //   handleChange(event, list);
  // };

  const onChangeHandler = (event, index) => {
    let oldVariations = menuInfo.variations;
    let newVariations = [];
    oldVariations?.map((item, i) => {
      if (i === index) {
        newVariations.push({ ...item, name: event.target.value });
      } else {
        newVariations.push(item);
      }
    });
    // oldVariations[index].name = event.target.value;
    setMenuInfo({ ...menuInfo, variations: newVariations });
  };

  const onChangePriceHandler = (event, index) => {
    let oldVariations = menuInfo.variations;
    let newVariations = [];
    oldVariations?.map((item, i) => {
      if (i === index) {
        newVariations.push({ ...item, price: event.target.value });
      } else {
        newVariations.push(item);
      }
    });
    // oldVariations[index].price = event.target.value;
    setMenuInfo({ ...menuInfo, variations: newVariations });
  };

  useEffect(() => {
    console.log(menuInfo?.variations, 345);
  }, [menuInfo]);

  // useEffect(() => {
  //   console.log(inputList);
  // }, [inputList]);

  return (
    <div className="TextField">
      {/* <div className="FieldLabel">{label} {validation && <span>*</span>}</div> */}
      {menuInfo?.variations?.length > 0 &&
        menuInfo?.variations?.map((obj, index) => {
          return (
            <>
              <div className="AddField">
                <div style={{ position: "relative" }}>
                  <div className="FieldLabel">
                    {label} {validation && <span>*</span>}
                  </div>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder={placeholder}
                    value={obj?.name}
                    InnerFieldCount={InnerFieldCount}
                    onChange={(e) => onChangeHandler(e, index)}
                    helperText={errors?.variations[index]?.name}
                    error={errors?.variations[index]?.name}
                    inputProps={{
                      maxLength: maxText,
                    }}
                    count={maxText}
                  />
                  {/* <span className="FieldCount">
                    {maxText ? (
                      <span style={{ color: "#B6B9B9", alignSelf: "flex-end" }}>
                        {"" + obj?.name?.length + "/" + maxText}
                      </span>
                    ) : (
                      ""
                    )}
                  </span> */}
                  {InnerFieldCount ? (
                    <span
                      // className="FieldCount "
                      style={{
                        position: "absolute",
                        top: "44px",
                        right: "36px",
                      }}
                    >
                      {maxText && (
                        <span
                          style={{ color: "#B6B9B9", alignSelf: "flex-end" }}
                        >
                          {"" + obj?.name?.length + "/" + maxText}
                        </span>
                      )}
                    </span>
                  ) : (
                    <span className="FieldCount">
                      {maxText && (
                        <span
                          style={{ color: "#B6B9B9", alignSelf: "flex-end" }}
                        >
                          {" "}
                          CHAR {"" + obj?.name?.length + "/" + maxText}
                        </span>
                      )}
                    </span>
                  )}
                </div>
                <>
                  <div className="FieldLabel" style={{ marginTop: "20px" }}>
                    {labelSecond} {validation && <span>*</span>}
                  </div>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder={placeholderPrice}
                    value={obj?.price}
                    onChange={(e) => onChangePriceHandler(e, index)}
                    helperText={errors?.variations[index]?.price}
                    error={errors?.variations[index]?.price}
                    inputProps={{
                      maxLength: maxText,
                    }}
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" sx={{ mt: -0.6 }}>
                          $
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <span className="FieldCount">
                  {maxText ? (
                    <span style={{ color: "#B6B9B9", alignSelf: "flex-end" }}>
                      {"" + obj?.price?.length + "/" + maxText}
                    </span>
                  ) : (
                    ""
                  )}
                </span> */}
                </>

                {inputList.length > minItem && (
                  <Button
                    className="RemoveField"
                    variant="text"
                    onClick={(event) => handleRemove(index, event)}
                  >
                    <DeleteForeverRoundedIcon />{" "}
                  </Button>
                )}
              </div>
            </>
          );
        })}
      {inputList.length < maxItem && (
        <Button
          variant="text"
          // onClick={handleaddclick}
          onClick={(e) => addVariationHandler()}
          className="AddMoreButton"
          sx={{
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <AddRoundedIcon />
          {addButtonLabel}
        </Button>
      )}
    </div>
  );
}

export default AddTextField;
