import React, { useState, useEffect } from "react";
import { Alert, Container, Snackbar } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Table from "../../components/Table/Table";
import tableImg from "../../assets/imgs/tableImg.png";
import DraggableDialog from "../../hooks/Dialog";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import SideBarDrawer from "../../components/SideBarDrawer/SideBarDrawer";
import { useSelector } from "react-redux";
import Crown from "../../assets/imgs/vip-crown-fill.svg";
import {
  useDeleteUserMutation,
  useGetUserListQuery,
} from "../../redux/features/users/userApi";
import useProtected from "../../hooks/useProtected";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "../../hooks/HelperFunctions";

const UsersListing = () => {
  const activeLocation = useSelector((state) => state.auth.activeLocation);
  const user = useSelector((state) => state.auth.user);

  const { data, isLoading, isFetching, error } = useGetUserListQuery(
    activeLocation,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [deleteUser, deleteUserOptions] = useDeleteUserMutation();

  useProtected(error);
  useProtected(deleteUserOptions.error);

  useEffect(() => {
    if (!isLoading && !isFetching && data && activeLocation != "") {
      setRows(convertDataToCellRows(data?.body?.data));
    }
  }, [data, isLoading, isFetching, activeLocation]);

  const [rows, setRows] = useState([]);
  const [itemDelete, setItemDelete] = useState(false);
  const [snakbaropen, setSnakbarOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const navigate = useNavigate();
  // menu
  // const [open, setOpen] = useState(false);
  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };
  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };
  const editUser = (id) => {
    navigate(`/user-update/${id}`);
  };
  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr?.map((item) => {
        let statusColor = "";
        let statusLabel = "";
        let statusBackground = "";

        if (item?.status == "active") {
          statusColor = "#0B9444";
          statusLabel = "Active";
          statusBackground = "#E1F2E8";
        } else if (item?.status == "inactive") {
          statusColor = "#E63946";
          statusLabel = "Inactive";
          statusBackground = "#FCE7E9";
        }
        let roleTitle = "";
        return {
          cell: [
            // {
            //   img: tableImg,
            //   roundedImg: true,
            //   imgStatus: true,
            //   imgText: item?.firstName
            //     ? `${item?.firstName} ${item?.lastName}`
            //     : "N/A",
            //   align: "left",
            // },
            {
              img: item?.photo,
              imgStatus: true,
              imgText: item?.firstName
                ? `${item?.firstName} ${item?.lastName}`
                : "N/A",
              align: "left",
            },
            { textBold: item?.email || "N/A" },
            {
              textBold:
                item?.role === "owner" ? (
                  <div className="IconCell">
                    {capitalizeFirstLetter(item?.role)} <img src={Crown} />
                  </div>
                ) : (
                  item?.role || "N/A"
                ),
            },
            {
              Status: true,
              background: statusBackground,
              color: statusColor,
              label: statusLabel,
            },
            {
              ActionButton: true,
              ActionButtonLabel: "Action",
            },
          ],
          action: [
            // { label: "View", onClick: onClick, },
            { label: "Edit", onClick: (e) => editUser(item?._id) },
            { label: "Delete", onClick: (e) => deleteHandler(item?._id) },
          ],
        };
      });
    }
  };

  //*  Item delete modal
  const onClick = () => {
    alert("onclick working fine!");
  };

  //* delete item
  const handleItemDelete = () => {
    setItemDelete(true);
  };

  const deleteHandler = (id) => {
    setDeleteId(id);
    handleItemDelete();
  };

  const ItemDeleteClose = () => {
    setDeleteId("");
    setItemDelete(false);
  };

  //*  Item delete snackbar
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnakbarOpen(false);
  };

  const deleteConfirmHandler = async () => {
    try {
      const result = await deleteUser(deleteId);
      if (result?.data) {
        setItemDelete(false);
        // setSnakbarOpen(true);
        toast.success("User has deleted successfully.");
      } else {
        toast.error(result?.data?.message);
        // console.error(result?.data?.message);
      }
      setDeleteId("");
    } catch (err) {
      console.error(err?.message);
    }
    // setSnakbarOpen(true);
  };
  const headCells = [
    {
      id: "User",
      numeric: false,
      disablePadding: true,
      label: "User",
      width: "30%",
    },
    {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "Email",
      width: "20%",
    },
    {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "Role",
      width: "20%",
    },
    {
      id: "Chip",
      numeric: true,
      disablePadding: false,
      label: "Status",
      width: "20%",
    },
    {
      id: "Actions",
      numeric: true,
      disablePadding: false,
      label: "",
      width: "10%",
    },
  ];

  return (
    <>
      <div className="Table">
        <Table
          title="Users"
          tableHeader={true}
          addLabel="New User"
          link="/user-stapper"
          headCells={headCells}
          rows={rows}
          AddButton={true}
          Checkbox={false}
          footer={true}
          innerHeader={false}
          innerTitle=""
          AddButtonInner={false}
          addLabelInner="Add Payment"
          InnerButtonColor="#004FE0"
          InnerButtonTextColor="#fff"
          InnderButtonIcon={<AddRoundedIcon />}
          showSearchBox={true}
        />
      </div>
      {/* ------------------- item delete mdoal ------- */}
      <DraggableDialog
        title="Delete this user?"
        body="Once you delete it, you can't get it back."
        openDialog={itemDelete}
        handleCloseDialog={ItemDeleteClose}
        ModalFooter={true}
        actionButtonN
        cancelColor="#1B1B1F"
        actionButton="Delete"
        handleConfirmCloseDialog={() => deleteConfirmHandler()}
      />
      {/* ------------------- snackbar ---------------- */}
      <Snackbar
        open={snakbaropen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
        zIndex={9999}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{
            display: "flex",
            alignItems: "center",
            width: "300px",
            fontSize: "1.2rem",
            "& > svg": {
              marginRight: "0.5rem",
            },
            backgroundColor: "green !important",
            color: "#fff !important",
          }}
        >
          User deleted
        </Alert>
      </Snackbar>
    </>
  );
};

export default UsersListing;
