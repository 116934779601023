import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import Table from "../../components/Table/Table";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import tableImg from "../../assets/imgs/tableImg.png";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import SideBarDrawer from "../../components/SideBarDrawer/SideBarDrawer";
import { useGetLocationListQuery } from "../../redux/features/locations/locationApi";
import useProtected from "../../hooks/useProtected";
import { useSelector } from "react-redux";

const LocationListing = () => {
  const { data, isLoading, isFetching, error } = useGetLocationListQuery();
  // menu

  useProtected(error);

  useEffect(() => {
    if (!isLoading && !isFetching && data) {
      setRows(convertDataToCellRows(data.body.data));
    }
  }, [data, isLoading, isFetching]);

  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map((item) => {
        let statusColor = "";
        let statusLabel = "";
        let statusBackground = "";

        if (item?.status == "Accepting orders") {
          statusColor = "#0B9444";
          statusLabel = "Accepting orders";
          statusBackground = "#E1F2E8";
        } else if (item?.status == "Coming soon") {
          statusColor = "#676C7B";
          statusLabel = "Coming soon";
          statusBackground = "#EDEDEF";
        } else if (item?.status == "Temporarily closed") {
          statusColor = "#E63946";
          statusLabel = "Temporarily closed";
          statusBackground = "#FCE7E9";
        }

        return {
          cell: [
            {
              img: item?.photo,
              imgStatus: true,
              imgText: item?.name,
              align: "left",
            },
            { textBold: item?.city || "N/A" },
            { textBold: item?.locationCode || "N/A" },
            {
              Status: true,
              background: statusBackground,
              color: statusColor,
              label: statusLabel,
            },
            {
              Button: true,
              buttonLabel: "Edit",
              buttonTextcolor: "#4C9F41",
              buttonLink: `/location-update/${item?._id}`,
            },
          ],
        };
      });
    }
  };
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      width: "35%",
    },
    {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "Address",
      width: "30%",
    },
    {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "Location Code",
      width: "15%",
    },
    {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "Status",
      width: "15%",
    },
    {
      id: "Actions",
      numeric: true,
      disablePadding: false,
      label: "",
      width: "5%",
    },
  ];

  // const rows = [
  //   {
  //     cell: [
  //       {
  //         img: tableImg,
  //         imgStatus: true,
  //         imgText: "Radhuni Sweets & Restaurant - Brooklyn",
  //         align: "left",
  //       },
  //       { textBold: "483 McDonald Ave Brooklyn, NY 11218" },
  //       {
  //         Status: true,
  //         background: "#E1F2E8",
  //         color: "#0B9444",
  //         label: "Accepting orders",
  //       },
  //       {
  //         Button: true,
  //         buttonLabel: "Edit",
  //         buttonTextcolor: "#4C9F41",
  //         buttonLink: "/location-stapper",
  //       },
  //     ],
  //   },
  //   {
  //     cell: [
  //       {
  //         img: tableImg,
  //         imgStatus: true,
  //         imgText: "Radhuni Sweets & Restaurant - Brooklyn",
  //         align: "left",
  //       },
  //       { textBold: "483 McDonald Ave Brooklyn, NY 11218" },
  //       {
  //         Status: true,
  //         background: "#EDEDEF",
  //         color: "#676C7B",
  //         label: "Coming soon",
  //       },
  //       {
  //         Button: true,
  //         buttonLabel: "Edit",
  //         buttonTextcolor: "#4C9F41",
  //         buttonLink: "/location-stapper",
  //       },
  //     ],
  //   },
  //   {
  //     cell: [
  //       {
  //         img: tableImg,
  //         imgStatus: true,
  //         imgText: "Radhuni Sweets & Restaurant - Brooklyn",
  //         align: "left",
  //       },
  //       { textBold: "483 McDonald Ave Brooklyn, NY 11218" },
  //       {
  //         Status: true,
  //         background: "#FCE7E9",
  //         color: "#E63946",
  //         label: "Temporarily closed",
  //       },
  //       {
  //         Button: true,
  //         buttonLabel: "Edit",
  //         buttonTextcolor: "#4C9F41",
  //         buttonLink: "/location-stapper",
  //       },
  //     ],
  //   },
  //   {
  //     cell: [
  //       {
  //         img: tableImg,
  //         imgStatus: true,
  //         imgText: "Radhuni Sweets & Restaurant - Brooklyn",
  //         align: "left",
  //       },
  //       { textBold: "483 McDonald Ave Brooklyn, NY 11218" },
  //       {
  //         Status: true,
  //         background: "#E1F2E8",
  //         color: "#0B9444",
  //         label: "Accepting orders",
  //       },
  //       {
  //         Button: true,
  //         buttonLabel: "Edit",
  //         buttonTextcolor: "#4C9F41",
  //         buttonLink: "/location-stapper",
  //       },
  //     ],
  //   },
  //   {
  //     cell: [
  //       {
  //         img: tableImg,
  //         imgStatus: true,
  //         imgText: "Radhuni Sweets & Restaurant - Brooklyn",
  //         align: "left",
  //       },
  //       { textBold: "483 McDonald Ave Brooklyn, NY 11218" },
  //       {
  //         Status: true,
  //         background: "#E1F2E8",
  //         color: "#0B9444",
  //         label: "Accepting orders",
  //       },
  //       {
  //         Button: true,
  //         buttonLabel: "Edit",
  //         buttonTextcolor: "#4C9F41",
  //         buttonLink: "/location-stapper",
  //       },
  //     ],
  //   },
  // ];

  return (
    <>
      <div className="Table">
        <Table
          title="Locations"
          tableHeader={true}
          addLabel="Add Location"
          link="/location-stapper"
          headCells={headCells}
          rows={rows}
          AddButton={true}
          Checkbox={false}
          footer={true}
          innerHeader={false}
          innerTitle=""
          AddButtonInner={false}
          addLabelInner="Add Payment"
          InnerButtonColor="#004FE0"
          InnerButtonTextColor="#fff"
          InnderButtonIcon={<AddRoundedIcon />}
          showSearchBox={true}
        />
      </div>
    </>
  );
};

export default LocationListing;
