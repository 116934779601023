import React, { useState, useEffect } from "react";
import { Alert, Container, Snackbar } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Table from "../../components/Table/Table";
import tableImg from "../../assets/imgs/tableImg.png";
import DraggableDialog from "../../hooks/Dialog";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import SideBarDrawer from "../../components/SideBarDrawer/SideBarDrawer";
import {
  useDeleteMenuItemMutation,
  useGetMenuListByLocationQuery,
  useSerachMenuItemsMutation,
  useUpdateMenuMutation,
} from "../../redux/features/menus/menuApi";
import useProtected from "../../hooks/useProtected";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { capitalizeWords } from "../../hooks/HelperFunctions";

const MenuListing = () => {
  const activeLocation = useSelector((state) => state.auth.activeLocation);
  const { data, isLoading, isFetching, error } = useGetMenuListByLocationQuery(
    activeLocation,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [deleteItem, deleteItemOptions] = useDeleteMenuItemMutation();
  const [searchItem, searchItemOptions] = useSerachMenuItemsMutation();
  const [updateMenuItem, updateMenuItemOptions] = useUpdateMenuMutation();

  useProtected(error);
  useProtected(updateMenuItemOptions.error);
  useProtected(deleteItemOptions?.error);
  useProtected(searchItemOptions?.error);

  const [itemDelete, setItemDelete] = useState(false);
  const [snakbaropen, setSnakbarOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [deletedId, setDeletedId] = useState("");
  const [serachText, setSerachText] = useState("");
  const [asc, setAsc] = useState(true);
  const [menuListing, setMenuListing] = useState([]);
  // menu
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  //*  Item delete modal
  const onClick = () => {
    alert("onclick working fine!");
  };

  //* delete item
  const handleItemDelete = (id) => {
    setDeletedId(id);
    setItemDelete(true);
  };

  const handeleMenuItemDete = async () => {
    try {
      const result = await deleteItem(deletedId);
      // console.log(result, 328);
      if (result?.data) {
        setItemDelete(false);
        toast.success("Menu item has deleted successfully");
      } else {
        console.error(result?.data?.message);
      }
    } catch (err) {
      console.error(err?.message);
    }
  };

  const ItemDeleteClose = () => {
    setItemDelete(false);
  };

  //*  Item delete snackbar
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnakbarOpen(false);
  };

  //=============================== edit data =============================================//
  const navigate = useNavigate();
  const handleeditItem = (id) => {
    navigate(`/update-menuItem/${id}`);
  };
  //=============================== edit data =============================================//

  //======================== fetaching data ======================================//
  useEffect(() => {
    if (data && !isLoading && activeLocation != "") {
      setRows(convertDataToCellRows(data?.body?.data));
      setMenuListing(data?.body?.data);
    }
  }, [data, isLoading, activeLocation]);
  //======================== fetaching data ======================================//

  // ============================= status data start ======================= //
  const Statustdata = [
    { value: "Available", statuscolor: "#0B9444", select: "Available" },
    { value: "Unavailable", statuscolor: "#E63946", select: "Unavailable" },
    { value: "Sold out", statuscolor: "#FF6200", select: "Sold out" },
  ];
  // ============================= status data end ======================= //

  //=========================== showing data in the table ============================//
  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Item",
      width: "50%",
    },
    {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "Price",
      width: "20%",
      // sort: "sort",
      // headerOnClick: () => ascendingOrDescendingSorting("price"),
    },
    {
      id: "Chip",
      numeric: true,
      disablePadding: false,
      label: "Status",
      width: "20%",
      sort: "sort",
      headerOnClick: () => ascendingOrDescendingSorting("status"),
    },
    {
      id: "Actions",
      numeric: true,
      disablePadding: true,
      label: "",
      width: "10%",
    },
  ];
  ///=================================== sorting part start =============//
  const ascendingOrDescendingSorting = (field) => {
    let sortedMediaListing = [...menuListing];
    if (asc) {
      sortedMediaListing.sort((a, b) => a[field].localeCompare(b[field]));
    } else {
      sortedMediaListing.sort((a, b) => b[field].localeCompare(a[field]));
    }
    setRows(convertDataToCellRows(sortedMediaListing));
    setAsc(!asc);
  };
  ///=================================== sorting part end =============//
  //============================= status code ==================================//
  //default value
  const getDataStatus = (status) => {
    let data = Statustdata?.find((statusData) => statusData?.select === status);
    return data?.value;
  };
  const updateMenuStatusHandler = async (statusData, item) => {
    let data = {
      ...item,
      status: statusData,
    };

    let result = await updateMenuItem({ ...data, id: item?._id });
    if (!result?.data.error) {
      toast.success("Menu has updated successfully");
    } else {
      console.error("something went wrong");
    }
  };
  //============================= status code ==================================//

  const convertDataToCellRows = (array) => {
    if (array?.length > 0) {
      return array?.map((item) => ({
        cell: [
          {
            img: item?.photo ? item?.photo : tableImg,
            imgStatus: true,
            imgText: item?.name ? capitalizeWords(item?.name) : "N/A",
            align: "left",
          },

          {
            textBold:
              item?.variations && item?.variations?.length > 0
                ? `$${parseFloat(item?.variations[0]?.price)?.toFixed(2)}`
                : `$${parseFloat(item?.price)?.toFixed(2)}`,
          },
          {
            StatusSelect: true,
            Statustdata: Statustdata,
            value: getDataStatus(item?.status),
            StatusSelectWidth: "100%",
            handleStatusChange: (e) =>
              updateMenuStatusHandler(e.target.value, item),
          },
          {
            ActionButton: true,
            ActionButtonLabel: "Action",
          },
        ],
        //======= Action Date Start===== //
        action: [
          // { label: "View", onClick: onClick, },
          { label: "Edit", onClick: (e) => handleeditItem(item?._id) },
          { label: "Delete", onClick: (e) => handleItemDelete(item?._id) },
        ],
        //======= Action Date End===== //
      }));
    } else {
      return [];
    }
  };
  //=========================== showing data in the table ============================//

  //============================== search part =========================================//
  const handleSearchText = (e) => {
    e.preventDefault();
    setSerachText(e.target.value);
    // console.log(serachText, "searched text");
  };
  const handleSearchClear = (e) => {
    setSerachText("");
  };
  const handleSearch = async () => {
    try {
      const result = await searchItem({
        term: serachText,
        locationId: activeLocation,
      });
      if (result?.data) {
        setRows(convertDataToCellRows(result?.data?.body?.data));
      } else {
        console.error(result?.data?.message);
      }
    } catch (err) {
      console.error(err?.message);
    }
  };
  //============================== search part =========================================//

  return (
    <>
      <div className="Table">
        <Table
          title="Menu"
          tableHeader={true}
          addLabel="New Item"
          link="/add-menuItem"
          headCells={headCells}
          rows={rows}
          AddButton={true}
          Checkbox={false}
          footer={true}
          innerHeader={true}
          innerTitle=""
          AddButtonInner={false}
          addLabelInner="Add Payment"
          InnerButtonColor="#004FE0"
          InnerButtonTextColor="#fff"
          InnderButtonIcon={<AddRoundedIcon />}
          showSearchBox={true}
          searchBoxPlaceholder="Search Product"
          searchBoxText={serachText}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          handleSearchClear={handleSearchClear}
          perPageItems={30}
        />
      </div>
      {/* <Container sx={{ mt: 16, }}>
        
      </Container> */}
      {/* ------------------- item delete mdoal ------- */}
      <DraggableDialog
        title="Delete this item?"
        body="Once you delete it, you can't get it back."
        openDialog={itemDelete}
        handleCloseDialog={ItemDeleteClose}
        ModalFooter={true}
        actionButtonN
        cancelColor="#1B1B1F"
        actionButton="Delete"
        handleConfirmCloseDialog={handeleMenuItemDete}
      />
      {/* ------------------- snackbar ---------------- */}
      <Snackbar
        open={snakbaropen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
        zIndex={9999}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{
            display: "flex",
            alignItems: "center",
            width: "300px",
            fontSize: "1.2rem",
            "& > svg": {
              marginRight: "0.5rem",
            },
            backgroundColor: "green !important",
            color: "#fff !important",
          }}
        >
          Item deleted
        </Alert>
      </Snackbar>
    </>
  );
};

export default MenuListing;
