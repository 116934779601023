import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import ComingSoon from "../../../components/ComingSoon/ComingSoon";
import VerticalTab from "../../../components/VerticalTab/VerticalTab";
import EventRoundedIcon from "@mui/icons-material/EventRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import Overview from "./profile/Profile";
import Courses from "./account/Account";
// import Payments from "./payments/Payments";
import { Person, Receipt, Security } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import Certificate from "@mui/icons-material/EmojiEvents";
import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from "@mui/material/colors";

import DraggableDialog from "../../../hooks/Dialog";
import Account from "./account/Account";
import Profile from "./profile/Profile";
import DefaultLocaiton from "./defaultLocaiton/DefaultLocaiton";
import Modals from "../../../components/Form/Modals/Modals";
import EditProfile from "../../../components/EditProfile/EditProfile";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetSingleUserListQuery,
  useUpdateSettingsMutation,
} from "../../../redux/features/users/userApi";
import useProtected from "../../../hooks/useProtected";
import { getProfileAvatarFromName } from "../../../utils/helpers";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import { setUserData } from "../../../redux/features/authSlice";
import { toast } from "react-toastify";

function SettingsDetails(props) {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const { data, isLoading, error, refetch } = useGetSingleUserListQuery(
    user?._id,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  // const [updateSettings, updateSettingsOptions] = useUpdateSettingsMutation();
  // useProtected(updateSettingsOptions.error);
  // useProtected(error);

  const [updateSettings, updateSettingsOptions] = useUpdateSettingsMutation();
  useProtected(updateSettingsOptions.error);

  const [editProfile, setEditProfile] = useState(false);
  const [birthDate, setBirthDate] = useState(null);
  const [birthMonth, setBirthMonth] = useState(null);
  const [birthYear, setBirthYear] = useState(null);
  const [personalInfo, setPersonalInfo] = useState({
    _id: "",
    gender: "",
    phone: "",
    birthDate: "",
    timezone: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    firstName: "",
    lastName: "",
    middleName: "",
    address: "",
    profilePicture: "",
    profilePictureName: "",
    nysedId: "",
    highestEducation: "",
    occupation: "",
    email: "",
    secondaryEmail: "",
    zipCode: "",
    role: "",
  });

  // console.log(data.body, "user data");

  const handleOpenModal = () => setEditProfile(true);
  const handleCloseModal = () => {
    setPersonalInfo(user);
    setEditProfile(false);
  };

  //========================= setting birthDate ===============================================//
  useEffect(() => {
    const dateObj = personalInfo?.birthDate
      ? new Date(personalInfo?.birthDate)
      : new Date();

    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate() - 1;

    if (!birthDate && personalInfo?.birthDate) setBirthDate(day);
    if (!birthMonth && personalInfo?.birthDate) setBirthMonth(month);
    if (!birthYear && personalInfo?.birthDate) setBirthYear(year);
  }, [personalInfo]);
  //========================= setting birthDate ===============================================//

  //=============================== data fetching ===================================//
  useEffect(() => {
    if (!isLoading && data && !error) {
      let personalInfoData = data.body.data;
      setPersonalInfo({
        ...personalInfo,
        _id: personalInfoData?._id,
        gender: personalInfoData?.gender,
        phone: personalInfoData?.phone,
        birthDate: personalInfoData?.birthDate,
        timezone: personalInfoData?.timezone,
        addressLine2: personalInfoData?.addressLine2,
        city: personalInfoData?.city,
        state: personalInfoData?.state,
        country: personalInfoData?.country,
        firstName: personalInfoData?.firstName,
        lastName: personalInfoData?.lastName,
        middleName: personalInfoData?.middleName,
        address: personalInfoData?.address,
        profilePicture: personalInfoData?.profilePicture,
        profilePictureName: personalInfoData?.profilePictureName,
        nysedId: personalInfoData?.nysedId,
        highestEducation: personalInfoData?.highestEducation,
        occupation: personalInfoData?.occupation,
        email: personalInfoData?.email,
        secondaryEmail: personalInfoData?.secondaryEmail,
        zipCode: personalInfoData?.secondaryEmail,
        role: personalInfoData?.role,
      });
    }
  }, [data, error, isLoading]);
  //=============================== data fetching ===================================//

  const tabData = [
    {
      icon: <Person />,
      label: "Profile",
    },

    {
      icon: <AdminPanelSettingsRoundedIcon />,
      label: "Account",
    },

    {
      icon: <LocationOnRoundedIcon />,
      label: "Default locaiton",
    },

    // {
    //   icon: <Security />,
    //   label: "Security",
    // },
  ];

  const tabBody = [
    {
      id: 0,
      panel: (
        <Profile
          personalInfo={personalInfo}
          birthDate={birthDate}
          birthMonth={birthMonth}
          birthYear={birthYear}
        />
      ),
    },
    {
      id: 1,
      panel: <Account personalInfo={personalInfo} />,
    },

    {
      id: 2,
      panel: <DefaultLocaiton />,
    },

    // {
    //   id: 4,
    //   panel: <SecurityTab />,
    // },
  ];

  const [currentTab, setCurrentTab] = useState(0);
  const handleTabSwitch = (activeTab) => {
    console.log(activeTab);
    setCurrentTab(activeTab);
  };

  //  ============ breadcrums ================
  // const breadcrumbsData = [
  //   {
  //     label: "Customers",
  //     link: "/customers-listing",
  //     icon: <GroupsRoundedIcon fontSize="small" />,
  //   },

  //   {
  //     label: "Details",
  //     link: "customer-details",
  //   },
  // ];
  //  ============ breadcrums ================
  // useEffect(() => {
  //   console.log(personalInfo, 328);
  // }, [personalInfo]);
  //=========================== errors hangdeling ======================================//
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    timezone: "",
    birthMonth: "",
    state: "",
    birthDate: "",
    birthYear: "",
  });

  const checkBasicFormField = async () => {
    let errorObject = { ...errors };
    let error = 0;
    if (personalInfo.firstName == "") {
      errorObject = { ...errorObject, firstName: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, firstName: "" };
    }

    if (personalInfo.lastName == "") {
      errorObject = { ...errorObject, lastName: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, lastName: "" };
    }
    if (personalInfo.timezone == "") {
      errorObject = { ...errorObject, timezone: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, timezone: "" };
    }
    if (personalInfo.state == "") {
      errorObject = { ...errorObject, state: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, state: "" };
    }
    if (birthMonth == "") {
      errorObject = { ...errorObject, birthMonth: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, birthMonth: "" };
    }
    if (birthDate == "") {
      errorObject = { ...errorObject, birthDate: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, birthDate: "" };
    }
    if (birthYear == "") {
      errorObject = { ...errorObject, birthYear: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, birthYear: "" };
    }

    setErrors({ ...errors, ...errorObject });

    return error > 0 ? false : true;
  };
  //=========================== errors hangdeling ======================================//
  //============================ update data ==========================================//
  const handleUpdate = async () => {
    let isValid = await checkBasicFormField();

    if (!isValid) {
      // alert(3425);
      return false;
    }
    const date = new Date(
      `${birthYear}-${String(birthMonth).padStart(2, "0")}-${String(
        birthDate
      ).padStart(2, "0")}T18:06:40.000Z`
    );
    const data = {
      role: personalInfo?.role,
      gender: personalInfo?.gender,
      whatsappNumber: personalInfo?.whatsappNumber,
      timezone: personalInfo?.timezone,
      addressLine2: personalInfo?.addressLine2,
      city: personalInfo?.city,
      state: personalInfo?.state,
      country: personalInfo?.country,
      firstName: personalInfo?.firstName,
      lastName: personalInfo?.lastName,
      middleName: personalInfo?.middleName,
      address: personalInfo?.address,
      profilePicture: personalInfo?.profilePicture,
      profilePictureName: personalInfo?.profilePictureName,
      nysedId: personalInfo?.nysedId,
      highestEducation: personalInfo?.highestEducation,
      occupation: personalInfo?.occupation,
      email: personalInfo?.email,
      secondaryEmail: personalInfo?.secondaryEmail,
      zipCode: personalInfo?.secondaryEmail,
      birthDate: date?.toISOString(),
    };
    const result = await updateSettings({ ...data, id: personalInfo?._id });
    // console.log(result?.data?.body?.data, 328);
    if (!result?.data?.error) {
      let userInfo = result?.data?.body?.data || null;
      dispatch(setUserData(userInfo));
      localStorage.setItem("user", JSON.stringify(userInfo));
      toast.success("User data has updated successfully");
      setEditProfile(false);
    }
  };
  //============================ update data ==========================================//

  return (
    <div>
      {/* <Breadcrumbs breadcrumbsData={breadcrumbsData} /> */}
      <div className="UserDetails">
        <li>
          <div className="UserDetailsleftcol">
            <div className="UserDetailsrIcon">
              <Avatar
                sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }}
                src={personalInfo?.profilePicture || ""}
              >
                {" "}
                {personalInfo?.profilePicture
                  ? ""
                  : getProfileAvatarFromName(personalInfo)}{" "}
              </Avatar>
              {/* <Avatar
                sx={{
                  bgcolor: deepPurple[300],
                  width: 96,
                  height: 96,
                  fontSize: "60px",
                }}
              >
                s
              </Avatar> */}
            </div>
            <div className="UserDetailsDetail">
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <span style={{ display: "inline-block" }}>
                  <h1>
                    {personalInfo?.firstName} {personalInfo?.lastName}
                  </h1>
                </span>{" "}
                {/* <span
                style={{ display: "inline-block" }}
                className="status rounded success"
              >
                Active
              </span> */}
              </div>
              <p> {personalInfo?.role ? personalInfo?.role : "N/A"}</p>
            </div>
          </div>

          <div className="EditUserProfile actionDoropdown">
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleOpenModal}
              sx={{ color: "#4C9F41" }}
              // endIcon={<KeyboardArrowDownRoundedIcon />}
            >
              Edit Profile
            </Button>

            {/* <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleOpenEditInfoButton}>Edit Info</MenuItem>
              <MenuItem onClick={handleOpenRevoke}>Send Offer</MenuItem>
              <MenuItem onClick={handleOpenDelete} sx={{color: '#BA1A1A'}}>Block</MenuItem>
              <MenuItem onClick={handleOpenDelete} sx={{color: '#4C9F41'}}>Unblock</MenuItem>
            </Menu> */}
          </div>
        </li>

        {/* <div className="UserStatus">
          <div className="UserStatusCol">{totalRunningCourses} running courses</div>
          <div className="UserStatusCol">{totalCompletedCourses} Completed Courses</div>
          <div className="UserStatusCol">{totalCirtificates} Certificates</div>
        </div> */}
      </div>

      <VerticalTab
        //title="Introduction to Islamic Economics and Finance"
        //name="MUNA Academy"
        link="#"
        tabData={tabData}
        tabBody={tabBody}
        onTabSwitch={handleTabSwitch}
        //onClick={onClick()}
        //ButtonLabel="Start Class"
      />

      {/*====================== Edit new Form Modal ===================== */}
      {/* <Modals
        ModalTitle="Edit Profile"
        handleClose={handleCloseModal}
        // handleConfirm={() => handleConfirm()}
        open={editProfile}
        // ButtonClose="CLOSE"
        ButtonConfirm="ok"
        width="570px"
        ModalFooter={false}
        Headerbackground="#fff"
        Headercolor="#676C7B"
        Footerbackground="#fff"
        ModalBody={
          <>
            <EditProfile
              personalInfo={personalInfo}
              setPersonalInfo={setPersonalInfo}
              birthDate={birthDate}
              setBirthDate={setBirthDate}
              birthMonth={birthMonth}
              setBirthMonth={setBirthMonth}
              birthYear={birthYear}
              setBirthYear={setBirthYear}
              setEditProfile={setEditProfile}
              handleCloseModal={handleCloseModal}
              updateSettings={updateSettings}
            />
          </>
        }
      /> */}
      {/* ==================================== */}
      <SideDrawer
        title="Edit Media"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        deleteText="Delete Permanently"
        // onClickDelete={onClickDelete}
        DeleteButton={false}
        sideOpen="right"
        open={editProfile}
        handleDrawerClose={handleCloseModal}
        CheckboxLabel="Add another after saving"
        Checkbox={false}
        LeftButton={false}
        ButtonLeft="Upload New"
        // ButtonLeftIcon={<FileUploadOutlinedIcon/>}
        ButtonLeftbackground="transparent"
        ButtonLeftBorder="#E4E2E6 1px solid"
        ButtonLeftTextColor="#0450E1"
        LeftButtonlink="/invite-new-users"
        clickSaveButton={handleUpdate}
        FooterWithoutTab={true}
        // panelTab={panelTab}
        // tabData={tabData}
        arrowIcon={false}
        body={
          <>
            <EditProfile
              personalInfo={personalInfo}
              setPersonalInfo={setPersonalInfo}
              birthDate={birthDate}
              setBirthDate={setBirthDate}
              birthMonth={birthMonth}
              setBirthMonth={setBirthMonth}
              birthYear={birthYear}
              setBirthYear={setBirthYear}
              setEditProfile={setEditProfile}
              handleCloseModal={handleCloseModal}
              updateSettings={updateSettings}
              errors={errors}
            />
          </>
        }
      />
    </div>
  );
}

export default SettingsDetails;
