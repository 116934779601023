import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import ModalOne from "../../../../components/Modals/ModalOne";
import ModalTwo from "../../../../components/Modals/ModalTwo";
import AddressForm from "./AccountForm/AccountForm";
// import ModalThree from "../../../../components/Modals/ModalThree";

import VerifiedUser from "@mui/icons-material/Verified";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Modals from "../../../../components/Form/Modals/Modals";
import AddPhoneModal from "../../../../components/SettingComponent/AddPhoneModal/AddPhoneModal";
import { Grid } from "@mui/material";
import FieldPassword from "../../../../components/Form/FieldPassword/FieldPassword";
import Buttons from "../../../../components/Button/Button";
import axiosApi from "../../../../utils/axios";
import { useNavigate } from "react-router";
import FieldInputPassword from "../../../../components/Form/FieldInputPassword/FieldInputPassword";
import { PasswordContext } from "../../../../context/PaswordProvider";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

function Account({ personalInfo }) {
  // * modal section
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const handleOpenPassModal = () => setPasswordModal(true);
  const navigate = useNavigate();

  //================== password change part start ====================================//

  //context api
  const { setPasswordValue } = useContext(PasswordContext);
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    password: "",
  });

  const handleClosePassModal = () => {
    setPassword("");
    setPasswordModal(false);
    setErrors({ ...errors, password: "" });
  };

  // console.log(password, 328);
  const isValidPassword = async () => {
    if (password == "") {
      setErrors({ ...errors, password: "Enter your password" });
      return false;
    }
    return true;
  };

  const handleCheckPassword = async () => {
    try {
      let email = personalInfo?.email;

      const data = {
        email: email ? email.toLowerCase() : "",
        password,
      };
      let isPasswordValid = await isValidPassword();
      if (!isPasswordValid) {
        return;
      }
      try {
        const result = await axiosApi.post("/users/login", data);
        setPasswordValue(data?.password);
        if (result?.data?.error) {
          setErrors({ ...errors, password: "Enter your correct password" });
          return;
        }
        if (result?.data?.body?.data) {
          navigate("/create-new-password");
        }
      } catch (err) {
        console.error(err.message);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  //================== password change part end ====================================//

  return (
    <>
      <div className="TableContainer">
        <div className="TableInnerHeader">
          <h3>Access details</h3>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left">Email address</StyledTableCell>
                <StyledTableCell align="left">
                  <div className="cellwithchip">
                    {personalInfo.email}
                    <div className="VerifyChip">
                      <VerifiedRoundedIcon /> Verified
                    </div>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">Phone number</StyledTableCell>
                <StyledTableCell align="left">
                  <div className="flex alignItem justifyBetween">
                    <div className="cellwithchip" style={{ color: "#A4A7B0" }}>
                      {personalInfo?.phone
                        ? personalInfo?.phone
                        : "Add a phone to help keep your account secure"}
                    </div>
                    <div>
                      {!personalInfo?.phone && (
                        <StyledButton onClick={handleOpenModal}>
                          Add
                        </StyledButton>
                      )}
                    </div>
                  </div>
                </StyledTableCell>
              </StyledTableRow>

              {/* <StyledTableRow >
                <StyledTableCell align="left">Phone</StyledTableCell>
                <StyledTableCell align="left">+1 (123) 123-1234</StyledTableCell>
            </StyledTableRow>*/}

              <StyledTableRow>
                <StyledTableCell align="left">Password</StyledTableCell>
                <StyledTableCell align="left">
                  <div className="flex alignItem justifyBetween">
                    <div className="PassworedDots">
                      <FiberManualRecordIcon />
                      <FiberManualRecordIcon />
                      <FiberManualRecordIcon />
                      <FiberManualRecordIcon />
                      <FiberManualRecordIcon />
                      <FiberManualRecordIcon />
                      <FiberManualRecordIcon />
                      <FiberManualRecordIcon />
                      <FiberManualRecordIcon />
                      <FiberManualRecordIcon />
                    </div>
                    <div>
                      <StyledButton onClick={handleOpenPassModal}>
                        Change
                      </StyledButton>
                    </div>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* --------------- add phone number modal --------------- */}
      <Modals
        ModalTitle="Add Phone Number"
        // handleClose={handleClose}
        // handleConfirm={() => handleConfirm()}
        handleClose={handleCloseModal}
        open={openModal}
        ButtonClose="CLOSE"
        ButtonConfirm="CONFIRM"
        width="570px"
        Headerbackground="#fff"
        Headercolor="#676C7B"
        Footerbackground="#00000000"
        ModalBody={
          <>
            <AddPhoneModal
              handleCloseModal={handleCloseModal}
              personalInfo={personalInfo}
            />
          </>
        }
      />
      {/* ------------- reset password modal ------------ */}
      <Modals
        ModalTitle="Reset password"
        // handleClose={handleClose}
        // handleConfirm={() => handleConfirm()}
        handleClose={handleClosePassModal}
        open={passwordModal}
        ButtonClose="CLOSE"
        ButtonConfirm="CONFIRM"
        width="570px"
        Headerbackground="#fff"
        Headercolor="#676C7B"
        Footerbackground="#00000000"
        ModalBody={
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p style={{ margin: 0, textAlign: "center", color: "#0B132B" }}>
                  To continue, first verify it’s you
                </p>
              </Grid>
              <Grid item xs={12}>
                <FieldInputPassword
                  required={true}
                  // handleOnChange={(e) => handleReenterPassword(e)}
                  value={password}
                  handleChange={(value) => {
                    if (value == "") {
                      setErrors((prev) => {
                        prev.password = "Enter your password";
                        return prev;
                      });
                    } else {
                      setErrors((prev) => {
                        prev.password = "";
                        return prev;
                      });
                    }
                    setPassword(value);
                  }}
                  helperText={errors?.password}
                  error={errors?.password}
                  placeholder="Password"
                />
              </Grid>
              <Grid item xs={12}>
                <Buttons
                  label="Continue"
                  buttonColor="#4C9F41"
                  color="#fff"
                  height="40px"
                  width="100%"
                  borderRadius="100px"
                  fontSize="14px"
                  link=""
                  onClick={() => handleCheckPassword()}
                />
              </Grid>
              <Grid item xs={12}>
                <Buttons
                  label="Cancel"
                  buttonColor="transparent"
                  color="#4C9F41"
                  height="40px"
                  width="100%"
                  borderRadius="100px"
                  fontSize="14px"
                  ModalFooter
                  link=""
                  onClick={handleClosePassModal}
                />
              </Grid>
            </Grid>
          </>
        }
      />
    </>
  );
}

export default Account;

const StyledButton = styled(Button)(({ theme }) => ({
  margin: "0 10px",
  // padding: "7px 20px",
  width: "90px",
  color: "#4C9F41",
  fontSize: "12px",
  textTransform: "capitalize",
  background: "transparent",
  border: "1px solid #E4E2E6",
  borderRadius: "1000px",
  gap: "10px",
  alignItems: "center",
  "&:hover": {
    border: "1px solid #4C9F41",
    // background: "#4C9F41",
  },
}));
