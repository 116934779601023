import React, { useEffect, useState } from "react";
import Table from "../../../../../components/Table/Table";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import tableImg from "../../../../../assets/imgs/tableImg.png";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import Modals from "../../../../../components/Form/Modals/Modals";
import { Button, Grid } from "@mui/material";
import SelectFieldChip from "../../../../../components/Form/SelectFieldChip/SelectFieldChip";
import DraggableDialog from "../../../../../hooks/Dialog";
import { capitalizeFirstLetter } from "../../../../../hooks/HelperFunctions";

const Team = (props) => {
  const {
    locationInfo,
    setLocationInfo,
    errors,
    setErrors,
    users,
  } = props

  // * dialog 
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  // * table button onclick
  const onClick = () => {
    alert('button is working fine')
  }

  // * modal section
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [rows, setRows] = useState([]);
  const [deleteMemberIndex,setDeleteMemberIndex] = useState('')

  const deleteMemberHandler = (index) => {
    setDeleteMemberIndex(index)
    handleOpenDialog()
  }
  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map((item,index) => {

        return {
          cell: [
            {
              img: tableImg,
              imgStatus: true,
              roundedImg: true,
              imgText: `${item?.firstName} ${item?.lastName}`,
              align: "left",
            },
            { textBold: item?.role ? capitalizeFirstLetter(item?.role) : "N/A" },
            {
              Button: true,
              buttonLabel: "",
              buttonColor: "#FFFFFF",
              buttonBorder: "#E4E8EC 1px solid",
              buttonTextcolor: "#004FE0",
              // buttonLink: "#",
              // buttonTarget: "_blank",
              onClick : (e) => deleteMemberHandler(index),
              buttonIcon: (
                <DeleteOutlineRoundedIcon
                  sx={{ mr: "0px !important", color: "black !important" }}
                />
              ),
            },
          ]
        }
      })
    }
  }

  // * cells
  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "User",
      width: "45%",
    },
    {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "Role",
      width: "45%",
    },
    {
      id: "Actions",
      numeric: true,
      disablePadding: false,
      label: "",
      width: "10%",
    },
  ];
  useEffect(() => {
    console.log(locationInfo)
    if(Object.keys(locationInfo).length > 0){
      setRows(convertDataToCellRows(locationInfo?.teamMembers))
  }
  },[locationInfo])
  // const rows = [
  //   {
  //     cell: [
  //       {
  //         img: tableImg,
  //         imgStatus: true,
  //         roundedImg: true,
  //         imgText: "Abdullah Al Razi",
  //         align: "left",
  //       },
  //       { textBold: "Manager" },
  //       {
  //         Button: true,
  //         buttonLabel: "",
  //         buttonColor: "#FFFFFF",
  //         buttonBorder: "#E4E8EC 1px solid",
  //         buttonTextcolor: "#004FE0",
  //         // buttonLink: "#",
  //         // buttonTarget: "_blank",
  //         onClick : handleOpenDialog,
  //         buttonIcon: (
  //           <DeleteOutlineRoundedIcon
  //             sx={{ mr: "0px !important", color: "black !important" }}
  //           />
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     cell: [
  //       {
  //         img: tableImg,
  //         imgStatus: true,
  //         roundedImg: true,
  //         imgText: "Abdullah Al Razi",
  //         align: "left",
  //       },
  //       { textBold: "Cashier" },
  //       {
  //         Button: true,
  //         buttonLabel: "",
  //         buttonColor: "#FFFFFF",
  //         buttonBorder: "#E4E8EC 1px solid",
  //         buttonTextcolor: "#004FE0",
  //         // buttonLink: "#",
  //         // buttonTarget: "_blank",
  //         onClick : onClick,
  //         buttonIcon: (
  //           <DeleteOutlineRoundedIcon
  //             sx={{ mr: "0px !important", color: "black !important" }}
  //           />
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     cell: [
  //       {
  //         img: tableImg,
  //         imgStatus: true,
  //         roundedImg: true,
  //         imgText: "Abdullah Al Razi",
  //         align: "left",
  //       },
  //       { textBold: "Cashier" },
  //       {
  //         Button: true,
  //         buttonLabel: "",
  //         buttonColor: "#FFFFFF",
  //         buttonBorder: "#E4E8EC 1px solid",
  //         buttonTextcolor: "#004FE0",
  //         // buttonLink: "#",
  //         // buttonTarget: "_blank",
  //         onClick : onClick,
  //         buttonIcon: (
  //           <DeleteOutlineRoundedIcon
  //             sx={{ mr: "0px !important", color: "black !important" }}
  //           />
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     cell: [
  //       {
  //         img: tableImg,
  //         imgStatus: true,
  //         roundedImg: true,
  //         imgText: "Abdullah Al Razi",
  //         align: "left",
  //       },
  //       { textBold: "Manager" },
  //       {
  //         Button: true,
  //         buttonLabel: "",
  //         buttonColor: "#FFFFFF",
  //         buttonBorder: "#E4E8EC 1px solid",
  //         buttonTextcolor: "#004FE0",
  //         // buttonLink: "#",
  //         // buttonTarget: "_blank",
  //         onClick : onClick,
  //         buttonIcon: (
  //           <DeleteOutlineRoundedIcon
  //             sx={{ mr: "0px !important", color: "black !important" }}
  //           />
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     cell: [
  //       {
  //         img: tableImg,
  //         imgStatus: true,
  //         roundedImg: true,
  //         imgText: "Abdullah Al Razi",
  //         align: "left",
  //       },
  //       { textBold: "Manager" },
  //       {
  //         Button: true,
  //         buttonLabel: "",
  //         buttonColor: "#FFFFFF",
  //         buttonBorder: "#E4E8EC 1px solid",
  //         buttonTextcolor: "#004FE0",
  //         // buttonLink: "#",
  //         // buttonTarget: "_blank",
  //         onClick : onClick,
  //         buttonIcon: (
  //           <DeleteOutlineRoundedIcon
  //             sx={{ mr: "0px !important", color: "black !important" }}
  //           />
  //         ),
  //       },
  //     ],
  //   },
  // ];

  
  const cancelAddTeamHandler = () => {
    setLocationInfo({...locationInfo, teamMembers : []})
    setOpenModal(false)
  }
  const confirmAddTeamHandler = () => {
    setOpenModal(false)
  }

  const deleteMemberConfirmHandler = () => {
    let newTeamMembers = locationInfo?.teamMembers?.filter((singleTeamMember, index) => index != deleteMemberIndex)
    setLocationInfo({...locationInfo, teamMembers : newTeamMembers})
    setDeleteMemberIndex('')

    handleCloseDialog()
  }

  return (
    <>
      <div className="TableSection">
        <Table
          title="Course"
          tableHeader={false}
          addLabel="New User"
          link="add-courses"
          headCells={headCells}
          rows={rows}
          AddButton={true}
          Checkbox={false}
          footer={true}
          innerHeader={true}
          innerTitle="Team"
          AddButtonInner={true}
          addLabelInner="Add Member"
          onClickInnerButton={handleOpenModal}
          InnerButtonColor="#4C9F41"
          InnerButtonTextColor="#fff"
          InnderButtonIcon={<AddRoundedIcon />}
          showSearchBox={false}
        />
      </div>

      {/* ----------------- modal ------------- */}
      <Modals
        ModalTitle="Add member"
        handleClose={handleCloseModal}
        // handleConfirm={() => handleConfirm()}
        // handleClose={() => handleClose()}
        open={openModal}
        ButtonClose="CLOSE"
        ButtonConfirm="CONFIRM"
        width="570px"
        Headerbackground="#fff"
        Headercolor="#676C7B"
        Footerbackground="#F7F9FA"
        ModalBody={
          <div>
            <Grid container>
              <Grid item xs={12}>
                <SelectFieldChip label="Select user" 
                users={users}
                locationInfo={locationInfo}
                setLocationInfo={setLocationInfo}
                errors={errors}
                setErrors={setErrors}/>
                <p className="dropdownSpan">
                  Add a new user from the{" "}
                  <span style={{ display: "inline-block", color: "#4C9F41" }}>
                    Users
                  </span>{" "}
                  menu
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  mt: 1.2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid #E4E8EC",
                    borderRadius: "100px",
                    color: "#1B1B1F",
                    "&:hover": {
                      border: "1px solid #E4E8EC",
                    },
                  }}
                  onClick={(e) => cancelAddTeamHandler()}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    ml: 2,
                    background: "#4C9F41",
                    borderRadius: "100px",
                    color: "#fff",
                    boxShadow: "none",
                    "&:hover": {
                      background: "#4C9F41",
                    },
                  }}
                  onClick={(e) => confirmAddTeamHandler()}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </div>
        }
      />

      {/* --------------------- member delete dialog --------------------- */}
      <DraggableDialog
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          handleConfirmCloseDialog={(e) => deleteMemberConfirmHandler()}
          title="Are you sure?"
          body="If you delete the member."
          ModalFooter={true}
          cancelColor="black !important"
          cancelButton="Cancel"
          actionButtonN
          actionButton='Delete'
        />

    </>
  );
};

export default Team;
