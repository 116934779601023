import React, { useRef, useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams, useSearchParams } from "react-router-dom";

import CustomizedSnackbar from "../../hooks/Snackbar";
import { Grid } from "@material-ui/core/";
import Buttons from "../../components/Form/Button/Button";
import PasswordStrength from "../../components/Form/FieldPassword/PasswordStrength";
import FieldPassword from "../../components/Form/FieldPassword/FieldPassword";
import Header from "./header/Header";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import "./Form.css";
import { Alert, Snackbar } from "@mui/material";
import { PasswordContext } from "../../context/PaswordProvider";
import {
  useChangeUserCurrentPasswordMutation,
  useForgotPasswordVerifyMutation,
} from "../../redux/features/users/userApi";
import { createRECAPTCHAToken } from "../../hooks/HelperFunctions";

function CreatNewPassword(props) {
  const { password: oldPassword } = useContext(PasswordContext);
  const [changePassword, options] = useChangeUserCurrentPasswordMutation();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [password, setPassword] = useState("");
  const [passwordValidation, setPasswordValidation] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");

  const handleChanges = (fieldName) => (event) => {
    const fieldValue = event.target.value;
    // console.log(fieldName, fieldValue);
    setConfirmPassword(fieldValue);
  };

  const processResponse = (response) => {
    console.log(response);
    if (!response.data.error) {
      setShowSnackbar(true);
      setSnackbarSeverity("success");
      setSnackbarMessage(response.data.message);
      // redirect to sign in page
      setTimeout(() => {
        setSnackbarMessage("Redirecting to sign in page...");
        window.location.replace(`${window.location.origin}/signin`);
      }, 2000);
    } else {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(response.data.message);
    }
  };

  const sendResetPasswordRequest = async () => {
    // console.log(password, confirmPassword);
    const token = await createRECAPTCHAToken();
    if (passwordValidation == false) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage(
        "Password should have matched all the below criteria."
      );
      return false;
    } else if (password !== confirmPassword) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Password is not matching.");
      return false;
    } else {
      let data = {
        oldPassword: oldPassword,
        newPassword: password,
        token,
      };

      const result = await changePassword(data);
      // console.log(result, 328, data)
      if (!result.data.error) {
        processResponse(result);
        return;
      }

      return true;
    }
  };

  // handle reference login
  // let [searchParams, setSearchParams] = useSearchParams();
  // let userToken = searchParams.get("token");
  // console.log(userToken);

  // useEffect(() => {
  //   if (userToken) {
  //     // console.log(userToken);
  //     // userToken = token.replace(" ", "+");
  //     // console.log(userToken);
  //     setToken(userToken);
  //   }
  // }, []);

  /**
   *
   * @param {*} event
   * @param {*} reason
   * @returns
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  return (
    <>
      <Header />
      <div className="SignContainer">
        <div className="SignContainerInner">
          <div className="SignForm">
            <h1>Setup New Password</h1>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <CustomizedSnackbar
                  open={showSnackbar}
                  severity={snackbarSeverity}
                  message={snackbarMessage}
                  handleClose={handleSnackbarClose}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <PasswordStrength
                  placeholder="New Password"
                  handlePasswordText={setPassword}
                  handlePasswordValidation={setPasswordValidation}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <FieldPassword
                  placeholder="Confirm New Password"
                  handleChange={handleChanges("confirmPassword")}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Buttons
                  label="Change Password"
                  buttonColor="#4C9F41"
                  border="#ccc 1px solid"
                  color="#fff"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  link=""
                  onClick={sendResetPasswordRequest}
                />
                <div style={{ paddingTop: "4px" }}>
                  <Buttons
                    label="Cancel"
                    color="#4C9F41"
                    width=""
                    height="48px"
                    fontSize="14px"
                    link="/signin"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="SignFormFooter">
            <LockOutlinedIcon />
            <span>SECURE SIGN IN</span>
            <SecurityOutlinedIcon />
            <span>SECURE SSL ENCRYPTION</span>
          </div>
        </div>
      </div>
      {/* <Snackbar
        open={snakbaropen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
        zIndex={9999}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            fontSize: "1.2rem",
            "& > svg": {
              marginRight: "0.5rem",
            },
            backgroundColor: "green !important",
            color: '#fff !important'
          }}
        >
          Default location updated
        </Alert>
      </Snackbar> */}
    </>
  );
}

export default CreatNewPassword;
