import React from "react";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PrintIcon from '@mui/icons-material/Print';
import Button from "../../components/Button/Button";
import Buttons from "../../components/Button/Button";
import { Divider } from "@mui/material";

function OrderPrint(props) {


  return (
    <div className="ModelDetails">
      <div className="ModelHeader" style={{ backgroundColor: "#FFFFFF", fontSize: "25px", boxShadow: "" }}>
        <div>
          <h1 style={{ color: "#1B1B1F", fontSize: "30px", marginLeft: "0", fontWeight: "700", lineHeight: "5px" }}>Radhuni Sweets & Restaurant - Brooklyn</h1>
          <span style={{ justifyContent: "left", color: "#1B1B1F", }}>
            <p style={{ color: "#1B1B1F", fontSize: "20px", marginLeft: "0", }}>ORDR1000001</p>
            <p style={{ color: "#1B1B1F", fontSize: "20px", }}>Nov 1, 2022, 6:10 PM</p>
          </span>
        </div>
      </div>


      <div className="ModelBodyContent" style={{ border: "none" }}>
        <div className="Delieverytime" style={{ backgroundColor: "#1B1B1F", height: "80px", width: "680px" }}>
          <span style={{ justifyContent: "space-between", color: "#FFFFFF", marginLeft: "20px" }}>
            <h1>DELIVERY</h1>
            <h1 style={{ marginRight: "20px" }}>PLACED AT 6:10 PM</h1>
          </span>
        </div>
        <h1>
          SHAYAN MAHMUD
        </h1>
      </div>
       {/* <Divider style={{marginTop:"30px"}}></Divider>  */}

      <div className="Itemdetails">
        <h2 style={{ fontWeight: "600",marginTop:"80px"}}>Instructions:</h2>
        <p style={{ fontSize: "20px" }}>Please ring the bell and leave package at front door</p>

        <div className="item">

          <div className="ItemName">
            <h2 style={{ fontWeight: "600" }}>1x</h2> &#160;
            <h2 style={{ fontWeight: "600" }}>Kacchi Biriyani</h2>
          </div>
          <div className="Price">
            <h2> $14.95</h2>
          </div>
        </div>

        <div className="Borderleft" style={{ lineHeight: "10px", marginLeft: "60px" }}>
          <p style={{ marginLeft: "10px", fontSize: "20px" }}>Small</p>
        </div>
        <div className="Borderleft" style={{ lineHeight: "10px", marginLeft: "60px" }}>
          <p style={{ marginLeft: "10px", fontSize: "20px" }}>Please remove all elaichi from the biriyani</p>
        </div>
      </div>
      <div className="Itemdetails">
        <div className="item">
          <div className="ItemName">
            <h2 style={{ fontWeight: "600" }}>1x</h2> &#160;
            <h2 style={{ fontWeight: "600" }}>Tehari</h2>
          </div>
          <div className="Price">
            <h2> $18.95</h2>
          </div>
        </div>

        <div className="Borderleft" style={{ lineHeight: "10px", marginLeft: "60px" }}>
          <p style={{ fontSize: "20px" }}>Large</p>
        </div>

      </div>
      <Divider></Divider>

      <div className="Order" style={{ display: "flex", justifyContent: "space-between", color: "#1B1B1F" }}>
        <div className="Orderdeliveryfee" style={{ textAlign: "left", marginRight: "80px", fontSize: "20px" }}>
          <p style={{ fontSize: "20px" }}>Subtotal</p>
          <p>Delivery fee</p>
          <h3>Total</h3>
        </div>

        <div className="Orderdeliveryfee" style={{ textAlign: "right", fontSize: "20px" }}>
          <p>$43.90</p>
          <p>$10</p>
          <h3>$53.90</h3>
        </div>
      </div>
    </div>





  );
}


export default OrderPrint;

