import React from "react";

import Header from "./header/Header";
import LockRoundedIcon from "@mui/icons-material/LockRounded";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";


function SignInAccountIDLocked(props) {

  return (
    <div className="PageBody">

      <Header/>

      <div className="SectionTransparent">
        <div className="SuccessCard">
          <div className="CanceledCardHeader">
            <span>
              <LockRoundedIcon />
            </span>
            <h1>Too many failed attempts</h1>
          </div>
          <div className="SuccessCardDetails">
            <h2>Try again after 24 hours</h2>

            <p>
              You have been locked out because of too many failed attempts, if
              you think it's a mistake please email at{" "}
              <a href="#">support@goradhuni.com</a>
            </p>
          </div>
          <div className="SignFormFooter">
            <LockOutlinedIcon />
            <span>SECURE SIGN IN</span>
            <SecurityOutlinedIcon />
            <span>SECURE SSL ENCRYPTION</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignInAccountIDLocked;
