import React from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, FormHelperText, OutlinedInput } from "@mui/material";
import "./SelectField.css";

function SelectField(props) {
  // const [age, setAge] = React.useState('');
  // const handleChanges = (event) => {
  //   setAge(event.target.value);
  // };

  // const options = [
  //   {
  //     label: "Twenty",
  //     value: "Twenty",
  //   },

  //   {
  //     label: "Twentydd",
  //     value: "Twentyd",
  //   },

  // ]

  //   < SelectField
  // label = "Month"
  // options = { options }
  // handleChange = { handleChanges }
  //   />

  const {
    label,
    validation,
    options,
    placeholder,
    selectedOption,
    handleChange,
    helperText,
    error,
  } = props;

  return (
    <div className="TextField">
      <div className="FieldLabel">
        {label} {validation && <span>*</span>}
      </div>
      <FormControl error={helperText === "" ? false : true}>
        <Select
          labelId="customized-select-label"
          id="customized-select"
          value={selectedOption}
          onChange={handleChange}
          error={error}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: "400px", // Set your desired maximum height here
              },
            },
          }}
        >
          {/* <MenuItem value="">
          <em>None</em>
        </MenuItem> */}
          {options.map((item, i) => (
            <MenuItem placeholder={placeholder} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </div>
  );
}

export default SelectField;
