import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import {
  Alert,
  Avatar,
  Button,
  Container,
  Grid,
  Snackbar,
} from "@mui/material";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";

import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import MenuThreeDot from "../../components/MenuThreeDot/MenuThreeDot";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import OrderDetails from "../../components/OrdersComponents/OrderDetails/OrderDetails";
import OrdersTimeline from "../../components/OrdersTimeline/OrdersTimeline";
import OrderStatusDropdown from "../../components/OrdersComponents/OrderStatusDropdown/OrderStatusDropdown";
import avatar from "../../assets/imgs/Avatar.png";
import Sms from "../../assets/imgs/sms.svg";
import LocalTranport from "../../assets/imgs/local_shipping.svg";
import StorefrontIcon from "@mui/icons-material/Storefront";
import SideBarDrawer from "../../components/SideBarDrawer/SideBarDrawer";
import { useParams } from "react-router-dom";
import {
  useChangeOrderStatusMutation,
  useGetOrderDetailsQuery,
} from "../../redux/features/orders/orderApi";
import useProtected from "../../hooks/useProtected";
import { capitalizeFirstLetter } from "../../hooks/HelperFunctions";
import { toast } from "react-toastify";
import { useGetLocationListQuery } from "../../redux/features/locations/locationApi";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import "../../assets/css/OrderDetailsInprogress.css";
import DraggableDialog from "../../hooks/Dialog";
import ChangeStatusDialog from "../../hooks/ChangeStatusDialog";

function OrderDetailsInprogress(props) {
  const { order_id } = useParams();
  const activeLocation = useSelector((state) => state.auth.activeLocation);
  const { data, isLoading, isFetching, error } = useGetOrderDetailsQuery(
    order_id,
    {
      skip: !order_id,
      refetchOnMountOrArgChange: true,
    }
  );
  const {
    data: locationsData,
    isLoading: locationsIsLoading,
    isFetching: locationsIsFetching,
    error: locationsError,
  } = useGetLocationListQuery();

  const [changeOrderStatus, changeOrderStatusOptions] =
    useChangeOrderStatusMutation();

  useProtected(error);
  useProtected(locationsError);
  useProtected(changeOrderStatusOptions.error);

  // menu
  const [open, setOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [locations, setLocations] = useState([]);
  const [activeLocationDetails, setActiveLocationDetails] = useState({});
  const [confirmChangeStatus, setConfirmChangeStatus] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  //========================== toast message ====================================//
  const [snakbaropen, setSnakbarOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnakbarOpen(false);
  };
  //========================== toast message ====================================//

  const date_options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const showOrderDateTime = () => {
    let timeline = orderDetails?.timeLine?.find(
      (singleTimeLine) => singleTimeLine?.status == "Order Placed"
    );
    const inputDate = new Date(timeline?.time);

    const formattedDate = inputDate.toLocaleDateString("en-US", date_options);
    return formattedDate ? formattedDate : "N/A";
  };

  const breadcrumbsData = [
    {
      label: "Orders",
      link: "/",
      icon: <ReceiptRoundedIcon fontSize="small" sx={{ fontSize: "16px" }} />,
    },

    {
      label: "Details",
      link: "",
    },
  ];
  const [statusData, setStatusData] = useState("");
  const changeStatus = async (status) => {
    // changeOrderStatus();

    // console.log(status, 328);
    let sendSMS = 0;
    if (
      status === "out-for-delivery" ||
      status === "ready-to-pickup" ||
      status === "cancelled"
    ) {
      setStatusData(status);
      setConfirmChangeStatus(true);
    } else {
      statusChange(status, sendSMS);
    }
  };

  const statusChange = async (status, sendSMS) => {
    let data = {
      orderId: order_id,
      orderStatus: status,
      sendSMS: sendSMS,
    };
    let result = await changeOrderStatus({ ...data });
    if (!result?.data?.error) {
      toast.success("Order status has changed");
    }
  };

  const handleCloseDialog = async () => {
    let sendSMS = 0;
    statusChange(statusData, sendSMS);
    setConfirmChangeStatus(false);
  };
  const handleConfirmCloseDialog = async () => {
    //if we change the status and also send sms that time sendSMS will be 1 otherwise sendSMS will be 0
    let sendSMS = 1;
    statusChange(statusData, sendSMS);
    setConfirmChangeStatus(false);
  };

  useEffect(() => {
    if (!isLoading && !isFetching && data) {
      setOrderDetails(data?.body?.data);
    }
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    if (!locationsIsLoading && !locationsIsFetching && locationsData) {
      let allLocations = locationsData?.body?.data;
      setLocations(allLocations);

      let activeLocationInfo = allLocations?.find(
        (singleLocation) => singleLocation._id == activeLocation
      );
      setActiveLocationDetails(activeLocationInfo);
    }
  }, [locationsData, locationsIsLoading, locationsIsFetching]);
  // console.log(orderDetails, 328);

  // * ==== print func ==== * //
  const taxData = process.env.REACT_APP_TAX_VALUE;
  const totalTax = (
    (orderDetails?.orderSubTotal * parseFloat(taxData)) /
    100
  )?.toFixed(2);
  const handlePrint = () => {
    const printWindow = window.open("", "_blank");

    const orderItemsHTMLtwo = orderDetails?.orderItems
      ? orderDetails.orderItems
          .map(
            (item) => `
            <tr>
            <td>
                <table>
                    <tr style="font-size: 22px;">
                        <td align="justify" style="display: flex;align-items: flex-start;"><span>${
                          item.quantity
                        }x</span></td>
                        <td style="width: 100%;">
                            <b style="font-weight: 500; display: block;">${capitalizeFirstLetter(
                              item.menuName
                            )}</b>
                            <span style=" display: block;"> ${
                              item?.variation?.name
                                ? capitalizeFirstLetter(item?.variation?.name)
                                : ""
                            }</span>
                            <span>${
                              item?.specialInstructions
                                ? item?.specialInstructions
                                : ""
                            }</span>
                        </td>
                        <td align="right" style="width: 100%; display: flex;align-items: flex-start; justify-content:flex-end">
                        <b style="width: 100px;">
                                ${
                                  item?.variation?.price
                                    ? Object.keys(item?.variation).length > 0
                                      ? `$${(
                                          item?.variation?.price *
                                          item?.quantity
                                        )?.toFixed(2)}`
                                      : `$${(
                                          item?.price * item?.quantity
                                        )?.toFixed(2)}`
                                    : `$${(
                                        item?.price * item?.quantity
                                      )?.toFixed(2)}`
                                }
                                </b>
                        </td>
                        <!-- <td align="right">
                            <b></b>
                        </td> -->
                    </tr>
                </table>
            </td>
          </tr>
          <tr>
              <td style="background-color: #e4e2e6;"></td>
          </tr>
    `
          )
          .join("")
      : "";

    const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">

    <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <!-- font family -->
        <link href="https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&amp;display=swap"
            rel="stylesheet">
        <!-- font family -->
        <style type="text/css" media="screen">
            body {
                margin: 0 !important;
                padding: 0 !important;
                width: 100% !important;
                box-sizing: border-box !important;
                background-color: #FFFFFF !important;
                font-family: Poppins !important;
                -webkit-text-size-adjust: none
            }
        </style>
        <title>Order Receipt</title>
    </head>

    <body>
        <table style="margin: 0 auto; width: 700px; border-collapse: collapse; font-family: Poppins !important;">
            <tbody>
                <tr>
                    <td><br></td>
                </tr>
                <tr>
                    <td style="font-size: 28px; font-weight: 600;">${
                      orderDetails?.locationName
                    }</td>
                </tr>
                <tr>
                    <td>
                        <table>
                            <tr style="font-size: 22px;">
                                <td>${orderDetails?.orderNumber}</td>
                                <td>&nbsp;&nbsp;</td>
                                <td>${moment(orderDetails?.createdAt)?.format(
                                  "MMM DD, YYYY, h:mm A"
                                )}</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table style="width: 100%; background-color: black; color: #fff; padding: 0 12px;">
                            <tr width="100%">
                                <td width="50%" style="font-size: 32px; font-weight: 600;">${orderDetails?.orderType?.toUpperCase()}</td>
                                <td width="50%" style=" width: 100%;font-size: 18px;" align="right"> 
                                <div style="display: flex; align-items: center;">
                                    <img src="https://radhuni-public-assets.s3.amazonaws.com/dev/radhuni/media/white-location-filled.svg"
                                        alt="email icon" height="40px" width="40px">
                                    <b style=" width: 100% !important; flex-grow: 1; text-align:left;"> 
                                        ${
                                          orderDetails?.orderType === "pickup"
                                            ? orderDetails?.locationName
                                            : `  <span style="font-weight: 500;">
                                      ${
                                        orderDetails?.customerAddress || "N/A"
                                      }, <br/>
                                                ${
                                                  orderDetails?.customerApartmentInfo
                                                    ? orderDetails.customerApartmentInfo +
                                                      "<br/>"
                                                    : ""
                                                }
                                      ${
                                        orderDetails?.customerCity || "N/A"
                                      },  ${
                                                orderDetails?.customerState ||
                                                "N/A"
                                              } ${
                                                orderDetails?.customerZip ||
                                                "N/A"
                                              }
                                    </span>`
                                        }
                                    </b>
                                </div>
                               
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td align="left" style="padding: 16px 0;">
                        <p style="margin: 0px;font-size: 45px; font-weight: 400;">${
                          orderDetails?.customerName || "N/A"
                        }</p>
                    </td>
                </tr>
           

                <tr>
                    <td>
                        <table>
                            <tr>
                                <td style="display: flex; align-items: center;">
                                    <img src="https://radhuni-public-assets.s3.amazonaws.com/dev/radhuni/media/call_black.svg"
                                        alt="email icon" height="40px" width="40px">
                                </td>
                                <td align="left" style="font-size: 28px; font-weight: 400;">&nbsp;<b
                                        style="font-weight: 500;">${
                                          orderDetails?.phoneNumberFormatted
                                        }</b>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table>
                            <tr>
                                <td style="display: flex; align-items: center;">
                                    <img src="https://radhuni-public-assets.s3.amazonaws.com/dev/radhuni/media/email_black.svg"
                                        alt="email icon" height="40px" width="40px">
                                </td>
                                <td align="left" style="font-size: 28px; font-weight: 400;">
                                    &nbsp;<b style="font-weight: 500;">${
                                      orderDetails?.customerEmail
                                    }</b>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td><br></td>
                </tr>
                <tr>
                    <td style="background-color: #e4e2e6;"></td>
                </tr>
                <tr>
                    <td><br></td>
                </tr>
                <tr>
                    <td style="font-size: 22px; font-weight: 500;">
                        <span>Instructions:</span>
                    </td>
                </tr>
              
                <tr>
                    <td>
                        <span>${orderDetails?.orderNote || "N/A"}</span>
                    </td>
                </tr>
                <tr>
                <td><br></td>
            </tr>

                <tr>
                <td style="background-color: #e4e2e6;"></td>
            </tr>
                ${orderItemsHTMLtwo}
                <tr>
                    <td><br></td>
                </tr>
               
              
               
                <tr>
                    <td>
                        <table style="width: 100%; font-size: 22px;">
                            <tr width="100%">
                                <td width="50%">Subtotal</td>
                                <td width="50%" align="right">$${
                                  orderDetails?.orderSubTotal?.toFixed(2) ||
                                  "N/A"
                                }</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table style="width: 100%; font-size: 22px;">
                            <tr width="100%">
                                <td width="50%">NYS Sales Tax (${taxData}%)</td>
                                <td width="50%" align="right">$${
                                  parseFloat(totalTax)?.toFixed(2) || "0.00"
                                }</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                ${
                  orderDetails?.orderType !== "pickup"
                    ? `<tr>
                    <td>
                      <table style="width: 100%; font-size: 22px;">
                        <tr width="100%">
                          <td width="50%">Delivery fee</td>
                          <td width="50%" align="right">
                            $${orderDetails?.deliveryFee?.toFixed(2) || "N/A"}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>`
                    : ``
                }
                <tr>
                    <td>
                        <table style="width: 100%; font-size: 22px; font-weight: 500;">
                            <tr width="100%">
                                <td width="50%">Total</td>
                                <td width="50%" align="right">$${
                                  parseFloat(
                                    orderDetails?.orderTotal +
                                      parseFloat(totalTax)
                                  )?.toFixed(2) || "0.00"
                                }</td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </body>

    </html>
    `;

    printWindow.document.write(htmlContent);

    printWindow.document.close();
    printWindow.print();
  };
  // console.log(orderDetails?.orderStatus, 345);
  return (
    <>
      <div>
        <div className="DesktopView">
          <Header />
          <Sidebar />
        </div>

        <div className="ResponsiveMenu">
          <Header
            IconMenu={
              <a onClick={handleDrawerOpen}>
                <MenuRoundedIcon />
              </a>
            }
          />
          <SideBarDrawer
            open={open}
            handleDrawerClose={handleDrawerClose}
            DrawerBody={<Sidebar />}
          />
        </div>
      </div>

      <div className="app-body app-second-body">
        <div className="orderDetailsInprogress">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Breadcrumbs breadcrumbsData={breadcrumbsData} />
            </Grid>
            <Grid item xs={12}>
              <div className="orderNumberContainer boxShadow">
                <div>
                  <h4>{orderDetails?.orderNumber}</h4>
                  <p>{showOrderDateTime()}</p>
                </div>
                <div className="flex alignItem justifyContent">
                  <PrintStyledButton
                    startIcon={<LocalPrintshopRoundedIcon />}
                    onClick={handlePrint}
                  >
                    Print
                  </PrintStyledButton>
                  {/* <MenuThreeDot /> */}
                </div>
              </div>
            </Grid>
            {/* <Grid item xs={12}>
              <button onClick={() => setConfirmChangeStatus(true)}>
                confirm dialog
              </button>
            </Grid> */}
            <Grid item xs={12} md={8}>
              {/* nested grid */}
              <Grid container spacing={2}>
                {/* drop down */}
                <Grid item xs={12} md={6}>
                  <OrderStatusDropdown
                    orderDetails={orderDetails}
                    changeStatus={changeStatus}
                    disabled={
                      orderDetails?.orderStatus === "cancelled" ||
                      orderDetails?.orderStatus === "declined" ||
                      orderDetails?.orderStatus === "delivered"
                        ? true
                        : false
                    }
                  />
                </Grid>

                {/*  user info */}
                <Grid item xs={12} md={6}>
                  <div className="sectionUserInfoContainer boxShadow">
                    <div className="sectionAvater">
                      <img src={avatar} alt="" />
                    </div>
                    <div className="sectionUserInfo">
                      <h4>{orderDetails?.customerName || "N/A"}</h4>
                      <p>{orderDetails?.customerEmail || "N/A"}</p>
                      <p>{orderDetails?.phoneNumberFormatted || "N/A"}</p>
                    </div>
                  </div>
                </Grid>
                {/* =============== delivery ============= */}
                {orderDetails?.orderType == "delivery" && (
                  <Grid item xs={12} md={6} lg={6}>
                    <div className="orderDeliveryContainer boxShadow">
                      <div
                        className="deliveryAvatar"
                        style={{
                          background:
                            orderDetails?.orderStatus === "scheduled"
                              ? "#FFB703"
                              : "#4c9f41",
                        }}
                      >
                        <LocalShippingIcon />
                      </div>
                      <div className="deliveryInfo">
                        <h4>
                          {orderDetails?.orderType &&
                            capitalizeFirstLetter(orderDetails?.orderType)}
                        </h4>
                        {orderDetails?.deliveryPreference === "schedule" ? (
                          <p>
                            {" "}
                            {moment(orderDetails?.deliveryTime)?.format(
                              "MMM DD, YYYY, h:mm a"
                            )}
                          </p>
                        ) : (
                          <p>As soon as possible</p>
                        )}
                      </div>
                    </div>
                  </Grid>
                )}

                {/* =============== address ============= */}
                {orderDetails?.orderType == "delivery" && (
                  <Grid item xs={12} md={6} lg={6}>
                    <div className="orderDeliveryContainer boxShadow">
                      <div
                        className="deliveryAvatar"
                        style={{
                          background:
                            orderDetails?.orderStatus === "scheduled"
                              ? "#FFB703"
                              : "#4c9f41",
                        }}
                      >
                        <LocationOnRoundedIcon />
                      </div>
                      <div className="deliveryInfo">
                        <h4>Address</h4>
                        <p style={{ width: "70%" }}>
                          {orderDetails?.customerAddress},{" "}
                          {orderDetails?.customerApartmentInfo
                            ? `${orderDetails.customerApartmentInfo},`
                            : ""}{" "}
                          {orderDetails?.customerCity},{" "}
                          {orderDetails?.customerState}{" "}
                          {orderDetails?.customerZip}
                        </p>
                      </div>
                    </div>
                  </Grid>
                )}
                {/* ================= Pickup =================== */}
                {orderDetails?.orderType == "pickup" && (
                  <Grid item xs={12} md={6} lg={6}>
                    <div className="orderDeliveryContainer boxShadow">
                      <div
                        className="deliveryAvatar"
                        style={{
                          background:
                            orderDetails?.orderStatus === "scheduled"
                              ? "#FFB703"
                              : "#4c9f41",
                        }}
                      >
                        <StorefrontIcon />
                      </div>
                      <div className="deliveryInfo">
                        <h4>Pickup</h4>
                        {orderDetails?.deliveryPreference === "schedule" ? (
                          <p>
                            {" "}
                            {moment(orderDetails?.deliveryTime)?.format(
                              "MMM DD, YYYY, h:mm a"
                            )}
                          </p>
                        ) : (
                          <p>As soon as possible</p>
                        )}
                      </div>
                    </div>
                  </Grid>
                )}
                {/* ================= location ==================== */}
                {orderDetails?.orderType == "pickup" && (
                  <Grid item xs={12} md={6} lg={6}>
                    <div className="orderDeliveryContainer boxShadow">
                      <div
                        className="deliveryAvatar"
                        style={{
                          background:
                            orderDetails?.orderStatus === "scheduled"
                              ? "#FFB703"
                              : "#4c9f41",
                        }}
                      >
                        <LocationOnRoundedIcon />
                      </div>
                      <div className="deliveryInfo">
                        <h4>Location</h4>
                        <p style={{ width: "70%" }}>
                          {activeLocationDetails?.name}
                        </p>
                      </div>
                    </div>
                  </Grid>
                )}
                {/* ================= delivery details =============== */}
                <Grid item xs={12}>
                  <div className="DeliveryDetailsContainer boxShadow">
                    <img src={Sms} alt="" />
                    <div className="DeliveryBoxHeader">
                      <p>Delivery instruction</p>
                      <span>{orderDetails?.orderNote}</span>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <OrderDetails orderDetails={orderDetails} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <OrdersTimeline orderDetails={orderDetails} />
            </Grid>
          </Grid>
          <br />
          <br />
        </div>
      </div>
      {/* ------------------- snackbar ---------------- */}
      <Snackbar
        open={snakbaropen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
        zIndex={9999}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{
            display: "flex",
            alignItems: "center",
            width: "400px",
            fontSize: "1.2rem",
            "& > svg": {
              marginRight: "0.5rem",
            },
            backgroundColor: "green !important",
            color: "#fff !important",
          }}
        >
          Order status has changed
        </Alert>
      </Snackbar>
      {/* -------------------- confirmation dialog ---------------- */}
      <ChangeStatusDialog
        title={`Change status to ${
          statusData === "cancelled"
            ? "Canceled ?"
            : statusData === "ready-to-pickup"
            ? "Ready for pickup"
            : "Out for delivery"
        }`}
        body=<div>
          {`Once you ${" "}
          ${
            statusData === "cancelled"
              ? " delete"
              : statusData === "ready-to-pickup"
              ? " pickup"
              : " delivery"
          }${" "}
          it, you can't get it back.`}
          <Alert
            severity="error"
            sx={{
              color: "#FF6200",
              background: "#fff3eb",
              "&.MuiPaper-root": {
                margin: "8px 0",
                padding: "0px 12px",
                borderRadius: "8px",
              },
              "& .MuiAlert-icon": {
                color: "#FF6200",
              },
            }}
          >
            {statusData === "cancelled"
              ? "Cancelling "
              : statusData === "ready-to-pickup"
              ? "Pickup"
              : "Delivery"}{" "}
            order will send an SMS to the customer.
          </Alert>
        </div>
        openDialog={confirmChangeStatus}
        handleCloseDialog={handleCloseDialog}
        ModalFooter={true}
        cancelColor="#1B1B1F"
        statusData={statusData}
        actionButtonN
        actionButton={`${
          statusData === "cancelled"
            ? "Cancel"
            : statusData === "ready-to-pickup"
            ? "Ready for pickup"
            : "Out for delivery"
        } & Send Sms`}
        handleConfirmCloseDialog={handleConfirmCloseDialog}
        setConfirmChangeStatus={setConfirmChangeStatus}
        // handleConfirmCloseDialog={handeleMenuItemDete}
      />
    </>
  );
}

export default OrderDetailsInprogress;

const PrintStyledButton = styled(Button)(({ theme }) => ({
  // margin: "0 10px",
  // padding: "25px 0",
  width: "100px",
  color: "#fff",
  fontSize: "14px",
  fontFamily: "Poppins, sans-serif !important",
  textTransform: "capitalize",
  background: "#4C9F41",
  border: "1px solid #E4E2E6",
  borderRadius: "1000px",
  gap: "10px",
  alignItems: "center",
  "&:hover": {
    border: "1px solid #4C9F41",
    background: "#4C9F41",
  },
  "& .MuiButton-startIcon": {
    margin: "0",
  },
}));
