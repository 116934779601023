import React, { useEffect, useState } from "react";
import { Button, Grid, Switch } from "@mui/material";
import TextField from "../../../../../components/Form/TextField/TextField";
import SelectField from "../../../../../components/Form/SelectField/SelectField";
import "./UserAccess.css";
import PasswordStrength from "../../../../../components/Form/FieldPassword/PasswordStrength";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import {
  capitalizeFirstLetter,
  getPreparedArray,
} from "../../../../../hooks/HelperFunctions";
import SideDrawer from "../../../../../components/SideDrawer/SideDrawer";
import RoleModal from "./RoleModal";
import MultipleSelect from "../../../../../components/Form/MultipleSelect/MultipleSelet";

const label = { inputProps: { "aria-label": "Size switch demo" } };

const UserAccess = (props) => {
  const { errors, userInfo, locationOptions, setUserInfo } = props;
  const [role, setRole] = useState(false);
  const [address, setAddress] = useState("");
  // const [location, setLocaiton] = React.useState([]);
  const [password, setPassword] = useState("");
  const [passwordValidation, setPasswordValidation] = useState(true);

  const [selectedRoleLabel, setSelectedRoleLabel] = useState(userInfo?.role);

  const handleChanges = (event, setValue) => {
    setValue(event.target.value);
  };

  // swtich styling
  const switchStyles = {};

  const handleChangesOfField = (fieldName, fieldValue) => {
    setUserInfo({ ...userInfo, [fieldName]: fieldValue });
  };

  useEffect(() => {
    setUserInfo({ ...userInfo, password });
  }, [password]);

  const portalAccessHandler = (e) => {
    if (e.target.checked) {
      setUserInfo({ ...userInfo, enablePortalAccess: true });
    } else {
      setUserInfo({ ...userInfo, enablePortalAccess: false });
    }
  };

  // * ========================== Multiple select Code =====================================

  const setLabelAsValueForMultiSelect = (locationOptions) => {
    let optionArray = locationOptions?.map((option) => {
      return {
        label: option.label,
        value: option.value,
      };
    });
    return optionArray;
  };

  // const options = [
  //   {
  //     label: "Twenty",
  //     value: "Twenty",
  //   },

  //   {
  //     label: "Twentydd",
  //     value: "Twentyd",
  //   },
  // ];

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // setLocaiton(
    //   // On autofill we get a stringified value.
    //   typeof value === "string" ? value.split(",") : value
    // );
  };

  //====================================  options ==================================//

  // === Multi Select == //
  const [value, setvalue] = React.useState([]);
  const handleChangeMultipleSelect = (event, fieldName) => {
    const {
      target: { value },
    } = event;
    setvalue(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    let newObject = {
      ...userInfo,
      [fieldName]: typeof value === "string" ? value.split(",") : value,
    };
    setUserInfo(newObject);
  };

  // === Multi Select == //
  const [defaultLocationOptions, setDefaultLocationOptions] = useState([]);

  useEffect(() => {
    if (
      userInfo &&
      userInfo?.selectLocations &&
      userInfo?.selectLocations?.length > 0
    ) {
      setvalue(userInfo?.selectLocations);
    }
  }, [userInfo]);

  // console.log(userInfo, 328);
  useEffect(() => {
    if (value.length > 0) {
      let locationsToShow = locationOptions?.filter((location) =>
        value.includes(location.value)
      );
      setDefaultLocationOptions(
        getPreparedArray(locationsToShow, "value", "label")
      );
      // console.log(locationsToShow, 328, locationOptions);
    }
  }, [value, locationOptions]);
  //====================================  options ==================================//

  useEffect(() => {
    if (selectedRoleLabel) {
      setUserInfo({ ...userInfo, role: selectedRoleLabel });
    }
    // console.log(selectedRoleLabel, 328);
  }, [selectedRoleLabel]);

  return (
    <>
      <div className="priceAndVariationContainer">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h2>User access</h2>
          </Grid>
          <Grid item xs={12}>
            {/* <SelectField
              label="Role"
              options={options}
              error={errors?.role}
              selectedOption={capitalizeFirstLetter(userInfo?.role)}
              handleChange={(e) => handleChangesOfField("role", e.target.value)}
            /> */}
            <div className="FieldLabel">Role</div>
            <Button
              variant="outlined"
              endIcon={<ArrowForwardIosRoundedIcon fontSize="small" />}
              sx={{
                padding: "14px",
                width: "100%",
                justifyContent: "space-between",
                borderColor: "#E4E8EC",
                borderRadius: "8px",
                "& .btnText": {
                  width: "100%",
                  flex: "1",
                  textTransform: "capitalize",
                  color: "#0B132B",
                },
                "& .MuiButton-endIcon": { color: "#80818a" },
                "&:hover": {
                  borderColor: "#E4E8EC",
                },
              }}
              onClick={() => setRole(!role)}
            >
              <span className="btnText">{selectedRoleLabel}</span>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <MultipleSelect
              FieldLabel={true}
              label="Select location(s)"
              option={setLabelAsValueForMultiSelect(locationOptions)}
              handleChange={(e) =>
                handleChangeMultipleSelect(e, "selectLocations")
              }
              // handleChange={(e) => handleChange(e, "locations")}
              value={value}
              helperText={errors?.selectLocations}
              error={errors?.selectLocations}
              // errorHelperText={errors?.selectLocations}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectField
              label="Default location"
              options={defaultLocationOptions}
              selectedOption={userInfo?.defaultLocation}
              handleChange={(e) =>
                handleChangesOfField("defaultLocation", e.target.value)
              }
              error={errors?.defaultLocation}
              helperText={errors?.defaultLocation}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "80%" }}>
              <h3 style={{ margin: 0, fontWeight: "400" }}>
                Enable portal access
              </h3>
              <p>
                If enabled the user will be able to access admin portal using
                the assigned email and password.
              </p>
            </div>
            <div className="userAccssSwitch">
              <Switch
                {...label}
                defaultChecked={userInfo?.enablePortalAccess}
                style={switchStyles}
                onChange={(e) => portalAccessHandler(e)}
              />
            </div>
          </Grid>
          {userInfo?.enablePortalAccess && (
            <>
              <Grid item xs={12}>
                <TextField
                  label="Login email"
                  // required={true}
                  email={true}
                  disabled={userInfo?._id ? true : false}
                  value={userInfo?.email}
                  // handleValidation={handleValidation}
                  // count={100}
                  handleChange={(e) =>
                    handleChangesOfField("email", e.target.value)
                  }
                  // placeholder="Email address"
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordStrength
                  label="Password"
                  error={errors?.password === "" ? false : true}
                  helperText="Required"
                  disabled={userInfo?._id ? true : false}
                  handlePasswordText={setPassword}
                  handlePasswordValidation={setPasswordValidation}
                />
              </Grid>
            </>
          )}
        </Grid>
      </div>

      <SideDrawer
        title="Select Role"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        deleteText="Delete Permanently"
        // onClickDelete={onClickDelete}
        DeleteButton={false}
        sideOpen="right"
        open={role}
        handleDrawerClose={() => setRole(false)}
        CheckboxLabel="Add another after saving"
        Checkbox={false}
        LeftButton={false}
        ButtonLeft="Upload New"
        // ButtonLeftIcon={<FileUploadOutlinedIcon/>}
        ButtonLeftbackground="transparent"
        ButtonLeftBorder="#E4E2E6 1px solid"
        ButtonLeftTextColor="#0450E1"
        LeftButtonlink="/invite-new-users"
        // clickSaveButton={handleUpdate}
        FooterWithoutTab={false}
        // panelTab={panelTab}
        // tabData={tabData}
        arrowIcon={false}
        body={
          <>
            <RoleModal
              locations={locationstwo}
              onSelectRole={(selectedRole) =>
                setSelectedRoleLabel(selectedRole)
              }
            />
          </>
        }
      />
    </>
  );
};

export default UserAccess;

const options = [
  {
    label: "Admin",
    value: "Admin",
  },
  {
    label: "Manager",
    value: "Manager",
  },
  {
    label: "Cashier",
    value: "Cashier",
  },
];

const addressArr = [
  {
    label: "483 McDonald Ave Brooklyn, NY 11218",
    value: "483 McDonald Ave Brooklyn, NY 11218",
  },

  {
    label: "483 McDonald Ave Brooklyn, NY 11219",
    value: "483 McDonald Ave Brooklyn, NY 11219",
  },
];

const locationstwo = [
  {
    id: 1,
    label: "Admin",
    address:
      "This role is for people who need similar access as the owner. This role can see and manage everything except adding another admin.",
  },
  {
    id: 2,
    label: "Manager",
    address:
      "This role is for people who will manage orders, menus, and customers.",
  },
  {
    id: 3,
    label: "Fulfillment",
    address:
      "This role is for people who will manage orders only. All others menus will hidded from them.",
  },
];
