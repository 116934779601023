import React, { useState } from "react";
import { Alert, Button, Snackbar } from "@mui/material";
import { styled } from "@mui/system";
import "./DefaultLocation.css";

const DefaultLocation = (props) => {
  const {
    handleCloseModal,
    label,
    location,
    buttonLabel,
    setActiveLocationHandler,
    location_id,
  } = props;
  const [snakbaropen, setSnakbarOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnakbarOpen(false);
  };
  const setLocationHandler = () => {
    setActiveLocationHandler(location_id);
    setSnakbarOpen(true);
    handleCloseModal();
  };

  return (
    <>
      <div className="defaultLocationContainer">
        <h3>{label}</h3>
        <p>{location}</p>
        <StyledButton variant="contained" onClick={() => setLocationHandler()}>
          Make My Default Location
        </StyledButton>
      </div>
      <Snackbar
        open={snakbaropen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
        zIndex={9999}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            fontSize: "1.2rem",
            "& > svg": {
              marginRight: "0.5rem",
            },
            backgroundColor: "green !important",
            color: "#fff !important",
          }}
        >
          Default location updated
        </Alert>
      </Snackbar>
    </>
  );
};

export default DefaultLocation;

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "40px",
  background: "#4C9F41",
  borderRadius: "1000px",
  textTransform: "capitalize",
  boxShadow: "none",
  "&:hover": {
    background: "#4C9F41",
  },
}));
