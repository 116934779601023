import React, { useState } from "react";
import SideDrawer from "../../components/SideDrawer/SideDrawer";
import MediaLibrarySelectMedia from "../../components/MediaLibrary/MediaLibrarySelectMedia";
import { Button, Grid } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import TextField from "../../components/Form/TextField/TextField";
import TextArea from "../../components/Form/TextArea/TextArea";
import { toast } from "react-toastify";
import Externaluse from "../../components/MediaLibrary/Externaluse";
import { Avatar, IconButton } from "@material-ui/core";
import { deepPurple } from "@material-ui/core/colors";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const PhotoDrawer = ({ galleryInfo, setGalleryInfo, errors, setErrors }) => {
  // =============================== media lib code start ================================ //

  const [mediaItem, setMediaItem] = useState({});
  const [selectedValue, setSelectedValue] = React.useState(false);
  const [openNewMedia2, setOpenNewMedia2] = useState(false);
  const [medialib, setMediaLib] = useState(false);
  const getMediaItem = (item) => {
    // setting the media item
    setMediaItem(item);
  };
  //handleMediaUploadClose
  const handleMediaUploadClose = () => {
    setOpenNewMedia2(false);
    setMediaLib(true);
  };
  //handleDrawerCloseSelectMedia
  const handleDrawerCloseSelectMedia = () => {
    setMediaLib(false);
  };

  const handleDrawerOpenNewMedia2 = () => {
    setOpenNewMedia2(true);
  };
  //handleOpenNewMedia
  const handleOpenNewMedia = (status) => {
    // alert(346);
    openNewMedia2(!status);
    setMediaLib(true);
  };

  const clickSelectMediaItem = async () => {
    //check if any item is selected
    if (selectedValue) {
      if (mediaItem?.mimeType?.startsWith("image/")) {
        let newObject = {
          ...setGalleryInfo,
          photo: mediaItem.url,
          photoName: mediaItem.name,
        };
        setGalleryInfo(newObject);
        setMediaLib(false);
      } else {
        toast.error("Image file can be accepeted");
      }
    } else {
      toast.error("Please select any media");
    }
  };
  const handleRemovePhoto = () => {
    setGalleryInfo({
      ...galleryInfo,
      photo: "",
    });
  };
  // =============================== media lib code end ================================ //

  const handleChangesOfField = (fieldName, fieldValue) => {
    setGalleryInfo({ ...galleryInfo, [fieldName]: fieldValue });
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div>
            <div className="FieldLabel">
              Photo <span>*</span>
            </div>

            {galleryInfo?.photo ? (
              <div className="MenuItemImageContainer">
                {" "}
                <Avatar
                  id="basic-button"
                  sx={{ bgcolor: deepPurple[300] }}
                  src={galleryInfo?.photo ? galleryInfo?.photo : ""}
                ></Avatar>
                <p>{galleryInfo?.photoName} </p>
                <IconButton onClick={handleRemovePhoto}>
                  <CloseRoundedIcon />
                </IconButton>
              </div>
            ) : (
              <Button
                variant="outlined"
                sx={{
                  py: 1.5,
                  width: "100%",
                  borderColor: `${errors?.photo ? "red" : "#E4E8EC"}`,
                  color: "#4C9F41",
                  textTransform: "capitalize",
                  textDecoration: "underline",
                  "&:hover": {
                    borderColor: "#E4E8EC",
                    color: "#4C9F41",
                  },
                }}
                startIcon={<AddRoundedIcon />}
                onClick={() => setMediaLib(true)}
              >
                Add From Media Library
              </Button>
            )}

            {errors?.photo ? (
              <p
                style={{
                  margin: "0px",
                  color: "#d32f2f",
                  fontSize: "12px",
                  textTransform: "capitalize",
                }}
              >
                {errors?.photo}
              </p>
            ) : (
              ""
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <TextField
              label="Caption"
              required={true}
              email={false}
              value={galleryInfo?.caption}
              // handleValidation={handleValidation}
              InnerFieldCount={true}
              count={50}
              handleChange={(e) =>
                handleChangesOfField("caption", e.target.value)
              }
              error={errors?.caption}
              helperText={errors?.caption}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <TextArea
            label="Description"
            rows={2}
            value={galleryInfo?.description}
            count={200}
            handleChange={(e) =>
              handleChangesOfField("description", e.target.value)
            }
          />
        </Grid>
      </Grid>

      {/* ================ */}
      <SideDrawer
        title="Media Library"
        ButtonLabel="Add"
        //clickSaveButton={clickSaveButton}
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={medialib}
        handleDrawerClose={handleDrawerCloseSelectMedia}
        FooterWithoutTab={true}
        LeftButton={true}
        ButtonLeft="Upload New"
        // ButtonLeftIcon={<FileUploadOutlinedIcon />}
        clickButtonLeft={handleDrawerOpenNewMedia2}
        clickSaveButton={clickSelectMediaItem}
        padding="0"
        body={
          <MediaLibrarySelectMedia
            onClickRadioIcon={setSelectedValue}
            onItemSelected={getMediaItem}
          />
        }
      />
      <Externaluse
        open={openNewMedia2}
        handleCloseButton={handleMediaUploadClose}
        handleOpenNewMedia={handleOpenNewMedia}
      />
    </div>
  );
};

export default PhotoDrawer;
