import React, { useState, useEffect } from "react";
import Table from "../Table/Table";

import axios from "axios";
import { generateTableRows } from "./Functions";
import {
  useAddMediaLibMutation,
  useDeleteMediaLibMutation,
  useGetMediaLibDetailsQuery,
  useGetMediaLibListQuery,
  useGetMediaLibrarySearchResultMutation,
  useUpdateMediaLibMutation,
} from "../../redux/features/media-lib/mediaLibApi";
import useProtected from "../../hooks/useProtected";

function MediaLibrarySelectMedia(props) {
  const [selectedMediaId, setSelectedMediaId] = useState("");
  const { data, isError, isLoading, error } = useGetMediaLibListQuery(
    undefined,
    { refetchOnMountOrArgChange: true }
  );

  useProtected(error);
  //base url for api taken from .env file
  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV == "dev"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;

  const userToken = localStorage.getItem("userToken");
  axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;

  const [orgId, setOrgId] = useState(localStorage.getItem("orgId"));

  const [items, setItems] = useState([]);

  const [totalItems, setTotalItems] = useState([]);

  const [selectedValue, setSelectedValue] = React.useState(false);
  const [serachText, setSerachText] = useState("");
  const [rows, setRows] = useState([]);

  const handleChangeRedio = (event) => {
    // console.log("handleChangeRedio", event.target.value)

    let data = totalItems;
    // setTotalItems(data);
    let temp = generateTableRows(data, handleChangeRedio, event.target.value);

    setRows(temp);
    setSelectedValue(event.target.value);

    props.onClickRadioIcon(event.target.value);
    //find the selected item from totalItems array
    let selectedItem = totalItems.find(
      (item) => item._id == event.target.value
    );

    props.onItemSelected(selectedItem);
  };

  useEffect(() => {
    if (!isLoading && !isError) {
      setTotalItems(data.body.data);
      let temp = generateTableRows(
        data.body.data,
        handleChangeRedio,
        selectedValue
      );
      setRows(temp);
      // setRows(data);
      // setRows(convertDataToCellRows(data.body.data));
    }
  }, [data, totalItems]);

  const headCells = [
    {
      id: "Media",
      numeric: true,
      disablePadding: true,
      label: "Media",
      width: "60%",
    },
    {
      id: "Type",
      numeric: true,
      disablePadding: false,
      label: "Type",
      width: "20%",
    },
    {
      id: "Size",
      numeric: true,
      disablePadding: true,
      label: "Size",
      width: "20%",
      textAlign: "left",
    },
  ];

  const handleSearchText = (event) => {
    // console.log("handleSearchText", event.target.value)
    let searchText = event.target.value;
    setSerachText(searchText);
    let data = totalItems;
    let temp = [];
    if (searchText.length > 0) {
      data.map((item, index) => {
        if (item.name.toLowerCase().includes(searchText.toLowerCase())) {
          temp.push(item);
        }
      });
    } else {
      temp = data;
    }
    setItems(temp);
  };

  const handleSearchClear = () => {
    setSerachText("");
    setItems(totalItems);
    setRows(generateTableRows(totalItems, handleChangeRedio, selectedValue));
  };

  const handleSearch = () => {
    let data = items;
    let temp = generateTableRows(data, handleChangeRedio, selectedValue);
    setRows(temp);
  };

  return (
    <>
      <div className="TableSection">
        <Table
          headCells={headCells}
          rows={rows}
          footer={true}
          innerHeader={true}
          FilterButton={props?.FilterButton}
          showSearchBox={true}
          searchBoxText={serachText}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          handleSearchClear={handleSearchClear}
          RowsPerPage={10}
        />
      </div>
    </>
  );
}

export default MediaLibrarySelectMedia;
