import React, { useState } from "react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import FilledInput from "@material-ui/core/FilledInput";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import "./FieldInputPassword.css";

function FieldInputPassword(props) {
  const [validationError, setValidationError] = useState(false);
  const [validationText, setValidationText] = useState("");
  const [passwordIcon, setPasswordIcon] = useState("");

  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  // const handleChange = (prop) => (event) => {
  //   setValues({ ...values, [prop]: event.target.value });
  // };

  const handleOnChange = (event) => {
    const value = event.target.value;

    // required field validation
    if (props.required && value?.length > 0) {
      setValidationError(false);
      setValidationText("");
    } else {
      setValidationError(true);
      setValidationText("Required");
    }

    if (props.handleChange) {
      props.handleChange(value);
    }
    setPasswordIcon(value);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="TextField TextFiledPassword">
      <FormControl variant="outlined">
        <OutlinedInput
          placeholder={props.placeholder}
          id="outlined-adornment-password"
          type={values.showPassword ? "text" : "password"}
          error={validationError || props.error != ""}
          value={props.value}
          onChange={handleOnChange}
          endAdornment={
            <InputAdornment
              position="end"
              style={{ display: `${passwordIcon === "" ? "none" : "flex"}` }}
            >
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
        <small style={{ color: "#d32f2f" }}>{props.helperText}</small>
      </FormControl>
    </div>
  );
}

export default FieldInputPassword;
