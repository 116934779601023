import React from "react";
import "./TextArea.css";

function TextArea(props) {
  const {
    label,
    validation,
    count,
    handleChange,
    value,
    helperText,
    disabled,
  } = props;

  const [textAreaCount, ChangeTextAreaCount] = React.useState(0);
  const recalculate = (e) => {
    ChangeTextAreaCount(e.target.value.length);
  };

  return (
    <div className="TextArea">
      <div className="FieldLabel">
        {label} {validation && <span>*</span>}
      </div>
      <textarea
        type="text"
        rows={props.rows ? props.rows : 5}
        maxlength={count}
        onChange={handleChange}
        value={value}
        disabled={disabled ? disabled : false}
      />
      <div className="HelperTextCounter">
        <p className="HelperText">{helperText}</p>
        {value && (
          <span className="CharCount">
            CHAR {value.length}/{count}
          </span>
        )}
      </div>
    </div>
  );
}

export default TextArea;
