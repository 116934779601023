import React, { useState, useEffect } from "react";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import PasswordStrengthBar from "react-password-strength-bar";
import "./FieldPassword.css";

function PasswordStrength(props) {
  const {
    label,
    validation,
    placeholder,
    error,
    helperText,
    handlePasswordText,
    handlePasswordValidation,
  } = props;

  const [type, setType] = useState("password");
  const [password, setPassword] = useState("");

  // validated states
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [specialValidated, setSpecialValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);

  const handleChange = (value) => {
    setPassword(value);
    setInputValue(value);

    const lower = new RegExp("(?=.*[a-z])");
    const upper = new RegExp("(?=.*[A-Z])");
    const number = new RegExp("(?=.*[0-9])");
    const special = new RegExp("(?=.*[!@#$%^&*])");
    const length = new RegExp("(?=.{8,})");

    if (lower.test(value)) {
      setLowerValidated(true);
    } else {
      setLowerValidated(false);
    }
    if (upper.test(value)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }
    if (number.test(value)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }
    if (special.test(value)) {
      setSpecialValidated(true);
    } else {
      setSpecialValidated(false);
    }
    if (length.test(value)) {
      setLengthValidated(true);
    } else {
      setLengthValidated(false);
    }
  };

  const handleResult = (value) => {
    // set password
    handlePasswordText(value);
    // set password validation status
    console.log(
      lowerValidated,
      upperValidated,
      numberValidated,
      specialValidated,
      lengthValidated
    );
    if (
      lowerValidated == false ||
      upperValidated == false ||
      numberValidated == false ||
      specialValidated == false ||
      lengthValidated == false
    ) {
      handlePasswordValidation(false);
    } else {
      handlePasswordValidation(true);
    }
  };

  useEffect(() => {
    // set password and it's validation status
    handleResult(password);
  }, [password]);

  const [inputValue, setInputValue] = useState("");

  return (
    <div className="FieldPasswordC">
      <div className="FieldLabel">
        {label} {validation && <span>*</span>}
      </div>

      <div className="wrapper">
        {/* box containing input field and validation tracker  */}
        <div className="box">
          {/* input */}
          <div className="FieldPassword">
            <input
              className={`custom-input ${error ? "errorActive" : ""}`}
              type={type}
              onChange={(e) => handleChange(e.target.value)}
              placeholder={placeholder}
              disabled={props.disabled}
            />
            {inputValue === "" && error ? (
              <span style={{ fontSize: "12px", color: "#d32f2f" }}>
                {helperText}
              </span>
            ) : (
              ""
            )}
            {/* https://www.npmjs.com/package/react-password-strength-bar */}
            {password && (
              <PasswordStrengthBar
                className="PasswordStrengthBar"
                password={inputValue}
                minLength={5}
                onChangeScore={(score, feedback) => {
                  console.log(score, feedback);
                }}
                scoreWords={["weak", "weak", "okay", "good", "strong"]}
                barColors={[
                  "#B83E26",
                  "#FFB829",
                  "#009200",
                  "#009200",
                  "#009200",
                  "#009200",
                ]}
                style={{ width: "94%" }}
              />
            )}

            {type === "password" ? (
              <span className="icon-span" onClick={() => setType("text")}>
                <VisibilityOff />
              </span>
            ) : (
              <span className="icon-span" onClick={() => setType("password")}>
                <Visibility />
              </span>
            )}
          </div>

          {/* validation tracker */}
          {password && (
            <main className="tracker-box">
              <div className={lowerValidated ? "validated" : "not-validated"}>
                {lowerValidated ? (
                  <span className="list-icon green">
                    <CheckRoundedIcon />
                  </span>
                ) : (
                  <span className="list-icon">
                    <CloseRoundedIcon />
                  </span>
                )}
                At least one lowercase letter (a-z)
              </div>
              <div className={upperValidated ? "validated" : "not-validated"}>
                {upperValidated ? (
                  <span className="list-icon green">
                    <CheckRoundedIcon />
                  </span>
                ) : (
                  <span className="list-icon">
                    <CloseRoundedIcon />
                  </span>
                )}
                At least one uppercase letter (A-Z)
              </div>
              <div className={numberValidated ? "validated" : "not-validated"}>
                {numberValidated ? (
                  <span className="list-icon green">
                    <CheckRoundedIcon />
                  </span>
                ) : (
                  <span className="list-icon">
                    <CloseRoundedIcon />
                  </span>
                )}
                At least one number (0-9)
              </div>
              <div className={specialValidated ? "validated" : "not-validated"}>
                {specialValidated ? (
                  <span className="list-icon green">
                    <CheckRoundedIcon />
                  </span>
                ) : (
                  <span className="list-icon">
                    <CloseRoundedIcon />
                  </span>
                )}
                At least one special character
              </div>
              <div className={lengthValidated ? "validated" : "not-validated"}>
                {lengthValidated ? (
                  <span className="list-icon green">
                    <CheckRoundedIcon />
                  </span>
                ) : (
                  <span className="list-icon">
                    <CloseRoundedIcon />
                  </span>
                )}
                At least 8 characters
              </div>
            </main>
          )}
        </div>
      </div>
    </div>
  );
}

export default PasswordStrength;
