import React from "react";
import dayjs, { Dayjs } from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import "./Datefield.css";
import { FormControl, FormHelperText } from "@mui/material";

function Datefield(props) {
  console.log(props, "props");
  return (
    <div className="Datefield">
      <div className="FieldLabel">
        {props.label} {props.validation && <span>*</span>}
      </div>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          // label="MM/DD/YYYY"
          inputFormat="MM/DD/YYYY"
          value={props.value}
          onChange={props.handleChange}
          renderInput={(params) => <TextField {...params} />}
          slotProps={{
            textField: {
              helperText: "values",
            },
          }}
        />
      </LocalizationProvider>
      {props.value === "" && (
        <span style={{ fontSize: "12px", color: "#d32f2f" }}>
          {props.helperText}
        </span>
      )}
    </div>
  );
}

export default Datefield;
