import React, { useEffect, useState } from "react";
import { Grid, InputAdornment, TextField } from "@mui/material";
import FileUplaoder from "../../../../../components/Form/FileUplaoder/FileUplaoder";
import LocationStapperDropDown from "../../../../../components/LocationStapperDropDown/LocationStapperDropDown";
import TextFields from "../../../../../components/Form/TextField/TextField";
import SelectField from "../../../../../components/Form/SelectField/SelectField";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ZipCode from "../../../../../components/Form/ZipCode/ZipCode";
import { Avatar, Button, IconButton } from "@material-ui/core";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { toast } from "react-toastify";
import "./BasicInfo.css";
import { deepPurple } from "@material-ui/core/colors";
import SideDrawer from "../../../../../components/SideDrawer/SideDrawer";
import MediaLibrarySelectMedia from "../../../../../components/MediaLibrary/MediaLibrarySelectMedia";
import Externaluse from "../../../../../components/MediaLibrary/Externaluse";
import PhoneInputField from "../../../../../components/PhoneInputField/PhoneInputField";

const BasicInfo = (props) => {
  const { locationInfo, setLocationInfo, errors, setErrors, setPhoneError } =
    props;

  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [values, setValues] = useState({
    itemName: "",
    description: "",
  });
  const handleChangesSpicy = (event) => {
    setState(event.target.value);
  };

  const handleChanges = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };

  const handleZipCodeChange = (newZipCode) => {
    setZipCode(zipCode); // update the state of the parent component with the new zip code value
  };

  const handleChangesOfField = (fieldName, fieldValue) => {
    setLocationInfo({ ...locationInfo, [fieldName]: fieldValue });
  };

  // =============================== media lib code start ================================ //
  const [openSelectMedia, setOpenSelectMedia] = useState(false);
  const [openNewMedia, setOpenNewMedia] = useState(false);
  const [mediaItem, setMediaItem] = useState({});
  const [isActiveLogoIcon, setActiveLogoIcon] = useState(true);
  const [selectedValue, setSelectedValue] = React.useState(false);

  const [openSelectMedia2, setOpenSelectMedia2] = useState(false);
  const [openNewMedia2, setOpenNewMedia2] = useState(false);
  const [selectedValue2, setSelectedValue2] = React.useState(false);
  const [mediaItem2, setMediaItem2] = useState({});
  const [medialib, setMediaLib] = useState(false);

  const getMediaItem = (item) => {
    // setting the media item
    setMediaItem(item);
  };
  const handleMediaUploadClose2 = () => {
    setOpenNewMedia2(false);
  };

  const handleDrawerCloseSelectMedia = () => {
    setOpenSelectMedia(false);
    //setOpen(true);
  };

  const handleDrawerOpenNewMedia2 = () => {
    setOpenNewMedia2(true);
  };

  const handleOpenNewMedia2 = (status) => {
    setOpenNewMedia2(!status);
    handleDrawerOpenSelectMedia2(status);
  };
  const getMediaItem2 = (item) => {
    // setting the media item
    setMediaItem2(item);
  };
  const handleDrawerOpenSelectMedia2 = () => {
    setOpenSelectMedia2(true);
  };

  const handleOpenNewMedia = (status) => {
    setOpenNewMedia(!status);
    handleDrawerOpenSelectMedia(status);
  };
  const handleDrawerOpenSelectMedia = () => {
    setOpenSelectMedia(true);
    setActiveLogoIcon(!isActiveLogoIcon);
  };
  const clickSelectMediaItem = async () => {
    //check if any item is selected
    if (selectedValue) {
      if (mediaItem?.mimeType?.startsWith("image/")) {
        let newObject = {
          ...locationInfo,
          photo: mediaItem.url,
          photoName: mediaItem.name,
        };
        setLocationInfo(newObject);
        // toast.success("Image has added successfully");
        setMediaLib(false);
      } else {
        toast.error("Image file can be accepeted");
      }
    } else {
      toast.error("Please select any media");
    }
  };

  const handleRemovePhoto = () => {
    let newObject = {
      ...locationInfo,
      photo: "",
    };
    setLocationInfo(newObject);
  };
  const [phoneNumberFormated, setPhoneNumberFormated] = useState("");
  const handleChangesOfFields = (fieldName, phoneNumber, country, e) => {
    let data = phoneNumber.slice(country?.dialCode?.length);
    if (data?.length === 0) {
      setPhoneError("Required");
    } else {
      setPhoneError("");
      setLocationInfo({
        ...locationInfo,
        [fieldName]: e.target.value,
      });
    }
  };

  const applicationStauts = process.env.REACT_APP_PROJECT_ENV;
  return (
    <>
      <div className="itemDetailsGrandContainer">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h3>Basic info</h3>
          </Grid>
          <Grid item xs={12}>
            <LocationStapperDropDown
              locationInfo={locationInfo}
              setLocationInfo={setLocationInfo}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFields
              label="Location name"
              required={false}
              email={false}
              value={locationInfo?.name}
              // handleValidation={handleValidation}
              InnerFieldCount={true}
              count={50}
              handleChange={(e) => handleChangesOfField("name", e.target.value)}
              error={errors?.name}
              helperText={errors?.name}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <FileUplaoder
              label="Photo"
              validation
              helpertext="Maximum size of 8.0MB. .jpg, .jpeg, or .png."
            />
          </Grid> */}

          <Grid item xs={12}>
            {locationInfo?.photo ? (
              <div className="MenuItemImageContainer">
                {" "}
                <Avatar
                  id="basic-button"
                  sx={{ bgcolor: deepPurple[300] }}
                  src={locationInfo?.photo ? locationInfo?.photo : ""}
                ></Avatar>
                <p>{locationInfo?.photoName} </p>
                <IconButton onClick={handleRemovePhoto}>
                  <CloseRoundedIcon />
                </IconButton>
              </div>
            ) : (
              <div className="MenuItemBtnContainer">
                <Button
                  variant="outlined"
                  sx={{
                    py: 1.5,
                    width: "100%",
                    borderColor: `${errors?.photo ? "red" : "#E4E8EC"}`,
                    color: "#4C9F41",
                    textTransform: "capitalize",
                    "&:hover": {
                      borderColor: "#E4E8EC",
                      color: "#4C9F41",
                    },
                  }}
                  startIcon={<FileUploadOutlinedIcon />}
                  onClick={() => setMediaLib(true)}
                >
                  Choose File
                </Button>
                <span className="MediaLibWarning">
                  File is too large, maximum file size is 10.0MB
                </span>
              </div>
            )}

            {errors?.photo ? (
              <p
                style={{
                  margin: "0px",
                  color: "#d32f2f",
                  fontSize: "12px",
                  textTransform: "capitalize",
                }}
              >
                {errors?.photo}
              </p>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12}>
            <TextFields
              label="Email address"
              email={true}
              value={locationInfo?.email}
              // disabled={locationInfo?._id ? true : false}
              handleChange={(e) =>
                handleChangesOfField("email", e.target.value)
              }
              error={errors?.email}
              helperText={errors?.email}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <div className="FieldLabel">Phone number</div>
            <PhoneInputField
              phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
              country="us"
              value={locationInfo?.phone}
              countryCodeEditable={false}
              // onChange={(phone, country) => handlePhoneChange(phone, country)}
              onChange={(phone, country, e) =>
                handleChangesOfFields("phone", `${phone}`, country, e)
              }
              // onBlur={(e) => setPhoneNumberFormated(e.target.value)}
              onKeyDown={(e) => setPhoneNumberFormated(e.target.value)}
              variant="outlined"
              onlyCountries={
                applicationStauts === "dev" ? ["us", "bd", "pk"] : ["us"]
              }
              // showDropdown={true}
              error={errors?.phone ? "error" : ""}
              containerStyle={{}}
              buttonStyle={{}}
              inputStyle={{
                marginLeft: "0px",
                height: "40px",
                width: "100%",
                fontFamily: "Roboto-Regular",
                fontSize: "16px",
              }}
              containerClass={{}}
              errorText={errors?.phone}
              errorStyles={{
                marginBottom: "5px",
                fontSize: "12px",
                background: "#fff",
                color: "#d32f2f",
              }}
              autoFormat={true}
              disableDropdown={false}
              disabled={false}
              disableCountryCode={false}
              enableSearch={false}
              disableSearchIcon={false}
              placeholder={""}
              // inputProps={{}}
              // specialLabel={"Primary Phone"}
              // preferredCountries={["us"]}
              // excludeCountries={["us"]}
            />

            {/* <TextField
              // required={true}
              email={false}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span style={{ color: "#0B132B !important" }}>+1</span>
                  </InputAdornment>
                ),
              }}
              value={locationInfo?.phone}
              onChange={(e) => handleChangesOfField("phone", e.target.value)}
              error={errors?.phone}
              helperText={errors?.phone}
            /> */}
          </Grid>

          <Grid item xs={12} md={4}>
            <TextFields
              label="Location Code"
              // required={true}
              email={false}
              value={locationInfo?.locationCode}
              // handleValidation={handleValidation}
              count={2}
              // placeholder="Email address"
              handleChange={(e) =>
                handleChangesOfField("locationCode", e.target.value)
              }
              error={errors?.locationCode}
              helperText={errors?.locationCode}
            />
          </Grid>

          <Grid item xs={12} md={8}>
            <TextFields
              label="Street address"
              required={true}
              email={false}
              value={locationInfo?.address}
              // handleValidation={handleValidation}
              // count={100}
              // handleChange={handleChanges("itemName")}
              // placeholder="Email address"
              handleChange={(e) =>
                handleChangesOfField("address", e.target.value)
              }
              error={errors?.address}
              helperText={errors?.address}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextFields
              label="Floor/Suite # (optional)"
              // required={true}
              email={false}
              value={locationInfo?.apartment}
              // handleValidation={handleValidation}
              count={200}
              // placeholder="Email address"
              handleChange={(e) =>
                handleChangesOfField("apartment", e.target.value)
              }
              // error={errors?.apartment}
              // helperText={errors?.apartment}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextFields
              label="City"
              required={true}
              email={false}
              value={locationInfo?.city}
              // handleValidation={handleValidation}
              count={200}
              // placeholder="Email address"
              handleChange={(e) => handleChangesOfField("city", e.target.value)}
              error={errors?.city}
              helperText={errors?.city}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectField
              label="State"
              required={true}
              options={states}
              selectedOption={locationInfo?.state}
              handleChange={(e) =>
                handleChangesOfField("state", e.target.value)
              }
              error={errors?.state}
              helperText={errors?.state}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ZipCode
              label="Zip/Postal Code"
              required
              value={locationInfo?.zip}
              // error={error.zipCode}
              // onZipCodeChange={handleZipCodeChange}

              onZipCodeChange={(e) => handleChangesOfField("zip", e)}
              error={errors?.zip}
              helperText={errors?.zip}
            />
          </Grid>
        </Grid>
        <div>
          {/* <button onClick={() => setMediaLib(true)}>media lib</button> */}
          <SideDrawer
            title="Media Library"
            ButtonLabel="Add"
            //clickSaveButton={clickSaveButton}
            cancelButtonLabel="Cancel"
            sideOpen="right"
            open={medialib}
            handleDrawerClose={() => setMediaLib(false)}
            FooterWithoutTab={true}
            LeftButton={true}
            ButtonLeft="Upload New"
            // ButtonLeftIcon={<FileUploadOutlinedIcon />}
            clickButtonLeft={handleDrawerOpenNewMedia2}
            clickSaveButton={clickSelectMediaItem}
            padding="0"
            body={
              <MediaLibrarySelectMedia
                onClickRadioIcon={setSelectedValue}
                onItemSelected={getMediaItem}
              />
            }
          />
          <Externaluse
            open={openNewMedia2}
            handleCloseButton={handleMediaUploadClose2}
            handleOpenNewMedia={getMediaItem2}
          />
        </div>
      </div>
    </>
  );
};

export default BasicInfo;

const states = [
  { label: "Alabama", value: "Alabama" },
  { label: "Alaska", value: "Alaska" },
  { label: "Arizona", value: "Arizona" },
  { label: "Arkansas", value: "Arkansas" },
  { label: "California", value: "California" },
  { label: "Colorado", value: "Colorado" },
  { label: "Connecticut", value: "Connecticut" },
  { label: "Delaware", value: "Delaware" },
  { label: "Florida", value: "Florida" },
  { label: "Georgia", value: "Georgia" },
  { label: "Hawaii", value: "Hawaii" },
  { label: "Idaho", value: "Idaho" },
  { label: "Illinois", value: "Illinois" },
  { label: "Indiana", value: "Indiana" },
  { label: "Iowa", value: "Iowa" },
  { label: "Kansas", value: "Kansas" },
  { label: "Kentucky", value: "Kentucky" },
  { label: "Louisiana", value: "Louisiana" },
  { label: "Maine", value: "Maine" },
  { label: "Maryland", value: "Maryland" },
  { label: "Massachusetts", value: "Massachusetts" },
  { label: "Michigan", value: "Michigan" },
  { label: "Minnesota", value: "Minnesota" },
  { label: "Mississippi", value: "Mississippi" },
  { label: "Missouri", value: "Missouri" },
  { label: "Montana", value: "Montana" },
  { label: "Nebraska", value: "Nebraska" },
  { label: "Nevada", value: "Nevada" },
  { label: "New Hampshire", value: "New Hampshire" },
  { label: "New Jersey", value: "New Jersey" },
  { label: "New Mexico", value: "New Mexico" },
  { label: "New York", value: "New York" },
  { label: "North Carolina", value: "North Carolina" },
  { label: "North Dakota", value: "North Dakota" },
  { label: "Ohio", value: "Ohio" },
  { label: "Oklahoma", value: "Oklahoma" },
  { label: "Oregon", value: "Oregon" },
  { label: "Pennsylvania", value: "Pennsylvania" },
  { label: "Rhode Island", value: "Rhode Island" },
  { label: "South Carolina", value: "South Carolina" },
  { label: "South Dakota", value: "South Dakota" },
  { label: "Tennessee", value: "Tennessee" },
  { label: "Texas", value: "Texas" },
  { label: "Utah", value: "Utah" },
  { label: "Vermont", value: "Vermont" },
  { label: "Virginia", value: "Virginia" },
  { label: "Washington", value: "Washington" },
  { label: "West Virginia", value: "West Virginia" },
  { label: "Wisconsin", value: "Wisconsin" },
  { label: "Wyoming", value: "Wyoming" },
];
