import React from "react";
import './VerticalTab.css';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Buttons from '../../components/Form/Button/Button';

import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';

function VerticalTab(props) {

  const { tabData, tabBody, } = props;

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    props.onTabSwitch(newValue);
    setValue(newValue);
  };


  // const tabData = [
  //   {
  //     icon: <CampaignRoundedIcon />,
  //     label: "Announcements",
  //   },
  //   {
  //     icon: <CampaignRoundedIcon />,
  //     label: "Announcements",
  //   },
  //   {
  //     icon: <CampaignRoundedIcon />,
  //     label: "Announcements",
  //   },
  // ]

  // const tabBody = [
  //   {
  //     id: 0,
  //     panel: "dsfsdf",
  //   },
  //   {
  //     id: 1,
  //     panel: "dsfsdfdsfsdv",
  //   },
  //   {
  //     id: 2,
  //     panel: "dsfsddsvsdvsdvsdvf",
  //   },

  // ]

  // <VerticalTab 
  //       title="Introduction to Islamic Economics and Finance"
  //       name="MUNA Academy"
            //ButtonLabel=""
          //Button={true}
  //       link="#"
  //       tabData={tabData}
  //       tabBody={tabBody}
  //     />


  return (
    <div className="VerticalTab">

      <div className="TabListingContainer">
        <div className="TabTitle">
          <h1>{props.title}</h1>
          <p><a href={props.link} target="_blank">{props.name}</a></p>
        </div>

        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            {tabData.map((item, i) => (
              <Tab label={item.label} icon={item.icon} {...a11yProps(0)} />
            ))}
          </Tabs>
        </AppBar>

        {props.Button &&
        <div style={{marginTop:"20px"}}>
        <Buttons
          label={props.ButtonLabel}
          buttonColor="#004FE0"
          color="#fff"
          width="100%"
          height="48px"
          fontSize="14px"
          link={props.buttonLink}
          target={props.buttonTarget}
          onClick={props.onClick}
          disabled={props.disabled}
        />
        </div>
         }
      </div>

      <div className="VerticalTabBody">
        {tabBody.map((item, i) => (
          <TabPanel value={value} index={item.id}>
            {item.panel}
          </TabPanel>
        ))}
      </div>

    </div>
  );
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default VerticalTab;

