import React, { useState } from "react";
import { Grid } from "@material-ui/core/";
import Buttons from "../../components/Form/Button/Button";
import TextField from "../../components/Form/TextField/TextField";
import Modals from "../../components/Form/Modals/Modals";
import Header from "./header/Header";
import { styled } from "@mui/system";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import "./Form.css";
import DraggableDialog from "../../hooks/Dialog";
import { Alert, Button } from "@mui/material";

function SignInOrgID(props) {
  const [values, setValues] = React.useState({
    name: "hello",
    number: "",
  });
  const handleChanges = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    console.log("close");
    setOpen(false);
  };

  const handleConfirm = () => {
    console.log("close");
    setOpen(false);
  };

  const handleCopied = () => {
    console.log("Copied");
  };

  return (
    <>
      <Header />
      <div className="SignContainer">
        <div className="SignContainerInner">
          <div className="SignForm">
            <h1>Sign in</h1>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Alert severity="error" sx={{alignItems: 'center', borderRadius: '8px', background: '#FBDFE1 !important'}}>
                  Sorry, we don't recognize that account ID. <b>4</b> attempts
                  remaining.
                </Alert>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  // value={values.name}
                  // onChange={handleChanges("name")}
                  placeholder="Account ID"
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Buttons
                  label="Continue"
                  buttonColor="#4C9F41"
                  border="#ccc 1px solid"
                  color="#fff"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  link=""
                  // onClick={}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Buttons
                  label="Forgot ID?"
                  color="#4C9F41"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  link=""
                  onClick={() => setOpen(true)}
                />
              </Grid>
            </Grid>
          </div>

          <div className="SignFormFooter">
            <LockOutlinedIcon />
            <span>SECURE SIGN IN</span>
            <SecurityOutlinedIcon />
            <span>SECURE SSL ENCRYPTION</span>
          </div>
        </div>

        {/* <Modals
          ModalTitle="Forgot organization ID?"
          handleClose={handleClose}
          handleConfirm={() => handleConfirm()}
          open={open}
          // ButtonClose="CLOSE"
          ButtonConfirm="ok"
          width="570px"
          ModalFooter={true}
          Headerbackground="#fff"
          Headercolor="#676C7B"
          Footerbackground="#fff"
          ModalBody={
            <div>Please contact your site admin for further assistance</div>
          }
        /> */}
        <DraggableDialog
          openDialog={open}
          handleCloseDialog={handleClose}
          title="Forgot account ID?"
          body="Please contact your site admin for further assistance"
          ModalFooter={true}
          cancelColor="#4C9F41 !important"
          cancelButton="OK"
        />
      </div>
    </>
  );
}

export default SignInOrgID;
