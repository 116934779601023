import React, { useEffect } from "react";
import Table from "../../components/Table/Table";
import tableImg from "../../assets/imgs/tableImg.png";
import { Backdrop, Button, Container } from "@mui/material";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Avatar from "../../assets/imgs/Avatar.png";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ModalOne from "../../components/Modals/ModalOne";
import { useState } from "react";
import { styled } from "@mui/system";
import OrdersActiveCard from "../../components/OrdersComponents/OrdersCard/OrdersActiveCard";
import OrdersTable from "../../components/OrdersComponents/OrdersTable/OrdersTable";
import OrdersTablEmptyCard from "../../components/OrdersComponents/OrdersTable/OrdersTablEmptyCard/OrdersTablEmptyCard";
import CircularProgress from "@mui/material/CircularProgress";
import OrdersModal from "../../components/OrdersComponents/OrdersModal/OrdersModal";
import TableTwo from "../../components/TableTwo/TableTwo";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import SideBarDrawer from "../../components/SideBarDrawer/SideBarDrawer";
import MediaLibrarySelectMedia from "../../components/MediaLibrary/MediaLibrarySelectMedia";
import Externaluse from "../../components/MediaLibrary/Externaluse";
import {
  useGetOrderListByLocationQuery,
  useGetOrderListQuery,
  useLazyGetOrderListByLocationQuery,
} from "../../redux/features/orders/orderApi";
import useProtected from "../../hooks/useProtected";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function createData(Date, OrderID, Amount, Status) {
  return { Date, OrderID, Amount, Status };
}

const backgroundAudio = new Audio("../../assets/audio/doorbell-3.mp3");

function OrdersNewListing(props) {
  const activeLocation = useSelector((state) => state.auth.activeLocation);
  const { data, isLoading, isFetching, error } = useGetOrderListByLocationQuery(
    activeLocation || "",
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    getOrderListByLocationLazy,
    {
      data: ordersDataLazy,
      isLoading: ordersDataLazyIsLoading,
      isFetching: ordersDataLazyIsFetching,
      error: ordersDataError,
    },
  ] = useLazyGetOrderListByLocationQuery(activeLocation || "", {
    refetchOnMountOrArgChange: true,
  });

  useProtected(error);

  const navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [getNewOrderId, setGetNewOrderId] = useState("");
  const [newOrders, setNewOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [activeData, setActiveData] = useState(0);
  const [openSnakbar, setOpenSnakbar] = useState(true);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  const [scheduledOrders, setScheduledOrders] = useState([]);
  const [declinedOrders, setDeclinedOrders] = useState([]);
  const [inProgressOrders, setInProgressOrders] = useState([]);
  const [scheduled, setScheduled] = useState("ASAP");
  const [loaderShow, setLoaderShow] = useState(false);

  // * order modal section
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const taxData = process.env.REACT_APP_TAX_VALUE;
  const [open, setOpen] = useState(false);
  const handleCloses = () => {
    setOpen(false);
  };
  const handleOpens = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (activeData == 0) {
      setRows(convertDataToCellRows(newOrders || []));
    } else if (activeData == 1) {
      setRows(convertDataToCellRows(inProgressOrders || []));
    } else if (activeData == 2) {
      setRows(convertDataToCellRows(scheduledOrders || []));
    } else if (activeData == 3) {
      setRows(convertDataToCellRows(deliveredOrders || []));
    } else if (activeData == 4) {
      setRows(convertDataToCellRows(declinedOrders || []));
    } else if (activeData == 5) {
      setRows(convertDataToCellRows(cancelledOrders || []));
    } else if (activeData == 6) {
      setRows(convertDataToCellRows(allOrders || []));
    }
  }, [activeData]);

  // console.log(activeLocation, 345);
  useEffect(() => {
    // Function to make the AJAX call
    const fetchData = async () => {
      try {
        // Your AJAX call logic here
        const response = await getOrderListByLocationLazy(activeLocation);

        const data = response?.data?.body?.data;
        let all = data;
        // alert(all?.length+'amar')
        // setLoaderShow(true);
        if (all?.length > 0) {
          let nOrders = all?.filter((order) => order.orderStatus == "new");
          let iProgressOrders = all?.filter(
            (order) =>
              order.orderStatus == "in-progress" ||
              order.orderStatus == "ready-to-pickup" ||
              order.orderStatus == "out-for-delivery"
          );
          let dOrders = all?.filter(
            (order) => order.orderStatus == "delivered"
          );
          let cOrders = all?.filter(
            (order) => order.orderStatus == "cancelled"
          );
          let declinedOrders = all?.filter(
            (order) => order.orderStatus == "declined"
          );

          let scheduledOrders = all?.filter(
            (order) => order.orderStatus == "scheduled"
          );
          // console.log(nOrders, 328);
          setAllOrders(all);
          setInProgressOrders(iProgressOrders);
          setDeliveredOrders(dOrders);
          setCancelledOrders(cOrders);
          setDeclinedOrders(declinedOrders);
          setScheduledOrders(scheduledOrders);
          setNewOrders(nOrders);
          if (nOrders?.length > 0) {
            setOrderId(nOrders[0]?._id);
            setOpenSnakbar(true);
          }
          // setActiveData(0);
          // setLoaderShow(false);
        } else {
          setAllOrders([]);
          setInProgressOrders([]);
          setDeliveredOrders([]);
          setCancelledOrders([]);
          setNewOrders([]);
          setScheduledOrders([]);

          // setActiveData("");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Set up an interval to make the AJAX call every 5 seconds
    if (!openModal) {
      const intervalId = setInterval(fetchData, 8000);

      return () => clearInterval(intervalId);
    }
  }, [openModal, activeLocation]);
  // }, []);

  useEffect(() => {
    if (!isLoading && !isFetching && data && activeLocation != "") {
      let all = data?.body?.data;

      if (all?.length > 0) {
        let nOrders = all?.filter((order) => order.orderStatus == "new");
        let iProgressOrders = all?.filter(
          (order) => order.orderStatus == "in-progress"
        );
        let dOrders = all?.filter((order) => order.orderStatus == "delivered");
        let cOrders = all?.filter((order) => order.orderStatus == "cancelled");
        let scheduledOrders = all?.filter(
          (order) => order.orderStatus == "scheduled"
        );
        let declinedOrders = all?.filter(
          (order) => order.orderStatus == "declined"
        );
        setAllOrders(all);
        setInProgressOrders(iProgressOrders);
        setDeliveredOrders(dOrders);
        setCancelledOrders(cOrders);
        setNewOrders(nOrders);
        setDeclinedOrders(declinedOrders);
        setScheduledOrders(scheduledOrders);
        if (nOrders?.length > 0) {
          setOrderId(nOrders[0]?._id);
          setOpenSnakbar(true);
        }

        // setActiveData(0);
      } else {
        setAllOrders([]);
        setInProgressOrders([]);
        setDeliveredOrders([]);
        setCancelledOrders([]);
        setDeclinedOrders([]);
        setNewOrders([]);
        setScheduledOrders([]);
        setActiveData("");
      }
    }
  }, [data, isLoading, isFetching, activeLocation]);

  const onViewHandler = (id) => {
    setOrderId(id);
    onClick();
  };
  const redirectToDetailsHandler = (id) => {
    navigate(`order-details/${id}`);
  };

  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr?.map((item) => {
        let statusColor = "";
        let statusLabel = "";
        let statusBackground = "";

        if (item?.orderStatus == "new") {
          statusColor = "#0B9444";
          statusLabel = "New";
          statusBackground = "#E1F2E8";
        } else if (item?.orderStatus == "in-progress") {
          statusColor = "#ff6200";
          statusLabel = "In Progress";
          statusBackground = "#fbd8ca";
        } else if (item?.orderStatus == "delivered") {
          statusColor = "#0B9444";
          statusLabel = "Delivered";
          statusBackground = "#E1F2E8";
        } else if (
          item?.orderStatus == "cancelled" ||
          item?.orderStatus == "declined"
        ) {
          statusColor = "#1b1b20";
          statusLabel =
            item?.orderStatus == "cancelled" ? "Cancelled" : "Declined";
          statusBackground = "#e4e4e4";
        } else if (item?.orderStatus == "ready-to-pickup") {
          statusColor = "#0450e1";
          statusLabel = "Ready For Pickup";
          statusBackground = "#cad5f4";
        } else if (item?.orderStatus == "out-for-delivery") {
          statusColor = "#7266fb";
          statusLabel = "Out For Delivery";
          statusBackground = "#dcd9fa";
        } else if (item?.orderStatus == "draft") {
          statusColor = "#E63946";
          statusLabel = "Draft";
          statusBackground = "#FCE7E9";
        } else if (item?.orderStatus == "scheduled") {
          statusColor = "#FFB703";
          statusLabel = "Scheduled";
          statusBackground = "#fff6e0";
        }

        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        };

        let date = new Date(item?.createdAt).toLocaleString("en-US", options);
        const totalTax = (
          (item?.orderSubTotal * parseFloat(taxData)) /
          100
        )?.toFixed(2);
        return {
          cell: [
            { textBold: date || "N/A" },
            { textBold: item?.orderNumber || "N/A" },
            {
              textBold:
                `$${parseFloat(
                  item?.orderTotal + parseFloat(totalTax)
                )?.toFixed(2)}` || "0.00",
            },
            {
              Status: true,
              background: statusBackground,
              color: statusColor,
              label: statusLabel,
            },
            {
              Button: true,
              buttonLabel: "View",
              buttonTextcolor: "#4C9F41",
              buttonLink: "",
              onClick:
                item?.orderStatus == "new"
                  ? (e) => onViewHandler(item?._id)
                  : (e) => redirectToDetailsHandler(item?._id),
            },
          ],
        };
      });
    }
  };

  const handleOpensnakbar = () => {
    setOpenSnakbar(true);
  };
  const handleClose = () => {
    console.log("close");
    setOpenSnakbar(false);
    // setEditProfile(false);
  };

  useEffect(() => {
    if (openSnakbar) {
      // Play the backdrop sound when the backdrop is opened
      backgroundAudio.play();
    } else {
      // Pause the backdrop sound when the backdrop is closed
      backgroundAudio.pause();
      // backgroundAudio.currentTime = 0; // Reset the playback to the beginning
    }
  }, [openSnakbar]);

  // * table button function
  const onClick = () => {
    // alert("onclick working fine!");
    handleOpenModal();
  };
  const handleOpen = () => {
    // alert("onclick working fine!");
    handleOpenModal();
  };

  const headCells = [
    {
      id: "Date",
      numeric: false,
      disablePadding: true,
      label: "Date",
      width: "30%",
    },
    {
      id: "OrderID",
      numeric: true,
      disablePadding: false,
      label: "Order ID",
      width: "20%",
    },
    {
      id: "Amount",
      numeric: true,
      disablePadding: false,
      label: "Amount",
      width: "20%",
    },
    {
      id: "Status",
      numeric: true,
      disablePadding: false,
      label: "Status",
      width: "20%",
    },
    {
      id: "action",
      numeric: true,
      disablePadding: false,
      label: "",
      width: "10%",
    },
  ];

  const [medialib, setMediaLib] = useState(false);
  const [deliveryInstructionData, setDeliveryInstructionData] = useState("");
  return (
    <>
      <div className="Orders">
        <h1 className="OrdersTitle">Orders</h1>
        <div>
          <OrdersActiveCard
            allOrders={allOrders}
            inProgressOrders={inProgressOrders}
            deliveredOrders={deliveredOrders}
            cancelledOrders={cancelledOrders}
            newOrders={newOrders}
            setOpenModal={setOpenModal}
          />
        </div>
      </div>
      <div
        className="TableSection"
        style={{ marginTop: "40px", borderRadius: "12px", overflow: "hidden" }}
      >
        <OrdersTable
          setActiveData={setActiveData}
          allOrders={allOrders}
          inProgressOrders={inProgressOrders}
          deliveredOrders={deliveredOrders}
          cancelledOrders={cancelledOrders}
          newOrders={newOrders}
          scheduledOrders={scheduledOrders}
          activeData={activeData}
        />
        {/* <OrdersTablEmptyCard/> */}
        <TableTwo
          title=""
          innerTitle=""
          addLabel="New User"
          link="/neworder-details"
          headCells={headCells}
          rows={rows}
          isDataLoading={isLoading || loaderShow ? true : false} // loader props
          AddButton={false}
          tableHeader={true}
          innerHeader={true}
          Checkbox={false}
          footer={true}
        />
      </div>

      {/* ------------------- back drop for new order alert --------------------- */}
      {newOrders?.length > 0 && !openModal && (
        <Backdrop
          sx={{
            color: "black",
            zIndex: 10000,
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={openSnakbar}
          onClick={handleClose}
        >
          <div className="newAlertContainer">
            <div className="newAlretNotification">
              <h1>1</h1>
            </div>
            <div className="newAlretNotificationButton">
              <h2>New order!</h2>
              <AcceptOrderStyledButton variant="outlined" onClick={onClick}>
                SEE ORDER DETAILS
              </AcceptOrderStyledButton>
            </div>
          </div>
          <audio src="../../assets/audio/doorbell-3.mp3" loop />
        </Backdrop>
      )}
      {/* ------------------- modal for new order -------------------------------- */}

      {/* testing  */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleCloses}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* ------ modal ------ */}
      <OrdersModal
        // ModalTitle="Delivery"
        handleClose={handleCloseModal}
        // handleConfirm={() => handleConfirm()}
        // handleClose={() => handleClose()}
        ModalFooter
        open={openModal}
        ButtonClose="CLOSE"
        ButtonConfirm="CONFIRM"
        width="670px"
        Headerbackground={scheduled === "ASAP" ? "#DD2D37" : "black"}
        Headercolor="#676C7B"
        Footerbackground="#fff"
        orderId={orderId}
        setOpenModal={setOpenModal}
        setActiveData={setActiveData}
        setScheduled={setScheduled}
        setDeliveryInstructionData={setDeliveryInstructionData}
        setLoaderShow={setLoaderShow}
        setGetNewOrderId={setGetNewOrderId}
        // Scheduled value
        Scheduled={scheduled}
        // Delivery instruction
        DeliveryInstruction={deliveryInstructionData ? true : false}
        DeliveryInstructionTitle={"Delivery instruction"}
        DeliveryInstructionParagraph={deliveryInstructionData}
      />
    </>
  );
}

export default OrdersNewListing;

const AcceptOrderStyledButton = styled(Button)(({ theme }) => ({
  margin: "0 5px",
  padding: "15px 0",
  width: "50%",
  color: "#fff",
  fontSize: "12px",
  background: "#4C9F41",
  border: "1px solid #E4E2E6",
  borderRadius: "1000px",
  gap: "10px",
  alignItems: "center",
  "&:hover": {
    border: "1px solid #4C9F41",
    background: "#4C9F41",
  },
}));
