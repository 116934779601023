import React, { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getUserAuthData, getUserData } from '../hooks/HelperFunctions'
import Header from '../components/Header/Header';
import SideBarDrawer from '../components/SideBarDrawer/SideBarDrawer';
import Sidebar from '../components/sidebar/Sidebar';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

const OwnerAuth = ({ children }) => {

    const [open, setOpen] = useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const location = useLocation();
    const userAuthenticated = getUserAuthData();
    // retrive user data
    const userData = getUserData();
    // assign user role
    const userRole = userData.userRole === "undefined" ? "" : userData.userRole;

    if (userAuthenticated && userRole === "owner") {
        return (
            <>
                <div className="DesktopView">
                    <Header />
                    <Sidebar />
                </div>

                <div className="ResponsiveMenu">
                    <Header
                        IconMenu={<a onClick={handleDrawerOpen}><MenuRoundedIcon /></a>}
                    />
                    <SideBarDrawer
                        open={open}
                        handleDrawerClose={handleDrawerClose}
                        DrawerBody={
                            <Sidebar />
                        }
                    />
                </div>
                <div className="app-body"> {children} </div>
            </>
        );
    }

    return <Navigate to="/signin" state={{ from: location }} replace></Navigate>;
}

export default OwnerAuth;