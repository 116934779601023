import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
  token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
  firstTime: false,
  rolesWithPermissions: localStorage.getItem('rolesWithPermissions') ? JSON.parse(localStorage.getItem('rolesWithPermissions')) : '',
  activeLocation:localStorage.getItem('activeLocation') ? localStorage.getItem('activeLocation') : ''
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(action.payload));
    },
    setToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem('token', action.payload);
    },
    logout: (state) => {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('rolesWithPermissions');
      localStorage.removeItem('activeLocation');
      state.token = '';
      state.user = null;
      state.rolesWithPermissions = '';
      state.activeLocation = '';
    },
    setFirstTimeLogin: (state, action) => {
      state.firstTime = action.payload
    },
    setRolesWithPermissions: (state, action) => {
      state.rolesWithPermissions = action.payload
    },
    setActiveLocation:(state,action) => {
      state.activeLocation = action.payload
    }
  },
});

export const { setToken, setUserData, setRolesWithPermissions, logout, setFirstTimeLogin, setActiveLocation } = authSlice.actions;
export default authSlice.reducer;
