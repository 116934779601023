import React, { useState } from "react";
import TextFieldCom from "@mui/material/TextField";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import Tooltip from "../Tooltip/Tooltip";
import "./TextField.css";
import { InputAdornment } from "@mui/material";

function TextField(props) {
  const {
    label,
    required,
    validation,
    email,
    number,
    error,
    placeholder,
    value,
    handleChange,
    handleValidation,
    readOnly,
    helperText,
    count,
    disabled,
    InnerFieldCount,
  } = props;

  const [validationError, setValidationError] = useState(false);
  const [validationText, setValidationText] = useState("");

  const handleOnChange = (event) => {
    const value = event.target.value;
    let validationErrorFlag = false;

    // required field validation
    if (required || validation) {
      if (value?.length > 0) {
        setValidationText("");
      } else {
        validationErrorFlag = true;
        setValidationText("Required");
      }
    }

    // email field validation
    if (email) {
      if (emailValidation(value)) {
        setValidationText("");
      } else {
        validationErrorFlag = true;
        setValidationText("Email is not valid");
      }
    }

    setValidationError(validationErrorFlag);

    if (handleValidation) {
      handleValidation(validationErrorFlag);
    }

    handleChange(event);
  };

  const emailValidation = (email) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || regex.test(email) === false) {
      return false;
    }
    return true;
  };

  return (
    <div className="TextField" style={{ position: "relative" }}>
      <div className="FieldLabel">
        {label} {(required || validation) && <span>*</span>}
        {props.TooltipStatus && (
          <Tooltip
            TooltipText={props.TooltipText}
            Tooltip={<InfoRoundedIcon />}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <TextFieldCom
          id="outlined-basic"
          variant="outlined"
          type={number ? "number" : "text"}
          error={error || validationError}
          placeholder={placeholder}
          value={value}
          onChange={handleOnChange}
          helperText={helperText || validationText}
          disabled={disabled ? disabled : false}
          inputProps={{
            maxLength: count,
            readOnly: readOnly,
          }}
          InputProps={{
            startAdornment: props.StartAdorment && (
              <InputAdornment position="start" sx={{ mt: -0.6 }}>
                {props.startAdornment}
              </InputAdornment>
            ),
          }}
        />

        {InnerFieldCount ? (
          <span className=" InnerFieldCount">
            {count && value && (
              <span style={{ color: "#B6B9B9" }}>
                {" "}
                {value.length} / {count}
              </span>
            )}
          </span>
        ) : (
          <span className="FieldCount">
            {count && value && (
              <span style={{ color: "#B6B9B9", alignSelf: "flex-end" }}>
                {" "}
                CHAR {value.length} / {count}
              </span>
            )}
          </span>
        )}
      </div>
      {/* temporary disabled by sujan */}
      {/* <a className="AvailableButton">Available?</a> */}
    </div>
  );
}

export default TextField;
