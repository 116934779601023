import react from "react";
import { styled } from "@mui/system";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { Chip } from "@mui/material";
import "./OrdersTimeline.css";
import moment from "moment-timezone";

function OrdersTimeline(props) {
  const { orderDetails } = props;

  const dateOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const showTime = (inputDate) => {
    const date = new Date(inputDate);
    const formattedTime = moment(inputDate)?.format("MMM DD, YYYY, h:mm A");
    // const formattedTime = date.toLocaleTimeString("en-US", dateOptions);
    return formattedTime;
  };

  const showTimeLine = () => {
    let timeLineRows = [];
    if (orderDetails?.timeLine?.length > 1) {
      orderDetails?.timeLine?.map((singleTimeline, index) => {
        if (index != 0) {
          let status = "";
          if (singleTimeline?.status == "in-progress") {
            status = "Accepted";
          } else if (singleTimeline?.status == "ready-to-pickup") {
            status = "Ready for pickup";
          } else if (singleTimeline?.status == "out-for-delivery") {
            status = "Out for delivery";
          } else if (singleTimeline?.status == "delivered") {
            status = "Delivered";
          } else if (singleTimeline?.status == "cancelled") {
            status = "Cancelled";
          } else if (singleTimeline?.status == "customer-cancelled") {
            status = "Cancelled by customer";
          } else if (singleTimeline?.status == "paid") {
            status = "Paid";
          } else if (singleTimeline?.status == "declined") {
            status = "Declined";
          } else if (singleTimeline?.status == "scheduled") {
            status = "Scheduled";
          }

          if (orderDetails?.timeLine?.length - 1 == index) {
            timeLineRows.push(
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot />
                </TimelineSeparator>
                <TimelineContent>
                  <StyledChip label={status} variant="outlined" />
                  <b>{showTime(singleTimeline?.time)}</b>
                </TimelineContent>
              </TimelineItem>
            );
          } else {
            timeLineRows.push(
              <TimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <StyledChip label={status} variant="outlined" />
                  <b>{showTime(singleTimeline?.time)}</b>
                  {/* <b>{showTime(singleTimeline?.time)}</b> */}
                </TimelineContent>
              </TimelineItem>
            );
          }
        }
      });
    }
    return timeLineRows;
  };
  return (
    <div className="Timeline boxShadow">
      <h2>TimeLine</h2>
      <style>{timelineItemStyles}</style>
      <Timeline sx={{ mt: 2 }}>
        {orderDetails?.timeLine?.length > 0 && (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <StyledChip
                label={
                  orderDetails?.timeLine[0]?.status == "Order Placed" &&
                  "Placed"
                }
                variant="outlined"
              />
              <b>{showTime(orderDetails?.timeLine[0]?.time)}</b>
            </TimelineContent>
          </TimelineItem>
        )}
        {orderDetails?.timeLine?.length > 0 && showTimeLine()}

        {/* <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent><StyledChip label="Accepted" variant="outlined" /><b>6:10 PM</b></TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent><StyledChip label="Ready for pickup" variant="outlined" /><b>6:10 PM</b></TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent><StyledChip label="Out for delivery" variant="outlined" /><b>6:10 PM</b></TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
          </TimelineSeparator>
          <TimelineContent><StyledChip label="Delivered" variant="outlined" /><b>6:10 PM</b></TimelineContent>
        </TimelineItem> */}
      </Timeline>
    </div>
  );
}

export default OrdersTimeline;

const StyledChip = styled(Chip)(({ theme }) => ({
  color: "#4C9F41",
  borderRadius: "8px",
  "& .MuiChip-label": {
    margin: "0",
  },
}));

const StyledTimelineDot = styled(TimelineDot)((theme) => ({
  ".MuiTimelineItem-root .MuiTimelineSeparator-root .MuiTimelineDot.defaultGrey":
    {
      color: "#000000 !important",
      borderColor: "transparent !important",
      backgroundColor: "#000000 !important",
    },
}));

const timelineItemStyles = `
b {
  margin-left: 10px;
  font-weight: 600;
}
.MuiTimeline-root {
  padding: 6px 0px;
}
.MuiTimelineConnector-root {
  padding: '0rem';
}
.MuiTimelineSeparator-root span : {
  padding: 0px !important;
}
.MuiTimelineItem-content{
  padding: 2px 16px;
}
.MuiTimelineItem-missingOppositeContent:before {
  content: none !important;
}
.MuiTimelineDot-defaultGrey {
  margin-top: 13px;
  color: 'black !important'
}
`;
