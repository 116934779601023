import { api } from "../../api/apiSlice";

const mediaLibApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMediaLibList: builder.query({
      query: () => ({
        url: "/media-lib/find-all",
      }),
      providesTags: ["Media"],
    }),
    getMediaLibrarySearchResult: builder.mutation({
      query: (data) => ({
        url: "/media-lib/search",
        method: "POST",
        body: data,
      }),
    }),
    getMediaLibDetails: builder.query({
      query: (id) => ({
        url: `/media-lib/getSingle/${id}`,
      }),
      providesTags: ["Media"],
    }),
    deleteMediaLib: builder.mutation({
      query: (id) => ({
        url: `/media-lib/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Media"],
    }),
    updateMediaLib: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/media-lib/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Media"],
    }),
    addMediaLib: builder.mutation({
      query: (data) => ({
        url: `/media-lib/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Media"],
    }),
    checkMediaLibName: builder.mutation({
      query: (data) => ({
        url: `/media-lib/check-name`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Media"],
    }),

    reArrangedFeild: builder.mutation({
      query: (data) => ({
        url: `/media-lib/search`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Media"],
    }),
    generatePresignedUrl: builder.mutation({
      query: (data) => ({
        url: `/media-lib/generate-presigned-url`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Media"],
    }),
  }),
});

export const {
  useGetMediaLibListQuery,
  useGetMediaLibrarySearchResultMutation,
  useGetMediaLibDetailsQuery,
  useDeleteMediaLibMutation,
  useUpdateMediaLibMutation,
  useAddMediaLibMutation,
  useCheckMediaLibNameMutation,
  useReArrangedFeildMutation,
  useGeneratePresignedUrlMutation,
} = mediaLibApi;
