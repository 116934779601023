import { api } from "../../api/apiSlice";

const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserList: builder.query({
      query: (locationId) => ({
        url: `/users/private/getall/${locationId}`,
      }),
      providesTags: ["Users"],
    }),

    getSingleUserList: builder.query({
      query: (id) => ({
        url: `/users/private/single/${id}`,
      }),
      providesTags: ["User"],
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/users/private/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Users"],
    }),
    updateSettings: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/users/private/update/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),
    createUser: builder.mutation({
      query: (data) => ({
        url: "/users/private/create",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),
    updateUser: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/users/private/update/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Users", "User"],
    }),
    addPhoneNumber: builder.mutation({
      query: (data) => ({
        url: `/users/private/add-phone`,
        method: "POST",
        body: data,
      }),
    }),

    verifyPhoneNumber: builder.mutation({
      query: (data) => ({
        url: `/users/private/verify-phone`,
        method: "POST",
        body: data,
      }),
    }),
    changeUserCurrentPassword: builder.mutation({
      query: (data) => ({
        url: `/users/private/change-password`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),
    forgotPasswordVerify: builder.mutation({
      query: (data) => ({
        url: "/users/public/forgot-password-verify",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetUserListQuery,
  useGetSingleUserListQuery,
  useDeleteUserMutation,
  useUpdateSettingsMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
  useAddPhoneNumberMutation,
  useChangeUserCurrentPasswordMutation,
  useVerifyPhoneNumberMutation,
  useForgotPasswordVerifyMutation,
} = userApi;
