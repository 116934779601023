import React, { useState } from "react";
import { Grid } from "@mui/material";
import AddTextField from "../../../../../components/Form/AddTextField/AddTextField";
import "./PriceAndVariations.css";
import TextField from "../../../../../components/Form/TextField/TextField";

const PriceAndVariations = (props) => {
  const { menuInfo, setMenuInfo, errors } = props;
  const [item, setItem] = useState([]);

  // === onChange event handler, get field data from parem === //
  const handleChangeWithData = (fieldName, fieldData) => {
    setItem({ ...item, [fieldName]: fieldData });
  };

  const handleChangesOfField = (fieldName, fieldValue) => {
    console.log(fieldName, fieldValue, "fieldName fieldValue");
    setMenuInfo({ ...menuInfo, [fieldName]: fieldValue });
  };

  const addVariationHandler = () => {
    let newVariations = [
      ...menuInfo?.variations,
      {
        name: "",
        price: "",
      },
    ];
    setMenuInfo({ ...menuInfo, variations: newVariations });
  };
  return (
    <>
      <div className="priceAndVariationContainer">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h2>Price and variations</h2>
          </Grid>
          <Grid item xs={12}>
            <p>
              Add a variation if this item comes in different sizes (e.g. Small,
              Medium, Large) or options. Include GST/VAT in prices if
              applicable.
            </p>
          </Grid>
          {menuInfo?.variations?.length == 0 && (
            <Grid item xs={12}>
              <TextField
                label="Price"
                number="number"
                // required={true}
                email={false}
                value={menuInfo?.price}
                // handleValidation={handleValidation}
                // count={100}
                error={errors?.price}
                helperText={errors?.price}
                // helperText={
                //   errors?.price === 0 || errors?.price === "" ? "Required" : ""
                // }
                handleChange={(e) =>
                  handleChangesOfField("price", e.target.value)
                }
                StartAdorment={true}
                startAdornment="$"
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <AddTextField
              label="Variation name"
              labelSecond="Price"
              placeholder="Type here"
              // placeholderPrice="$"
              options={item}
              handleChange={(event, fieldData) =>
                handleChangeWithData("courseLearningObjs", fieldData)
              }
              InnerFieldCount={true}
              maxText={50}
              minItem={0}
              maxItem={6}
              primaryField="objId"
              textField="title"
              addButtonLabel="Add Variation"
              errors={errors}
              addVariationHandler={() => addVariationHandler()}
              menuInfo={menuInfo}
              setMenuInfo={setMenuInfo}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PriceAndVariations;
