import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useEffect } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const SelectFieldChip = (props) => {
  const { 
    label, 
    array, 
    helperText, 
    users,
    locationInfo,
    setLocationInfo,
    errors,
    setErrors,
  } = props;
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setLocationInfo({...locationInfo, teamMembers: typeof value === "string" ? value.split(",") : value})
    // setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  // useEffect(() => {
  //   setLocationInfo({...locationInfo, teamMembers: personName})
  // }, [personName]);

  return (
    <>
      <div>
        <InputLabel sx={{ ml: 1 }}>{label}</InputLabel>
        <FormControl sx={{ m: 1, width: "100%" }}>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={locationInfo?.teamMembers}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((user) => (
                  <Chip key={user._id} label={user.fullName} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {users.map((user) => (
              <MenuItem
                key={user._id}
                value={user}
                style={getStyles(user?.fullName, locationInfo?.teamMembers, theme)}
              >
                {user.fullName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
};

export default SelectFieldChip;
