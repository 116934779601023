import React, { useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Snackbar,
} from "@mui/material";
import TextField from "../Form/TextField/TextField";
import profileImg from "../../assets/imgs/Avatar.png";
import "./EditProfile.css";
import FileUplaoder from "../Form/FileUplaoder/FileUplaoder";
import SelectField from "../Form/SelectField/SelectField";
import ZipCode from "../Form/ZipCode/ZipCode";
import {
  getProfileAvatarFromName,
  getYears,
  mapMonth,
  monthArray,
} from "../../utils/helpers/index";
import SeletedFeildNew from "../SeletedFeildNew/SeletedFeildNew";
import { useDispatch } from "react-redux";
import { setUserData } from "../../redux/features/authSlice";
import SideDrawer from "../SideDrawer/SideDrawer";
import MediaLibrarySelectMedia from "../MediaLibrary/MediaLibrarySelectMedia";
import Externaluse from "../MediaLibrary/Externaluse";
import { deepPurple } from "@mui/material/colors";
import { toast } from "react-toastify";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

const EditProfile = ({
  personalInfo,
  setPersonalInfo,
  birthMonth,
  setBirthMonth,
  birthYear,
  birthDate,
  setBirthDate,
  setBirthYear,
  errors,
}) => {
  const [snakbaropen, setSnakbarOpen] = useState(false);

  //============================= get months ==============================================//
  const getMonths = () => {
    const months = [];
    monthArray.forEach((monthName, index) => {
      months.push({ value: index, label: monthName });
    });
    return months;
  };
  //============================= get months ==============================================//

  //============================ set data by fieldName ======================================//
  const handleChangesOfField = (fieldName, fieldValue) => {
    setPersonalInfo({ ...personalInfo, [fieldName]: fieldValue });
  };
  //=========================== set data by fieldName ======================================//
  // useEffect(() => {
  //   console.log(personalInfo, 328);
  // }, [personalInfo]);

  // =============================== media lib code start ================================ //
  const [medialib, setMediaLib] = useState(false);
  const [openSelectMedia, setOpenSelectMedia] = useState(false);
  const [openNewMedia, setOpenNewMedia] = useState(false);
  const [mediaItem, setMediaItem] = useState({});
  const [isActiveLogoIcon, setActiveLogoIcon] = useState(true);
  const [selectedValue, setSelectedValue] = React.useState(false);

  const [openSelectMedia2, setOpenSelectMedia2] = useState(false);
  const [openNewMedia2, setOpenNewMedia2] = useState(false);
  const [selectedValue2, setSelectedValue2] = React.useState(false);
  const [mediaItem2, setMediaItem2] = useState({});

  const getMediaItem = (item) => {
    // setting the media item
    setMediaItem(item);
  };
  const handleMediaUploadClose2 = () => {
    setOpenNewMedia2(false);
  };

  const handleChanges = (fieldName) => (event) => {
    const fieldData = event.target.value;
    console.log(fieldName, fieldData);
    setPersonalInfo({ ...personalInfo, [fieldName]: fieldData });
    // setDisableSaveBtn(false);
  };

  const handleDrawerCloseSelectMedia = () => {
    setOpenSelectMedia(false);
    //setOpen(true);
  };

  const handleDrawerOpenNewMedia2 = () => {
    setOpenNewMedia2(true);
  };

  const handleOpenNewMedia2 = (status) => {
    setOpenNewMedia2(!status);
    handleDrawerOpenSelectMedia2(status);
  };
  const getMediaItem2 = (item) => {
    // setting the media item
    setMediaItem2(item);
  };
  const handleDrawerOpenSelectMedia2 = () => {
    setOpenSelectMedia2(true);
  };

  const handleOpenNewMedia = (status) => {
    setOpenNewMedia(!status);
    handleDrawerOpenSelectMedia(status);
  };
  const handleDrawerOpenSelectMedia = () => {
    setOpenSelectMedia(true);
    setActiveLogoIcon(!isActiveLogoIcon);
  };
  const clickSelectMediaItem = async () => {
    //check if any item is selected
    if (selectedValue) {
      if (mediaItem?.mimeType?.startsWith("image/")) {
        let newObject = {
          ...personalInfo,
          profilePicture: mediaItem.url,
          profilePictureName: mediaItem.name,
        };
        setPersonalInfo(newObject);

        setMediaLib(false);
      } else {
        toast.error("Image file can be accepeted");
      }
    } else {
      toast.error("Please select any media");
    }
  };

  const handleRemovePhoto = () => {
    let newObject = {
      ...personalInfo,
      profilePicture: "",
    };
    setPersonalInfo(newObject);
  };
  // =============================== media lib code end  ================================ //

  // dates
  const [dateOptions, setDateOptions] = useState([]);
  useEffect(() => {
    let dates = [];
    if (birthMonth !== undefined && birthYear !== undefined) {
      let daysInMonth;
      switch (birthMonth) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          daysInMonth = 31;
          break;
        case 4:
        case 6:
        case 9:
        case 11:
          daysInMonth = 30;
          break;
        case 2:
          if (
            (birthYear % 4 === 0 && birthYear % 100 !== 0) ||
            birthYear % 400 === 0
          ) {
            daysInMonth = 29;
          } else {
            daysInMonth = 28;
          }
          break;
        default:
          daysInMonth = 0; // Invalid month
          break;
      }

      if (daysInMonth > 0) {
        for (let i = 1; i <= daysInMonth; i++) {
          dates.push({ value: i, label: i });
        }
      }
    }
    setDateOptions(dates);
  }, [birthMonth, birthYear]);

  //*  snackbar close
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnakbarOpen(false);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="First name"
            required={true}
            value={personalInfo.firstName}
            error={errors?.firstName}
            helperText={errors?.firstName}
            handleChange={(e) =>
              handleChangesOfField("firstName", e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Last name"
            value={personalInfo.lastName}
            error={errors?.lastName}
            helperText={errors?.lastName}
            handleChange={(e) =>
              handleChangesOfField("lastName", e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="FieldLabel">Profile Photo</div>
          <div className="profilePictureContainer">
            <Avatar
              sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }}
              src={personalInfo?.profilePicture || ""}
            >
              {" "}
              {personalInfo?.profilePicture
                ? ""
                : getProfileAvatarFromName(personalInfo)}{" "}
            </Avatar>
          </div>
        </Grid>
        <Grid item xs={12} md={6} sx={{ alignSelf: "center" }}>
          {personalInfo?.profilePicture ? (
            <div className="MenuItemImageContainer">
              {" "}
              <Avatar
                id="basic-button"
                sx={{ bgcolor: deepPurple[300] }}
                src={
                  personalInfo?.profilePicture
                    ? personalInfo?.profilePicture
                    : ""
                }
              ></Avatar>
              <p>{personalInfo?.profilePictureName || "N/A"}</p>
              <IconButton onClick={handleRemovePhoto}>X</IconButton>
            </div>
          ) : (
            <Button
              variant="outlined"
              sx={{
                py: 1.5,
                width: "100%",
                borderColor: `${errors?.profilePicture ? "red" : "#E4E8EC"}`,
                color: "#4C9F41",
                textTransform: "capitalize",
                "&:hover": {
                  borderColor: "#E4E8EC",
                  color: "#4C9F41",
                },
              }}
              startIcon={<FileUploadOutlinedIcon />}
              onClick={() => setMediaLib(true)}
            >
              Choose File
            </Button>
          )}

          {errors?.profilePicture && (
            <p
              sx={{
                color: "red",
              }}
            >
              {errors?.profilePicture}
            </p>
          )}
        </Grid>
        {/* <Grid item xs={12} md={8}>
          <FileUplaoder helpertext="Maximum size of 10.0MB. .jpg, .jpeg, or .png." />
        </Grid> */}
        <Grid item xs={12}>
          <div className="RedioButton">
            <FormControl>
              <span className="FieldLabel">Gender</span>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={personalInfo?.gender}
                onChange={(e) => handleChangesOfField("gender", e.target.value)}
              >
                <FormControlLabel
                  value="Male"
                  control={<Radio color="success" />}
                  label="Male"
                />
                <FormControlLabel
                  value="Female"
                  control={<Radio color="success" />}
                  label="Female"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            label="Birthday"
            placeholder="Month"
            error={errors?.birthMonth}
            helperText={errors?.birthMonth}
            options={getMonths()}
            selectedOption={birthMonth && birthMonth - 1}
            handleChange={(e) => setBirthMonth(e.target.value + 1)}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          {/* <TextField
            placeholder="Day"
            count={2}
            error={errors?.birthDate}
            helperText={errors?.birthDate}
            value={birthDate}
            handleChange={(e) => setBirthDate(e.target.value)}
          /> */}
          <SelectField
            placeholder="Day"
            error={errors?.birthDate}
            helperText={errors?.birthDate}
            options={dateOptions}
            selectedOption={birthDate}
            handleChange={(e) => setBirthDate(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          {/* <TextField
            placeholder="Year"
            count={4}
            value={birthYear}
            handleChange={(e) => setBirthYear(e.target.value)}
          /> */}
          <SelectField
            placeholder="Year"
            error={errors?.birthYear}
            helperText={errors?.birthYear}
            options={getYears()}
            selectedOption={birthYear}
            handleChange={(e) => setBirthYear(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Street address"
            value={personalInfo.address}
            handleChange={(e) =>
              handleChangesOfField("address", e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Apt, suite, floor (optional)"
            value={personalInfo.addressLine2}
            handleChange={(e) =>
              handleChangesOfField("addressLine2", e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="City"
            value={personalInfo.city}
            handleChange={(e) => handleChangesOfField("city", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            label="State"
            options={states}
            error={errors?.state}
            helperText={errors?.state}
            selectedOption={personalInfo.state}
            handleChange={(e) => handleChangesOfField("state", e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Zip/Postal Code"
            // count={5}
            value={personalInfo.zipCode}
            handleChange={(e) =>
              handleChangesOfField("zipCode", e.target.value)
            }
          />
        </Grid>
        {/* <Grid item xs={12}>
          <SelectField
            label="Select Timezone"
            options={timeZones}
            selectedOption={personalInfo.timezone}
            error={errors?.timezone}
            helperText={errors?.timezone}
            handleChange={(e) =>
              handleChangesOfField("timezone", e.target.value)
            }
          />
        </Grid> */}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Button
            variant="outlined"
            onClick={handleCloseModal}
            sx={{
              border: "1px solid #E4E8EC",
              borderRadius: "100px",
              color: "#1B1B1F",
              "&:hover": {
                border: "1px solid #E4E8EC",
              },
            }}
          >
            Cancel
          </Button> */}
          {/* <Button
            variant="contained"
            onClick={handleUpdate}
            sx={{
              ml: 2,
              background: "#4C9F41",
              borderRadius: "100px",
              color: "#fff",
              boxShadow: "none",
              "&:hover": {
                background: "#4C9F41",
              },
            }}
          >
            Save
          </Button> */}
        </Grid>
      </Grid>

      <div>
        {/* <button onClick={() => setMediaLib(true)}>media lib</button> */}
        <SideDrawer
          title="Media Library"
          ButtonLabel="Add"
          //clickSaveButton={clickSaveButton}
          cancelButtonLabel="Cancel"
          sideOpen="right"
          open={medialib}
          handleDrawerClose={() => setMediaLib(false)}
          FooterWithoutTab={true}
          LeftButton={true}
          ButtonLeft="Upload New"
          // ButtonLeftIcon={<FileUploadOutlinedIcon />}
          clickButtonLeft={handleDrawerOpenNewMedia2}
          clickSaveButton={clickSelectMediaItem}
          padding="0"
          body={
            <MediaLibrarySelectMedia
              onClickRadioIcon={setSelectedValue}
              onItemSelected={getMediaItem}
            />
          }
        />

        <Externaluse
          open={openNewMedia2}
          handleCloseButton={handleMediaUploadClose2}
          handleOpenNewMedia={getMediaItem2}
        />
      </div>

      <Snackbar
        open={snakbaropen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
        zIndex={9999}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{
            display: "flex",
            alignItems: "center",
            width: "300px",
            fontSize: "1.2rem",
            "& > svg": {
              marginRight: "0.5rem",
            },
            backgroundColor: "green !important",
            color: "#fff !important",
          }}
        >
          User data has updated successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditProfile;

const timeZones = [
  {
    label: "America/Los Angeles (GMT-07:00)",
    value: "America/Los Angeles (GMT-07:00)",
  },
];

const months = [
  {
    label: "January",
    value: "January",
  },

  {
    label: "February",
    value: "February",
  },

  {
    label: "March",
    value: "March",
  },

  {
    label: "April",
    value: "April",
  },

  {
    label: "May",
    value: "May",
  },

  {
    label: "June",
    value: "June",
  },

  {
    label: "July",
    value: "July",
  },

  {
    label: "August",
    value: "August",
  },

  {
    label: "September",
    value: "September",
  },

  {
    label: "October",
    value: "October",
  },

  {
    label: "November",
    value: "November",
  },

  {
    label: "December",
    value: "December",
  },
];

const states = [
  { label: "Alabama", value: "Alabama" },
  { label: "Alaska", value: "Alaska" },
  { label: "Arizona", value: "Arizona" },
  { label: "Arkansas", value: "Arkansas" },
  { label: "California", value: "California" },
  { label: "Colorado", value: "Colorado" },
  { label: "Connecticut", value: "Connecticut" },
  { label: "Delaware", value: "Delaware" },
  { label: "Florida", value: "Florida" },
  { label: "Georgia", value: "Georgia" },
  { label: "Hawaii", value: "Hawaii" },
  { label: "Idaho", value: "Idaho" },
  { label: "Illinois", value: "Illinois" },
  { label: "Indiana", value: "Indiana" },
  { label: "Iowa", value: "Iowa" },
  { label: "Kansas", value: "Kansas" },
  { label: "Kentucky", value: "Kentucky" },
  { label: "Louisiana", value: "Louisiana" },
  { label: "Maine", value: "Maine" },
  { label: "Maryland", value: "Maryland" },
  { label: "Massachusetts", value: "Massachusetts" },
  { label: "Michigan", value: "Michigan" },
  { label: "Minnesota", value: "Minnesota" },
  { label: "Mississippi", value: "Mississippi" },
  { label: "Missouri", value: "Missouri" },
  { label: "Montana", value: "Montana" },
  { label: "Nebraska", value: "Nebraska" },
  { label: "Nevada", value: "Nevada" },
  { label: "New Hampshire", value: "New Hampshire" },
  { label: "New Jersey", value: "New Jersey" },
  { label: "New Mexico", value: "New Mexico" },
  { label: "New York", value: "New York" },
  { label: "North Carolina", value: "North Carolina" },
  { label: "North Dakota", value: "North Dakota" },
  { label: "Ohio", value: "Ohio" },
  { label: "Oklahoma", value: "Oklahoma" },
  { label: "Oregon", value: "Oregon" },
  { label: "Pennsylvania", value: "Pennsylvania" },
  { label: "Rhode Island", value: "Rhode Island" },
  { label: "South Carolina", value: "South Carolina" },
  { label: "South Dakota", value: "South Dakota" },
  { label: "Tennessee", value: "Tennessee" },
  { label: "Texas", value: "Texas" },
  { label: "Utah", value: "Utah" },
  { label: "Vermont", value: "Vermont" },
  { label: "Virginia", value: "Virginia" },
  { label: "Washington", value: "Washington" },
  { label: "West Virginia", value: "West Virginia" },
  { label: "Wisconsin", value: "Wisconsin" },
  { label: "Wyoming", value: "Wyoming" },
];
