import React from 'react'
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import './Modals.css'



function ModalOne(props) {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  {/* <Button onClick={() => setOpen(true)}><AddRoundedIcon />Module</Button> */}

  // <ModalOne
  //   open={open}
  //   handleClose={handleClose}
  //   width="390px"
  //   Modaltitle="Filter"
  //   titileColor="#33B651"
  //   padding="16px"

  //   body={
  //     <div>
       
  //     </div>
  //   }

  // />



  return (
    <div>


      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>

          <div className="ModalOne">

            <div className="ModalOneContainer" style={{ width: props.width, padding: props.padding, }}>

              <div className="ModalOneIconCtn ModalTitle">
                <CloseRoundedIcon className="closeIcon" onClick={() => props.handleClose()} />
                <h1>{props.Modaltitle}</h1>
              </div>

              <div className="ModalOneMdlBody">
                <h2 style={{ color: props.titileColor }}>{props.title}</h2>
                {props.body}
              </div>

            </div>
          </div>

        </Fade>
      </Modal>


    </div>


  )
}






export default ModalOne;