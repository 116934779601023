import React, { useEffect, useState } from "react";
import "./Payment.css";
import { Grid } from "@mui/material";
import TextInputField from "../../../../../components/Form/TextInputField/TextInputField";

const Payment = ({ locationInfo, setLocationInfo, errors, setErrors }) => {
  const [textInput, setRextInput] = useState(locationInfo?.stripeKey);
  const handleChangesOfField = (fieldName, e) => {
    setRextInput(e);

    setLocationInfo({ ...locationInfo, [fieldName]: e });
  };
  console.log(textInput, 328);
  // useEffect(() => {
  //   if (textInput) {
  //     setLocationInfo({ ...locationInfo, stripeKey: textInput });
  //   }
  //   // console.log("locationInfo:", locationInfo, 328);
  // }, [textInput]);

  const handleValidation = () => {
    return true;
  };
  return (
    <div className="paymentParentContainer">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h3>Setup Payments</h3>
        </Grid>
        <Grid item xs={12}>
          <TextInputField
            label="Enter strip key"
            required={true}
            email={false}
            value={textInput}
            handleValidation={handleValidation}
            // count={50}
            handleChange={(e) => handleChangesOfField("stripeKey", e)}
            error={errors?.stripeKey}
            helperText={errors?.stripeKey}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Payment;
