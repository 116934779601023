import React, { useState, useEffect } from "react";
import axios from "axios";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import StepLabel from "@mui/material/StepLabel";
import { Alert, Container, Snackbar } from "@mui/material";
import { styled } from "@mui/system";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import Buttons from "../../../components/Button/Button";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import BasicInfo from "./steps/BasicInfo/BasicInfo";
import UserAccess from "./steps/UserAccess/UserAccess";
import "./AddNewUserStapper.css";
import { useGetLocationListQuery } from "../../../redux/features/locations/locationApi";
import useProtected from "../../../hooks/useProtected";
import { getPreparedArray, isValidEmail } from "../../../hooks/HelperFunctions";
import { useCreateUserMutation } from "../../../redux/features/users/userApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// development related
// import { sendDataToAPI, retriveDataFromAPI } from '../../../../hooks/HelperFunctions'

function getSteps() {
  return ["Basic Info", "User access"];
}

function getCourseRelationalData(orgId, courseId) {
  // const result = retriveDataFromAPI('courses/getAddCourseRelatedData', `orgId=${orgId}&courseId=${courseId}`)
  // console.log(result);
  // return [];
  // const baseUrl =
  //   process.env.REACT_APP_PROJECT_ENV === "prod"
  //     ? process.env.REACT_APP_API_URL_PROD
  //     : process.env.REACT_APP_API_URL_DEV;
  // axios
  //   .get(
  //     `${baseUrl}/courses/getAddCourseRelatedData?orgId=${orgId}&courseId=${courseId}`
  //   )
  //   .then((result) => {
  //     console.log(result.data.body);
  //     return result.data.body;
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });
}

function AddNewUserStapper(props) {
  const {
    data: locationsData,
    isLoading: locationsIsLoading,
    isFetching: locationsIsFetching,
    error: locationsError,
  } = useGetLocationListQuery();

  const [createUser, createUserOptions] = useCreateUserMutation();

  useProtected(locationsError);
  useProtected(createUserOptions.error);
  const [phoneError, setPhoneError] = useState("");
  const initialState = {
    username: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    status: "Active",
    role: "Manager",
    defaultLocation: "",
    selectLocations: [],
    phone: "",
    employmentStartDate: "",
    enablePortalAccess: false,
    address: "",
    fullName: "",
  };
  const initialStateError = {
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    status: "",
    role: "",
    defaultLocation: "",
    phone: "",
    employmentStartDate: "",
    enablePortalAccess: "",
    selectLocations: [],
  };

  const navigate = useNavigate();

  const [locations, setLocations] = useState([]);
  const [userInfo, setUserInfo] = useState(initialState);
  const [snakbaropen, setSnakbarOpen] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [errors, setErrors] = useState(initialStateError);
  const [skipped, setSkipped] = React.useState(new Set());
  const [courseOverview, setCourseOverview] = useState({});
  const [courseRelationalData, setCourseRelationalData] = useState([]);

  useEffect(() => {
    if (!locationsIsLoading && !locationsIsFetching && locationsData) {
      setLocations(locationsData?.body?.data);
    }
  }, [locationsData, locationsIsLoading, locationsIsFetching]);

  const steps = getSteps();

  // useEffect(() => {
  //   const baseUrl =
  //     process.env.REACT_APP_PROJECT_ENV === "prod"
  //       ? process.env.REACT_APP_API_URL_PROD
  //       : process.env.REACT_APP_API_URL_DEV;
  //   axios
  //     .get(`${baseUrl}/courses/getAddCourseRelatedData?orgId=1&courseId=0`)
  //     .then((result) => {
  //       // console.log(result.data.body);
  //       setCourseRelationalData(result.data.body);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  // console.log(courseRelationalData.courseLanguages);
  const [locationOptions, setLocationOptions] = useState([]);
  useEffect(() => {
    let exceptComingSoon = locations?.filter(
      (location) => location.status != "Coming soon"
    );
    setLocationOptions(getPreparedArray(exceptComingSoon, "_id", "name"));
  }, [locations]);

  const getStepContent = (step, courseRelationalData) => {
    switch (step) {
      case 0:
        //return <AccountType/>;
        return (
          <BasicInfo
            errors={errors}
            setErrors={setErrors}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            setPhoneError={setPhoneError}
          />
        );
      case 1:
        return (
          <UserAccess
            errors={errors}
            setErrors={setErrors}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            locationOptions={locationOptions}
          />
        );
      default:
        return "Unknown step";
    }
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const checkBasicFormField = async () => {
    let errorObject = { ...errors };
    let error = 0;
    if (userInfo.firstName == "") {
      errorObject = { ...errorObject, firstName: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, firstName: "" };
    }
    if (userInfo.lastName == "") {
      errorObject = { ...errorObject, lastName: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, lastName: "" };
    }
    if (userInfo?.phone == "") {
      errorObject = { ...errorObject, phone: "Required" };
      error++;
    } else if (phoneError) {
      errorObject = { ...errorObject, phone: phoneError };
      error++;
    } else {
      errorObject = { ...errorObject, phone: "" };
    }
    if (userInfo.email == "" || !isValidEmail(userInfo.email)) {
      errorObject = { ...errorObject, email: "Enter a valid email address" };
      error++;
    } else {
      errorObject = { ...errorObject, email: "" };
    }

    if (userInfo.employmentStartDate == "") {
      errorObject = { ...errorObject, employmentStartDate: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, employmentStartDate: "" };
    }

    setErrors({ ...errors, ...errorObject });

    return error > 0 ? false : true;
  };
  const checkSecondStepFormField = async () => {
    let isBasicFormValid = await checkBasicFormField();
    let errorObject = { ...errors };
    let error = 0;
    if (userInfo.selectLocations?.length === 0) {
      errorObject = { ...errorObject, selectLocations: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, selectLocations: "" };
    }
    if (userInfo.defaultLocation == "") {
      errorObject = { ...errorObject, defaultLocation: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, defaultLocation: "" };
    }
    if (userInfo?.enablePortalAccess && userInfo.password == "") {
      errorObject = { ...errorObject, password: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, password: "" };
    }

    setErrors({ ...errors, ...errorObject });

    return error > 0 || !isBasicFormValid ? false : true;
  };
  const getStructuredData = async () => {
    const {
      username,
      email,
      password,
      firstName,
      lastName,
      status,
      role,
      defaultLocation,
      phone,
      employmentStartDate,
      enablePortalAccess,
      address,
      fullName,
      selectLocations,
    } = userInfo;
    return {
      username: `${firstName} ${lastName}`,
      email,
      password,
      firstName,
      lastName,
      status: status.toLowerCase(),
      role: role.toLowerCase(),
      defaultLocation,
      phone,
      employmentStartDate: employmentStartDate.$d,
      enablePortalAccess,
      fullName: `${firstName} ${lastName}`,
      selectLocations,
    };
  };

  const settleDataStructure = async () => {
    let userInfo = await getStructuredData();
    let newUserInfo = { ...userInfo };
    let locationToAdd = [];
    newUserInfo?.selectLocations?.map((selectLocation) => {
      let found = locations?.find(
        (locationObj) => locationObj._id == selectLocation
      );
      if (found) {
        locationToAdd?.push({
          _id: found?._id,
          name: found?.name,
        });
      }
    });
    newUserInfo.selectLocations = locationToAdd;
    return newUserInfo;
  };

  let submitUserInfo = async () => {
    // let data = await getStructuredData();
    let data = await settleDataStructure();

    let result = await createUser({ ...data });

    if (result?.data?.body?.data) {
      // setSnakbarOpen(true);
      toast.success("User has added successfully");
      navigate("/user-listing");
    } else {
      console.error("something went wrong");
    }
  };

  const handleNext = async () => {
    let newSkipped = skipped;
    if (activeStep == 0) {
      let isValid = await checkBasicFormField();
      if (!isValid) {
        return false;
      }
      console.log(courseOverview);
    }
    if (activeStep == 1) {
      let isValid = await checkSecondStepFormField();
      if (!isValid) {
        return false;
      }
      await submitUserInfo();
      return false;
    }
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // breadcrum
  const breadcrumbsData = [
    {
      label: "Users",
      link: "/user-listing",
      icon: <PersonRoundedIcon fontSize="small" />,
    },

    {
      label: "Add new",
      link: "",
    },
  ];

  //*  Item delete snackbar
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnakbarOpen(false);
  };
  return (
    <>
      <div className="stapperHeaderContainer">
        <Container>
          <div className="stapperHeaderBox">
            <Buttons
              label="Exit"
              buttonColor="transparent"
              color="#4C9F41"
              height="100%"
              fontSize="14px"
              iconStart={
                <ClearRoundedIcon
                  sx={{ mr: "8px !important", color: "#4C9F41 !important" }}
                  fontSize="small"
                />
              }
              link="/user-listing"
            />
            <p
              style={{
                margin: "0",
                flexGrow: "1",
                fontSize: "22px",
                textAlign: "center",
              }}
            >
              Add new user
            </p>
          </div>
        </Container>
      </div>
      <Container>
        <div className="FormStepper">
          <Breadcrumbs breadcrumbsData={breadcrumbsData} />
          <div className="StapperContaier">
            <div className="StapperBody Section">
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  // if (isStepOptional(index)) {
                  //   labelProps.optional = <Typography variant="caption">Optional</Typography>;
                  // }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </div>

            <div className="StapperContent">
              {activeStep === steps.length ? (
                <div>
                  <Typography>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Button onClick={handleReset}>Reset</Button>
                </div>
              ) : (
                <div>
                  <Typography>
                    {getStepContent(activeStep, courseRelationalData)}
                  </Typography>
                  <div className="SpperButtonContainer">
                    <div className="StepperButton">
                      <StyledBackButton
                        disabled={activeStep === 0}
                        onClick={handleBack}
                      >
                        {activeStep == 0 ? "Cancel" : "Back"}
                      </StyledBackButton>
                      {/* {isStepOptional(activeStep) && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSkip}
                        >
                          Skip
                        </Button>
                      )} */}

                      <StyledBackContinue
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        {activeStep === steps.length - 1
                          ? "Finish"
                          : "Continue"}
                      </StyledBackContinue>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>

      {/* ------------------- snackbar ---------------- */}
      <Snackbar
        open={snakbaropen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
        zIndex={9999}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{
            display: "flex",
            alignItems: "center",
            width: "300px",
            fontSize: "1.2rem",
            "& > svg": {
              marginRight: "0.5rem",
            },
            backgroundColor: "green !important",
            color: "#fff !important",
          }}
        >
          User saved
        </Alert>
      </Snackbar>
    </>
  );
}

export default AddNewUserStapper;

const StyledBackButton = styled(Button)(({ theme }) => ({
  margin: "0 5px",
  padding: "10px 0",
  width: "98px",
  color: "#4C9F41",
  fontSize: "12px",
  background: "#fff",
  border: "1px solid #E4E2E6",
  borderRadius: "1000px",
  gap: "10px",
  alignItems: "center",
  "&:hover": {
    color: "#fff",
    border: "1px solid #4C9F41",
    background: "#4C9F41",
  },
}));

const StyledBackContinue = styled(Button)(({ theme }) => ({
  margin: "0 5px",
  padding: "10px 0",
  width: "98px",
  color: "#fff",
  fontSize: "12px",
  background: "#4C9F41",
  // border: "1px solid #E4E2E6",
  boxShadow: "none",
  borderRadius: "1000px",
  gap: "10px",
  alignItems: "center",
  "&:hover": {
    color: "#fff",
    boxShadow: "none",
    border: "1px solid #4C9F41",
    background: "#4C9F41",
  },
}));
