import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import "./Header.css";
import { deepPurple } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Rahdunilogo from "../../assets/imgs/Rahdunilogo.png";
import Avatar from "@mui/material/Avatar";

import Stack from "@mui/material/Stack";
import "../../assets/css/style.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { styled } from "@mui/system";
// Menu Icons
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import InsertInvitationRoundedIcon from "@mui/icons-material/InsertInvitationRounded";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import Modals from "../Form/Modals/Modals";
import Buttons from "../Form/Button/Button";
import DefaultLocation from "./DefaultLocation/DefaultLocation";
import { Hidden } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { logout, setActiveLocation } from "../../redux/features/authSlice";
import { useGetLocationListQuery } from "../../redux/features/locations/locationApi";
import useProtected from "../../hooks/useProtected";

function Header(props) {
  const user = useSelector((state) => state.auth.user);
  // console.log(user, 328);
  const activeLocation = useSelector((state) => state.auth.activeLocation);
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userData"));

  const { data, isLoading, isFetching, error } = useGetLocationListQuery();

  useProtected(error);

  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(logout());
    // * custom navigate
    navigate("/signin");
  };

  // Logout menue unauthorized
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [locations, setLocations] = useState([]);
  const [defaultLocation, setDefaultLocation] = useState({});

  useEffect(() => {
    if (!isLoading && !isFetching && data) {
      setLocations(data?.body?.data);
    }
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    if (locations?.length > 0 && activeLocation && activeLocation != "") {
      let found = locations?.find(
        (singleLocation) => singleLocation._id == activeLocation
      );
      if (found) {
        setDefaultLocation(found);
      }
    }
  }, [locations, activeLocation]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Logout menue unauthorized

  // * modal section
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const setActiveLocationHandler = (id) => {
    dispatch(setActiveLocation(id));
    localStorage.setItem("activeLocation", id);
  };
  const showLocations = () => {
    let locationsToShow = [];
    locations.map((location, index) => {
      locationsToShow.push(
        <DefaultLocation
          key={index}
          handleCloseModal={handleCloseModal}
          label={`Radhuni Sweets & Restaurant - ${location?.name}`}
          location={`${location?.city} ${location?.state} ${location?.zip}`}
          location_id={location?._id}
          setActiveLocationHandler={setActiveLocationHandler}
        />
      );
    });
    return locationsToShow;
  };

  return (
    <div className="HeaderContainer">
      <div className="Header">
        <div className="HdrLeftCol">
          <div className="IconMenu">{props.IconMenu}</div>
          <Hidden mdDown>
            <a className="Branding" href="#">
              <img src={Rahdunilogo} />
            </a>
          </Hidden>
          <div className="LocationMenu">
            <StyledButton
              variant="outlined"
              startIcon={<LocationOnIcon sx={{ m: 0 }} />}
              endIcon={<ArrowDropDownRoundedIcon fontSize="large" />}
              onClick={handleOpenModal}
            >
              <Hidden mdDown>{defaultLocation && defaultLocation?.name}</Hidden>
            </StyledButton>
          </div>
        </div>

        <div className="HdrRightCol">
          {/* <Button variant="text" className="NotificationButton">
            <div className="NotificationCount">1</div>
            <NotificationsRoundedIcon />
          </Button> */}

          {/* <Avatar onClick={handleClick}>{Array.from(userData.firstName)[0]}</Avatar> */}
          {user?.profilePicture ? (
            <Avatar
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{ bgcolor: deepPurple[300] }}
              src={user?.profilePicture ? user?.profilePicture : ""}
            ></Avatar>
          ) : (
            <Avatar
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{ bgcolor: deepPurple[300] }}
            >
              {" "}
              {user?.firstName ? Array.from(user?.firstName)[0] : ""}{" "}
            </Avatar>
          )}

          <div className="">
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              className="DropdownMenu"
            >
              <div className="DropdownMenuList">
                <MenuItem onClick={handleClose}>
                  <div className="ProfileSection">
                    {user?.profilePicture ? (
                      <Avatar
                        sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }}
                        src={user?.profilePicture ? user?.profilePicture : ""}
                      ></Avatar>
                    ) : (
                      <Avatar
                        sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }}
                      >
                        {" "}
                        {user?.firstName
                          ? Array.from(user?.firstName)[0]
                          : ""}{" "}
                      </Avatar>
                    )}
                    <div>
                      <h1>
                        {user?.firstName} {user?.lastName}
                      </h1>
                      <p>
                        <a href="#">{user?.email}</a>
                      </p>
                    </div>
                  </div>
                </MenuItem>
                <span className="divider"></span>
                {/* <MenuItem onClick={handleClose}><HomeRoundedIcon /> Home</MenuItem> */}
                {/* <MenuItem onClick={handleClose}>
                  <MenuBookRoundedIcon /> Courses
                </MenuItem>
                <span className="divider"></span>
                <MenuItem onClick={handleClose}><NotificationsRoundedIcon /> Alerts</MenuItem>
                <MenuItem onClick={handleClose}><QuestionAnswerRoundedIcon /> Messages</MenuItem>
                <MenuItem onClick={handleClose}>
                  <InsertInvitationRoundedIcon /> Calendar
                </MenuItem>
                <span className="divider"></span>
                <MenuItem onClick={handleClose}>
                  <EmojiEventsRoundedIcon /> Certificates
                </MenuItem> */}
                {/* <MenuItem onClick={handleClose}>
                  <SettingsRoundedIcon /> Settings
                </MenuItem>
                <span className="divider"></span> */}
                <MenuItem onClick={logoutHandler}>
                  <LogoutIcon /> Log Out
                </MenuItem>
              </div>
            </Menu>
          </div>
        </div>
      </div>

      <Modals
        ModalTitle="Change Location"
        handleClose={handleCloseModal}
        // handleConfirm={() => handleConfirm()}
        // handleClose={() => handleClose()}
        open={openModal}
        ButtonClose="CLOSE"
        ButtonConfirm="CONFIRM"
        width="570px"
        Headerbackground="#fff"
        Headercolor="#676C7B"
        Footerbackground="#F7F9FA"
        ModalBody={<div>{showLocations()}</div>}
      />
    </div>
  );
}

export default Header;

//  * location button css
const StyledButton = styled(Button)(({ theme }) => ({
  // width: "218px",
  color: "#000000",
  fontSize: "12px",
  border: "1px solid #E4E2E6",
  borderRadius: "8px",
  gap: "10px",
  alignItems: "center",
  "&:hover": {
    border: "1px solid #E4E2E6",
    background: "#E4E2E6",
  },
  "& span": {
    margin: "0",
  },
  "& span .MuiSvgIcon-root": {
    padding: "0",
  },
}));
