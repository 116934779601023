import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Tables from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import SouthRoundedIcon from "@mui/icons-material/SouthRounded";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import Buttons from "../Form/Button/Button";
import SelectField from "../Form/SelectField/SelectField";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import TableCells from "./TableCells";
import { Box } from "@mui/system";

import SearchBox from "../Form/SearchBox/SearchBox";
import Filter from "./Filter";

// const headCells = [
//   { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
//   { id: 'calories', numeric: true, disablePadding: false, label: 'Calories' },
//   { id: 'fat', numeric: true, disablePadding: false, label: 'Fat (g)' },
//   { id: 'carbs', numeric: true, disablePadding: false, label: 'Carbs (g)' },
//   { id: 'protein', numeric: true, disablePadding: false, label: 'Protein (g)' },
// ];

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData('Cupcake',
//   <a href="#">sdvcsdv</a>,
//   3.7, 67, 4.3),

//   createData('Cupcake',
//   <a href="#">sdvcsdv</a>,
//   3.7, 67, 4.3),

//   createData('Cupcake',
//   <a href="#">sdvcsdv</a>,
//   3.7, 67, 4.3),

//   createData('Donut', 452, 25.0, 51, 4.9),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
//   createData('Honeycomb', 408, 3.2, 87, 6.5),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Jelly Bean', 375, 0.0, 94, 0.0),
//   createData('KitKat', 518, 26.0, 65, 7.0),
//   createData('Lollipop', 392, 0.2, 98, 0.0),
//   createData('Marshmallow', 318, 0, 81, 2.0),
//   createData('Nougat', 360, 19.0, 9, 37.0),
//   createData('Oreo', 437, 18.0, 63, 4.0),
// ];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const [activeHeader, setActiveHeader] = useState(null);
  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
  };

  const handleHeaderClick = (property) => {
    setActiveHeader(property === activeHeader ? null : property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.Checkbox && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        )}

        {typeof headCells !== "undefined" &&
          headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              // align={headCell.numeric ? 'left' : 'left'}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{ minWidth: headCell.width, width: headCell.width }}
              align={headCell.align}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                // onClick={createSortHandler(headCell.id)}
                onClick={(e) => {
                  if (headCell.headerOnClick) {
                    headCell.headerOnClick();
                    handleToggle();
                    handleHeaderClick(headCell.id);
                  }
                }}
                style={{ paddingRight: "6px" }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span>
                    {/* {order === 'desc' ? 'descending' : 'ascending'} */}
                  </span>
                ) : null}
                {headCell.sort &&
                  (activeHeader === headCell.id ? (
                    <div className="yes">
                      <SouthRoundedIcon className="SortIcon" />
                    </div>
                  ) : (
                    <div className="no">
                      <SouthRoundedIcon className="SortIcon" />
                    </div>
                  ))}
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, titleTow } = props;

  // return (
  //   <Toolbar
  //     className={clsx(classes.root, {
  //       [classes.highlight]: numSelected > 0,
  //     })}
  //   >
  //     {numSelected > 0 ? (
  //       <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
  //         {/* {numSelected} selected */}
  //       </Typography>
  //     ) : (
  //       <Typography className={classes.title} variant="h6" id="tableTitle" component="div">

  //       </Typography>
  //     )}

  //     {numSelected > 0 ? (
  //       <Tooltip title="Delete">
  //         <IconButton aria-label="delete">
  //           <DeleteIcon />
  //         </IconButton>
  //       </Tooltip>
  //     ) : (
  //       <Tooltip title="Filter list">
  //         <IconButton aria-label="filter list">
  //           <FilterListIcon />
  //         </IconButton>
  //       </Tooltip>
  //     )}
  //   </Toolbar>
  // );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function Table(props) {
  const {
    rows,
    col,
    perPageItems,
    handleOpenDialog,
    btnHandleClick,
    iconButtonHandler,
  } = props;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    perPageItems ? perPageItems : 5
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      if (typeof rows !== "undefine") {
        const newSelecteds = rows.map((n) => n.name);
        setSelected(newSelecteds);
      }

      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);

  return (
    <div className="Table">
      {props.tableHeader && (
        <div className="TableHeader">
          <h1 className="TableTitle">{props.title}</h1>
          <div>
            {props.AddButton && (
              <Buttons
                icon={<AddRoundedIcon />}
                label={props.addLabel}
                buttonColor="#4C9F41"
                color="#fff"
                height="48px"
                fontSize="14px"
                link={props.link}
                onClick={props.onClick}
                className="cy_AddButton"
              />
            )}
          </div>
        </div>
      )}

      <div className="TableContainer">
        {props.innerHeader && (
          <div className="TableInnerHeader">
            <div className="TableInnerHeaderLeft">
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <div style={{ flexGrow: 1 }}>
                  <h3>{props.innerTitle}</h3>
                  {props.showSearchBox && (
                    <div className="TableSearchField">
                      <SearchBox
                        placeholder={
                          props.searchBoxPlaceholder
                            ? props.searchBoxPlaceholder
                            : "Search here"
                        }
                        searchText={props.searchBoxText}
                        handleSearchText={props.handleSearchText}
                        handleSearch={props.handleSearch}
                        handleSearchClear={props.handleSearchClear}
                      />
                    </div>
                  )}
                </div>
                <div>
                  {props.showSearchCount && props.searchBoxText && (
                    <div className="TableSearchField">
                      <span>
                        {rows?.length > 0
                          ? `${rows.length} results found`
                          : "No matching records found"}{" "}
                      </span>
                    </div>
                  )}
                </div>
              </Box>
            </div>
            <div className="TableInnerHeaderRight">
              {props.SelectField && (
                <SelectField
                  options={props.options}
                  handleChange={props.handleChanges}
                />
              )}

              {props.FilterButton && <Filter />}

              {props.AddButtonInner && (
                <Buttons
                  icon={props.InnderButtonIcon}
                  label={props.addLabelInner}
                  buttonColor={props.InnerButtonColor}
                  color={props.InnerButtonTextColor}
                  height="48px"
                  fontSize="14px"
                  link={props.linkInnerButton}
                  onClick={props.onClickInnerButton}
                  // add clas name here
                  className="cy_AddButton"
                />
              )}
            </div>
          </div>
        )}

        <Paper>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Tables
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                headCells={props.headCells}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={typeof rows == "undefined" ? 0 : rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                  ?.map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.name)}
                        //role="checkbox"
                        //aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        //selected={isItemSelected}
                      >
                        {props.Checkbox && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                        )}
                        {/* <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.name}
                        </TableCell> */}

                        <TableCells
                          cell={row.cell}
                          action={row?.action}
                          handleOpenDialog={handleOpenDialog}
                          btnHandleClick={btnHandleClick}
                          iconButtonHandler={iconButtonHandler}
                        />
                      </TableRow>
                    );
                  })}

                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Tables>
          </TableContainer>
          {props.footer && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
              component="div"
              count={rows?.length > 0 ? rows?.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
        {/* <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        /> */}
      </div>
    </div>
  );
}

export default Table;
