import React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import "./MultipleSelet.css";
import { Box, Chip, FormHelperText } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      disableScrollLock: true,
    },
  },
};

function MultipleSelect(props) {
  const { option, handleChange, value, setvalue } = props;

  // const [value, setvalue] = React.useState([]);

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setvalue(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   );
  // };
  // const showLabel = () => {
  //   console.log(value, option)
  //   let labels = value?.map((singleVal) => {
  //     return option?.find(singleOption => singleOption.value)
  //   })
  //   return value
  // }
  return (
    <div className="MultipleSelet">
      {props.FieldLabel && (
        <div className="FieldLabel">
          {props.label} {props.validation && <span>*</span>}
        </div>
      )}
      <Select
        id="demo-multiple-checkbox"
        multiple
        displayEmpty
        value={value}
        onChange={handleChange}
        input={<OutlinedInput />}
        MenuProps={MenuProps}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return (
              <em>
                {(!props.Placeholder || props.Placeholder === "") &&
                props.PlaceHolderStatus
                  ? "Select"
                  : props.Placeholder}
              </em>
            );
          }

          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((user) => (
                <Chip
                  key={user}
                  label={
                    option?.find((singleOption) => singleOption?.value == user)
                      ?.label
                  }
                />
              ))}
            </Box>
          );
        }}
        inputProps={{ "aria-label": "Without label" }}
        error={props.error}
      >
        <MenuItem disabled value=""></MenuItem>
        {option.map((singleOption, index) => (
          <MenuItem key={singleOption?.label} value={singleOption?.value}>
            <Checkbox checked={value.includes(singleOption?.value)} />
            {singleOption?.label}
          </MenuItem>
        ))}
      </Select>
      {props.errorHelperText && (
        <FormHelperText sx={{ color: "#ba1b1a" }}>Required</FormHelperText>
      )}
    </div>
  );
}

export default MultipleSelect;
