import React from "react";

import Table from './Table';
import tableImg from '../../assets/imgs/tableImg.png';
import Visa from '../../assets/imgs/Visa.png';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';


function createData(name, calories, fat, carbs, protein, Secondary, date, dateday, datetime, Icon, Checkbox, Chip, Button, ButtonWithIcon, ButtonDisabled, ButtonFilld, Details, Actions) {
  return { name, calories, fat, carbs, protein, Secondary, date, dateday, datetime, Icon, Checkbox, Chip, Button, ButtonWithIcon, ButtonDisabled, ButtonFilld, Details, Actions };
}

function TableData(props) {

  const onClick = () => {
    alert("onclick working fine!");
  }


  const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Image W/Primary Text', width: "200px" },
    { id: 'calories', numeric: true, disablePadding: false, label: 'Primary Text', width: "150px" },
    { id: 'fat', numeric: true, disablePadding: false, label: 'Primary Text W/Subtitle', width: "200px" },
    { id: 'carbs', numeric: true, disablePadding: false, label: 'Primary Text W/Icon & Chip', width: "300px" },
    { id: 'protein', numeric: true, disablePadding: false, label: 'Secondary Text', width: "200px" },
    { id: 'Secondary', numeric: true, disablePadding: false, label: 'Secondary Text W/Chip', width: "300px" },
    { id: 'dateday', numeric: true, disablePadding: false, label: 'Date', width: "180px" },
    { id: 'datetime', numeric: true, disablePadding: false, label: 'Date W/Day', width: "180px" },
    { id: 'date', numeric: true, disablePadding: false, label: 'Date W/Time', width: "180px" },
    { id: 'Icon', numeric: true, disablePadding: false, label: 'Icon', width: "100px" },
    { id: 'Checkbox', numeric: true, disablePadding: false, label: 'Checkbox', width: "100px" },
    { id: 'Chip', numeric: true, disablePadding: false, label: 'Chip Completed', width: "160px" },
    { id: 'Chip', numeric: true, disablePadding: false, label: 'Chip n Progress', width: "160px" },
    { id: 'Chip', numeric: true, disablePadding: false, label: 'Chip Enrolled', width: "160px" },
    { id: 'Chip', numeric: true, disablePadding: false, label: 'Chip Enrolled', width: "160px" },
    { id: 'Chip', numeric: true, disablePadding: false, label: 'Chip Online', width: "160px" },
    { id: 'Button', numeric: true, disablePadding: false, label: 'Buttons', width: "160px" },
    { id: 'ButtonWithIcon', numeric: true, disablePadding: false, label: 'Buttons with icon', width: "180px" },
    { id: 'ButtonDisabled', numeric: true, disablePadding: false, label: 'Button Disabled', width: "200px" },
    { id: 'ButtonFilld', numeric: true, disablePadding: false, label: 'Button Filld', width: "200px" },
    { id: 'Details', numeric: true, disablePadding: false, label: 'Details', width: "150px" },
    { id: 'Actions', numeric: true, disablePadding: false, label: 'Actions', width: "150px" },


  ];

  const rows = [
    {
      cell: [
        { img: <img src={tableImg} />, imgStatus: true, imgText: "Name", align: "left", },
        { textBold: "Text Bold", },
        { textBold: "Text Bold", subtitle: "History of Islamic Finance", },
        { cardicon: <img src={Visa} />, number: "Visa - 4242", align: "left", chip: "PRIMARY", chipStatus: true, },
        { textBold: "Diploma", },
        { linkText: "Islamic Studies", chip: "PRIMARY", chipStatus: true, linkTextStatus:true, link:"#" },
        { date: "Jan 14, 2022", },
        { date: "Wed, Dec 14, 2022", },
        { date: "Dec 13, 2022, 10:00 AM", },
        { icon: <DownloadForOfflineOutlinedIcon />, iocnLink: "#", iconStatus: true, },
        { Check: true, },
        { Status: true, background: "#E1F2E8", color: "#0B9444", label: "Completed", },
        { Status: true, background: "#FFF5E6", color: "#FFAE35", label: "In Progress", },
        { Status: true, background: "#EEECFF", color: "#7266FB", label: "Enrolled", },
        { Status: true, background: "#FCE7E9", color: "#E63946", label: "Not Enrolled", },
        { Status: true, background: "#EDEDEF", color: "#676C7B", label: "Online",  },

        {
          Button: true, buttonLabel: "Go To Course", buttonColor: "#FFFFFF", buttonBorder: "#E4E8EC 1px solid",
          buttonTextcolor: "#004FE0", buttonLink: "#", onClick: onClick,
        },

        {
          Button: true, buttonLabel: "Download", buttonColor: "#FFFFFF", buttonBorder: "#E4E8EC 1px solid",
          buttonTextcolor: "#004FE0", buttonLink: "#", onClick: onClick, buttonIcon: <DownloadForOfflineOutlinedIcon />,
        },

        {
          Button: true, buttonLabel: "Button Disabled", buttonColor: "#FFFFFF", buttonBorder: "#E4E8EC 1px solid",
          buttonTextcolor: "#004FE0", buttonLink: "#", onClick: onClick, disabled: "disabled",
        },

        {
          Button: true, buttonLabel: "Button Filled", buttonColor: "#004FE0", buttonBorder: "#004FE0 1px solid",
          buttonTextcolor: "#fff", buttonLink: "#", onClick: onClick,
        },

        {
          Button: true, buttonLabel: "Details",
          buttonTextcolor: "#004FE0", buttonLink: "#", onClick: onClick,
        },

        {
          ActionButton: true, ActionButtonLabel: "Action"
        },

      ],


      //======= Action Date Start===== //
      action: [
        { label: "View", onClick: onClick, },
        { label: "Edit", onClick: onClick },
        { label: "Delete", onClick: onClick },
      ]
      //======= Action Date End===== //


    },


  ];






  return (
    <div className="TableSection">

      <Table
        title="Course"
        innerTitle="Table Name"
        addLabel="New User"
        link="add-courses"
        headCells={headCells}
        rows={rows}
        AddButton={true}
        tableHeader={true}
        innerHeader={true}
        Checkbox={true}
        footer={true}
      />
    </div>

  );
}


export default TableData;

