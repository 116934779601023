import React, { useEffect } from "react";
import "./Hours.css";
import { Grid, TextField } from "@mui/material";
import { styled } from "@mui/system";
import SelectField from "../../../../../components/Form/SelectField/SelectField";

const Hours = (props) => {
  const { locationInfo, setLocationInfo, errors, setErrors } = props;

  const [time, setTime] = React.useState("");
  const handleChanges = (event) => {
    setTime(event.target.value);
  };

  const handleChangesOfField = (fieldName, index, fieldValue) => {
    let operatingHours = [...locationInfo?.operatingHours];

    let updatedOperatingHours = operatingHours.map((singleOperatingHour, i) => {
      if (i == index) {
        return { ...singleOperatingHour, [fieldName]: fieldValue };
      }
      return singleOperatingHour;
    });

    // operatingHours[index][fieldName] = fieldValue
    setLocationInfo({ ...locationInfo, operatingHours: updatedOperatingHours });
  };
  useEffect(() => {
    console.log(locationInfo?.operatingHours, 404);
  }, [locationInfo]);
  return (
    <>
      <div className="hoursContainer">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h4>Hours</h4>
          </Grid>
          {/* ----- monday ------ */}
          <Grid item xs={12} md={4}>
            <span style={{ color: "#676C7B" }}>Day</span>
            <StyledTextField value="Monday" />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectField
              label="Start time"
              options={options}
              error={errors?.operatingHours[0]?.day}
              helperText={errors?.operatingHours[0]?.day}
              selectedOption={locationInfo?.operatingHours[0]?.startTime}
              handleChange={(e) =>
                handleChangesOfField("startTime", "0", e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {locationInfo?.operatingHours[0]?.startTime !== "24/7 hours" &&
              locationInfo?.operatingHours[0]?.startTime !== "Closed" && (
                <SelectField
                  label="End time"
                  options={options}
                  error={errors?.operatingHours[0]?.day}
                  // helperText={errors?.operatingHours[0]?.day}
                  selectedOption={locationInfo?.operatingHours[0]?.endTime}
                  handleChange={(e) =>
                    handleChangesOfField("endTime", "0", e.target.value)
                  }
                />
              )}
          </Grid>
          {/* ----- Tuesday ------ */}
          <Grid item xs={12} md={4}>
            <span style={{ color: "#676C7B" }}>Day</span>
            <StyledTextField value="Tuesday" />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectField
              label="Start time"
              options={options}
              error={errors?.operatingHours[1]?.day}
              helperText={errors?.operatingHours[1]?.day}
              selectedOption={locationInfo?.operatingHours[1]?.startTime}
              handleChange={(e) =>
                handleChangesOfField("startTime", "1", e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {locationInfo?.operatingHours[1]?.startTime != "Closed" &&
              locationInfo?.operatingHours[1]?.startTime != "24/7 hours" && (
                <SelectField
                  label="End time"
                  error={errors?.operatingHours[1]?.day}
                  // helperText={errors?.operatingHours[1]?.day}
                  options={options}
                  selectedOption={locationInfo?.operatingHours[1]?.endTime}
                  handleChange={(e) =>
                    handleChangesOfField("endTime", "1", e.target.value)
                  }
                />
              )}
          </Grid>
          {/* ----- Wednesday ------ */}
          <Grid item xs={12} md={4}>
            <span style={{ color: "#676C7B" }}>Day</span>
            <StyledTextField value="Wednesday" />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectField
              label="Start time"
              options={options}
              error={errors?.operatingHours[2]?.day}
              helperText={errors?.operatingHours[2]?.day}
              selectedOption={locationInfo?.operatingHours[2]?.startTime}
              handleChange={(e) =>
                handleChangesOfField("startTime", "2", e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {locationInfo?.operatingHours[2]?.startTime != "Closed" &&
              locationInfo?.operatingHours[2]?.startTime != "24/7 hours" && (
                <SelectField
                  label="End time"
                  options={options}
                  error={errors?.operatingHours[2]?.day}
                  // helperText={errors?.operatingHours[2]?.day}
                  selectedOption={locationInfo?.operatingHours[2]?.endTime}
                  handleChange={(e) =>
                    handleChangesOfField("endTime", "2", e.target.value)
                  }
                />
              )}
          </Grid>
          {/* ----- Thursday ------ */}
          <Grid item xs={12} md={4}>
            <span style={{ color: "#676C7B" }}>Day</span>
            <StyledTextField value="Thursday" />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectField
              label="Start time"
              options={options}
              error={errors?.operatingHours[3]?.day}
              helperText={errors?.operatingHours[3]?.day}
              selectedOption={locationInfo?.operatingHours[3]?.startTime}
              handleChange={(e) =>
                handleChangesOfField("startTime", "3", e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {locationInfo?.operatingHours[3]?.startTime != "Closed" &&
              locationInfo?.operatingHours[3]?.startTime != "24/7 hours" && (
                <SelectField
                  label="End time"
                  options={options}
                  error={errors?.operatingHours[3]?.day}
                  // helperText={errors?.operatingHours[3]?.day}
                  selectedOption={locationInfo?.operatingHours[3]?.endTime}
                  handleChange={(e) =>
                    handleChangesOfField("endTime", "3", e.target.value)
                  }
                />
              )}
          </Grid>
          {/* ----- Friday ------ */}
          <Grid item xs={12} md={4}>
            <span style={{ color: "#676C7B" }}>Day</span>
            <StyledTextField value="Friday" />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectField
              label="Start time"
              options={options}
              error={errors?.operatingHours[4]?.day}
              helperText={errors?.operatingHours[4]?.day}
              selectedOption={locationInfo?.operatingHours[4]?.startTime}
              handleChange={(e) =>
                handleChangesOfField("startTime", "4", e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {locationInfo?.operatingHours[4]?.startTime != "Closed" &&
              locationInfo?.operatingHours[4]?.startTime != "24/7 hours" && (
                <SelectField
                  label="End time"
                  options={options}
                  selectedOption={locationInfo?.operatingHours[4]?.endTime}
                  handleChange={(e) =>
                    handleChangesOfField("endTime", "4", e.target.value)
                  }
                  error={errors?.operatingHours[4]?.day}
                  // helperText={errors?.operatingHours[4]?.day}
                />
              )}
          </Grid>
          {/* ----- Saturday ------ */}
          <Grid item xs={12} md={4}>
            <span style={{ color: "#676C7B" }}>Day</span>
            <StyledTextField value="Saturday" />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectField
              label="Start time"
              options={options}
              error={errors?.operatingHours[5]?.day}
              helperText={errors?.operatingHours[5]?.day}
              selectedOption={locationInfo?.operatingHours[5]?.startTime}
              handleChange={(e) =>
                handleChangesOfField("startTime", "5", e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {locationInfo?.operatingHours[5]?.startTime != "Closed" &&
              locationInfo?.operatingHours[5]?.startTime != "24/7 hours" && (
                <SelectField
                  label="End time"
                  options={options}
                  error={errors?.operatingHours[5]?.day}
                  // helperText={errors?.operatingHours[5]?.day}
                  selectedOption={locationInfo?.operatingHours[5]?.endTime}
                  handleChange={(e) =>
                    handleChangesOfField("endTime", "5", e.target.value)
                  }
                />
              )}
          </Grid>
          {/* ----- Sunday ------ */}
          <Grid item xs={12} md={4}>
            <span style={{ color: "#676C7B" }}>Day</span>
            <StyledTextField value="Sunday" />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectField
              label="Start time"
              options={options}
              error={errors?.operatingHours[6]?.day}
              helperText={errors?.operatingHours[6]?.day}
              selectedOption={locationInfo?.operatingHours[6]?.startTime}
              handleChange={(e) =>
                handleChangesOfField("startTime", "6", e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {locationInfo?.operatingHours[6]?.startTime != "Closed" &&
              locationInfo?.operatingHours[6]?.startTime != "24/7 hours" && (
                <SelectField
                  label="End time"
                  options={options}
                  error={errors?.operatingHours[6]?.day}
                  // helperText={errors?.operatingHours[6]?.day}
                  selectedOption={locationInfo?.operatingHours[6]?.endTime}
                  handleChange={(e) =>
                    handleChangesOfField("endTime", "6", e.target.value)
                  }
                />
              )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Hours;

const options = [
  {
    label: "Closed",
    value: "Closed",
  },
  {
    label: "24/7 hours",
    value: "24/7 hours",
  },
  {
    label: "12:00 AM",
    value: "12:00 AM",
  },
  {
    label: "12:30 AM",
    value: "12:30 AM",
  },
  {
    label: "01:00 AM",
    value: "01:00 AM",
  },
  {
    label: "01:30 AM",
    value: "01:30 AM",
  },
  {
    label: "02:00 AM",
    value: "02:00 AM",
  },
  {
    label: "02:30 AM",
    value: "02:30 AM",
  },
  {
    label: "03:00 AM",
    value: "03:00 AM",
  },
  {
    label: "03:30 AM",
    value: "03:30 AM",
  },
  {
    label: "04:00 AM",
    value: "04:00 AM",
  },
  {
    label: "04:30 AM",
    value: "04:30 AM",
  },
  {
    label: "05:00 AM",
    value: "05:00 AM",
  },
  {
    label: "05:30 AM",
    value: "05:30 AM",
  },
  {
    label: "06:00 AM",
    value: "06:00 AM",
  },
  {
    label: "06:30 AM",
    value: "06:30 AM",
  },
  {
    label: "07:00 AM",
    value: "07:00 AM",
  },
  {
    label: "07:30 AM",
    value: "07:30 AM",
  },
  {
    label: "08:00 AM",
    value: "08:00 AM",
  },
  {
    label: "08:30 AM",
    value: "08:30 AM",
  },
  {
    label: "09:00 AM",
    value: "09:00 AM",
  },
  {
    label: "09:30 AM",
    value: "09:30 AM",
  },
  {
    label: "10:00 AM",
    value: "10:00 AM",
  },
  {
    label: "10:30 AM",
    value: "10:30 AM",
  },
  {
    label: "11:00 AM",
    value: "11:00 AM",
  },
  {
    label: "11:30 AM",
    value: "11:30 AM",
  },
  {
    label: "12:00 PM",
    value: "12:00 PM",
  },
  {
    label: "01:00 PM",
    value: "01:00 PM",
  },
  {
    label: "01:30 PM",
    value: "01:30 PM",
  },
  {
    label: "02:00 PM",
    value: "02:00 PM",
  },
  {
    label: "02:30 PM",
    value: "02:30 PM",
  },
  {
    label: "03:00 PM",
    value: "03:00 PM",
  },
  {
    label: "03:30 PM",
    value: "03:30 PM",
  },
  {
    label: "04:00 PM",
    value: "04:00 PM",
  },
  {
    label: "04:30 PM",
    value: "04:30 PM",
  },
  {
    label: "05:00 PM",
    value: "05:00 PM",
  },
  {
    label: "05:30 PM",
    value: "05:30 PM",
  },
  {
    label: "06:00 PM",
    value: "06:00 PM",
  },
  {
    label: "06:30 PM",
    value: "06:30 PM",
  },
  {
    label: "07:00 PM",
    value: "07:00 PM",
  },
  {
    label: "07:30 PM",
    value: "07:30 PM",
  },
  {
    label: "08:00 PM",
    value: "08:00 PM",
  },
  {
    label: "08:30 PM",
    value: "08:30 PM",
  },
  {
    label: "09:00 PM",
    value: "09:00 PM",
  },
  {
    label: "09:30 PM",
    value: "09:30 PM",
  },
  {
    label: "10:00 PM",
    value: "10:00 PM",
  },
  {
    label: "10:30 PM",
    value: "10:30 PM",
  },
  {
    label: "11:00 PM",
    value: "11:00 PM",
  },
  {
    label: "11:30 PM",
    value: "11:30 PM",
  },
];

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  "& .MuiInputBase-root.MuiOutlinedInput-root input": {
    padding: "14px 14px",
    color: "#45464F !important",
    background: "#F2F0F4",
    borderRadius: "12px",
    overflow: "hidden !important",
  },
}));
