import React, { useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Grid } from "@mui/material";
import Modals from "../../Form/Modals/Modals";
import OrdersModal from "../OrdersModal/OrdersModal";

const OrdersActiveCard = (props) => {
  const {
    setOpenModal,
    allOrders,
    inProgressOrders,
    deliveredOrders,
    cancelledOrders,
    newOrders,
  } = props;

  // const handleOpenModal = () => {};
  // * modal section
  // const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <>
      <Grid container spacing={2}>
        {newOrders?.length > 0 && (
          <Grid item xs={12} md={6}>
            <div
              role="button"
              tabIndex="0"
              className="NewOrders"
              style={{ cursor: "pointer" }}
              onClick={handleOpenModal}
            >
              <div className="Avatar"></div>
              <div className="Ordercontent">
                <h2>New orders</h2>
                <div className="ordercountlabel">
                  <FiberManualRecordIcon className="AvtrIcon" />
                  <h3> {newOrders?.length} new order</h3>
                </div>
                <h4>Start accepting orders</h4>
              </div>
            </div>
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <div
            className="NewOrders"
            style={{ background: "rgb(177 159 159 / 9%)" }}
          >
            <div
              className="Avatar"
              style={{ background: "rgb(36 173 38 / 9%)" }}
            ></div>
            <div className="Ordercontent">
              <h2 style={{ color: "#1B1B1F" }}>Active orders</h2>
              <div className="ordercountlabel">
                <FiberManualRecordIcon
                  className="AvtrIcon"
                  style={{ color: "#4C9F41" }}
                />
                <h3 style={{ color: "#45464F" }}>
                  {" "}
                  {inProgressOrders?.length} active orders
                </h3>
              </div>
              <h4 style={{ color: "#4C9F41" }}>Start preparing orders</h4>
            </div>
          </div>
        </Grid>
        {newOrders?.length == 0 && (
          <Grid item xs={12} md={6}>
            <div
              style={{
                padding: "16px 0",
                borderRadius: "12px",
                background: "#F2F0F4",
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <div
                className="Avatar"
                style={{
                  marginLeft: "12px",
                  background: "rgb(36 173 38 / 9%)",
                }}
              ></div>
              <div>
                <h2
                  style={{
                    color: "#1B1B1F",
                    fontSize: "22px",
                    fontWeight: "400",
                  }}
                >
                  No new orders
                </h2>
                {/* <FiberManualRecordIcon
                className="AvtrIcon"
                style={{ color: "#4C9F41" }}
              />
              <h3 style={{ color: "#45464F" }}> 2 active orders</h3>
              <h4 style={{ color: "#4C9F41" }}>Start accepting orders</h4> */}
              </div>
            </div>
          </Grid>
        )}
      </Grid>

      {/* ------ modal ------ */}
      {/* <OrdersModal
        ModalTitle="Delivery"
        handleClose={handleCloseModal}
        // handleConfirm={() => handleConfirm()}
        // handleClose={() => handleClose()}
        ModalFooter
        open={openModal}
        ButtonClose="CLOSE"
        ButtonConfirm="CONFIRM"
        width="570px"
        Headerbackground="#DD2D37"
        Headercolor="#676C7B"
        Footerbackground="#fff"
      /> */}
    </>
  );
};

export default OrdersActiveCard;
