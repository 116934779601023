import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/system";
import AddNewUserDropDown from "../../../../../components/AddNewUserDropDown/AddNewUserDropDown";
import InputAdornment from "@mui/material/InputAdornment";
import TextFields from "../../../../../components/Form/TextField/TextField";
import TextField from "@mui/material/TextField";
import "./BasicInfo.css";
import Datefield from "../../../../../components/Form/Datefield/Datefield";
import PhoneInputField from "../../../../../components/PhoneInputField/PhoneInputField";

const BasicInfo = (props) => {
  const { errors, setErrors, userInfo, setUserInfo, setPhoneError } = props;
  const [spicy, setSpicy] = useState("");
  const [date, setDate] = useState();
  const [values, setValues] = useState({
    itemName: "",
    description: "",
  });
  const handleChangesSpicy = (event) => {
    setSpicy(event.target.value);
  };

  const handleChanges = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };

  // for the date
  const handleChangeDate = (newValue) => {
    setDate(newValue);
  };

  // for the phone field
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    const numericValue = event.target.value.replace(/\D/g, ""); // Removes any non-digit characters
    setInputValue(numericValue);
  };

  const handleChangesOfField = (fieldName, fieldValue) => {
    setUserInfo({ ...userInfo, [fieldName]: fieldValue });
  };
  const [phoneNumberFormated, setPhoneNumberFormated] = useState("");
  const handleChangesOfFields = (fieldName, phoneNumber, country, e) => {
    let data = phoneNumber.slice(country?.dialCode?.length);
    if (data?.length === 0) {
      setPhoneError("Required");
    } else {
      setPhoneError("");
      setUserInfo({
        ...userInfo,
        [fieldName]: e.target.value,
      });
    }
  };
  // useEffect(() => {
  //   console.log(userInfo, "userInfo");
  // }, [userInfo]);
  const applicationStauts = process.env.REACT_APP_PROJECT_ENV;
  return (
    <>
      <div className="itemDetailsGrandContainer">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h3>Basic info</h3>
          </Grid>
          <Grid item xs={12}>
            <AddNewUserDropDown userInfo={userInfo} setUserInfo={setUserInfo} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFields
              label="First name"
              // required={true}
              email={false}
              value={userInfo?.firstName}
              // handleValidation={handleValidation}
              // count={100}
              handleChange={(e) =>
                handleChangesOfField("firstName", e.target.value)
              }
              // placeholder="Email address"
              error={errors?.firstName}
              helperText={errors?.firstName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFields
              label="Last name"
              // required={true}
              email={false}
              value={userInfo?.lastName}
              // handleValidation={handleValidation}
              // count={100}
              handleChange={(e) =>
                handleChangesOfField("lastName", e.target.value)
              }
              // placeholder="Email address"
              error={errors?.lastName}
              helperText={errors?.lastName}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ mt: 1 }}>
            <div className="FieldLabel">Phone number</div>
            <PhoneInputField
              phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
              country="us"
              value={userInfo?.phone}
              countryCodeEditable={false}
              onChange={(phone, country, e) =>
                handleChangesOfFields("phone", `${phone}`, country, e)
              }
              // onBlur={(e) => setPhoneNumberFormated(e.target.value)}
              onKeyDown={(e) => setPhoneNumberFormated(e.target.value)}
              variant="outlined"
              onlyCountries={
                applicationStauts === "dev" ? ["us", "bd", "pk"] : ["us"]
              }
              showDropdown={true}
              containerStyle={{}}
              buttonStyle={{}}
              inputStyle={{
                marginLeft: "0px",
                height: "40px",
                width: "100%",
                fontFamily: "Roboto-Regular",
                fontSize: "16px",
              }}
              containerClass={{}}
              errorText={errors?.phone}
              errorStyles={{
                marginBottom: "5px",
                fontSize: "12px",
                background: "#fff",
                color: "#d32f2f",
              }}
              autoFormat={true}
              disableDropdown={false}
              disabled={false}
              disableCountryCode={false}
              enableSearch={false}
              disableSearchIcon={false}
              placeholder={""}
              error={errors?.phone ? "error" : ""}

              // inputProps={{}}
              // specialLabel={"Primary Phone"}
              // preferredCountries={["us"]}
              // excludeCountries={["us"]}
              // error="error"
            />
            {/* <StyledTextField
              value={userInfo?.phone}
              onChange={(e) => handleChangesOfField("phone", e.target.value)}
              error={errors?.phone}
              helperText={errors?.phone}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span style={{ color: "#0B132B !important" }}>+1</span>
                  </InputAdornment>
                ),
              }}
            /> */}
          </Grid>
          <Grid item xs={12} md={6} sx={{ mt: 1 }}>
            <TextFields
              label="Email address"
              // required={true}
              email={true}
              value={userInfo?.email}
              // handleValidation={handleValidation}
              // count={100}
              disabled={userInfo?._id ? true : false}
              handleChange={(e) =>
                handleChangesOfField("email", e.target.value)
              }
              // placeholder="Email address"
              error={errors?.email}
              helperText={errors?.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Datefield
              label="Employment start date"
              // validation={true}
              value={userInfo?.employmentStartDate}
              handleChange={(e) =>
                handleChangesOfField("employmentStartDate", e)
              }
              error={errors?.employmentStartDate}
              helperText={errors?.employmentStartDate}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default BasicInfo;

const spicyLevel = [
  {
    label: "Mild",
    value: "Mild",
  },

  {
    label: "Medium",
    value: "Medium",
  },

  {
    label: "Hot",
    value: "Hot",
  },

  {
    label: "Extra Hot",
    value: "Extra Hot",
  },

  {
    label: "Extremely Hot",
    value: "Extremely Hot",
  },
];

const StyledTextField = styled(TextField)(({ theme }) => ({}));
