import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./DeleteModal.css";
import TextField from "../Form/TextField/TextField";
import { toast } from "react-toastify";
import TextInputField from "../Form/TextInputField/TextInputField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: 200,
    sm: 400,
    md: 400,
    lg: 500,
    xl: 500,
  },
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "12px",
  boxShadow: 24,
  p: "24px",
};

// * === How to use this component === * //

// TODO : Import this in your page
// let DeletedConfirmWord = "CONFIRM" // please provide a Uppercase value
// const [openModal, setOpenModal] = useState(false)
// const [textValue, setTextValue] = React.useState("")
// const [error, setError] = React.useState(false);
// const handleOpen = () => setOpenModal(true)
// const handleClose = () => setOpenModal(false);

// ? --------- this is the handle confirm func ------------
// const handleConfirm = (textValue) => {
//     if (textValue === DeletedConfirmWord) {
//         setError(false)
//         setOpenModal(false)
//         setTextValue("")
//         toast.success("Deleted successfully")
//     } else {
//         setError(true)
//     }
// }

// TODO : The component
/* 
    <DeleteModal
        DeletedConfirmWord={DeletedConfirmWord}
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleOpen={handleOpen}
        handleClose={handleClose}
        textValue={textValue}
        error={error}
        setTextValue={setTextValue}
        handleConfirm={handleConfirm}
    /> 
*/

// * === End === * //

const DeleteModal = (props) => {
  const { DeletedConfirmWord, openModal, handleClose, handleConfirm } = props;
  const [error, setError] = React.useState(false);
  const [textValue, setTextValue] = React.useState("");
  const handleChangeConfirm = (e) => {
    setTextValue(e.toUpperCase());
  };

  const permanentDeleteHandler = () => {
    if (textValue === DeletedConfirmWord) {
      setError(false);
      handleClose();
      setTextValue("");
      handleConfirm();
    } else {
      setError(true);
    }
  };
  const closeModalHandler = () => {
    setTextValue("");
    setError(false);
    handleClose();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModal}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h5"
              component="h2"
              sx={{ fontWeight: 600 }}
            >
              Delete course?
            </Typography>
            <Typography
              id="transition-modal-description"
              sx={{ mt: 2, color: "#33415C", m: 0 }}
            >
              Are you sure you want to delete this course? All of your data will
              be permanently deleted. This action cannot be undone.
              <br />
              <br />
              Type{" "}
              <span style={{ color: "#BA1A1A" }}>{DeletedConfirmWord}</span> to
              proceed.
            </Typography>
            <div style={{ paddingTop: "6px" }}>
              <TextInputField
                validation={false}
                CharAlignMid="CharAlignMid"
                error={error ? "error" : ""}
                helperText={
                  error ? `Please type “${DeletedConfirmWord}” to proceed.` : ""
                }
                value={textValue}
                handleChange={(e) => handleChangeConfirm(e)}
              />
            </div>
            <br />
            <div className="flexEnd">
              <Button
                variant="text"
                sx={{ fontWeight: 700, color: "#001233" }}
                onClick={(e) => closeModalHandler()}
              >
                Cancel
              </Button>
              <Button
                variant="text"
                sx={{ fontWeight: 700, color: "#BA1A1A" }}
                onClick={() => permanentDeleteHandler()}
                // disabled={textValue === DeletedConfirmWord ? false : true}
              >
                Permanently delete
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default DeleteModal;
