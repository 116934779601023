import React, { useEffect, useState } from "react";
import VerifiedUser from "@mui/icons-material/Verified";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

function Profile({ personalInfo, birthDate, birthMonth, birthYear }) {
  // console.log(personalInfo, "personal");
  return (
    <>
      <div className="TableContainer">
        <div className="TableInnerHeader">
          <h3>Profile</h3>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left">Full name</StyledTableCell>
                <StyledTableCell align="left">
                  <div className="cellwithchip">
                    {" "}
                    {personalInfo?.firstName} {personalInfo?.lastName}
                  </div>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">Gender</StyledTableCell>
                <StyledTableCell align="left">
                  <div className="cellwithchip">
                    {personalInfo?.gender ? personalInfo?.gender : "N/A"}
                  </div>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">Birthday</StyledTableCell>
                <StyledTableCell align="left">
                  <div className="cellwithchip">
                    {" "}
                    {personalInfo?.birthDate
                      ? `${birthDate}-${birthMonth}-${birthYear}`
                      : "N/A"}
                  </div>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">Address</StyledTableCell>
                <StyledTableCell align="left">
                  <div className="cellwithchip">
                    {/* 1337 14th St NW Brooklyn, NY 99301 */}
                    {personalInfo?.address ? personalInfo?.address : "N/A"}
                  </div>
                </StyledTableCell>
              </StyledTableRow>
              {/* <StyledTableRow>
                <StyledTableCell align="left">Timezone</StyledTableCell>
                <StyledTableCell align="left">
                  <div className="cellwithchip">
                    {personalInfo?.timezone ? personalInfo?.timezone : "N/A"}
                   
                  </div>
                </StyledTableCell>
              </StyledTableRow> */}

              {/* <StyledTableRow >
                <StyledTableCell align="left">Phone</StyledTableCell>
                <StyledTableCell align="left">+1 (123) 123-1234</StyledTableCell>
              </StyledTableRow>

              <StyledTableRow >
                <StyledTableCell align="left">Password</StyledTableCell>
                <StyledTableCell align="left">
                  <div className="PassworedDots">
                    <FiberManualRecordIcon />
                    <FiberManualRecordIcon />
                    <FiberManualRecordIcon />
                    <FiberManualRecordIcon />
                    <FiberManualRecordIcon />
                    <FiberManualRecordIcon />
                    <FiberManualRecordIcon />
                    <FiberManualRecordIcon />
                    <FiberManualRecordIcon />
                    <FiberManualRecordIcon />
                  </div>
                </StyledTableCell>
              </StyledTableRow> */}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default Profile;
