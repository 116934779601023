import React, { useState, useEffect } from "react";
import axios from "axios";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import StepLabel from "@mui/material/StepLabel";
import { Container } from "@mui/material";
import { styled } from "@mui/system";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import Buttons from "../../../components/Button/Button";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import ItemDetails from "./steps/ItemDetails/ItemDetails";
import PriceAndVariations from "./steps/PriceAndVariations/PriceAndVariations";
import "./MenuAddItemStapper.css";
import {
  useCreateMenuMutation,
  useGetSingleMenuListQuery,
  useUpdateMenuMutation,
} from "../../../redux/features/menus/menuApi";
import useProtected from "../../../hooks/useProtected";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetCategoryListByOrgIdQuery } from "../../../redux/features/categories/categoryApi";
import { getPreparedArray } from "../../../hooks/HelperFunctions";

// development related
// import { sendDataToAPI, retriveDataFromAPI } from '../../../../hooks/HelperFunctions'

function getSteps() {
  return ["Item details", "Price and variations"];
}

function getCourseRelationalData(orgId, courseId) {
  // const result = retriveDataFromAPI('courses/getAddCourseRelatedData', `orgId=${orgId}&courseId=${courseId}`)
  // console.log(result);
  // return [];
  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;
  axios
    .get(
      `${baseUrl}/courses/getAddCourseRelatedData?orgId=${orgId}&courseId=${courseId}`
    )
    .then((result) => {
      console.log(result.data.body);
      return result.data.body;
    })
    .catch((err) => {
      console.log(err);
    });
}

function MenuUpdateItemStapper(props) {
  const { id } = useParams();

  const user = useSelector((state) => state.auth.user);

  const {
    data: categoriesData,
    isLoading: categoriesIsLoading,
    isFetching: categoriesIsFetching,
    error: categoriesError,
  } = useGetCategoryListByOrgIdQuery(user?.orgId, {
    skip: !user?.orgId,
    refetchOnMountOrArgChange: true,
  });

  const activeLocation = useSelector((state) => state.auth.activeLocation);
  const { data, isLoading, isFetching, error } = useGetSingleMenuListQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const [updateMenuItem, updateMenuItemOptions] = useUpdateMenuMutation();
  useProtected(error);
  useProtected(updateMenuItemOptions.error);
  const initialState = {
    name: "",
    description: "",
    spicyLevel: "",
    photo: "",
    photoName: "",
    price: 0,
    variations: [],
    locationId: activeLocation,
    isFeatured: true,
    status: "Available",
  };
  const initialStateError = {
    name: "",
    photo: "",
    price: "",
    variations: [],
  };

  const [errors, setErrors] = useState(initialStateError);
  const [menuInfo, setMenuInfo] = useState(initialState);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [courseOverview, setCourseOverview] = useState({});
  const [courseRelationalData, setCourseRelationalData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  //========================================= fetching data =========================//
  useEffect(() => {
    if (!isLoading && !isFetching && data) {
      if (
        data?.body?.data?.categories &&
        data?.body?.data?.categories?.length > 0
      ) {
        let categoryIds = data?.body?.data?.categories?.map(
          (singleCategory) => singleCategory?.id
        );
        setMenuInfo({ ...data?.body?.data, categories: categoryIds });
      } else {
        setMenuInfo(data?.body?.data);
      }
    }
  }, [data, isLoading, isFetching]);
  //========================================= fetching data =========================//
  const steps = getSteps();

  // useEffect(() => {
  //   const baseUrl =
  //     process.env.REACT_APP_PROJECT_ENV === "prod"etTermsNameError
  //       ? process.env.REACT_APP_API_URL_PROD
  //       : process.env.REACT_APP_API_URL_DEV;
  //   axios
  //     .get(`${baseUrl}/courses/getAddCourseRelatedData?orgId=1&courseId=0`)
  //     .then((result) => {
  //       // console.log(result.data.body);
  //       setCourseRelationalData(result.data.body);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  const [termsNameError, setTermsNameError] = useState([]);
  const [termsPriceError, setTermsPriceError] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    // console.log("Menu Info:", menuInfo.name);

    if (menuInfo && menuInfo?.categories && menuInfo?.categories?.length > 0) {
      let categoryIds = menuInfo?.categories?.map(
        (singleCategory) => singleCategory?.id
      );
    }
  }, [menuInfo]);

  // console.log(courseRelationalData.courseLanguages);

  useEffect(() => {
    if (!categoriesIsLoading && !categoriesIsFetching && categoriesData) {
      setCategories(categoriesData?.body?.data);
    }
  }, [categoriesData, categoriesIsLoading, categoriesIsFetching]);

  useEffect(() => {
    if (categories?.length > 0) {
      setCategoryOptions(getPreparedArray(categories, "_id", "name"));
    }
  }, [categories]);

  const getStepContent = (step, courseRelationalData) => {
    switch (step) {
      case 0:
        //return <AccountType/>;
        return (
          <ItemDetails
            menuInfo={menuInfo}
            setMenuInfo={setMenuInfo}
            errors={errors}
            setErrors={setErrors}
            categoryOptions={categoryOptions}
          />
        );
      case 1:
        return (
          <PriceAndVariations
            menuInfo={menuInfo}
            setMenuInfo={setMenuInfo}
            termsNameError={termsNameError}
            termsPriceError={termsPriceError}
            errors={errors}
            setErrors={setErrors}
          />
        );
      default:
        return "Unknown step";
    }
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    if (activeStep == 0) {
      let isValid = await handleFirstCheck();
      if (!isValid) {
        return false;
      }
    }
    if (activeStep == 1) {
      let isValid = await handleSecondCheck();
      if (!isValid) {
        return false;
      }
      await updateMenuHandler();
      return false;
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // breadcrum
  const breadcrumbsData = [
    {
      label: "Menu",
      link: "/menu-listing",
      icon: <MenuBookRoundedIcon fontSize="small" />,
    },

    {
      label: "Add new",
      link: "",
    },
  ];
  const handleFirstCheck = async () => {
    let errorObject = { ...errors };
    let error = 0;
    if (menuInfo.photo == "") {
      errorObject = { ...errorObject, photo: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, photo: "" };
    }

    if (menuInfo.name == "") {
      errorObject = { ...errorObject, name: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, name: "" };
    }
    if (menuInfo.categories?.length === 0) {
      errorObject = { ...errorObject, categories: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, categories: "" };
    }

    setErrors({ ...errors, ...errorObject });

    return error > 0 ? false : true;
  };
  const checkBasicFormField = async () => {
    let errorObject = { ...errors };
    let error = 0;
    if (menuInfo.name == "") {
      errorObject = { ...errorObject, name: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, name: "" };
    }
    // if (messageObject.category == "") {
    //   errorObject = { ...errorObject, category: "Select a category" }
    //   error++
    // } else {
    //   errorObject = { ...errorObject, category: "" }
    // }
    // if (messageObject.to.length == 0) {
    //   errorObject = { ...errorObject, to: "Select at least one user" }
    //   error++
    // } else {
    //   errorObject = { ...errorObject, to: "" }
    // }
    setErrors({ ...errors, ...errorObject });

    return error > 0 ? false : true;
  };

  const handleSecondCheck = async () => {
    if (menuInfo?.variations?.length > 0) {
      let newVariationErrorsArray = [];
      let error = 0;
      menuInfo?.variations.map((singleVariation) => {
        let errorObjectVariation = {
          name: "",
          price: "",
        };
        if (singleVariation.name == "") {
          errorObjectVariation.name = "Required";
          error++;
        } else {
          errorObjectVariation.name = "";
        }
        if (singleVariation.price == "") {
          errorObjectVariation.price = "Required";
          error++;
          // } else if (singleVariation.price <= 0) {
          //   errorObjectVariation.price = "Please input a vaild price range";
          //   error++;
        } else {
          errorObjectVariation.price = "";
        }

        newVariationErrorsArray.push(errorObjectVariation);
      });
      setErrors({ ...errors, variations: newVariationErrorsArray });
      return error > 0 ? false : true;
    } else {
      let errorObject = { ...errors };
      let error = 0;
      if (menuInfo.price == "") {
        errorObject = { ...errorObject, price: "Required" };
        error++;
        // } else if (menuInfo.price <= 0) {
        //   errorObject = {
        //     ...errorObject,
        //     price: "Please input a vaild price range",
        //   };
        //   error++;
      } else {
        errorObject = { ...errorObject, price: "" };
      }

      setErrors({ ...errors, ...errorObject });
      return error > 0 ? false : true;
    }
  };

  const settleDataStructure = async () => {
    let newMenuInfo = { ...menuInfo };
    let categoriesToAdd = [];
    newMenuInfo?.categories?.map((singleCategory) => {
      let found = categories?.find(
        (categoryObj) => categoryObj._id == singleCategory
      );
      if (found) {
        categoriesToAdd?.push({
          id: found?._id,
          name: found?.name,
        });
      }
    });
    newMenuInfo.categories = categoriesToAdd;
    return newMenuInfo;
  };

  const updateMenuHandler = async () => {
    let isValid = await checkBasicFormField();
    // let valid_term = await handleSecondCheck();

    if (!isValid) {
      // alert(3425);
      return false;
    }

    let data = await settleDataStructure();
    let result = await updateMenuItem({ ...data, id: id });

    if (!result?.data.error) {
      toast.success("Menu has updated successfully");
      navigate("/menu-listing");
    } else {
      console.error("something went wrong");
    }
  };
  return (
    <>
      <div className="stapperHeaderContainer">
        <Container>
          <div className="stapperHeaderBox">
            <Buttons
              label="Exit"
              buttonColor="transparent"
              color="#4C9F41"
              height="100%"
              fontSize="14px"
              iconStart={
                <ClearRoundedIcon
                  sx={{ mr: "8px !important", color: "#4C9F41 !important" }}
                  fontSize="small"
                />
              }
              link="/menu-listing"
            />
            <p
              style={{
                margin: "0",
                flexGrow: "1",
                fontSize: "22px",
                textAlign: "center",
              }}
            >
              Add item
            </p>
          </div>
        </Container>
      </div>
      <Container>
        <div className="FormStepper">
          <Breadcrumbs breadcrumbsData={breadcrumbsData} />
          <div className="StapperContaier">
            <div className="StapperBody Section">
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  // if (isStepOptional(index)) {
                  //   labelProps.optional = <Typography variant="caption">Optional</Typography>;
                  // }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </div>

            <div className="StapperContent">
              {activeStep === steps.length ? (
                <div>
                  <Typography>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Button onClick={handleReset}>Reset</Button>
                </div>
              ) : (
                <div>
                  <Typography>
                    {getStepContent(activeStep, courseRelationalData)}
                  </Typography>
                  <div className="SpperButtonContainer">
                    <div className="StepperButton">
                      <StyledBackButton
                        disabled={activeStep === 0}
                        onClick={handleBack}
                      >
                        {activeStep == 0 ? "Cancel" : "Back"}
                      </StyledBackButton>
                      {/* {isStepOptional(activeStep) && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSkip}
                        >
                          Skip
                        </Button>
                      )} */}

                      <StyledBackContinue
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        {activeStep === steps.length - 1
                          ? "Finish"
                          : "Continue"}
                      </StyledBackContinue>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default MenuUpdateItemStapper;

const StyledBackButton = styled(Button)(({ theme }) => ({
  margin: "0 5px",
  padding: "10px 0",
  width: "98px",
  color: "#4C9F41",
  fontSize: "12px",
  background: "#fff",
  border: "1px solid #E4E2E6",
  borderRadius: "1000px",
  gap: "10px",
  alignItems: "center",
  "&:hover": {
    color: "#fff",
    border: "1px solid #4C9F41",
    background: "#4C9F41",
  },
}));

const StyledBackContinue = styled(Button)(({ theme }) => ({
  margin: "0 5px",
  padding: "10px 0",
  width: "98px",
  color: "#fff",
  fontSize: "12px",
  background: "#4C9F41",
  // border: "1px solid #E4E2E6",
  boxShadow: "none",
  borderRadius: "1000px",
  gap: "10px",
  alignItems: "center",
  "&:hover": {
    color: "#fff",
    boxShadow: "none",
    border: "1px solid #4C9F41",
    background: "#4C9F41",
  },
}));
