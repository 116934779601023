import axios from "axios";

const baseUrl =
  process.env.REACT_APP_PROJECT_ENV === "prod"
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_DEV;

export function sendDataToAPI(method, data) {
  axios.post(`${baseUrl}/${method}}`, data).then(
    (response) =>
      function () {
        return response.data;
      }
  );
}

export const makeSafeString = (str) => {
  // Replace spaces with dashes
  let safeStr = str.replace(/ /g, "-");

  // Remove special characters except for dashes
  safeStr = safeStr.replace(/[^a-zA-Z0-9-]/g, "");

  // Convert to lowercase
  safeStr = safeStr.toLowerCase();

  return safeStr;
};

export const getDates = () => {
  const dates = [];
  for (let i = 1; i <= 31; i++) {
    dates.push({ value: i, label: i });
  }
  return dates;
};

export const getPreparedArray = (data, key, label) => {
  return data.map((item) => ({
    value: key ? item[key] : item.name,
    label: label ? item[label] : item.name,
  }));
};

export const capitalizeFirstLetter = (string) => {
  return string[0]?.toUpperCase() + string?.slice(1);
};

export const isValidEmail = (email) => {
  // Regular expression for a valid email address
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  // Test the email against the regex
  return emailRegex.test(email);
};

export const retriveDataFromAPI = async (method, queryString) => {
  const result = await axios
    .get(`${baseUrl}/${method}?${queryString}`)
    .then((result) => {
      console.log(result.data.body);
      return result.data.body;
    })
    .catch((err) => {
      console.log(err);
    });

  return result;
};
export const capitalizeWords = (str) => {
  const words = str?.split(/\s+/); // Split at spaces
  return words
    ?.map((word, index) => {
      return word.length > 0
        ? index === 0
          ? word.charAt(0).toUpperCase() + word.slice(1)
          : word
        : "";
    })
    .join(" ");
};

// === get months list for select field === //
export const getMonths = () => {
  const months = [];

  var monthArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  monthArray.forEach((monthName) => {
    months.push({ value: monthName, label: monthName });
  });

  return months;
};

// === get data map for select/multiselect fields courses === //
export const getDataMap = (
  dataArray,
  primaryField,
  labelField,
  additionalField = ""
) => {
  const result = [];
  if (typeof dataArray !== "undefined") {
    dataArray.forEach((element) => {
      if (
        typeof element[labelField] !== "undefined" &&
        element[labelField] !== null
      ) {
        const label =
          additionalField === ""
            ? `${element[labelField]}`
            : `${element[labelField]} ${element[additionalField]}`;
        result.push({ value: `${element[primaryField]}`, label: label });
      }
    });
  }
  // console.log(result);
  return result;
};

/**
 * retrive logged in user data
 */
export const getUserData = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (userData) {
    return userData;
  } else {
    return {};
  }
};

/**
 * retrive user auth data
 */
export const getUserAuthData = () => {
  const userAuthenticated = localStorage.getItem("userAuthenticated");
  if (userAuthenticated) {
    return true;
  } else {
    return false;
  }
};

/**
 * retrive user token data
 */
export const getUserTokenData = () => {
  const userToken = localStorage.getItem("userToken");
  if (userToken) {
    return userToken;
  } else {
    return "";
  }
};

export default function emailValidation(email) {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (!email || regex.test(email) === false) {
    return false;
  }
  return true;
}

export const createRECAPTCHAToken = async () => {
  const token = await window.grecaptcha.execute(
    `${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`,
    { action: "submit" }
  );
  return token;
};
// us number formatting
export const USformatPhoneNumber = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, ""); // Remove all non-digit characters
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Match the parts of the phone number

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`; // Format the number into (XXX) XXX-XXXX
  }

  return null; // Return null if the input number does not match the expected format
};
