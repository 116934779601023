import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import "./Dialog.css";

// import Draggable from 'react-draggable';

// function PaperComponent(props) {
//   return (
//     <Draggable
//       handle="#draggable-dialog-title"
//       cancel={'[class*="MuiDialogContent-root"]'}
//     >
//       <Paper {...props} />
//     </Draggable>
//   );
// }

export default function DraggableDialog(props) {
  const {
    openDialog,
    title,
    body,
    actionButton,
    cancelButton,
    handleCloseDialog,
    actionButtonN,
    cancelColor,
    handleConfirmCloseDialog,
  } = props;

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        // PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
          className="DialogTitle"
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="DialogContentText">
            {body}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="DialogActions">
          <Button
            autoFocus
            onClick={handleCloseDialog}
            className="DialogActionsBlack"
            sx={{ color: `${cancelColor}` }}
          >
            {cancelButton ? cancelButton : "Cancel"}
          </Button>
          {actionButtonN ? (
            <Button
              onClick={handleConfirmCloseDialog}
              className="DialogActionsgreen"
            >
              {actionButton ? actionButton : "Continue"}
            </Button>
          ) : (
            ""
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
