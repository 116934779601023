import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import "./OrderStatusDropdown.css";
import { useEffect } from "react";

let options = [
  { value: "in-progress", label: "In progress" },
  { value: "ready-to-pickup", label: "Ready for pickup" },
  { value: "out-for-delivery", label: "Out for delivery" },
  { value: "delivered", label: "Delivered" },
  { value: "cancelled", label: "Cancelled" },
  { value: "declined", label: "Declined" },
  { value: "scheduled", label: "Scheduled" },
];

let optionsNew = [
  {
    value: "in-progress",
    label: "In progress",
    color: "#FF6200",
    border: "#FF62003D",
  },
  {
    value: "ready-to-pickup",
    label: "Ready for pickup",
    color: "#0450E1",
    border: "#0450E13D",
  },
  {
    value: "out-for-delivery",
    label: "Out for delivery",
    color: "#7266FB",
    border: "#7266FB3D",
  },
  {
    value: "delivered",
    label: "Delivered",
    color: "#4C9F41",
    border: "#4C9F413D",
  },
  {
    value: "cancelled",
    label: "Cancelled",
    color: "#1B1B1F",
    border: "#1e1b1b1f",
  },
  {
    value: "declined",
    label: "Declined",
    color: "#1B1B1F",
    border: "#1e1b1b1f",
  },
  {
    value: "scheduled",
    label: "Scheduled",
    color: "#FFB703",
    border: "#fbf5e9",
  },
];

const OrderStatusDropdown = (props) => {
  const { orderDetails, changeStatus } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    changeStatus(options[index].value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   options.map((singleOption, index) => {
  //     if (singleOption.value == orderDetails?.orderStatus) {
  //       setSelectedIndex(index);
  //     }
  //   });
  // }, [orderDetails]);
  const filterOrderStatusOption = (filterStatus) => {
    return optionsNew?.filter((data) => data?.value !== filterStatus);
  };

  useEffect(() => {
    if (orderDetails?.orderType === "delivery") {
      optionsNew = filterOrderStatusOption("ready-to-pickup");
      options = filterOrderStatusOption("ready-to-pickup");
    }
    if (orderDetails?.orderType === "pickup") {
      optionsNew = filterOrderStatusOption("out-for-delivery");
      options = filterOrderStatusOption("out-for-delivery");
    }
    optionsNew?.map((singleOption, index) => {
      if (singleOption.value === orderDetails?.orderStatus) {
        // Change here
        setSelectedIndex(index);
      }
    });
  }, [orderDetails]);

  return (
    <div className="orderStatusContainer boxShadow">
      <List
        component="nav"
        aria-label="Device settings"
        sx={{
          bgcolor: optionsNew[selectedIndex].border,
          "& .MuiButtonBase-root.MuiListItem-root": {
            padding: "0 16px !important",
          },
        }}
      >
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
          sx={{
            "& .MuiTypography-root.MuiTypography-body2": {
              fontSize: "16px !important",
              color: optionsNew[selectedIndex].color,
            },
            "& .MuiTypography-root.MuiTypography-body3": {
              fontSize: "16px !important",
              color: optionsNew[selectedIndex].color,
            },
          }}
        >
          <ListItemText
            primary="Order status"
            secondary={options[selectedIndex].label}
          />
          <ArrowDropDownRoundedIcon />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {optionsNew.map((option, index) => (
          <MenuItem
            key={option.value}
            disabled={props.disabled}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
            sx={{
              "&:first-of-type": {
                color: optionsNew[index].color + " !important",
              },
              "&:not(:first-of-type)": {
                color: optionsNew[index].color + " !important",
              },
              "& p": {
                fontSize: "16px !important",
                backgroundColor:
                  index === 0
                    ? optionsNew[0].color + " !important"
                    : "green !important",
              },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default OrderStatusDropdown;
