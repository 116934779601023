import React from 'react';
import noOrder from '../../../../assets/imgs/undraw_searching.svg';
import './OrdersTablEmptyCard.css';

const OrdersTablEmptyCard = () => {
  return (
    <div className='ordersTablemptyCardContainer'>
      <img src={noOrder} alt="sectionImg" />
      <h2>No new orders</h2>
      <p>Start accepting orders</p>
    </div>
  )
}

export default OrdersTablEmptyCard