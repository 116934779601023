import React, { useState } from "react";
import Button from '@mui/material/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { styled } from "@mui/system";
import './Modals.css';






function ModalsTwo(props) {


  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    console.log('close')
    setOpen(false)
  };

  const handleConfirm = () => {
    console.log('close')
    setOpen(false)
  };

  const handleCopied = () => {
    console.log('Copied');
  };

{/* <Button onClick={() => handleOpen(true)}><AddRoundedIcon />Module</Button> */}

  return (
    <div className="ModalOneContainerMain">

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modalOneMaster"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className="ModalOneContainer" style={{ width: props.width }}>
            <div className="ModalHeader" style={{ background: props.Headerbackground }}>
              <div className="ModalTitle" style={{ color: props.Headercolor }}>{props.ModalTitle}</div>
              <span><CloseRoundedIcon className="ModaClose" onClick={() => props.handleClose()} style={{ color: props.Headercolor }} /></span>
            </div>

            <div className="ModalOneBody">
              {props.ModalBody}
            </div>
            {props.ModalFooter &&
              <div className="ModalOneFooter" style={{ background: props.Footerbackground }}>
                <div className="FooterButton">
                  {/* <Button variant="contained" className='btLight' onClick={() => props.handleClose()} >{props.ButtonClose}</Button> */}
                  <StyledButton variant="outlined" onClick={() => props.handleConfirm()} >{props.ButtonConfirm}</StyledButton>
                </div>
              </div>
            }

          </div>
        </Fade>

      </Modal>


      {/* <Modals
        ModalTitle="Add parameters"
        handleClose={handleClose}
        handleConfirm={() => handleConfirm()}
        handleClose={() => handleClose()}
        open={open}
        ButtonClose="CLOSE"
        ButtonConfirm="CONFIRM"
        width="570px"

        Headerbackground="#fff"
        Headercolor="#676C7B"
        Footerbackground="#F7F9FA"

        ModalBody={
          <div>BODY</div>
        }

      /> */}



    </div>
  );
}


export default ModalsTwo;

const StyledButton = styled(Button)(({ theme }) => ({
    margin: "0 10px",
    padding: "15px 0",
    width: "30%",
    color: "#fff",
    fontSize: "12px",
    background: "#4C9F41",
    border: "1px solid #E4E2E6",
    borderRadius: "1000px",
    gap: "10px",
    alignItems: "center",
    "&:hover": {
      border: "1px solid #4C9F41",
      background: "#4C9F41",
    },
  }));

