import React, {useState} from "react";
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import './FieldPassword.css';

function FieldPassword(props) {

  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  // const handleChange = (prop) => (event) => {
  //   setValues({ ...values, [prop]: event.target.value });
  // };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };




  return (
    <div className="TextField">
      <FormControl variant="outlined">
          <OutlinedInput
            placeholder={props.placeholder}
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={props.value}
            onChange={props.handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  style={{margin: '-.2rem 0 0 0'}}
                  sx={{
                    ".MuiInputAdornment-root .MuiIconButton-root": {
                      padding: "1px !important",
                  }
                  }}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>

        

    </div>
  );
}


export default FieldPassword;

