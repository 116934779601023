import React, { useState, useEffect } from "react";
import axios from "axios";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import StepLabel from "@mui/material/StepLabel";
import { Container } from "@mui/material";
import { styled } from "@mui/system";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import Buttons from "../../../components/Button/Button";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import BasicInfo from "./steps/BasicInfo/BasicInfo";
import "./MenuAddItemStapper.css";
import Hours from "./steps/Hours/Hours";
import Team from "./steps/Team/Team";
import { useNavigate } from "react-router-dom";
import {
  useCreateLocationMutation,
  useLazyCheckLocationNameQuery,
} from "../../../redux/features/locations/locationApi";
import useProtected from "../../../hooks/useProtected";
import { useGetUserListQuery } from "../../../redux/features/users/userApi";
import { useSelector } from "react-redux";
import Payment from "./steps/Payment/Payment";

// development related
// import { sendDataToAPI, retriveDataFromAPI } from '../../../../hooks/HelperFunctions'

function getSteps() {
  return ["Basic Info", "Hours", "Team", "Payment"];
}

function getCourseRelationalData(orgId, courseId) {
  // const result = retriveDataFromAPI('courses/getAddCourseRelatedData', `orgId=${orgId}&courseId=${courseId}`)
  // console.log(result);
  // return [];
  const baseUrl =
    process.env.REACT_APP_PROJECT_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;
  axios
    .get(
      `${baseUrl}/courses/getAddCourseRelatedData?orgId=${orgId}&courseId=${courseId}`
    )
    .then((result) => {
      console.log(result.data.body);
      return result.data.body;
    })
    .catch((err) => {
      console.log(err);
    });
}

function LocationStapper(props) {
  const activeLocation = useSelector((state) => state.auth.activeLocation);
  const user = useSelector((state) => state.auth.user);

  const [createLocation, createLocationOptions] = useCreateLocationMutation();

  const { data, isLoading, isFetching, error } = useGetUserListQuery(
    activeLocation,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useProtected(error);
  useProtected(createLocationOptions.error);

  const initialState = {
    name: "",
    address: "",
    zip: "",
    state: "",
    city: "",
    apartment: "",
    phone: "",
    createdBy: "",
    email: "",
    photo: "",
    photoName: "",
    locationCode: "",
    status: "Accepting orders",
    stripeKey: "",
    operatingHours: [
      {
        day: "Monday",
        startTime: "10:00 AM",
        endTime: "08:00 PM",
      },
      {
        day: "Tuesday",
        startTime: "10:00 AM",
        endTime: "08:00 PM",
      },
      {
        day: "Wednesday",
        startTime: "10:00 AM",
        endTime: "08:00 PM",
      },
      {
        day: "Thursday",
        startTime: "10:00 AM",
        endTime: "08:00 PM",
      },
      {
        day: "Friday",
        startTime: "10:00 AM",
        endTime: "08:00 PM",
      },
      {
        day: "Saturday",
        startTime: "10:00 AM",
        endTime: "08:00 PM",
      },
      {
        day: "Sunday",
        startTime: "10:00 AM",
        endTime: "08:00 PM",
      },
    ],
    teamMembers: [],
  };
  const initialStateError = {
    name: "",
    address: "",
    zip: "",
    state: "",
    city: "",
    apartment: "",
    email: "",
    phone: "",
    createdBy: "",
    locationCode: "",
    photo: "",
    status: "",
    operatingHours: [
      {
        day: "",
      },
      {
        day: "",
      },
      {
        day: "",
      },
      {
        day: "",
      },
      {
        day: "",
      },
      {
        day: "",
      },
      {
        day: "",
      },
    ],
    teamMembers: [],
    stripeKey: "",
  };

  const navigate = useNavigate();

  const [locationInfo, setLocationInfo] = useState(initialState);
  const [errors, setErrors] = useState(initialStateError);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [courseOverview, setCourseOverview] = useState({});
  const [courseRelationalData, setCourseRelationalData] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (!isLoading && !isFetching && data && activeLocation != "") {
      setUsers(data?.body?.data);
    }
  }, [data, isLoading, isFetching, activeLocation]);

  const steps = getSteps();

  // useEffect(() => {
  //   const baseUrl =
  //     process.env.REACT_APP_PROJECT_ENV === "prod"
  //       ? process.env.REACT_APP_API_URL_PROD
  //       : process.env.REACT_APP_API_URL_DEV;
  //   axios
  //     .get(`${baseUrl}/courses/getAddCourseRelatedData?orgId=1&courseId=0`)
  //     .then((result) => {
  //       // console.log(result.data.body);
  //       setCourseRelationalData(result.data.body);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  // console.log(courseRelationalData.courseLanguages);

  //=========================== check duplicate location name =================================//
  const [getDuplicateName] = useLazyCheckLocationNameQuery(
    (locationInfo?.name,
    {
      refetchOnMountOrArgChange: true,
    })
  );
  const [phoneError, setPhoneError] = useState("");
  //=========================== check duplicate location name =================================//

  const getStepContent = (step, courseRelationalData) => {
    switch (step) {
      case 0:
        //return <AccountType/>;
        return (
          <BasicInfo
            locationInfo={locationInfo}
            setLocationInfo={setLocationInfo}
            errors={errors}
            setErrors={setErrors}
            setPhoneError={setPhoneError}
          />
        );
      case 1:
        return (
          <Hours
            locationInfo={locationInfo}
            setLocationInfo={setLocationInfo}
            errors={errors}
            setErrors={setErrors}
          />
        );
      case 2:
        return (
          <Team
            locationInfo={locationInfo}
            setLocationInfo={setLocationInfo}
            errors={errors}
            setErrors={setErrors}
            users={users}
          />
        );
      case 3:
        return (
          <Payment
            locationInfo={locationInfo}
            setLocationInfo={setLocationInfo}
            errors={errors}
            setErrors={setErrors}
          />
        );
      default:
        return "Unknown step";
    }
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleFirstCheck = async () => {
    const checkLocationName = await getDuplicateName(locationInfo?.name);
    let errorObject = { ...errors };
    let error = 0;

    if (locationInfo?.name == "") {
      errorObject = { ...errorObject, name: "Required" };
      error++;
    } else if (checkLocationName?.data?.error) {
      errorObject = { ...errorObject, name: checkLocationName?.data?.message };
      error++;
    } else {
      errorObject = { ...errorObject, name: "" };
    }

    if (locationInfo?.phone == "") {
      errorObject = { ...errorObject, phone: "Required" };
      error++;
    } else if (phoneError) {
      errorObject = { ...errorObject, phone: phoneError };
      error++;
    } else {
      errorObject = { ...errorObject, phone: "" };
    }

    if (locationInfo?.address == "") {
      errorObject = { ...errorObject, address: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, address: "" };
    }

    if (locationInfo?.photo == "") {
      errorObject = { ...errorObject, photo: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, photo: "" };
    }

    if (locationInfo?.email == "") {
      errorObject = { ...errorObject, email: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, email: "" };
    }

    if (locationInfo?.city == "") {
      errorObject = { ...errorObject, city: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, city: "" };
    }

    if (locationInfo?.state == "") {
      errorObject = { ...errorObject, state: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, state: "" };
    }
    if (locationInfo?.locationCode == "") {
      errorObject = { ...errorObject, locationCode: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, locationCode: "" };
    }

    if (locationInfo?.zip == "") {
      errorObject = { ...errorObject, zip: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, zip: "" };
    }

    setErrors({ ...errors, ...errorObject });

    return error > 0 ? false : true;
  };

  const handleSecondCheck = async () => {
    let isBasicFormValid = await handleFirstCheck();
    // let error = 0;
    // let operatingHoursError = [];
    // locationInfo?.operatingHours?.map((singleOperatingHour, index) => {
    //   let newErrorObject = {
    //     day: "",
    //   };
    //   if (
    //     singleOperatingHour?.startTime != "Closed" &&
    //     singleOperatingHour?.endTime != "Closed"
    //   ) {
    //     let date1 = new Date("2000-01-01 " + singleOperatingHour?.startTime);
    //     let date2 = new Date("2000-01-01 " + singleOperatingHour?.endTime);

    //     if (date1 > date2) {
    //       newErrorObject.day =
    //         "The start time should be lower than the end time";
    //       error++;
    //     } else {
    //       newErrorObject.day = "";
    //     }
    //   }
    //   operatingHoursError.push(newErrorObject);
    // });

    // setErrors({ ...errors, operatingHours: operatingHoursError });

    return !isBasicFormValid ? false : true;
    // return error > 0 || !isBasicFormValid ? false : true;
  };

  const handleThirdCheck = async () => {
    return true;
  };

  const handleForuthCheck = async () => {
    let isBasicFormValid1 = await handleFirstCheck();
    let isBasicFormValid2 = await handleSecondCheck();
    let isBasicFormValid3 = await handleThirdCheck();
    let error = 0;
    let errorObject = { ...errors };
    if (locationInfo?.stripeKey == "") {
      errorObject = { ...errorObject, stripeKey: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, stripeKey: "" };
    }
    setErrors({ ...errors, ...errorObject });
    return error > 0 ||
      (!isBasicFormValid1 && !isBasicFormValid3 && !isBasicFormValid2)
      ? false
      : true;
  };

  const getStructuredData = async () => {
    const {
      name,
      address,
      zip,
      state,
      city,
      apartment,
      phone,
      createdBy,
      photo,
      status,
      operatingHours,
      teamMembers,
      stripeKey,
      photoName,
      locationCode,
      email,
    } = locationInfo;

    let newOperatingHours = [];
    operatingHours?.map((singleOperatingHour) => {
      if (
        singleOperatingHour?.startTime == "Closed" ||
        singleOperatingHour?.endTime == "Closed"
      ) {
        newOperatingHours.push({
          day: singleOperatingHour?.day,
          startTime: "Closed",
          endTime: "Closed",
        });
      } else if (
        singleOperatingHour?.startTime == "24/7 hours" ||
        singleOperatingHour?.endTime == "24/7 hours"
      ) {
        newOperatingHours.push({
          day: singleOperatingHour?.day,
          startTime: "24/7 hours",
          endTime: "24/7 hours",
        });
      } else {
        newOperatingHours.push(singleOperatingHour);
      }
    });

    let newTeamMembers = teamMembers?.map((singleMember) => singleMember._id);

    return {
      name,
      address,
      zip,
      state,
      city,
      apartment,
      phone,
      createdBy,
      photo,
      status,
      stripeKey,
      operatingHours: newOperatingHours,
      teamMembers: newTeamMembers,
      photoName,
      locationCode,
      email,
    };
  };

  const addLocationHandler = async () => {
    let data = await getStructuredData();

    let result = await createLocation({ ...data });

    if (result?.data?.body?.data) {
      // setSnakbarOpen(true);
      navigate("/location-listing");
    } else {
      console.error("something went wrong");
    }
  };
  const handleNext = async () => {
    if (activeStep == 0) {
      let isValid = await handleFirstCheck();
      if (!isValid) {
        return false;
      }
    }
    if (activeStep == 1) {
      let isValid = await handleSecondCheck();
      if (!isValid) {
        return false;
      }
    }
    if (activeStep == 2) {
      let isValid = await handleThirdCheck();
      if (!isValid) {
        return false;
      }
      // await addLocationHandler();
      // return false;
    }

    if (activeStep == 3) {
      let isValid = await handleForuthCheck();
      if (!isValid) {
        return false;
      }
      await addLocationHandler();
      return false;
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep == 0) {
      console.log(courseOverview);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // breadcrum
  const breadcrumbsData = [
    {
      label: "Locations",
      link: "/location-listing",
      icon: <LocationOnRoundedIcon fontSize="small" />,
    },

    {
      label: "Add new",
      link: "",
    },
  ];

  // useEffect(() => {
  //   console.log("locationInfo:", locationInfo);
  //   console.log("errors:", errors);
  // }, [locationInfo, errors]);

  return (
    <>
      <div className="stapperHeaderContainer">
        <Container>
          <div className="stapperHeaderBox">
            <Buttons
              label="Exit"
              buttonColor="transparent"
              color="#4C9F41"
              height="100%"
              fontSize="14px"
              iconStart={
                <ClearRoundedIcon
                  sx={{ mr: "8px !important", color: "#4C9F41 !important" }}
                  fontSize="small"
                />
              }
              link="/location-listing"
            />
            <p
              style={{
                margin: "0",
                flexGrow: "1",
                fontSize: "22px",
                textAlign: "center",
              }}
            >
              Add location
            </p>
          </div>
        </Container>
      </div>
      <Container>
        <div className="FormStepper">
          <Breadcrumbs breadcrumbsData={breadcrumbsData} />
          <div className="StapperContaier">
            <div className="StapperBody Section">
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  // if (isStepOptional(index)) {
                  //   labelProps.optional = <Typography variant="caption">Optional</Typography>;
                  // }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </div>

            <div className="StapperContent">
              {activeStep === steps.length ? (
                <div>
                  <Typography>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Button onClick={handleReset}>Reset</Button>
                </div>
              ) : (
                <div>
                  <Typography>
                    {getStepContent(activeStep, courseRelationalData)}
                  </Typography>
                  <div className="SpperButtonContainer">
                    <div className="StepperButton">
                      <StyledBackButton
                        disabled={activeStep === 0}
                        onClick={handleBack}
                      >
                        {activeStep == 0 ? "Cancel" : "Back"}
                      </StyledBackButton>
                      {/* {isStepOptional(activeStep) && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSkip}
                        >
                          Skip
                        </Button>
                      )} */}

                      <StyledBackContinue
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        {activeStep === steps.length - 1
                          ? "Finish"
                          : "Continue"}
                      </StyledBackContinue>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default LocationStapper;

const StyledBackButton = styled(Button)(({ theme }) => ({
  margin: "0 5px",
  padding: "10px 0",
  width: "98px",
  color: "#4C9F41",
  fontSize: "12px",
  background: "#fff",
  border: "1px solid #E4E2E6",
  borderRadius: "1000px",
  gap: "10px",
  alignItems: "center",
  "&:hover": {
    color: "#fff",
    border: "1px solid #4C9F41",
    background: "#4C9F41",
  },
}));

const StyledBackContinue = styled(Button)(({ theme }) => ({
  margin: "0 5px",
  padding: "10px 0",
  width: "98px",
  color: "#fff",
  fontSize: "12px",
  background: "#4C9F41",
  // border: "1px solid #E4E2E6",
  boxShadow: "none",
  borderRadius: "1000px",
  gap: "10px",
  alignItems: "center",
  "&:hover": {
    color: "#fff",
    boxShadow: "none",
    border: "1px solid #4C9F41",
    background: "#4C9F41",
  },
}));
