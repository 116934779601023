import React from "react";
import "./OrderDetails.css";
import { capitalizeFirstLetter } from "../../../hooks/HelperFunctions";

const OrderDetails = (props) => {
  const { orderDetails } = props;
  const taxData = process.env.REACT_APP_TAX_VALUE;
  const totalTax = (
    (orderDetails?.orderSubTotal * parseFloat(taxData)) /
    100
  )?.toFixed(2);

  const showOrderItemsDetails = () => {
    let itemsToShow = [];

    if (orderDetails?.orderItems?.length > 0) {
      orderDetails?.orderItems?.map((singleItem, index) => {
        itemsToShow.push(
          <div key={index} className="orderlistParentContainer">
            <p>{singleItem?.quantity || "N/A"}x</p>
            <div style={{ width: "100%" }}>
              <div className="orderlistItemDetailsContainer">
                <h2>{capitalizeFirstLetter(singleItem?.menuName) || "N/A"}</h2>
                {/* item info */}
                <div className="orderlistItemInfo">
                  <span>Choose size</span>
                  {singleItem?.variation &&
                    Object.keys(singleItem?.variation).length > 0 && (
                      <p>
                        {singleItem?.variation?.name
                          ? capitalizeFirstLetter(singleItem?.variation?.name)
                          : "N/A"}
                      </p>
                    )}
                </div>
                {singleItem?.specialInstructions &&
                  singleItem?.specialInstructions != "" && (
                    <div className="orderlistItemInfo">
                      <span>Special instruction</span>
                      <p>
                        <b>{singleItem?.specialInstructions}</b>
                      </p>
                    </div>
                  )}
              </div>
            </div>
            <p>
              {singleItem?.variation?.price
                ? Object.keys(singleItem?.variation).length > 0
                  ? `$${(
                      singleItem?.variation?.price * singleItem?.quantity
                    ).toFixed(2)}`
                  : `$${(singleItem?.price * singleItem?.quantity).toFixed(2)}`
                : `$${(singleItem?.price * singleItem?.quantity).toFixed(2)}`}
            </p>
          </div>
        );
      });
    }

    return itemsToShow;
  };
  return (
    <div className="orderDetailsGradParent boxShadow">
      {showOrderItemsDetails()}

      {/* list footer */}
      <div className="listFooterParentContainer">
        <div className="listFooterContainer">
          <div className="listFooterItem">
            <p>Subtotal</p>
            <p>${orderDetails?.orderSubTotal?.toFixed(2)}</p>
          </div>
          <div className="listFooterItem">
            <p>NYS Sales Tax ({taxData}%)</p>
            <p>${totalTax || "0.00"}</p>
          </div>
          {orderDetails?.orderType !== "pickup" && (
            <div className="listFooterItem">
              <p>Delivery fee</p>
              <p>$ {orderDetails?.deliveryFee?.toFixed(2) || "O.00"}</p>
            </div>
          )}

          <div className="listFooterItem">
            <p>
              <b style={{ margin: "0" }}>Total</b>
            </p>
            <p>
              <b>
                $
                {parseFloat(
                  orderDetails?.orderTotal + parseFloat(totalTax)
                )?.toFixed(2) || "0.00"}
              </b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
