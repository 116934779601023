import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import TeamMemberDetails from "./details/TeamMemberDetails";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import SideBarDrawer from "../../components/SideBarDrawer/SideBarDrawer";
import { useParams } from "react-router-dom";
import {
  useGetCustomerDetailsQuery,
  useGetCustomerOrdersQuery,
} from "../../redux/features/customers/customerApi";
import useProtected from "../../hooks/useProtected";

const CustomersDetails = () => {
  const { customer_id } = useParams();

  const { data, isLoading, isFetching, error } = useGetCustomerDetailsQuery(
    customer_id,
    {
      skip: !customer_id,
      refetchOnMountOrArgChange: true,
    }
  );
  const {
    data: customerOrdersData,
    isLoading: customerOrdersIsLoading,
    isFetching: customerOrdersIsFetching,
    error: customerOrdersError,
  } = useGetCustomerOrdersQuery(customer_id, {
    skip: !customer_id,
    refetchOnMountOrArgChange: true,
  });

  useProtected(error);
  useProtected(customerOrdersError);

  // menu
  const [open, setOpen] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({});
  const [customerOrders, setCustomerOrders] = useState([]);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const breadcrumbsData = [
    {
      label: "Customers",
      link: "/customers-listing",
      icon: <GroupsRoundedIcon fontSize="small" />,
    },

    {
      label: "Details",
      link: "/customer-details",
    },
  ];

  useEffect(() => {
    if (!isLoading && !isFetching && data) {
      setCustomerDetails(data?.body?.data || {});
    }
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    if (
      !customerOrdersIsLoading &&
      !customerOrdersIsFetching &&
      customerOrdersData
    ) {
      setCustomerOrders(customerOrdersData?.body?.data || []);
    }
  }, [customerOrdersData, customerOrdersIsLoading, customerOrdersIsFetching]);

  return (
    <>
      <Container sx={{ mt: 14 }}>
        {/* <Breadcrumbs breadcrumbsData={breadcrumbsData} /> */}
        <TeamMemberDetails
          customerDetails={customerDetails}
          customerOrders={customerOrders}
        />
      </Container>
      <div>
        <div className="DesktopView">
          <Header />
          <Sidebar />
        </div>

        <div className="ResponsiveMenu">
          <Header
            IconMenu={
              <a onClick={handleDrawerOpen}>
                <MenuRoundedIcon />
              </a>
            }
          />
          <SideBarDrawer
            open={open}
            handleDrawerClose={handleDrawerClose}
            DrawerBody={<Sidebar />}
          />
        </div>
      </div>
    </>
  );
};

export default CustomersDetails;
