import { useState } from "react";
// import Link from "next/link";
import { Box, Grid, Radio } from "@mui/material";
import "./RoleModal.css";

const RoleModal = (props) => {
  const [selectedValue, setSelectedValue] = useState(1);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item.id,
    onChange: handleChange,
    value: item.id,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item.label },
  });

  const handleClick = (item) => {
    setSelectedValue(item.id);

    props.onSelectRole(item.label);
  };

  const getBoxClassName = (item) => {
    return selectedValue === item.id
      ? `activeBox styledBox`
      : `deActiveBox styledBox`;
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {props.locations.map((location) => (
          <Grid item xs={12} key={location.id}>
            <Box
              className={getBoxClassName(location)}
              onClick={() => handleClick(location)}
            >
              <Box className="addInfo">
                <h2>{location.label}</h2>
                <span>{location.address}</span>
                {/* <a href={location.link}>More info</a> */}
              </Box>
              <Radio {...controlProps(location)} color="success" />
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default RoleModal;
