import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import "./AddNewUserDropDown.css";
import { useEffect } from "react";
import { toast } from "react-toastify";

const options = ["Active", "Inactive"];

const AddNewUserDropDown = (props) => {
  const { userInfo, setUserInfo } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (userInfo && userInfo?._id != "") {
      options?.map((singleOption, index) => {
        if (singleOption.toLowerCase() == userInfo?.status) {
          setSelectedIndex(index);
        }
      });
    }
  }, [userInfo]);

  const handleMenuItemClick = (event, index) => {
    setUserInfo({ ...userInfo, status: options[index] });
    setSelectedIndex(index);
    toast.success("User status has updated successfully");
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="orderStatusContainer boxShadow">
      <List
        component="nav"
        aria-label="Device settings"
        sx={{
          // bgcolor: "background.paper",
          bgcolor: selectedIndex === 0 ? "#4C9F413D" : "#FF62003D",
          "& .MuiButtonBase-root.MuiListItem-root": {
            padding: "0 16px !important",
          },
        }}
      >
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
        >
          <ListItemText
            primary="Status"
            secondary={options[selectedIndex]}
            sx={{
              "& .MuiTypography-root.MuiTypography-body1": {
                fontSize: "12px !important",
                color: "#45464F !important",
                // color: selectedIndex === 0 ? "green" : "#FF6200",
              },
              "& .MuiTypography-root.MuiTypography-body2": {
                fontSize: "16px !important",
                // color: "#FF6200",
                color: selectedIndex === 0 ? "green" : "#FF6200",
              },
            }}
          />
          <ArrowDropDownRoundedIcon />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
        sx={{
          ".MuiPaper-root.MuiPaper-elevation ": {
            width: "42%",
            borderRadius: "8px",
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            //   disabled={index === 0}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
            sx={{
              "&:first-of-type": {
                color: "green !important", // Orange background for the first index
              },
              "&:not(:first-of-type)": {
                color: "#FF6200 !important", // Green background for other indices
              },
              "& p": {
                fontSize: "16px !important",
                backgroundColor:
                  index === 0 ? "green !important" : "#FF6200 !important",
              },
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default AddNewUserDropDown;
