// OrdersModal
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ModalDropDown from "./ModalDropDown/ModalDropDown";
import PrintIcon from "@mui/icons-material/Print";
import Sms from "../../../assets/imgs/sms.svg";
import LocalTranport from "../../../assets/imgs/local_shipping.svg";
import Location from "../../../assets/imgs/location-new.svg";
import Buttons from "../../Button/Button";
import { styled } from "@mui/system";
import "./OrdersModal.css";
import Modals from "../../Form/Modals/Modals";
import SelectField from "../../Form/SelectField/SelectField";
import TextField from "../../Form/TextField/TextField";
import { Avatar, CircularProgress, Grid } from "@mui/material";
import ModalsTwo from "../../Form/Modals/ModalsTwo";
import {
  useChangeOrderStatusMutation,
  useGetOrderDetailsQuery,
  useUpdateOrderMutation,
} from "../../../redux/features/orders/orderApi";
import useProtected from "../../../hooks/useProtected";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "../../../hooks/HelperFunctions";
import moment from "moment-timezone";
import { useGetLocationListQuery } from "../../../redux/features/locations/locationApi";
import { useSelector } from "react-redux";

function OrdersModal(props) {
  const {
    orderId,
    setOpenModal,
    setActiveData,
    Scheduled,
    setGetNewOrderId,
    DeliveryInstruction,
    DeliveryInstructionTitle,
    DeliveryInstructionParagraph,
    setScheduled,
    setDeliveryInstructionData,
    setLoaderShow,
  } = props;

  const { data, isLoading, isFetching, error } = useGetOrderDetailsQuery(
    orderId,
    {
      skip: !orderId,
      refetchOnMountOrArgChange: true,
    }
  );

  const [changeOrderStatus, changeOrderStatusOptions] =
    useChangeOrderStatusMutation();
  const [updateOrder, updateOrderOptions] = useUpdateOrderMutation();

  useProtected(error);
  useProtected(updateOrderOptions.error);
  useProtected(changeOrderStatusOptions.error);

  const [textValue, setTextValue] = useState("");
  const [orderDetails, setOrderDetails] = useState({});
  const [openCancelModal, setOpencancelModal] = useState(false);

  // useEffect(() => {
  //   if (changeOrderStatusOptions?.isLoading) {
  //     setOpen(true);
  //   } else {
  //     setOpen(false);
  //   }
  // }, [changeOrderStatusOptions]);

  // text feild
  const textFeildhandleChanges = (event) => {
    setTextValue(event.target.value);
  };

  const handleOpenCancelModal = () => {
    setOpencancelModal(true);
  };

  const handleCloseCancelModal = () => {
    console.log("close");
    setOpencancelModal(false);
  };

  //    cancel modal functions and arr

  const [orderCancel, setOrderCancel] = useState("");
  const handleChanges = (event) => {
    setOrderCancel(event.target.value);
  };

  const Canceloptions = [
    {
      label: "Out of item(s)",
      value: "Out of item(s)",
    },

    {
      label: "Kitchen closed",
      value: "Kitchen closed",
    },

    {
      label: " Customer called to cancel",
      value: " Customer called to cancel",
    },

    {
      label: "Restaurant too busy",
      value: "Restaurant too busy",
    },

    {
      label: "Can't complete customer note",
      value: "Can't complete customer note",
    },

    {
      label: "Other",
      value: "Other",
    },
  ];
  // * ==== print func ==== * //
  const taxData = process.env.REACT_APP_TAX_VALUE;
  const totalTax = (
    (orderDetails?.orderSubTotal * parseFloat(taxData)) /
    100
  )?.toFixed(2);
  const handlePrint = () => {
    const printWindow = window.open("", "_blank");

    const orderItemsHTMLtwo = orderDetails?.orderItems
      ? orderDetails.orderItems
          .map(
            (item) => `
            <tr>
            <td>
                <table>
                    <tr style="font-size: 22px;">
                        <td align="justify" style="display: flex;align-items: flex-start;"><span>${
                          item.quantity
                        }x</span></td>
                        <td style="width: 100%;">
                            <b style="font-weight: 500; display: block;">${capitalizeFirstLetter(
                              item.menuName
                            )}</b>
                            <span style=" display: block;"> ${
                              item?.variation?.name
                                ? capitalizeFirstLetter(item?.variation?.name)
                                : ""
                            }</span>
                            <span>${
                              item?.specialInstructions
                                ? item?.specialInstructions
                                : ""
                            }</span>
                        </td>
                        <td align="right" style="width: 100%; display: flex;align-items: flex-start; justify-content:flex-end">
                        <b style="width: 100px;">
                                ${
                                  item?.variation?.price
                                    ? Object.keys(item?.variation).length > 0
                                      ? `$${(
                                          item?.variation?.price *
                                          item?.quantity
                                        )?.toFixed(2)}`
                                      : `$${(
                                          item?.price * item?.quantity
                                        )?.toFixed(2)}`
                                    : `$${(
                                        item?.price * item?.quantity
                                      )?.toFixed(2)}`
                                }
                                </b>
                        </td>
                        <!-- <td align="right">
                            <b></b>
                        </td> -->
                    </tr>
                </table>
            </td>
          </tr>
          <tr>
              <td style="background-color: #e4e2e6;"></td>
          </tr>
    `
          )
          .join("")
      : "";

    const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">

    <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <!-- font family -->
        <link href="https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&amp;display=swap"
            rel="stylesheet">
        <!-- font family -->
        <style type="text/css" media="screen">
            body {
                margin: 0 !important;
                padding: 0 !important;
                width: 100% !important;
                box-sizing: border-box !important;
                background-color: #FFFFFF !important;
                font-family: Poppins !important;
                -webkit-text-size-adjust: none
            }
        </style>
        <title>Order Receipt</title>
    </head>

    <body>
        <table style="margin: 0 auto; width: 700px; border-collapse: collapse; font-family: Poppins !important;">
            <tbody>
                <tr>
                    <td><br></td>
                </tr>
                <tr>
                    <td style="font-size: 28px; font-weight: 600;">${
                      orderDetails?.locationName
                    }</td>
                </tr>
                <tr>
                    <td>
                        <table>
                            <tr style="font-size: 22px;">
                                <td>${orderDetails?.orderNumber}</td>
                                <td>&nbsp;&nbsp;</td>
                                <td>${moment(orderDetails?.createdAt)?.format(
                                  "MMM DD, YYYY, h:mm A"
                                )}</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table style="width: 100%; background-color: black; color: #fff; padding: 0 12px;">
                            <tr width="100%">
                                <td width="50%" style="font-size: 32px; font-weight: 600;">${orderDetails?.orderType?.toUpperCase()}</td>
                                <td width="50%" style=" width: 100%;font-size: 18px;" align="right"> 
                                <div style="display: flex; align-items: center;">
                                    <img src="https://radhuni-public-assets.s3.amazonaws.com/dev/radhuni/media/white-location-filled.svg"
                                        alt="email icon" height="40px" width="40px">
                                    <b style=" width: 100% !important; flex-grow: 1; text-align:left;"> 
                                        ${
                                          orderDetails?.orderType === "pickup"
                                            ? orderDetails?.locationName
                                            : `  <span style="font-weight: 500;">
                                      ${
                                        orderDetails?.customerAddress || "N/A"
                                      }, <br/>
                                             ${
                                               orderDetails?.customerApartmentInfo
                                                 ? orderDetails.customerApartmentInfo +
                                                   "<br/>"
                                                 : ""
                                             }
                                      ${
                                        orderDetails?.customerCity || "N/A"
                                      },  ${
                                                orderDetails?.customerState ||
                                                "N/A"
                                              } ${
                                                orderDetails?.customerZip ||
                                                "N/A"
                                              }
                                    </span>`
                                        }
                                    </b>
                                </div>
                               
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td align="left" style="padding: 16px 0;">
                        <p style="margin: 0px;font-size: 45px; font-weight: 400;">${
                          orderDetails?.customerName || "N/A"
                        }</p>
                    </td>
                </tr>
           

                <tr>
                    <td>
                        <table>
                            <tr>
                                <td style="display: flex; align-items: center;">
                                    <img src="https://radhuni-public-assets.s3.amazonaws.com/dev/radhuni/media/call_black.svg"
                                        alt="email icon" height="40px" width="40px">
                                </td>
                                <td align="left" style="font-size: 28px; font-weight: 400;">&nbsp;<b
                                        style="font-weight: 500;">${
                                          orderDetails?.phoneNumberFormatted
                                        }</b>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table>
                            <tr>
                                <td style="display: flex; align-items: center;">
                                    <img src="https://radhuni-public-assets.s3.amazonaws.com/dev/radhuni/media/email_black.svg"
                                        alt="email icon" height="40px" width="40px">
                                </td>
                                <td align="left" style="font-size: 28px; font-weight: 400;">
                                    &nbsp;<b style="font-weight: 500;">${
                                      orderDetails?.customerEmail
                                    }</b>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td><br></td>
                </tr>
                <tr>
                    <td style="background-color: #e4e2e6;"></td>
                </tr>
                <tr>
                    <td><br></td>
                </tr>
                <tr>
                    <td style="font-size: 22px; font-weight: 500;">
                        <span>Instructions:</span>
                    </td>
                </tr>
              
                <tr>
                    <td>
                        <span>${orderDetails?.orderNote || "N/A"}</span>
                    </td>
                </tr>
                <tr>
                <td><br></td>
            </tr>

                <tr>
                <td style="background-color: #e4e2e6;"></td>
            </tr>
                ${orderItemsHTMLtwo}
                <tr>
                    <td><br></td>
                </tr>
               
              
               
                <tr>
                    <td>
                        <table style="width: 100%; font-size: 22px;">
                            <tr width="100%">
                                <td width="50%">Subtotal</td>
                                <td width="50%" align="right">$${
                                  orderDetails?.orderSubTotal?.toFixed(2) ||
                                  "N/A"
                                }</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table style="width: 100%; font-size: 22px;">
                            <tr width="100%">
                                <td width="50%">NYS Sales Tax (${taxData}%)</td>
                                <td width="50%" align="right">$${
                                  parseFloat(totalTax)?.toFixed(2) || "0.00"
                                }</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                ${
                  orderDetails?.orderType !== "pickup"
                    ? `<tr>
                    <td>
                      <table style="width: 100%; font-size: 22px;">
                        <tr width="100%">
                          <td width="50%">Delivery fee</td>
                          <td width="50%" align="right">
                            $${orderDetails?.deliveryFee?.toFixed(2) || "N/A"}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>`
                    : ``
                }
                <tr>
                    <td>
                        <table style="width: 100%; font-size: 22px; font-weight: 500;">
                            <tr width="100%">
                                <td width="50%">Total</td>
                                <td width="50%" align="right">$${
                                  parseFloat(
                                    orderDetails?.orderTotal +
                                      parseFloat(totalTax)
                                  )?.toFixed(2) || "0.00"
                                }</td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </body>

    </html>
    `;

    printWindow.document.write(htmlContent);

    printWindow.document.close();
    printWindow.print();
  };
  // const handlePrint = () => {
  //   // window.print();
  //   // window.addEventListener("afterprint", () => {});

  //   const printWindow = window.open("", "_blank");
  //   const orderItemsHTML = orderDetails?.orderItems
  //     ? orderDetails.orderItems
  //         .map(
  //           (item) => `
  //       <tr style="width: 100%; border-top: 1px solid #E4E2E6; border-bottom: 1px solid #E4E2E6; font-size: 22px;">
  //         <td style="white-space: nowrap;">
  //           <span>${item.quantity}x</span>
  //         </td>
  //         <td>
  //           <b>${capitalizeFirstLetter(item.menuName)}</b><br>
  //           <span style="font-weight: 400; color:#1B1B1F;">
  //                 ${
  //                   item?.variation?.name
  //                     ? capitalizeFirstLetter(item?.variation?.name)
  //                     : ""
  //                 }
  //               </span>

  //         </td>
  //         <td align="right">${
  //           item?.variation?.price
  //             ? Object.keys(item?.variation).length > 0
  //               ? `$${(item?.variation?.price * item?.quantity)?.toFixed(2)}`
  //               : `$${(item?.price * item?.quantity)?.toFixed(2)}`
  //             : `$${(item?.price * item?.quantity)?.toFixed(2)}`
  //         }</td>
  //       </tr>
  //   `
  //         )
  //         .join("")
  //     : "";

  //   const orderItemsHTMLtwo = orderDetails?.orderItems
  //     ? orderDetails.orderItems
  //         .map(
  //           (item) => `
  //           <tr>
  //           <td>
  //               <table>
  //                   <tr style="font-size: 22px;">
  //                       <td align="justify" style="display: flex;align-items: flex-start;"><span>${
  //                         item.quantity
  //                       }x</span></td>
  //                       <td style="width: 100%;">
  //                           <b style="font-weight: 500; display: block;">${capitalizeFirstLetter(
  //                             item.menuName
  //                           )}</b>
  //                           <span style=" display: block;"> ${
  //                             item?.variation?.name
  //                               ? capitalizeFirstLetter(item?.variation?.name)
  //                               : ""
  //                           }</span>
  //                           <span>${
  //                             item?.specialInstructions
  //                               ? item?.specialInstructions
  //                               : ""
  //                           }</span>
  //                       </td>
  //                       <td align="right" style="width: 100%; display: flex;align-items: flex-start; justify-content:flex-end">
  //                       <b style="width: 100px;">
  //                               ${
  //                                 item?.variation?.price
  //                                   ? Object.keys(item?.variation).length > 0
  //                                     ? `$${(
  //                                         item?.variation?.price *
  //                                         item?.quantity
  //                                       )?.toFixed(2)}`
  //                                     : `$${(
  //                                         item?.price * item?.quantity
  //                                       )?.toFixed(2)}`
  //                                   : `$${(
  //                                       item?.price * item?.quantity
  //                                     )?.toFixed(2)}`
  //                               }
  //                               </b>
  //                       </td>
  //                       <!-- <td align="right">
  //                           <b></b>
  //                       </td> -->
  //                   </tr>
  //               </table>
  //           </td>
  //         </tr>
  //         <tr>
  //             <td style="background-color: #e4e2e6;"></td>
  //         </tr>
  //   `
  //         )
  //         .join("")
  //     : "";

  //   const htmlContent = `
  //     <!DOCTYPE html>
  //     <html lang="en">

  //     <head>
  //         <meta charset="UTF-8">
  //         <meta http-equiv="X-UA-Compatible" content="IE=edge">
  //         <meta name="viewport" content="width=device-width, initial-scale=1.0">
  //         <!-- font family -->
  //         <link href="https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&amp;display=swap"
  //             rel="stylesheet">
  //         <!-- font family -->
  //         <style type="text/css" media="screen">
  //             body {
  //                 margin: 0 !important;
  //                 padding: 0 !important;
  //                 width: 100% !important;
  //                 box-sizing: border-box !important;
  //                 background-color: #FFFFFF !important;
  //                 font-family: Poppins !important;
  //                 -webkit-text-size-adjust: none
  //             }
  //         </style>
  //         <title>Order Receipt</title>
  //     </head>

  //     <body>
  //         <table style="margin: 0 auto; width: 700px; border-collapse: collapse; font-family: Poppins !important;">
  //             <tbody>
  //                 <tr>
  //                     <td><br></td>
  //                 </tr>
  //                 <tr>
  //                     <td style="font-size: 28px; font-weight: 600;">${
  //                       orderDetails?.locationName
  //                     }</td>
  //                 </tr>
  //                 <tr>
  //                     <td>
  //                         <table>
  //                             <tr style="font-size: 22px;">
  //                                 <td>${orderDetails?.orderNumber}</td>
  //                                 <td>&nbsp;&nbsp;</td>
  //                                 <td>${moment(orderDetails?.createdAt)?.format(
  //                                   "MMM DD, YYYY, h:mm A"
  //                                 )}</td>
  //                             </tr>
  //                         </table>
  //                     </td>
  //                 </tr>
  //                 <tr>
  //                     <td>
  //                         <table style="width: 100%; background-color: black; color: #fff; padding: 0 12px;">
  //                             <tr width="100%">
  //                                 <td width="50%" style="font-size: 32px; font-weight: 600;">${orderDetails?.orderType?.toUpperCase()}</td>
  //                                 <td width="50%" style="font-size: 32px;" align="right">PLACED AT <b>${moment(
  //                                   orderDetails?.createdAt
  //                                 )?.format("h:mm A")}</b>
  //                                 </td>
  //                             </tr>
  //                         </table>
  //                     </td>
  //                 </tr>
  //                 <tr>
  //                     <td align="left" style="padding: 16px 0;">
  //                         <p style="margin: 0px;font-size: 45px; font-weight: 400;">${
  //                           orderDetails?.customerName || "N/A"
  //                         }</p>
  //                     </td>
  //                 </tr>
  //                 ${
  //                   orderDetails?.customerCity
  //                     ? `                <tr>
  //                 <td>
  //                     <table>
  //                         <tr>
  //                             <td style="display: flex; align-items: center;">
  //                                 <img src="https://radhuni-public-assets.s3.amazonaws.com/dev/radhuni/media/location-filled.svg"
  //                                     alt="email icon" height="40px" width="40px">
  //                             </td>
  //                             <td align="left" style="font-size: 28px; font-weight: 400;">&nbsp;
  //                                 <span style="font-weight: 500;">
  //                                 ${orderDetails?.customerAddress || "N/A"}, ${
  //                         orderDetails?.customerState || "N/A"
  //                       }, ${orderDetails?.customerCity || "N/A"} ${
  //                         orderDetails?.customerZip || "N/A"
  //                       }
  //                                 </span>
  //                             </td>
  //                         </tr>
  //                     </table>
  //                 </td>
  //             </tr>`
  //                     : ""
  //                 }

  //                 <tr>
  //                     <td>
  //                         <table>
  //                             <tr>
  //                                 <td style="display: flex; align-items: center;">
  //                                     <img src="https://radhuni-public-assets.s3.amazonaws.com/dev/radhuni/media/call_black.svg"
  //                                         alt="email icon" height="40px" width="40px">
  //                                 </td>
  //                                 <td align="left" style="font-size: 28px; font-weight: 400;">&nbsp;<b
  //                                         style="font-weight: 500;">${
  //                                           orderDetails?.phoneNumberFormatted
  //                                         }</b>
  //                                 </td>
  //                             </tr>
  //                         </table>
  //                     </td>
  //                 </tr>
  //                 <tr>
  //                     <td>
  //                         <table>
  //                             <tr>
  //                                 <td style="display: flex; align-items: center;">
  //                                     <img src="https://radhuni-public-assets.s3.amazonaws.com/dev/radhuni/media/email_black.svg"
  //                                         alt="email icon" height="40px" width="40px">
  //                                 </td>
  //                                 <td align="left" style="font-size: 28px; font-weight: 400;">
  //                                     &nbsp;<b style="font-weight: 500;">${
  //                                       orderDetails?.customerEmail
  //                                     }</b>
  //                                 </td>
  //                             </tr>
  //                         </table>
  //                     </td>
  //                 </tr>
  //                 <tr>
  //                     <td><br></td>
  //                 </tr>
  //                 <tr>
  //                     <td style="background-color: #e4e2e6;"></td>
  //                 </tr>
  //                 <tr>
  //                     <td><br></td>
  //                 </tr>
  //                 <tr>
  //                     <td style="font-size: 22px; font-weight: 500;">
  //                         <span>Instructions:</span>
  //                     </td>
  //                 </tr>

  //                 <tr>
  //                     <td>
  //                         <span>${orderDetails?.orderNote || "N/A"}</span>
  //                     </td>
  //                 </tr>
  //                 <tr>
  //                 <td><br></td>
  //             </tr>

  //                 <tr>
  //                 <td style="background-color: #e4e2e6;"></td>
  //             </tr>
  //                 ${orderItemsHTMLtwo}
  //                 <tr>
  //                     <td><br></td>
  //                 </tr>

  //                 <tr>
  //                     <td>
  //                         <table style="width: 100%; font-size: 22px;">
  //                             <tr width="100%">
  //                                 <td width="50%">Subtotal</td>
  //                                 <td width="50%" align="right">$${
  //                                   orderDetails?.orderSubTotal?.toFixed(2) ||
  //                                   "N/A"
  //                                 }</td>
  //                             </tr>
  //                         </table>
  //                     </td>
  //                 </tr>
  //                 <tr>
  //                 <td>
  //                     <table style="width: 100%; font-size: 22px;">
  //                         <tr width="100%">
  //                             <td width="50%">NYS Sales Tax (${taxData}%)</td>
  //                             <td width="50%" align="right">$${
  //                               parseFloat(totalTax)?.toFixed(2) || "0.00"
  //                             }</td>
  //                         </tr>
  //                     </table>
  //                 </td>
  //             </tr>
  //             ${
  //               orderDetails?.orderType !== "pickup"
  //                 ? `<tr>
  //                 <td>
  //                   <table style="width: 100%; font-size: 22px;">
  //                     <tr width="100%">
  //                       <td width="50%">Delivery fee</td>
  //                       <td width="50%" align="right">
  //                         $${orderDetails?.deliveryFee?.toFixed(2) || "N/A"}
  //                       </td>
  //                     </tr>
  //                   </table>
  //                 </td>
  //               </tr>`
  //                 : ``
  //             }

  //                 <tr>
  //                     <td>
  //                         <table style="width: 100%; font-size: 22px; font-weight: 500;">
  //                             <tr width="100%">
  //                                 <td width="50%">Total</td>
  //                                 <td width="50%" align="right">$${
  //                                   parseFloat(
  //                                     orderDetails?.orderTotal +
  //                                       parseFloat(totalTax)
  //                                   )?.toFixed(2) || "0.00"
  //                                 }</td>
  //                             </tr>
  //                         </table>
  //                     </td>
  //                 </tr>
  //             </tbody>
  //         </table>
  //     </body>

  //     </html>
  //     `;

  //   printWindow.document.write(htmlContent);

  //   printWindow.document.close();
  //   printWindow.print();
  // };

  const showOrderDetails = () => {
    let itemRows = [];
    orderDetails?.orderItems?.map((singleItem) => {
      itemRows.push(
        <div className="orderlistParent">
          <p>{singleItem?.quantity}x</p>
          <div style={{ width: "100%" }}>
            <div className="orderlistItemDetails">
              <h2>{capitalizeFirstLetter(singleItem?.menuName) || "N/A"}</h2>
              <div className="orderlistItemInfo">
                {/* <span>Choose size</span> */}
                {singleItem?.variation &&
                  Object.keys(singleItem?.variation).length > 0 && (
                    <div>
                      <span style={{ fontWeight: "400", fontSize: "12px" }}>
                        Choose size
                      </span>
                      <p>{singleItem?.variation?.name || "N/A"}</p>
                    </div>
                  )}
              </div>
              {singleItem?.specialInstructions &&
                singleItem?.specialInstructions !== "" && (
                  <div className="orderlistItemInfo">
                    <span>Special instruction</span>
                    <p>
                      <b>{singleItem?.specialInstructions || "N/A"}</b>
                    </p>
                  </div>
                )}
            </div>
          </div>
          <p>
            {singleItem?.variation?.price
              ? Object.keys(singleItem?.variation).length > 0
                ? `$${(
                    singleItem?.variation?.price * singleItem?.quantity
                  ).toFixed(2)}`
                : `$${(singleItem?.price * singleItem?.quantity).toFixed(2)}`
              : `$${(singleItem?.price * singleItem?.quantity).toFixed(2)}`}
            {/* {singleItem?.variation &&
            Object.keys(singleItem?.variation).length > 0
              ? `$${(
                  singleItem?.variation?.price * singleItem?.quantity
                ).toFixed(2)}`
              : `$${(singleItem?.price * singleItem?.quantity).toFixed(2)}`} */}
          </p>
        </div>
      );
    });

    return itemRows;
  };

  const acceptOrderHandler = async () => {
    setLoaderShow(true);
    let data = {
      orderId,
      orderStatus:
        orderDetails?.deliveryPreference === "asap"
          ? "in-progress"
          : "scheduled",
      sendSMS: 1, //if we change the status and also send sms that time sendSMS will be 1 otherwise sendSMS will be 0
    };
    let result = await changeOrderStatus({ ...data });

    if (result?.data?.body?.data) {
      let result2 = await updateOrder({
        estimatedDeliveryTime: "20 minutes",
        id: orderId,
      });
      if (!result2.data?.error) {
        setOpenModal(false);
        window.location.reload();
        setTimeout(() => setLoaderShow(false), 5000);
      }
    }
  };

  const cancelOrderHandler = async () => {
    setLoaderShow(true);
    //if we change the status and also send sms that time sendSMS will be 1 otherwise sendSMS will be 0
    let data = {
      orderId,
      orderStatus: "declined",
      sendSMS: 1,
      orderCancelReason: orderCancel,
      orderCancelNote: textValue,
    };
    let result = await changeOrderStatus({ ...data });

    if (result?.data?.body?.data) {
      let data2 = {
        id: orderId,
        orderCancelReason: orderCancel,
        orderCancelNote: textValue,
      };

      let result2 = await updateOrder({ ...data2 });
      if (!result2.data?.error) {
        setTextValue("");
        setOrderCancel("");
        setOpenModal(false);
        setOpencancelModal(false);
        toast.error("Order has canceled");
        window.location.reload();
        setTimeout(() => setLoaderShow(false), 5000);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  useEffect(() => {
    if (!isLoading && !isFetching && data) {
      setOrderDetails(data?.body?.data);
      setScheduled(data?.body?.data?.deliveryPreference?.toUpperCase());
      setDeliveryInstructionData(data?.body?.data?.orderNote);
      setGetNewOrderId(data?.body?.data?._id);
    }
  }, [data, isLoading, isFetching]);
  // console.log(orderDetails, 328);
  let itemNumeber = 0;
  orderDetails?.orderItems?.map((item) => (itemNumeber += item?.quantity));

  //================================== get location  ===================================//
  const [activeLocationDetails, setActiveLocationDetails] = useState({});
  const activeLocation = useSelector((state) => state.auth.activeLocation);
  const {
    data: locationsData,
    isLoading: locationsIsLoading,
    isFetching: locationsIsFetching,
    error: locationsError,
  } = useGetLocationListQuery();
  useProtected(locationsError);
  useEffect(() => {
    if (!locationsIsLoading && !locationsIsFetching && locationsData) {
      let allLocations = locationsData?.body?.data;
      // setLocations(allLocations);

      let activeLocationInfo = allLocations?.find(
        (singleLocation) => singleLocation._id == activeLocation
      );
      setActiveLocationDetails(activeLocationInfo);
    }
  }, [locationsData, locationsIsLoading, locationsIsFetching]);

  // console.log(orderDetails, 345);
  return (
    <div className="ModalOneContainerMain">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modalOneMaster"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div
            className="OrdersModalOneContainer"
            style={{ width: `min(90%, ${props.width})` }}
          >
            <div
              className="OrdersModalHeader"
              style={{ background: props.Headerbackground }}
            >
              <div
                className="OrderModalTitle"
                style={{ color: props.Headercolor }}
              >
                <p>
                  {/* {orderDetails?.orderType
                    ? capitalizeFirstLetter(orderDetails?.orderType)
                    : "N/A"} */}

                  {orderDetails?.orderNumber}
                </p>
                <span>
                  {itemNumeber}items •{" "}
                  {moment(orderDetails?.orderDate).format("MMM DD, YYYY")}{" "}
                  {orderDetails?.orderTime}
                  {/* {orderDetails?.customerAddress
                    ? `${orderDetails?.customerAddress}`
                    : ``}{" "}
                  {orderDetails?.customerCity
                    ? `${orderDetails?.customerCity},`
                    : ``}{" "}
                  {orderDetails?.customerState
                    ? `${orderDetails?.customerState},`
                    : ``}{" "}
                  {orderDetails?.customerZip
                    ? `${orderDetails?.customerZip},`
                    : ``} */}
                </span>
              </div>
              <div className="OrderId">
                <p>
                  {/* {orderDetails?.orderNumber || "N/A"} */}
                  {Scheduled === "ASAP" ? (
                    <span style={{ color: "#dd2d37" }}>ASAP</span>
                  ) : (
                    <span style={{ color: "#FFB703" }}>SCHEDULED</span>
                  )}
                </p>
              </div>
            </div>

            <div className="OrderModalOneBody">
              <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                {/* =================== modal dropdown =================== */}
                {Scheduled === "ASAP" && (
                  <Grid item xs={12} md={12} lg={6}>
                    <div>
                      <ModalDropDown />
                    </div>
                  </Grid>
                )}

                <Grid item xs={12} md={12} lg={Scheduled === "ASAP" ? 6 : 12}>
                  {/* =================== user details =================== */}
                  <div className="OrderedUserDetails">
                    <div className="OrderUserAvatar">
                      <Avatar />
                    </div>
                    <div className="OrderUserInfo">
                      <h3>{orderDetails?.customerName || "N/A"}</h3>
                      <p>
                        <span>
                          {orderDetails?.phoneNumberFormatted || "N/A"}
                        </span>
                        <br />
                        <span>{orderDetails?.customerEmail || "N/A"}</span>
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  {/* =================== Delivery =================== */}
                  <div className="OrderedUserDetails">
                    <div
                      className="OrderUserAvatar"
                      style={{
                        backgroundColor: `${
                          Scheduled === "ASAP" ? "#4c9f41" : "#ffb704"
                        }`,
                      }}
                    >
                      <Avatar src={LocalTranport} />
                    </div>
                    <div className="OrderUserInfo">
                      {orderDetails?.orderType === "delivery" ? (
                        <>
                          <h3>Delivery</h3>
                          <p>
                            <span>
                              {Scheduled === "ASAP"
                                ? "As soon as possible"
                                : moment(orderDetails?.deliveryTime).format(
                                    "MMM DD, YYYY, h:mm a"
                                  )}
                            </span>
                          </p>
                        </>
                      ) : (
                        <>
                          {" "}
                          <h3>Pickup</h3>
                          <p>
                            <span>
                              {Scheduled === "ASAP"
                                ? "As soon as possible"
                                : moment(orderDetails?.deliveryTime).format(
                                    "MMM DD, YYYY, h:mm a"
                                  )}
                            </span>
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  {/* =================== address =================== */}
                  <div className="OrderedUserDetails">
                    <div
                      className="OrderUserAvatar"
                      style={{
                        backgroundColor: `${
                          Scheduled === "ASAP" ? "#4c9f41" : "#ffb704"
                        }`,
                      }}
                    >
                      <Avatar src={Location} alt="location svg" />
                    </div>
                    <div className="OrderUserInfo">
                      {orderDetails?.orderType === "delivery" ? (
                        <>
                          {" "}
                          <h3>Address</h3>
                          <p>
                            <span>
                              {" "}
                              {orderDetails?.customerAddress},{" "}
                              {orderDetails?.customerApartmentInfo
                                ? `${orderDetails.customerApartmentInfo},`
                                : ""}{" "}
                              {orderDetails?.customerCity},{" "}
                              {orderDetails?.customerState}{" "}
                              {orderDetails?.customerZip}
                            </span>
                          </p>
                        </>
                      ) : (
                        <>
                          {" "}
                          <h3>Location</h3>
                          <p style={{ width: "90%" }}>
                            {activeLocationDetails?.name}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </Grid>
                {/* <Grid item xs={12} md={12} lg={6}></Grid> */}
                {/* =================== Delivery instruction ======================  */}
                {DeliveryInstruction && (
                  <Grid item xs={12} md={12}>
                    <div className="DeliveryContainer">
                      <img src={Sms} alt="" />
                      <div className="DeliveryBoxHeader">
                        <p>{DeliveryInstructionTitle}</p>
                        <span>{DeliveryInstructionParagraph}</span>
                      </div>
                    </div>
                  </Grid>
                )}
              </Grid>
              {orderDetails?.orderItems?.length > 0 && showOrderDetails()}

              {/* list footer */}
              <div className="listFooterParent">
                <div className="listFooterContainer">
                  <div className="listFooterItem">
                    <p>Subtotal</p>
                    <p>${orderDetails?.orderSubTotal?.toFixed(2)}</p>
                  </div>
                  <div className="listFooterItem">
                    <p>NYS Sales Tax ({taxData}%)</p>
                    <p>${totalTax || "0.00"}</p>
                  </div>
                  {orderDetails?.orderType !== "pickup" && (
                    <div className="listFooterItem">
                      <p>Delivery fee</p>
                      <p>${orderDetails?.deliveryFee?.toFixed(2) || "O.00"}</p>
                    </div>
                  )}

                  <div className="listFooterItem">
                    <p>
                      <b>Total</b>
                    </p>
                    <p>
                      <b>
                        $
                        {parseFloat(
                          orderDetails?.orderTotal + parseFloat(totalTax)
                        )?.toFixed(2) || "0.00"}
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* modal footer */}
            {props.ModalFooter && (
              <div
                className="OrderModalOneFooter"
                style={{ background: props.Footerbackground }}
              >
                <div className="FooterButton">
                  <a href="#" className="Printbutton" onClick={handlePrint}>
                    <PrintIcon sx={{ color: "black" }} />
                  </a>
                  <DeclinedStyledButton
                    variant="outlined"
                    onClick={handleOpenCancelModal}
                  >
                    DECLINE
                  </DeclinedStyledButton>
                  <AcceptOrderStyledButton
                    variant="outlined"
                    onClick={(e) => acceptOrderHandler()}
                  >
                    ACCEPT ORDER
                  </AcceptOrderStyledButton>
                </div>
              </div>
            )}
          </div>
        </Fade>
      </Modal>

      <ModalsTwo
        ModalTitle="Decline order"
        handleClose={handleCloseCancelModal}
        handleConfirm={(e) => cancelOrderHandler()}
        // handleClose={() => handleClose()}
        open={openCancelModal}
        // ButtonClose="CLOSE"
        ButtonConfirm="Save"
        width="570px"
        Headerbackground="#fff"
        Headercolor="#676C7B"
        ModalFooter
        Footerbackground="#fff"
        ModalBody={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectField
                label="Select a reason for cancelling"
                options={Canceloptions}
                handleChange={handleChanges}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <TextField
                label="Anything else to add?"
                // validation={true}
                count={200}
                value={textValue}
                handleChange={textFeildhandleChanges}
              />
            </Grid>
          </Grid>
        }
      />
    </div>
  );
}

export default OrdersModal;

const DeclinedStyledButton = styled(Button)(({ theme }) => ({
  margin: "0 10px",
  padding: "25px 0",
  width: "50%",
  color: "#000000",
  fontSize: "12px",
  border: "1px solid #E4E2E6",
  borderRadius: "1000px",
  gap: "10px",
  alignItems: "center",
  "&:hover": {
    border: "1px solid #E4E2E6",
  },
}));

const AcceptOrderStyledButton = styled(Button)(({ theme }) => ({
  margin: "0 10px",
  padding: "25px 0",
  width: "50%",
  color: "#fff",
  fontSize: "12px",
  background: "#4C9F41",
  border: "1px solid #E4E2E6",
  borderRadius: "1000px",
  gap: "10px",
  alignItems: "center",
  "&:hover": {
    border: "1px solid #4C9F41",
    background: "#4C9F41",
  },
}));
