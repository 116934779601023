import React, { useEffect, useState } from "react";

import tableImg from "../../assets/imgs/tableImg.png";

import Table from "../../components/Table/Table";
import SideDrawer from "../../components/SideDrawer/SideDrawer";
import PhotoDrawer from "./PhotoDrawer";
import DraggableDialog from "../../hooks/Dialog";
import {
  useAddGalleryDataMutation,
  useDeleteGalleryMutation,
  useGetGalleryDetailsQuery,
  useGetGalleryListQuery,
  useUpdatedGalleryListMutation,
  useUpdateGalleryMutation,
} from "../../redux/features/gallery/galleryApi";
import useProtected from "../../hooks/useProtected";
import { toast } from "react-toastify";
import SimpleBackdrop from "../../components/Backdrop/SimpleBackdrop";
import DndTable from "../../components/Table/DndTable";

const Gallery = () => {
  const [photoDrawer, setPhotoDrawer] = useState(false);
  const [deleteImage, setDeleteImage] = useState(false);
  const intailGalleryData = {
    photo: "",
    caption: "",
    description: "",
    photoName: "",
  };
  const intailGalleryError = {
    photo: "",
    caption: "",
  };
  const [galleryInfo, setGalleryInfo] = useState(intailGalleryData);
  const [errors, setErrors] = useState(intailGalleryError);
  const [checkBeforeSavingData, setCheckBeforeSavingData] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [dataDraged, setDataDraged] = useState(false);
  const [id, setId] = useState("");
  const [rows, setRows] = useState([]);
  const [galleryDatas, setGalleryDatas] = useState([]);
  const { data, isError, isLoading, error } = useGetGalleryListQuery({
    refetchOnMountOrArgChange: true,
  });
  const {
    data: getSingleGallerDetails,
    isError: getSingleGallerDetailsIsError,
    isLoading: getSingleGallerDetailsIsLoading,
    error: getSingleGallerDetailsError,
  } = useGetGalleryDetailsQuery(id || "", {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });
  const [createGalleryData, createGalleryDataOptions] =
    useAddGalleryDataMutation();
  const [updateGalleryData, updateGalleryDataOptions] =
    useUpdateGalleryMutation();
  const [deleteGalleryData, deleteGalleryDataOptions] =
    useDeleteGalleryMutation();
  const [updateGalleryDatas, updateGalleryDatasOptions] =
    useUpdatedGalleryListMutation();

  useProtected(error);
  useProtected(getSingleGallerDetailsError);
  useProtected(createGalleryDataOptions.error);
  useProtected(deleteGalleryDataOptions.error);
  useProtected(updateGalleryDataOptions.error);
  useProtected(updateGalleryDatasOptions.error);

  const goBackToIntialState = () => {
    setGalleryInfo(intailGalleryData);
    setErrors(intailGalleryError);
    setId("");
    setOpenBackdrop(false);
  };

  //==================================== gallery listing view ================================//
  useEffect(() => {
    if (!isLoading && !isError && data) {
      setGalleryDatas(data?.body?.data);
    }
  }, [data, isLoading, isError]);
  useEffect(() => {
    if (galleryDatas?.length > 0) {
      setRows(convertDataToCellRows(galleryDatas));
    }
  }, [galleryDatas]);

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Photo",
      width: "5%",
    },
    {
      id: "caption",
      numeric: false,
      disablePadding: false,
      label: "Caption",
      width: "80%",
    },
    {
      id: "Actions",
      numeric: true,
      disablePadding: false,
      label: "",
      width: "10%",
    },
  ];
  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map((item) => {
        const itemId = item._id;
        return {
          cell: [
            { img: item?.photo, imgStatus: true, imgText: "", align: "left" },
            {
              textBold: item?.caption || "N/A",
            },
            {
              ActionButton: true,
              ActionButtonLabel: "Action",
            },
          ],
          //======= Action Date Start===== //
          action: [
            // { label: "View", onClick: onClick, },
            {
              label: "Edit",
              onClick: (e) => {
                openHandleUpdateDrawer(itemId); // Use itemId here
              },
            },
            {
              label: "Delete",
              onClick: (e) => openHnadleDeleteDrawer(itemId),
            },
          ],
          //======= Action Date End===== //
        };
      });
    }
  };

  //==================================== gallery listing view ================================//
  //========================== get single gallery deatils ================================//
  const openHandleUpdateDrawer = (idData) => {
    // setOpenBackdrop(true);
    setId(idData);
    setPhotoDrawer(true);
  };
  useEffect(() => {
    if (
      !getSingleGallerDetailsIsLoading &&
      !getSingleGallerDetailsIsError &&
      getSingleGallerDetails &&
      id
    ) {
      setGalleryInfo(getSingleGallerDetails?.body?.data);
      // setOpenBackdrop(false);
    }
  }, [
    getSingleGallerDetails,
    getSingleGallerDetailsIsError,
    getSingleGallerDetailsIsLoading,
    id,
  ]);

  //========================== get single gallery deatils ================================//

  //============================== checking errors ==========================================//
  const handleCheckError = async () => {
    let errorObject = { ...errors };
    let error = 0;

    if (galleryInfo?.photo === "") {
      errorObject = { ...errorObject, photo: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, photo: "" };
    }
    if (galleryInfo?.caption === "") {
      errorObject = { ...errorObject, caption: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, caption: "" };
    }
    setErrors({ ...errors, ...errorObject });
    return error > 0 ? false : true;
  };
  //============================== checking errors ==========================================//

  //============================== creating gallery =======================================//
  const saveGalleryData = async () => {
    setCheckBeforeSavingData(true);
    const isValid = await handleCheckError();
    if (!isValid) {
      return false;
    }
    let data = { ...galleryInfo };
    let result = !id
      ? await createGalleryData(data)
      : await updateGalleryData({ id: id, ...data });
    try {
      if (!result?.data?.error) {
        setPhotoDrawer(false);
        toast.success(`Photo ${!id ? "added" : "updated"}`);
        goBackToIntialState();
        setCheckBeforeSavingData(false);
      } else {
        toast.error(result?.data?.message);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  //============================== creating gallery =======================================//

  //================================ automatic error state handling ======================//
  useEffect(() => {
    if (checkBeforeSavingData) {
      handleCheckError();
    }
  }, [checkBeforeSavingData, galleryInfo]);
  //================================ automatic error state handling ======================//

  //=========================== handle delet gallery==========================================//
  const openHnadleDeleteDrawer = (idData) => {
    setId(idData);
    setDeleteImage(true);
  };
  const deleteConfirmHandler = async () => {
    try {
      const result = await deleteGalleryData(id);
      if (result?.data) {
        setDeleteImage(false);
        toast.success("Photo deleted");
      } else {
        toast.error(result?.data?.message);
      }
      setId("");
    } catch (err) {
      console.error(err?.message);
    }
  };
  //=========================== handle delet gallery==========================================//
  //=========================== updaated listing data =====================================//
  const handleUpdatedList = async () => {
    let data = [];
    if (galleryDatas?.length > 0) {
      galleryDatas?.map((item, index) =>
        data.push({ _id: item?._id, index: index + 1 })
      );
    }
    try {
      const result = await updateGalleryDatas(data);
      if (!result?.data?.error) {
        setDataDraged(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (dataDraged) {
      handleUpdatedList();
    }
    // console.log(dataDraged, 345);
  }, [dataDraged]);
  //=========================== updaated listing data =====================================//

  return (
    <>
      <SimpleBackdrop open={openBackdrop} />

      <div className="Table">
        <DndTable
          title="Gallery"
          tableHeader={true}
          addLabel="New Photo"
          onClick={() => {
            setPhotoDrawer(true);
          }}
          headCells={headCells}
          rows={rows}
          setRows={setRows}
          items={galleryDatas}
          setItems={setGalleryDatas}
          setDataDraged={setDataDraged}
          AddButton={true}
          Checkbox={false}
          footer={true}
          innerHeader={false}
          innerTitle=""
          AddButtonInner={false}
          addLabelInner="Add Payment"
          InnerButtonColor="#004FE0"
          InnerButtonTextColor="#fff"
          setId={setId}
          //   InnderButtonIcon={<AddRoundedIcon />}
          showSearchBox={true}
        />
      </div>
      {/* ========= Photo upload ============ */}
      <SideDrawer
        title={id ? "Edit Photo" : "Add New Photo"}
        open={photoDrawer}
        handleDrawerClose={() => {
          setOpenBackdrop(false);
          setPhotoDrawer(false);
          goBackToIntialState();
          setCheckBeforeSavingData(false);
          setId("");
        }}
        ButtonLabel={id ? "Edit" : "Save"}
        loading={checkBeforeSavingData ? true : false} // to show the loading icon in button
        SaveButtonDisabledg={checkBeforeSavingData ? true : false} // to disable save button conditionally
        BtnPadding="6px 28px"
        BtnColor="#4C9F41"
        borderRadius="999px"
        cancelButtonLabel="Cancel"
        // onClickDelete={deleteConfirm}
        clickSaveButton={saveGalleryData}
        FooterWithoutTab={true}
        DeleteButton={true}
        sideOpen="right"
        CheckboxLabel="Add another after saving"
        body={
          <>
            <PhotoDrawer
              galleryInfo={galleryInfo}
              setGalleryInfo={setGalleryInfo}
              errors={errors}
              setErrors={setErrors}
            />
          </>
        }
      />
      {/* ------------------- delete mdoal ------- */}
      <DraggableDialog
        title="Delete photo?"
        body="Once you delete it, you can't get it back."
        openDialog={deleteImage}
        handleCloseDialog={() => setDeleteImage(false)}
        ModalFooter={true}
        actionButtonN
        cancelColor="#1B1B1F"
        actionButton="Delete"
        handleConfirmCloseDialog={() => deleteConfirmHandler()}
      />
    </>
  );
};

export default Gallery;
