import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Grid } from "@material-ui/core/";
import Buttons from "../../components/Form/Button/Button";
import TextField from "../../components/Form/TextField/TextField";
import FieldPassword from "../../components/Form/FieldPassword/FieldPassword";
import fbIcon from "../../assets/imgs/fb-icon.png";
import google from "../../assets/imgs/google.png";

import Header from "./header/Header";
import CustomizedSnackbar from "../../hooks/Snackbar";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";

import "./Form.css";
import { Alert, Button } from "@mui/material";
import axiosApi from "../../utils/axios";
import {
  setActiveLocation,
  setFirstTimeLogin,
  setToken,
  setUserData,
} from "../../redux/features/authSlice";
import emailValidation from "../../hooks/HelperFunctions";

function SignIn(props) {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [values, setValues] = React.useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const [alertOptions, setAlertOptions] = useState({
    msg: "",
    type: "error",
  });

  const handleChanges = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };

  const redirectToPortal = (result, tokenBased = false) => {
    console.log(result);
    const response = result.data;

    if (response.error) {
      setShowSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Sign in failed, Email, password incorrect");
    } else {
      const userData = {
        id: response.body.id,
        firstName: response.body.first_name,
        lastName: response.body.last_name,
        email: response.body.email,
        image: response.body.image,
        orgId: response.body.org_id,
        userRole: response.body.user_role,
      };

      // set user data and user access token
      localStorage.setItem("userData", JSON.stringify(userData));
      localStorage.setItem("userToken", response.token);
      // set user as authorized
      localStorage.setItem("userAuthenticated", true);
      // set user id related data
      localStorage.setItem("orgId", response.body.org_id);
      localStorage.setItem("stuId", response.body.stuId);

      if (response?.orgData?.subDomain) {
        localStorage.setItem("subDomain", response.orgData.subDomain);
      }

      // show success message
      setShowSnackbar(true);
      setSnackbarSeverity("success");
      setSnackbarMessage("Sign in Success! Redirecting to the portal...");

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.token}`;

      setTimeout(() => {
        // Redirects to portal page
        switch (userData.userRole) {
          case "org":
            navigate("/orders-newlisting", { replace: true });
            break;
          case "student":
            navigate("/orders-newlisting", { replace: true });
            break;
          case "owner":
            navigate("/orders-newlisting", { replace: true });
            break;
          default:
            console.log("User role mismatching!");
        }
      }, 2000);
    }
  };

  const isValidPassword = async () => {
    if (values?.password == "") {
      setErrors({ ...errors, password: "Enter your password" });
      return false;
    }
    return true;
  };

  const isValidEmail = async () => {
    let enteredEmail = values.email ? values.email.toLowerCase() : "";

    if (enteredEmail == "") {
      setErrors({ ...errors, email: "Enter your email" });
      return false;
    }

    let isValidEmail = emailValidation(enteredEmail);
    if (!isValidEmail) {
      setErrors({ ...errors, email: "Email address is not valid" });
      return false;
    }
    return true;
  };

  const signInHandler = async () => {
    // const signInHandler = (userData, tokenBased = false) => {
    // const baseUrl =
    //   process.env.REACT_APP_PROJECT_ENV === "prod"
    //     ? process.env.REACT_APP_API_URL_PROD
    //     : process.env.REACT_APP_API_URL_DEV;
    // console.log(userData);

    // axios
    //   .post(`${baseUrl}/login`, userData)
    //   .then((response) => redirectToPortal(response))
    //   .catch((err) => {
    //     console.log(err);
    //     if (err.response.data.message) {
    //       setShowSnackbar(true);
    //       setSnackbarSeverity("error");
    //       setSnackbarMessage(err.response.data.message);
    //     }
    //   });

    window.grecaptcha.ready(async () => {
      ///===================== recapcha ==============================///
      try {
        const token = await window.grecaptcha.execute(
          `${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`,
          { action: "submit" }
        );
        ///===================== recapcha ==============================///
        const data = {
          email: values?.email ? values?.email.toLowerCase() : "",
          password: values?.password,
          token: token,
        };
        let isPasswordValid = await isValidPassword();
        let isEmailValid = await isValidEmail();

        if (!isEmailValid || !isPasswordValid) {
          return;
        }
        try {
          const result = await axiosApi.post("/users/login", data);

          if (result?.data?.error) {
            // setAlertOptions({
            //   msg: "Sorry, we don't recognize that email or password. You can try again or reset your password",
            //   type: "error",
            // });
            setShowSnackbar(true);
            setSnackbarSeverity("error");
            setSnackbarMessage("Sign in failed, Email, password incorrect");
            return;
          }

          // if (result.data.body.data.user.enableMFA) {
          //   navigate(
          //     `/verify-email?userId=${result.data.body.data.user._id}&mfa=true`,
          //     { state: { ...location.state, token: result.data.body.data.token } },
          //   );
          //   return;
          // }

          setAlertOptions({
            msg: "User logged in successfully",
            type: "success",
          });

          // console.log(result?.data?.body?.data, "user data");

          let userInfo = result?.data?.body?.data?.user || null;
          let token = result?.data?.body?.data?.token || "";

          let resultUser = null;
          let config;
          if (userInfo && token != "") {
            console.log("hamsa");
            dispatch(setUserData(userInfo));
            dispatch(setToken(token));
            dispatch(setFirstTimeLogin(true));
            dispatch(
              setActiveLocation(
                userInfo?.locationId || userInfo?.defaultLocation
              )
            );
            // dispatch(setRolesWithPermissions(rolesAndPermissionsData))

            localStorage.setItem("user", JSON.stringify(userInfo));
            localStorage.setItem("token", token);
            localStorage.setItem(
              "activeLocation",
              userInfo?.locationId || userInfo?.defaultLocation
            );
            // localStorage.setItem('rolesWithPermissions', JSON.stringify(rolesAndPermissionsData));

            navigate(location.state?.from ? location.state?.from : "/");
            window.location.reload();

            localStorage.setItem("user", JSON.stringify(userInfo));
            localStorage.setItem("token", token);
            // localStorage.setItem('rolesWithPermissions', JSON.stringify(rolesAndPermissionsData));

            navigate(location.state?.from ? location.state?.from : "/");
            window.location.reload();
          }

          let rolesAndPermissionsData = [];
        } catch (err) {
          setAlertOptions({
            msg: err.message,
            type: "error",
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  return (
    <>
      <Header />
      <div className="SignContainer">
        <div className="SignContainerInner">
          <div className="SignForm">
            <h1>Sign in</h1>
            <Grid container spacing={3}>
              {/* <Grid item xs={12} md={12}>
                <Alert severity="error" sx={{alignItems: 'center', borderRadius: '8px', background: '#FBDFE1 !important'}}>
                {alertOptions.msg}
                </Alert>
              </Grid> */}
              <Grid item xs={12} md={12} style={{ padding: "2rem !important" }}>
                <TextField
                  placeholder="Email Address"
                  validation={false}
                  value={values.email}
                  handleChange={handleChanges("email")}
                  helperText=""
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <FieldPassword
                  placeholder="Password"
                  value={values.password}
                  handleChange={handleChanges("password")}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Buttons
                  label="Continue"
                  buttonColor="#4C9F41"
                  // border="#4C9F41 1px solid"
                  color="#fff"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  link=""
                  onClick={() => signInHandler(values)}
                />
              </Grid>

              <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                <Buttons
                  label="Forgot your password?"
                  color="#4C9F41"
                  width=""
                  height="48px"
                  fontSize="14px"
                  link="/forgot-password"
                />
              </Grid>

              {/* for student sign in, we will show social login */}

              {false && (
                <>
                  <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                    <div className="OrBox">
                      <span>or</span>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <div className="SocialButton">
                      <Buttons
                        icon={<img src={fbIcon} />}
                        label="Continue with Facebook"
                        buttonColor="#fff"
                        border="#A4A7B0 1px solid"
                        color="#0B132B"
                        width="100%"
                        height="48px"
                        fontSize="14px"
                        link=""
                        // onClick={}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <div className="SocialButton">
                      <Buttons
                        icon={<img src={google} />}
                        label="Continue with Google"
                        buttonColor="#fff"
                        border="#A4A7B0 1px solid"
                        color="#0B132B"
                        width="100%"
                        height="48px"
                        fontSize="14px"
                        link=""
                        // onClick={}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <div className="AlreadyText">
                      You don't have an account? <a href="#">Sign Up</a>
                    </div>
                  </Grid>
                </>
              )}

              <Grid item xs={12} md={12}>
                {/* <div className="TosText">
                  This site is protected by reCAPTCHA Enterprise and the Google  <a href="#">Privacy Policy</a> and <a href="#">Terms of Service</a> apply.
                </div> */}
              </Grid>
            </Grid>
          </div>
          <div className="SignFormFooter">
            <LockOutlinedIcon />
            <span>SECURE SIGN IN</span>
            <SecurityOutlinedIcon />
            <span>SECURE SSL ENCRYPTION</span>
          </div>
        </div>
      </div>

      <CustomizedSnackbar
        open={showSnackbar}
        severity={snackbarSeverity}
        message={snackbarMessage}
        handleClose={handleSnackbarClose}
      />
    </>
  );
}

export default SignIn;
