import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import "./Dialog.css";

const ChangeStatusDialog = (props) => {
  const {
    openDialog,
    title,
    body,
    actionButton,
    cancelButton,
    handleCloseDialog,
    actionButtonN,
    cancelColor,
    handleConfirmCloseDialog,
    setConfirmChangeStatus,
    statusData,
  } = props;

  const handleCloseGoback = () => {
    window.location.reload();
    // setConfirmChangeStatus(false);
  };
  return (
    <div className="changeStatusDialog">
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        // PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        sx={{
          padding: "0",
          "& .MuiPaper-root": {
            padding: "12px",
            minWidth: `min(90%, 550px)`,
          },
        }}
      >
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
          className="DialogTitle"
          sx={{ padding: "12px 12px 0 12px" }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className="DialogContentText"
            sx={{ "&.MuiDialogContent-root": { padding: "0 12px" } }}
          >
            {body}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          className="DialogActions"
          sx={{
            display: "flex",
            flexDirection: { sm: "column", md: "row" },
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            autoFocus
            onClick={handleCloseGoback}
            className="DialogActionsBlack"
            sx={{ color: `${cancelColor}` }}
          >
            Go Back
          </Button>
          <div>
            <Button
              autoFocus
              onClick={handleCloseDialog}
              className="DialogActionsBlack"
              sx={{ color: `${cancelColor}` }}
            >
              {cancelButton
                ? cancelButton
                : `${
                    statusData === "cancelled"
                      ? "Cancel"
                      : statusData === "ready-to-pickup"
                      ? "Ready for pickup"
                      : "Out for delivery"
                  }`}
            </Button>
            {actionButtonN && (
              <Button
                onClick={handleConfirmCloseDialog}
                variant="contained"
                sx={{
                  boxShadow: "none",
                  background: "#4C9F41",
                  borderRadius: "1000px",
                  "&:hover": {
                    boxShadow: "none",
                  },
                }}
              >
                {actionButton ? actionButton : "Continue"}
              </Button>
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChangeStatusDialog;
