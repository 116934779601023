import React, { useEffect, useState } from "react";
import Table from "../../../../components/Table/Table";
import ModalOne from "../../../../components/Modals/ModalOne";
import ModalTwo from "../../../../components/Modals/ModalTwo";
import AddressForm from "./AddressForm/AddressForm";
// import ModalThree from "../../../../components/Modals/ModalThree";

function Addresses(props) {
  const { courseList } = props;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const headCells = [
    {
      id: "Course",
      numeric: false,
      disablePadding: true,
      label: "Name",
      width: "20%",
    },
    {
      id: "Enrolled",
      numeric: false,
      disablePadding: true,
      label: "Address",
      width: "70%",
    },
    {
      id: "Actions",
      numeric: true,
      disablePadding: false,
      label: "",
      width: "10%",
    },
  ];

  const rows = [
    {
      cell: [
        { textBold: "Home" },
        { textBold: "483 McDonald Ave Brooklyn, NY 10218" },
        {
          Button: true,
          buttonLabel: "Edit",
          buttonTextcolor: "#4C9F41",
          // buttonLink: "#",
          // buttonTarget: "_blank",
          onClick: handleOpen,
        },
      ],
    },
    {
      cell: [
        { textBold: "Work" },
        { textBold: "483 McDonald Ave 15f Brooklyn, NY 10218" },
        {
          Button: true,
          buttonLabel: "Edit",
          buttonTextcolor: "#4C9F41",
          // buttonLink: "#",
          // buttonTarget: "_blank",
          onClick: handleOpen,
        },
      ],
    },
  ];

  return (
    <>
      {/* ---------------- table -------------- */}
      <div className="TableSection">
        <Table
          tableHeader={true}
          innerTitle="Addresses"
          innerHeader={true}
          headCells={headCells}
          rows={rows}
          footer={true}
        />
      </div>
      {/* ------------------ modal --------------- */}
      {" "}
      <ModalTwo
        open={open}
        handleClose={handleClose}
        ModalTitle="Edit address"
        width="30%"
        Modaltitle="Filter"
        titileColor="#33B651"
        padding="16px"
        body={
        <>
        <AddressForm/>
        </>
        }
      />
    </>
  );
}

export default Addresses;
