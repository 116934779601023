import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import DehazeRoundedIcon from "@mui/icons-material/DehazeRounded";
import Buttons from "../Form/Button/Button";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import TablePagination from "@mui/material/TablePagination";
import { Button } from "@mui/material";

const StyledMenu = withStyles({})((props) => (
  <div className="ActionMenu">
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  </div>
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#fff",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#004FE0",
      },
    },
  },
}))(MenuItem);

const DndTable = ({
  title,
  headCells,
  rows,
  setRows,
  addLabel,
  onClick,
  setId,
  items,
  setItems,
  setDataDraged,
  ...props
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const actionBtnHandler = (label, path, id) => {
    console.log(label, path);
    if (label === "View") {
      // Redirects to course listing page
      navigate(path, { replace: true });
    } else if (label === "Edit") {
      // Redirects to course listing page
      navigate(path, { replace: true });
    } else if (label === "Delete") {
      props.handleOpenDialog(id);
    }
  };

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    const startIndex = e.source.index;
    const endIndex = e.destination.index;
    const copyItems = [...items];
    const [reorderItem] = copyItems.splice(startIndex, 1);
    copyItems.splice(endIndex, 0, reorderItem);
    setItems(copyItems);
    setDataDraged(true);
    // console.log(startIndex, 222, endIndex, reorderItem);
  };

  // const handleDragEnd = (e) => {
  //   if (!e.destination) return;
  //   const tempData = Array.from(rows);
  //   const [sourceData] = tempData.splice(e.source.index, 1);
  //   tempData.splice(e.destination.index, 0, sourceData);
  //   setRows(tempData);
  //   console.log(e, 222);
  // };

  // * === table action menu button start === * //
  const handleMenuClick = (event, rowIndex) => {
    setAnchorEl(event.currentTarget);
    setCurrentRowIndex(rowIndex);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentRowIndex(null);
  };
  // * === table action menu button end === * //

  // * === table footer start === * //
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const paginatedRows = rows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  // * === table footer end === * //

  return (
    <div className="Table" style={{ marginTop: "60px" }}>
      <div className="TableHeader">
        <h1 className="TableTitle">{title}</h1>
        <Buttons
          icon={<AddRoundedIcon />}
          label={addLabel}
          buttonColor="#4C9F41"
          color="#fff"
          height="48px"
          fontSize="14px"
          onClick={onClick}
          className="cy_AddButton"
        />
      </div>
      <div className="TableContainer">
        <DragDropContext onDragEnd={handleDragEnd}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="dnd table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ minWidth: "5%", width: "5%" }}
                  ></TableCell>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? "right" : "left"}
                      style={{
                        minWidth: headCell.width,
                        width: headCell.width,
                      }}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <Droppable droppableId="droppable-1">
                {(provider) => (
                  <TableBody
                    ref={provider.innerRef}
                    {...provider.droppableProps}
                  >
                    {paginatedRows.map((row, index) => (
                      <Draggable
                        key={index}
                        draggableId={String(index)}
                        index={index}
                      >
                        {(provider) => (
                          <TableRow
                            ref={provider.innerRef}
                            {...provider.draggableProps}
                          >
                            <TableCell {...provider.dragHandleProps}>
                              <DragIndicatorRoundedIcon />
                            </TableCell>
                            {row.cell.map((cell, cellIndex) => (
                              <TableCell
                                key={cellIndex}
                                align={cell.align || "left"}
                              >
                                {cell.imgStatus ? (
                                  <img
                                    src={cell.img}
                                    alt={cell.imgText}
                                    style={{
                                      height: "40px",
                                      width: "40px",
                                      objectFit: "cover",
                                      borderRadius: "12px",
                                    }}
                                  />
                                ) : (
                                  cell.textBold && (
                                    <strong>{cell.textBold}</strong>
                                  )
                                )}
                                {cell.ActionButton && (
                                  <div className="ActionButtons">
                                    <Button
                                      id={`basic-menu-${index}`}
                                      aria-controls={
                                        open ? "basic-menu" : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={open ? "true" : undefined}
                                      onClick={(e) => handleMenuClick(e, index)}
                                    >
                                      {cell.ActionButtonLabel}{" "}
                                      <KeyboardArrowDownOutlinedIcon />
                                    </Button>
                                    <Menu
                                      id={`basic-menu-${index}`}
                                      anchorEl={anchorEl}
                                      open={open && currentRowIndex === index}
                                      onClose={handleMenuClose}
                                      MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                      }}
                                      sx={{
                                        top: "20px",
                                      }}
                                    >
                                      {row.action?.map(
                                        (action, actionIndex) => (
                                          <MenuItem
                                            key={actionIndex}
                                            // onClick={() => {
                                            //   action.onClick();
                                            //   handleMenuClose();
                                            // }}
                                            onClick={
                                              action.onClick
                                                ? () => {
                                                    action.onClick();
                                                    handleMenuClose();
                                                  }
                                                : () =>
                                                    actionBtnHandler(
                                                      action.label,
                                                      action?.path,
                                                      action?.id
                                                    )
                                            }
                                          >
                                            {action.label}
                                          </MenuItem>
                                        )
                                      )}
                                    </Menu>
                                  </div>
                                )}
                              </TableCell>
                            ))}
                            {/* <TableCell>
                              {row.action.map((action, actionIndex) => (
                                <button
                                  key={actionIndex}
                                  onClick={action.onClick}
                                >
                                  {action.label}
                                </button>
                              ))}
                            </TableCell> */}
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provider.placeholder}
                  </TableBody>
                )}
              </Droppable>
              <div className="ActionListing">
                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {props.action?.map((item, i) => (
                    <StyledMenuItem
                      onClick={
                        item.onClick
                          ? () => {
                              item.onClick();
                              handleClose();
                            }
                          : () =>
                              actionBtnHandler(item.label, item?.path, item?.id)
                      }
                    >
                      <ListItemText primary={item.label} />
                    </StyledMenuItem>
                  ))}
                </StyledMenu>
              </div>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </DragDropContext>
      </div>
    </div>
  );
};

export default DndTable;
