import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import ComingSoon from "../../../components/ComingSoon/ComingSoon";
import VerticalTab from "../../../components/VerticalTab/VerticalTab";
import EventRoundedIcon from "@mui/icons-material/EventRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import Overview from "./overview/Overview";
import SecurityTab from "./security/Security";
import Courses from "./addresses/Addresses";
// import Payments from "./payments/Payments";
import { Person, Receipt, Security } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import Certificate from "@mui/icons-material/EmojiEvents";
import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from "@mui/material/colors";

// import Modals from "../../../../components/Form/Modals/Modals";
// import EditUser from "../forms/EditUser";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
// import SideDrawer from "../../../components/";
// import EditInformation from "../forms/EditInformation";
import DraggableDialog from "../../../hooks/Dialog";
import Orders from "./orders/Orders";
import Addresses from "./addresses/Addresses";
import Payments from "./payments/Payments";

function TeamMemberDetails(props) {
  const { customerDetails, customerOrders } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenEditInfoButton = () => {
    handleClose();
    handleOpenEditInfo();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const tabData = [
    {
      icon: <Person />,
      label: "Overview",
    },

    {
      icon: <Receipt />,
      label: "Orders",
    },

    // {
    //   icon: <LocationOnRoundedIcon />,
    //   label: "Addresses",
    // },

    // {
    //   icon: <AdminPanelSettingsRoundedIcon />,
    //   label: "Payment Methods",
    // },

    // {
    //   icon: <Security />,
    //   label: "Security",
    // },
  ];

  const tabBody = [
    {
      id: 0,
      panel: <Overview customerDetails={customerDetails} />,
    },
    {
      id: 1,
      panel: (
        <Orders
          customerDetails={customerDetails}
          customerOrders={customerOrders}
        />
      ), //<ComingSoon title="Calendar" />,
    },
    // {
    //   id: 2,
    //   panel: <Addresses  customerDetails={customerDetails}/>,
    // },

    // {
    //   id: 3,
    //   panel: <Payments customerDetails={customerDetails}/> //<ComingSoon title="Payments" />,
    // },

    // {
    //   id: 4,
    //   panel: <SecurityTab />,
    // },
  ];

  const [currentTab, setCurrentTab] = useState(0);
  const handleTabSwitch = (activeTab) => {
    console.log(activeTab);
    setCurrentTab(activeTab);
  };

  //  =========== edit info drawer ==========
  const [openEditInfo, setOpenEditInfo] = useState(false);

  const handleOpenEditInfo = () => {
    setOpenEditInfo(true);
  };

  const handleCloseEditInfo = () => {
    setOpenEditInfo(false);
  };
  //  ============ eidit info drawer =========

  //  ============ revoke pop up =============
  const [revokePopUp, setRevokePopUp] = useState(false);

  const handleOpenRevoke = () => {
    handleClose();
    setRevokePopUp(true);
  };

  const handleCloseRevoke = () => {
    setRevokePopUp(false);
  };
  //  ============ revoke pop up =============

  //  ============ delete pop up =============
  const [deletePopUp, setDeletePopUp] = useState(false);

  const handleOpenDelete = () => {
    handleClose();
    setDeletePopUp(true);
  };

  const handleCloseDelete = () => {
    setDeletePopUp(false);
  };
  //  ============ delete pop up =============

  //  ============ breadcrums ================
  const breadcrumbsData = [
    {
      label: "Customers",
      link: "/customers-listing",
      icon: <GroupsRoundedIcon fontSize="small" />,
    },

    {
      label: "Details",
      link: "customer-details",
    },
  ];
  //  ============ breadcrums ================

  return (
    <div>
      <Breadcrumbs breadcrumbsData={breadcrumbsData} />
      <div className="UserDetails">
        <li>
          <div className="UserDetailsleftcol">
            <div className="UserDetailsrIcon">
              <Avatar sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }}>
                {" "}
                {customerDetails?.fullName
                  ? customerDetails?.fullName[0]?.toUpperCase()
                  : "N/A"}
              </Avatar>
            </div>
            <div className="UserDetailsDetail">
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <span style={{ display: "inline-block" }}>
                  <h1>{customerDetails?.fullName || "N/A"}</h1>
                </span>{" "}
                <span
                  style={{ display: "inline-block" }}
                  className="status rounded success"
                >
                  {customerDetails?.status &&
                    customerDetails?.status[0]?.toUpperCase() +
                      customerDetails?.status?.slice(1)}
                </span>
              </div>
              <p>
                Joined in {new Date(customerDetails?.createdAt)?.getFullYear()}
              </p>
            </div>
          </div>

          {/* <div className="EditUserProfile actionDoropdown">
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              sx={{ color: '#4C9F41' }}
              endIcon={<KeyboardArrowDownRoundedIcon />}
            >
              Actions
            </Button>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleOpenRevoke}>Send Offer</MenuItem>
              <MenuItem onClick={handleOpenDelete} sx={{color: '#BA1A1A'}}>Block</MenuItem>
              <MenuItem onClick={handleOpenDelete} sx={{color: '#4C9F41'}}>Unblock</MenuItem>
            </Menu>
          </div> */}
        </li>

        {/* <div className="UserStatus">
          <div className="UserStatusCol">{totalRunningCourses} running courses</div>
          <div className="UserStatusCol">{totalCompletedCourses} Completed Courses</div>
          <div className="UserStatusCol">{totalCirtificates} Certificates</div>
        </div> */}
      </div>

      <VerticalTab
        //title="Introduction to Islamic Economics and Finance"
        //name="MUNA Academy"
        link="#"
        tabData={tabData}
        tabBody={tabBody}
        onTabSwitch={handleTabSwitch}
        //onClick={onClick()}
        //ButtonLabel="Start Class"
      />

      {/*====================== Edit new Form Modal ===================== */}
      {/* <SideDrawer
        title="Edit Information"
        buttonLabel="Save"
        // buttonLink={}
        sideOpen="right"
        open={openEditInfo}
        handleDrawerClose={handleCloseEditInfo}
        FooterWithoutTab={true}
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        Checkbox={false}
        CheckboxLabel=""
        body={
          <>
            <EditInformation />
          </>
        }
      /> */}
      {/*====================== Edit new Form Modal ===================== */}

      {/*====================== Revoke pop up  ========================== */}
      {/* <DraggableDialog
        title="Revoke access?"
        body="Are you sure you want to revoke access of this team member? User won't be able to access the portal anymore."
        openDialog={revokePopUp}
        handleCloseDialog={handleCloseRevoke}
        // handleConfirmCloseDialog={handleConfirmCloseSessionCompleteDialog}
        ModalFooter={true}
        actionButton="Revoke"
      /> */}
      {/*====================== Revoke pop up  ========================== */}

      {/*====================== Delete pop up  ========================== */}
      <DraggableDialog
        title="Block this customer?"
        body="Are you sure you want block this customer? Once blocked, this customer can't place an order."
        openDialog={deletePopUp}
        handleCloseDialog={handleCloseDelete}
        // handleConfirmCloseDialog={handleConfirmCloseSessionCompleteDialog}
        ModalFooter={true}
        actionButtonN
        actionButton="Block"
        cancelColor="#1B1B1F"
      />
      {/*====================== Delete pop up  ========================== */}
    </div>
  );
}

export default TeamMemberDetails;
