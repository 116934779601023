import React from "react";
import { emphasize, withStyles } from "@material-ui/core/styles";
import BreadcrumbsComp from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import HomeIcon from "@material-ui/icons/Home";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./Breadcrumbs.css";

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    cursor: "pointer",
    "& .MuiChip-label": {
      margin: "0",
      padding: "0 10px",
    },
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

function Breadcrumbs(props) {
  const { breadcrumbsData } = props;

  // const breadcrumbsData = [
  //   {
  //     label: "Home",
  //     link:"",
  //     icon:<HomeIcon fontSize="small" />,
  //   },

  //   {
  //     label: "Course",
  //     link:"",
  //   },

  // ];

  // <Breadcrumbs
  //   breadcrumbsData={breadcrumbsData}
  // />

  return (
    <div className="Breadcrumbs">
      <BreadcrumbsComp aria-label="breadcrumb">
        {breadcrumbsData.map((item, i) => (
          <StyledBreadcrumb
            component="a"
            href={item.link}
            label={item.label}
            icon={item.icon}
            //onClick={handleClick}
          />
        ))}
      </BreadcrumbsComp>
    </div>
  );
}

export default Breadcrumbs;
