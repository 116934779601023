import React, { useEffect, useState } from "react";
import VerifiedUser from "@mui/icons-material/Verified";
import { withStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./DefaultLocation.css";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Modals from "../../../../components/Form/Modals/Modals";
import DefaultLocation from "../../../../components/Header/DefaultLocation/DefaultLocation";
import { useGetLocationListQuery } from "../../../../redux/features/locations/locationApi";
import useProtected from "../../../../hooks/useProtected";
import { useDispatch, useSelector } from "react-redux";
import { setActiveLocation } from "../../../../redux/features/authSlice";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

function DefaultLocaiton(props) {
  const activeLocation = useSelector((state) => state.auth.activeLocation);
  const dispatch = useDispatch();
  const { data, isLoading, isFetching, error } = useGetLocationListQuery();

  useProtected(error);

  const [locations, setLocations] = useState([]);
  const [defaultLocation, setDefaultLocation] = useState({});

  useEffect(() => {
    if (!isLoading && !isFetching && data) {
      setLocations(data?.body?.data);
    }
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    if (locations?.length > 0 && activeLocation && activeLocation != "") {
      let found = locations?.find(
        (singleLocation) => singleLocation._id == activeLocation
      );
      if (found) {
        setDefaultLocation(found);
      }
    }
  }, [locations, activeLocation]);

  // console.log(defaultLocation, 328);

  // * modal section
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const setActiveLocationHandler = (id) => {
    dispatch(setActiveLocation(id));
    localStorage.setItem("activeLocation", id);
  };
  const showLocations = () => {
    let locationsToShow = [];
    locations.map((location, index) => {
      locationsToShow.push(
        <DefaultLocation
          key={index}
          label={`Radhuni Sweets & Restaurant - ${location?.name}`}
          location={`${location?.city} ${location?.state} ${location?.zip}`}
          location_id={location?._id}
          setActiveLocationHandler={setActiveLocationHandler}
        />
      );
    });
    return locationsToShow;
  };

  return (
    <>
      <div className="TableContainer">
        <div className="TableInnerHeader">
          <h3>Default location</h3>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left">
                  <div className="defaultAddressCell">
                    <h3>
                      Radhuni Sweets & Restaurant - {defaultLocation?.name}
                    </h3>
                    <p>
                      {defaultLocation?.city},{defaultLocation?.state}
                      {defaultLocation?.zip}
                    </p>
                    <StyledButton onClick={handleOpenModal}>
                      Change Default Location
                    </StyledButton>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Modals
        ModalTitle="Change Location"
        handleClose={handleCloseModal}
        // handleConfirm={() => handleConfirm()}
        // handleClose={() => handleClose()}
        open={openModal}
        ButtonClose="CLOSE"
        ButtonConfirm="CONFIRM"
        width="570px"
        Headerbackground="#fff"
        Headercolor="#676C7B"
        Footerbackground="#F7F9FA"
        ModalBody={<div>{showLocations()}</div>}
      />
    </>
  );
}

export default DefaultLocaiton;

const StyledButton = styled(Button)(({ theme }) => ({
  margin: "0 5px",
  // padding: "7px 20px",
  width: "30%",
  color: "#fff",
  fontSize: "12px",
  textTransform: "capitalize",
  background: "#4C9F41",
  border: "1px solid #E4E2E6",
  borderRadius: "1000px",
  gap: "10px",
  alignItems: "center",
  "&:hover": {
    border: "1px solid #4C9F41",
    background: "#4C9F41",
  },
}));
