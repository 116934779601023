import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import Table from "../../components/Table/Table";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import SideBarDrawer from "../../components/SideBarDrawer/SideBarDrawer";
import {
  useGetCustomerListQuery,
  useGetCustomerSearchResultMutation,
} from "../../redux/features/customers/customerApi";
import useProtected from "../../hooks/useProtected";
import { useSelector } from "react-redux";
import { USformatPhoneNumber } from "../../hooks/HelperFunctions";

const CustomersListing = () => {
  const activeLocation = useSelector((state) => state.auth.activeLocation);
  const { data, isLoading, isFetching, error } = useGetCustomerListQuery();
  const [searchCustomer, searchCustomerOptions] =
    useGetCustomerSearchResultMutation();

  useProtected(error);
  useProtected(searchCustomerOptions.error);

  // menu
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [serachText, setSerachText] = useState("");
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const headCells = [
    {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "Name",
      width: "25%",
    },
    {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "Phone",
      width: "25%",
    },
    {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "Email",
      width: "25%",
    },
    {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "Status",
      width: "20%",
    },
    {
      id: "Actions",
      numeric: true,
      disablePadding: false,
      label: "",
      width: "5%",
    },
  ];

  useEffect(() => {
    if (!isLoading && !isFetching && data && activeLocation != "") {
      setRows(convertDataToCellRows(data.body.data));
    }
  }, [data, isLoading, isFetching, activeLocation]);

  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map((item) => {
        let statusColor = "";
        let statusLabel = "";
        let statusBackground = "";

        if (item?.status == "active") {
          statusColor = "#0B9444";
          statusLabel = "Active";
          statusBackground = "#E1F2E8";
        } else if (item?.status == "blocked") {
          statusColor = "#E63946";
          statusLabel = "Blocked";
          statusBackground = "#FCE7E9";
        } else {
          statusLabel = "N/A";
        }
        //we are formatting only for us number
        const formattedNumber = USformatPhoneNumber(item?.phone?.slice(2));

        return {
          cell: [
            { textBold: item?.fullName || "N/A" },
            // { textBold: item?.phone || "N/A" },
            {
              textBold: item?.phone
                ? formattedNumber
                  ? `+1 ${formattedNumber}`
                  : "Invalid Number"
                : "N/A",
            },
            { textBold: item?.email || "N/A" },
            {
              Status: true,
              background: statusBackground,
              color: statusColor,
              label: statusLabel,
            },
            {
              Button: true,
              buttonLabel: "View",
              buttonTextcolor: "#4C9F41",
              buttonLink: `/customer-details/${item?._id}`,
            },
          ],
        };
      });
    }
  };

  //============================== search part =========================================//
  const handleSearchText = (e) => {
    e.preventDefault();
    setSerachText(e.target.value);
  };
  const handleSearchClear = (e) => {
    setSerachText("");
  };
  const handleSearch = async () => {
    try {
      const result = await searchCustomer({
        term: serachText,
        locationId: activeLocation,
      });
      if (result?.data) {
        setRows(convertDataToCellRows(result?.data?.body?.data));
      } else {
        console.error(result?.data?.message);
      }
    } catch (err) {
      console.error(err?.message);
    }
  };

  return (
    <>
      <div className="Table">
        <Table
          title="Customers"
          tableHeader={true}
          addLabel="New Item"
          link="/add-menuItem"
          headCells={headCells}
          rows={rows}
          AddButton={false}
          Checkbox={false}
          footer={true}
          innerHeader={true}
          innerTitle=""
          AddButtonInner={false}
          addLabelInner="Add Payment"
          InnerButtonColor="#004FE0"
          InnerButtonTextColor="#fff"
          InnderButtonIcon={<AddRoundedIcon />}
          showSearchBox={true}
          searchBoxPlaceholder="Search Customers"
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          searchBoxText={serachText}
          handleSearchClear={handleSearchClear}
          perPageItems={5}
        />
      </div>
    </>
  );
};

export default CustomersListing;
