import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
// import "./OrderStatusDropdown.css";

const options = [
  "10 min",
  "20 min",
  "30 min",
  "40 min",
  "50 min",
  "60 min",
  "More than 60 min",
];

const ModalDropDown = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="orderStatusContainer boxShadow">
      <List
        component="nav"
        aria-label="Device settings"
        sx={{
          minHeight: "95px",
          display: "flex",
          bgcolor: "#4C9F41",
          // bgcolor: selectedIndex === 0 ? "#FF62003D" : "#4C9F413D",
          "& .MuiButtonBase-root.MuiListItem-root": {
            padding: "0 16px !important",
          },
        }}
      >
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
        >
          <ListItemText
            primary="Estimated time"
            secondary={options[selectedIndex]}
            sx={{
              "& .MuiTypography-root.MuiTypography-body1": {
                fontSize: "12px !important",
                color: "#fff !important",
                // color: selectedIndex === 0 ? "#FF6200" : "green",
              },
              "& .MuiTypography-root.MuiTypography-body2": {
                fontSize: "16px !important",
                fontWeight: "500",
                color: "#fff !important",
                // color: selectedIndex === 0 ? "#FF6200" : "green",
              },
            }}
          />
          <ArrowDropDownRoundedIcon sx={{ color: "#ffffff !Important" }} />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            //   disabled={index === 0}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
            sx={{
              color: "black",
              fontWeight: "400",
              "& p": {
                fontSize: "16px !important",
                backgroundColor:
                  index === 0 ? "#FF6200 !important" : "green !important",
              },
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ModalDropDown;
