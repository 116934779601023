import React, { useState } from "react";
import { Grid, InputAdornment, TextField } from "@mui/material";
import { styled } from "@mui/system";
import SelectField from "../../Form/SelectField/SelectField";
import Buttons from "../../Button/Button";
import PhoneInputField from "../../PhoneInputField/PhoneInputField";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router";
import useProtected from "../../../hooks/useProtected";
import { useAddPhoneNumberMutation } from "../../../redux/features/users/userApi";
import Loader from "../../Loader/Loader";
import { toast } from "react-toastify";

const AddPhoneModal = ({ handleCloseModal, personalInfo }) => {
  const [addPhoneNumber, addPhoneNumberOptions] = useAddPhoneNumberMutation();
  useProtected(addPhoneNumberOptions.error);
  const navigate = useNavigate();
  const [phone, setPhone] = React.useState({
    country: {},
    number: "",
  });
  const [country, setCountry] = useState("Unitaed State");

  //handle phone change event
  const handlePhoneChange = (phone, country) => {
    console.log(country);
    console.log({ phone });
    setPhone({ country: country, number: phone });
  };

  //handle submit event
  const handleSubmit = async () => {
    console.log("submit");
    const data = {
      // phone_number: phone.number,
      // country_code: phone.country.dialCode,
      phone: `+${phone.number}`,
      id: personalInfo?._id,
    };
    const result = await addPhoneNumber(data);
    if (!result?.data?.error) {
      toast.success(result?.data?.message);
      navigate(`/verify-phone-address`);
    } else {
      toast.error(result?.data?.message);
    }
  };

  return (
    <>
      <div className="addPhoneModalContainer">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p>
              Confirming your mobile number helps you reset your password easily
              and receive SMS notifications.
            </p>
          </Grid>
          {/* <Grid item xs={12}>
            <SelectField
              // label="Month"
              options={options}
              handleChange={handleChange}
            />
          </Grid> */}
          <Grid item xs={12}>
            {/* <TextField
              // value={inputValue}
              // onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span style={{ color: "#0B132B !important" }}>+1</span>
                  </InputAdornment>
                ),
              }}
            /> */}
            <PhoneInputField
              phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
              country="us"
              // value={phone.number}
              countryCodeEditable={false}
              onChange={(phone, country) => handlePhoneChange(phone, country)}
              // onBlur={(e) => setPhoneNumberFormated(e.target.value)}
              // onKeyDown={(e) => setPhoneNumberFormated(e.target.value)}
              variant="outlined"
              onlyCountries={["us", "bd", "pk"]}
              showDropdown={true}
              containerStyle={{}}
              buttonStyle={{}}
              inputStyle={{
                marginLeft: "0px",
                height: "40px",
                width: "100%",
                fontFamily: "Roboto-Regular",
                fontSize: "16px",
              }}
              containerClass={{}}
              errorText={"Phone number is required"}
              errorStyles={{ marginBottom: "5px" }}
              autoFormat={true}
              disableDropdown={false}
              disabled={false}
              disableCountryCode={false}
              enableSearch={false}
              disableSearchIcon={false}
              placeholder={""}
              // inputProps={{}}
              // specialLabel={"Primary Phone"}
              // preferredCountries={["us"]}
              // excludeCountries={["us"]}
              // error="error"
            />
          </Grid>
          <Grid item xs={12}>
            <Buttons
              label={addPhoneNumberOptions?.isLoading ? "Loading" : "Continue"}
              buttonColor="#4C9F41"
              color="#fff"
              height="40px"
              width="100%"
              borderRadius="100px"
              fontSize="14px"
              link=""
              icon={
                addPhoneNumberOptions?.isLoading ? (
                  <Loader height="20px" width="20px" />
                ) : null
              }
              disabled={
                phone.number.length < 11 || addPhoneNumberOptions?.isLoading
                  ? "disabled"
                  : ""
              }
              onClick={handleSubmit}
            />
          </Grid>
          <Grid item xs={12}>
            <Buttons
              label="Cancel"
              buttonColor="transparent"
              color="#4C9F41"
              height="40px"
              width="100%"
              borderRadius="100px"
              fontSize="14px"
              ModalFooter
              link=""
              onClick={handleCloseModal}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AddPhoneModal;

const options = [
  {
    label: "United States",
    value: "United States",
  },

  {
    label: "Bangladesh",
    value: "Bangladesh",
  },
];

// const StyledTextField = styled(TextField)(({ theme }) => ({}));
