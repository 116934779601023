import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import axios from "axios";
import { setBearerToken } from "../src/hooks/HelperFunctions";
import OrgAuth from "./routing/OrgAuth";
import "./App.css";

import "./assets/csstwo/style.css";
import "./assets/css/style.css";

// import AddMenu from './pages/menu/addMenu/AddMenu';

// * ----------------Orders Start--------------- *
import OrdersNewListing from "./pages/orders/OrdersNewListing";
import OrderPrint from "./pages/orders/OrderPrint";
import OrderDetailsInprogress from "./pages/orders/OrderDetailsInprogress";
//  * ----------------Orders End-----------------*

// * ----------------Menu Start--------------- *
import MenuListing from "./pages/menu/MenuListing";
import MenuAddItemStapper from "./pages/menu/MenuAddItemStapper/MenuAddItemStapper";
import MenuUpdateItemStapper from "./pages/menu/MenuAddItemStapper/MenuUpdateItemStapper";
// * ----------------Menu End----------------- *

//  * ----------------Customers Start-----------------*
import CustomersListing from "./pages/customers/CustomersListing";
import CustomersDetails from "./pages/customers/CustomersDetails";
//  * ----------------Customers end-------------------*

//  * ----------------Locations Start-----------------*
import LocationListing from "./pages/location/LocationListing";
import LocationStapper from "./pages/location/LocationStapper/LocationStapper";
//  * ----------------Locations end-------------------*

//  * ----------------Users Start-----------------*
import UsersListing from "./pages/users/UsersListing";
import AddNewUserStapper from "./pages/users/AddNewUserStapper/AddNewUserStapper";
import UpdateUserStapper from "./pages/users/AddNewUserStapper/UpdateUserStapper";
//  * ----------------Users end-------------------*

/** ----------------- public pages start ------------------- */
import SignInOrgID from "../src/pages/authentication/SignInOrgID";
import SignInOrgEmail from "../src/pages/authentication/SignInOrgEmail";
import SignIn from "../src/pages/authentication/SignIn";
import ForgotPassword from "../src/pages/authentication/ForgotPassword";
import Otp from "../src/pages/authentication/Otp";
import ResetPassword from "../src/pages/authentication/ResetPassword";
import TokenBasedSignIn from "../src/pages/authentication/TokenBasedSignIn";
import Table from "./components/Table/Table";
import TableData from "./components/Table/TableData copy";
import SignInAccountIDLocked from "./pages/authentication/SignInAccountIDLocked";
import TeamMemberDetails from "./pages/customers/details/TeamMemberDetails";
import Settings from "./pages/settings/Settings";
import OtpPhone from "./pages/authentication/OtpPhone";
import OwnerAuth from "./routing/OwnerAuth";
import MediaLibraryTableView from "./components/MediaLibrary/MediaLibraryTableView";
import LocationStapperUpdate from "./pages/location/LocationStapper/LocationStapperUpdate";
import CreatNewPassword from "./pages/authentication/CreatNewPassword";
import OrgAuthSecond from "./routing/OrgAuthSecond";
import TabName from "./components/TabName/TabName";
import Gallery from "./pages/Gallery/Gallery";

/** ----------------- public pages end --------------------- */

function App() {
  // useEffect(() => {
  //   setBearerToken();
  // }, []);
  const userToken = localStorage.getItem("userToken");
  axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;

  //================== google recapcha v3 part start ====================//
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  //================== google recapcha v3 part end ====================//

  return (
    <>
      <TabName />
      <Routes>
        {/*----------Orders-------- */}
        {/* <Route path="/orders-newlisting" element={<OrdersNewListing />} /> */}
        <Route
          path="/"
          element={
            <OrgAuth>
              <OrdersNewListing />
            </OrgAuth>
          }
        />
        <Route path="/order-print" element={<OrderPrint />} />
        <Route
          path="/order-details/:order_id"
          element={
            <OrgAuth>
              <OrderDetailsInprogress />
            </OrgAuth>
          }
        />
        {/*----------Orders-------- *}

      {/* -------------Menu--------------- */}
        <Route
          path="/menu-listing"
          element={
            <OrgAuth>
              <MenuListing />
            </OrgAuth>
          }
        />
        <Route
          path="/add-menuItem"
          element={
            <OrgAuthSecond>
              <MenuAddItemStapper />
            </OrgAuthSecond>
          }
        />
        <Route
          path="/update-menuItem/:id"
          element={
            <OrgAuthSecond>
              <MenuUpdateItemStapper />
            </OrgAuthSecond>
          }
        />
        {/* -------------Menu--------------- */}

        {/* -------------Customers--------------- */}
        <Route
          path="/customers-listing"
          element={
            <OrgAuth>
              <CustomersListing />
            </OrgAuth>
          }
        />
        <Route
          path="/customer-details/:customer_id"
          element={
            <OrgAuth>
              <CustomersDetails />
            </OrgAuth>
          }
        />
        {/* -------------Customers--------------- */}

        {/* -------------Location--------------- */}
        <Route
          path="/location-listing"
          element={
            <OrgAuth>
              <LocationListing />
            </OrgAuth>
          }
        />
        <Route
          path="/location-stapper"
          element={
            <OrgAuthSecond>
              <LocationStapper />
            </OrgAuthSecond>
          }
        />
        <Route
          path="/location-update/:location_id"
          element={
            <OrgAuthSecond>
              <LocationStapperUpdate />
            </OrgAuthSecond>
          }
        />
        {/* -------------Location--------------- */}

        {/* -------------Users--------------- */}
        <Route
          path="/user-listing"
          element={
            <OrgAuth>
              <UsersListing />
            </OrgAuth>
          }
        />
        <Route path="/user-stapper" element={<AddNewUserStapper />} />
        <Route path="/user-update/:user_id" element={<UpdateUserStapper />} />
        {/* -------------Users--------------- */}

        {/* -------------Media Lib--------------- */}
        <Route
          path="/media-library-table-view"
          element={
            <OrgAuth>
              {" "}
              <MediaLibraryTableView />{" "}
            </OrgAuth>
          }
        />
        {/* -------------Media Lib--------------- */}

        {/* -------------Media Lib--------------- */}
        <Route
          path="/gallery"
          element={
            <OrgAuth>
              <Gallery />
            </OrgAuth>
          }
        />
        {/* -------------Media Lib--------------- */}

        {/* -------------Settings--------------- */}
        <Route
          path="/settings"
          element={
            <OrgAuth>
              <Settings />
            </OrgAuth>
          }
        />
        {/* -------------Settings--------------- */}

        {/* --------------------- */}
        <Route path="/owner" element={<OwnerAuth />} />

        {/*----------public routes start----------*/}
        {/* <Route path="/" element={<SignIn />} /> */}
        <Route path="/singin-org-id" element={<SignInOrgID />} />
        <Route
          path="/signin-account-locked"
          element={<SignInAccountIDLocked />}
        />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-email-address" element={<Otp />} />
        <Route path="/verify-phone-address" element={<OtpPhone />} />
        <Route path="/create-new-password" element={<CreatNewPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/token-based-signin" element={<TokenBasedSignIn />} />
        <Route path="/table" element={<TableData />} />
        {/*----------public routes end----------*/}

        {/* Rahduniproduct routes start */}
        {/* <Route path="/courses-listing" element={<OrgAuth><Courses /></OrgAuth>} />  */}
      </Routes>
    </>
  );
}

export default App;
