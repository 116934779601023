import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SearchIcon from "@material-ui/icons/Search";

export default function SearchBox(props) {
  return (
    <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
      <OutlinedInput
        id="outlined-adornment-password"
        placeholder={props.placeholder}
        value={props.searchText}
        type="text"
        onChange={props.handleSearchText}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              sx={{
                marginTop: "4px",
                background: "#fff !important",
                display: `${props.searchText === "" ? "block" : "none"}`,
                "&.MuiButtonBase-root": {
                  display: `${props.searchText !== "" ? "block" : "none"}`,
                },
                "&.MuiButtonBase-root:hover": {
                  display: "inline-block",
                },
              }}
              onClick={() => props.handleSearchClear()}
            >
              <CloseRoundedIcon
                sx={{
                  color: "#676C7B",
                  // background: "#f0f0f0",
                  borderRadius: "50%",
                }}
              />
            </IconButton>
            <IconButton
              aria-label="toggle password visibility"
              onClick={props.handleSearch}
              // onMouseDown={props.handleSearch}
              edge="end"
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}
