// import React, { useState, useRef, useEffect } from 'react';
// import TextField from '../Form/TextField/TextField';
// import { Grid } from '@material-ui/core/';
// import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
// import FileUplaoder from '../Form/FileUplaoder/FileUplaoder'
// import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
// import ReplyRoundedIcon from '@mui/icons-material/ReplyRounded';
// // import CustomizedSnackbar from '../../hooks/Snackbar';
// import './MediaLibrary.css';
// import axios from 'axios';

// function UploadFromYoutube(props) {

//   const [formValidationError, setFormValidationError] = useState(false);

//   const handleValidation = (validationError,) => {
//     // set form validation error
//     if (validationError) {
//       setFormValidationError(true);
//     } else {
//       setFormValidationError(false);
//     }
//   }

//   const handleChanges = (fieldName) => (event) => {

//     if (fieldName === "videoName") {
//       // setVideoName(event.target.value);
//       props.setVideoName(event.target.value);
//     } else if (fieldName === "videoUrl") {
//       // setVideoUrl(event.target.value);
//       props.setVideoUrl(event.target.value);
//     } else if (fieldName === "imageName") {
//       // setImageName(event.target.value);
//       props.setImageName(event.target.value);
//     }
//   };

//   return (
//     <>
//       <Grid container spacing={3}>
//         <Grid item xs={12} md={12}>
//           <div className='MediaLibPanelTextField'>
//             <TextField
//               label="Media Name"
//               required={true}
//               value={props.videoName}
//               handleValidation={handleValidation}
//               handleChange={(val, e) => handleChanges("videoName")(e)}
//               placeholder="Media Name"
//               count={50}
//               helperText={props.errors.videoNameError}
//               error={Boolean(props.errors.videoNameError)}
//             />
//           </div>
//         </Grid>

//         <Grid item xs={12} sm={12} md={12}>
//           <div className='MediaLibPanelTextField UploadyoutubeVideo'>
//             <TextField
//               label="YouTube video URL"
//               required={true}
//               // email={true}
//               value={props.videoUrl}
//               handleValidation={handleValidation}
//               handleChange={(val, e) => handleChanges("videoUrl")(e)}
//               placeholder="YouTube Video URL"
//               count={200}
//               helperText={props.errors.url}
//               error={Boolean(props.errors.url)}
//             />
//           </div>
//         </Grid>

//         <Grid item xs={12} sm={12} md={12}>
//           <div className='MediaLibPanelInfoCont'>
//             <div className='MediaLibPanelInfoText'><InfoRoundedIcon /> Where can you find a video URL?</div>
//             <div className='MediaLibPanelInfoList'>1. Start watching a video on<a href=''>youtube.com <LaunchRoundedIcon /></a></div>
//             <div className='MediaLibPanelInfoList'>2. Under the video, click Share <ReplyRoundedIcon className='ReplyIcon' /></div>
//             <div className='MediaLibPanelInfoList'>3. A panel will appear, presenting different sharing options.</div>
//             <div className='MediaLibPanelInfoList'>4. Click the Copy button to copy a link to the video that you can paste in the field above.</div>

//           </div>
//         </Grid>

//       </Grid>
//     </>
//   );
// }

// export default UploadFromYoutube;

import React, { useState, useRef, useEffect } from "react";
import TextField from "../Form/TextField/TextField";
import { Grid } from "@material-ui/core/";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import FileUplaoder from "../Form/FileUplaoder/FileUplaoder";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import ReplyRoundedIcon from "@mui/icons-material/ReplyRounded";
// import CustomizedSnackbar from '../../hooks/Snackbar';
import "./MediaLibrary.css";
import axios from "axios";

function UploadFromYoutube(props) {
  const [formValidationError, setFormValidationError] = useState(false);

  const handleValidation = (validationError) => {
    // set form validation error
    if (validationError) {
      setFormValidationError(true);
    } else {
      setFormValidationError(false);
    }
  };
  const handleChanges = (fieldName) => (event) => {
    // Ensure that event exists and has a target property before accessing event.target
    if (event && event.target) {
      const value = event.target.value;

      if (fieldName === "videoName") {
        // Update the state using props.setVideoName
        props.setVideoName(value);
      } else if (fieldName === "videoUrl") {
        // Update the state using props.setVideoUrl
        props.setVideoUrl(value);
      } else if (fieldName === "imageName") {
        // Update the state using props.setImageName
        props.setImageName(value);
      }
    }
  };

  // const handleChanges = (fieldName) => (event) => {
  //   if (fieldName === "videoName") {
  //     // setVideoName(event.target.value);
  //     console.log(event?.target?.value, 440);
  //     props.setVideoName(event?.target?.value);
  //   } else if (fieldName === "videoUrl") {
  //     // setVideoUrl(event.target.value);
  //     props.setVideoUrl(event?.target?.value);
  //   } else if (fieldName === "imageName") {
  //     // setImageName(event.target.value);
  //     props.setImageName(event?.target?.value);
  //   }
  // };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <div className="MediaLibPanelTextField">
            <TextField
              label="Media Name"
              required={true}
              value={props.videoName}
              handleValidation={handleValidation}
              handleChange={(e) => handleChanges("videoName")(e)}
              placeholder="Media Name"
              count={50}
              helperText={props.errors.videoNameError}
              error={Boolean(props.errors.videoNameError)}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <div className="MediaLibPanelTextField UploadyoutubeVideo">
            <TextField
              label="YouTube video URL"
              required={true}
              // email={true}
              value={props.videoUrl}
              handleValidation={handleValidation}
              handleChange={(e) => handleChanges("videoUrl")(e)}
              placeholder="YouTube Video URL"
              count={200}
              helperText={props.errors.url}
              error={Boolean(props.errors.url)}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <div className="MediaLibPanelInfoCont">
            <div className="MediaLibPanelInfoText">
              <InfoRoundedIcon /> Where can you find a video URL?
            </div>
            <div className="MediaLibPanelInfoList">
              1. Start watching a video on
              <a href="">
                youtube.com <LaunchRoundedIcon />
              </a>
            </div>
            <div className="MediaLibPanelInfoList">
              2. Under the video, click Share{" "}
              <ReplyRoundedIcon className="ReplyIcon" />
            </div>
            <div className="MediaLibPanelInfoList">
              3. A panel will appear, presenting different sharing options.
            </div>
            <div className="MediaLibPanelInfoList">
              4. Click the Copy button to copy a link to the video that you can
              paste in the field above.
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default UploadFromYoutube;
