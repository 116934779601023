import { api } from '../../api/apiSlice';

const categoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCategoryListByOrgId: builder.query({
      query: (orgId) => ({
        url: `categories/public/get-all/${orgId}`,
      }),
      providesTags: ['Category'],
    })

  }),
});

export const {
  useGetCategoryListByOrgIdQuery
} = categoryApi;
